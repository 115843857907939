import React from 'react';

import { injectGlobal } from '@emotion/css';
import { QueryClientProvider } from '@tanstack/react-query';
import 'moment/locale/fr';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import 'react-toastify/dist/ReactToastify.min.css';

import '@/app.worker';
import { FontDefinitions } from '@/assets/fonts';
import { ToastContainer } from '@/components/toast/ToastComponent';
import '@/plugins/i18n';
import { LangsEnum } from '@/plugins/i18n';
import { setDefaultLocale } from '@/plugins/moment';
import { queryClient } from '@/queries/QueryClient';
import { store } from '@/state/store';
import '@/utils/prototypes';
import '@/utils/strings';

import { KeycloakAuth } from './keycloak';
import { Router } from './router/Router';
import './styles/tailwind.scss';

setDefaultLocale(LangsEnum.FR);

const container = document.getElementById('root');
container?.classList.add('h-screen');

createRoot(container as HTMLElement).render(
  <KeycloakAuth>
    <React.StrictMode>
      <ToastContainer style={{ touchAction: 'none' }} />

      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <Router />
        </Provider>
      </QueryClientProvider>
    </React.StrictMode>
  </KeycloakAuth>,
);

injectGlobal`
  ${FontDefinitions.NunitoSans}
`;
