import React from 'react';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { CardTitle } from '@/components/card/CardTitle';
import { Typo } from '@/components/typography/Text';
import { PatientWithCare } from '@/models/PatientWithCareModel';

import { PatientsTagsList } from '../patients/PatientsTagsList';

type RPMInfoProps = {
  patient: PatientWithCare;
};
export const RPMInfo = ({ patient }: RPMInfoProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <Card elevation={0}>
      <CardTitle style={{ justifyContent: 'space-between' }}>
        <Typo Component="h3" type="subtitle">
          {t('components.diabetes_form.telemonitoring')}
        </Typo>
        <div>
          <IconButton
            style={{ marginRight: 0, marginLeft: 0, paddingRight: 0 }}
            icon={Icons.externalLink}
            buttonType="link"
            placement="left"
            onClick={() => navigate(`/patients/${patient.id}/settings`)}
          />
        </div>
      </CardTitle>
      <CardBody>
        <PatientsTagsList patient={patient} hovering={false} isNurse={false} />
      </CardBody>
    </Card>
  );
};
