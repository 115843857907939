import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Images } from '@/assets/images';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { Col, Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';

export type AboutStetoProps = {
  // Empty
};

export const AboutSteto: React.FC<AboutStetoProps> = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card className={styles.container} elevation={0}>
      <TextCardTitle id="about-title" title={t('pages.about.title')} />
      <CardBody className={styles.body}>
        <Col className={styles.content}>
          <Typo id="about-description" type="paragraph">
            {t('pages.about.description')}
          </Typo>
          <Row className={styles.imagesRow}>
            <Images.stetoInfo
              id="steto-info"
              className={styles.image}
              alt="steto-info"
            />
            <Col className={styles.subContent}>
              <Typo id="about-name" type="paragraph">
                {t('pages.about.name')}
              </Typo>
              <Typo id="about-address" type="paragraph">
                {t('pages.about.address')}
              </Typo>
              <Typo id="about-postCode" type="paragraph">
                {t('pages.about.postCode')}
              </Typo>
              <Typo id="about-rcs" type="paragraph">
                {t('pages.about.rcs')}
              </Typo>
              <Typo id="about-class" type="paragraph">
                {t('pages.about.class')}
              </Typo>
              <Icons.ce id="about-ce-logo" className={styles.icon} />
            </Col>
          </Row>
        </Col>
      </CardBody>
    </Card>
  );
};

const makeStyles = () => ({
  container: css`
    margin-top: ${Dimensions.M};
  `,
  body: css`
    align-items: center;
  `,
  content: css`
    width: 100%;
  `,
  imagesRow: css`
    align-items: center;
    justify-content: center;
    margin: ${Dimensions.S} 0;
  `,
  subContent: css`
    width: min(300px, 45%);
    align-items: center;
  `,
  image: css`
    width: min(300px, 45%);
  `,
  icon: css`
    height: 3em;
    margin-top: ${Dimensions.S};
  `,
});
