import React from 'react';

import { css, cx } from '@emotion/css';

import { Icons } from '@/assets/icons';
import { useStyles } from '@/hooks/useTheme';
import { AlertCriticity } from '@/models/AlertModel';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

type AlertSize = 'S' | 'M' | 'L';

export type AlertIconNotificationProps = {
  criticity: AlertCriticity;
  size: AlertSize;
};

export const AlertIconNotification: React.FC<AlertIconNotificationProps> = ({
  size,
  criticity,
}) => {
  const styles = useStyles(makeStyles);

  switch (criticity) {
    case 'low':
      return (
        <Icons.alertCircle
          className={cx(styles[`icon${size}`], styles[criticity])}
        />
      );
    case 'medium':
      return (
        <Icons.alertTriangle
          className={cx(styles[`icon${size}`], styles[criticity])}
        />
      );
    case 'high':
      return (
        <Icons.alertTriangle
          className={cx(styles[`icon${size}`], styles[criticity])}
        />
      );
  }
};

const makeStyles = (theme: Theme) => ({
  iconS: css`
    width: ${Dimensions.font.S};
    height: ${Dimensions.font.S};
  `,
  iconM: css`
    width: ${Dimensions.font.M};
    height: ${Dimensions.font.M};
  `,
  iconL: css`
    width: ${Dimensions.font.L};
    height: ${Dimensions.font.L};
  `,
  low: css`
    fill: ${theme.typography.warning};
  `,
  medium: css`
    fill: ${theme.typography.warning};
  `,
  high: css`
    fill: ${theme.typography.error};
  `,
});
