import React, { ReactElement, ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { useId } from '@floating-ui/react';
import { UseFormReturn, useController } from 'react-hook-form';
import { FieldPath } from 'react-hook-form/dist/types';
import { FieldValues } from 'react-hook-form/dist/types/fields';
import { RegisterOptions } from 'react-hook-form/dist/types/validator';

import { IconType, Icons } from '@/assets/icons';
import { Tooltip } from '@/components/floating/Tooltip';
import { Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { FileToUpload } from '@/models/FileModel';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';
import { SupportedMimes } from '@/utils/files';

export type BaseControlledFileFormInputProps<T extends FieldValues> = {
  name: FieldPath<T>;
  context: UseFormReturn<T>;
  inputProps: Omit<
    RegisterOptions<T, FieldPath<T>>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs'
  >;
  accepts: SupportedMimes[];
  label?: string | ReactElement;
  labelTooltip?: ReactNode;
  className?: string;
  labelStyle?: string;
  Icon?: IconType;
  iconStyle?: string;
  noFileText?: string;
};

export const BaseFileFormInput = <T extends FieldValues>({
  name,
  context,
  inputProps: { disabled, ...inputProps },
  accepts,
  label,
  className,
  labelTooltip,
  labelStyle,
  Icon: PropsIcon,
  iconStyle,
  noFileText,
}: BaseControlledFileFormInputProps<T>): ReactElement => {
  const inputController = useController({
    name,
    control: context.control,
    defaultValue: undefined,
    rules: inputProps,
    disabled: disabled,
  });
  const styles = useStyles(makeStyles);
  const id = useId();
  const { value, onChange, ...field } = inputController.field;
  const Icon = PropsIcon ?? Icons.fileUpload;
  return (
    <fieldset className={className}>
      {label ? (
        <legend>
          <Row align="center" className={styles.label}>
            <Typo type="subtitle" className={labelStyle}>
              {label}
            </Typo>
            {labelTooltip ? (
              <Tooltip placement="bottom" content={labelTooltip}>
                <Icons.questionCircle className={styles.tooltipIcon} />
              </Tooltip>
            ) : null}
          </Row>
        </legend>
      ) : null}
      <input
        id={id}
        type="file"
        accept={accepts.join(', ')}
        style={{ display: 'none' }}
        onChange={event => {
          const file =
            event.target.files && event.target.files.length > 0
              ? event.target.files.item(0)
              : undefined;
          if (file) {
            const newFile: FileToUpload = {
              file: file,
              contentType: file.type as SupportedMimes,
              name: file.name,
              size: file.size,
            };
            onChange(newFile);
          }
        }}
        name={field.name}
      />
      <label htmlFor={id}>
        <Row className={styles.input}>
          <Icon className={cx(styles.icon, iconStyle)} />
          {value?.name ? (
            <Typo type="paragraph">{value.name}</Typo>
          ) : (
            <Typo type="novalue" italic={false}>
              {noFileText}
            </Typo>
          )}
        </Row>
      </label>
    </fieldset>
  );
};

const makeStyles = (theme: Theme) => ({
  hidden: css`
    display: none;
  `,
  input: css`
    cursor: pointer;
    border-radius: ${theme.input.borderRadius};
    border: solid ${theme.input.border} ${theme.input.borderWidth};
    padding: ${theme.input.paddingVertical} ${theme.input.paddingHorizontal};
  `,
  icon: css`
    width: ${Dimensions.font.XXL};
    height: ${Dimensions.font.XXL};
    margin-right: ${theme.input.paddingHorizontal};
  `,
  tooltipIcon: css`
    width: ${Dimensions.font.XS};
    height: ${Dimensions.font.XS};
    margin-left: ${Dimensions.XS};
  `,
  text: css`
    white-space: pre-wrap;
  `,
  label: css`
    margin-bottom: ${theme.input.paddingVertical};
  `,
});
