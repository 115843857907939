import { string } from 'typescript-json-decoder';

export const INSEE_FRENCH_COUNTRY = {
  France: {
    name: 'France',
    code: undefined,
    fullName: 'République Française',
  },
  'Clipperton (Ile)': {
    name: 'Clipperton (Ile)',
    code: undefined,
    fullName: 'Clipperton (ÎLe)',
  },
  Mayotte: {
    name: 'Mayotte',
    code: undefined,
    fullName: 'Département De Mayotte',
  },
  'Wallis-et-futuna': {
    name: 'Wallis-et-futuna',
    code: undefined,
    fullName: 'Wallis-et-futuna',
  },
  'Terres Australes Francaises': {
    name: 'Terres Australes Francaises',
    code: undefined,
    fullName: 'Terres Australes Françaises',
  },
  'La Reunion': {
    name: 'La Reunion',
    code: undefined,
    fullName: 'Département De La Réunion',
  },
  'Saint-pierre-et-miquelon': {
    name: 'Saint-pierre-et-miquelon',
    code: undefined,
    fullName: 'Collectivité Territoriale De Saint-pierre-et-miquelon',
  },
  'Polynesie Francaise': {
    name: 'Polynesie Francaise',
    code: undefined,
    fullName: 'Polynésie Française',
  },
  'Nouvelle-caledonie': {
    name: 'Nouvelle-caledonie',
    code: undefined,
    fullName: 'Nouvelle-calédonie',
  },
  Martinique: {
    name: 'Martinique',
    code: undefined,
    fullName: 'Département De La Martinique',
  },
  'Saint-martin': {
    name: 'Saint-martin',
    code: undefined,
    fullName: "Collectivité D'outre-mer De Saint-martin",
  },
  Guadeloupe: {
    name: 'Guadeloupe',
    code: undefined,
    fullName: 'Département De La Guadeloupe',
  },
  Guyane: {
    name: 'Guyane',
    code: undefined,
    fullName: 'Département De La Guyane',
  },
  'Saint-barthelemy': {
    name: 'Saint-barthelemy',
    code: undefined,
    fullName: "Collectivité D'outre-mer De Saint-barthélemy",
  },
} as const;

export const INSEE_FOREIGN_COUNTRIES = {
  Danemark: {
    name: 'Danemark',
    code: '99101',
    fullName: 'Royaume Du Danemark',
  },
  'Feroe (Iles)': {
    name: 'Feroe (Iles)',
    code: '99101',
    fullName: 'Féroé (ÎLes)',
  },
  Islande: {
    name: 'Islande',
    code: '99102',
    fullName: 'Islande',
  },
  Norvege: {
    name: 'Norvege',
    code: '99103',
    fullName: 'Royaume De Norvège',
  },
  'Svalbard Et Ile Jan Mayen': {
    name: 'Svalbard Et Ile Jan Mayen',
    code: '99103',
    fullName: 'Svalbard Et ÎLe Jan Mayen',
  },
  'Bouvet (Ile)': {
    name: 'Bouvet (Ile)',
    code: '99103',
    fullName: 'Bouvet (ÎLe)',
  },
  Suede: {
    name: 'Suede',
    code: '99104',
    fullName: 'Royaume De Suède',
  },
  Finlande: {
    name: 'Finlande',
    code: '99105',
    fullName: 'République De Finlande',
  },
  Estonie: {
    name: 'Estonie',
    code: '99106',
    fullName: "République D'estonie",
  },
  Lettonie: {
    name: 'Lettonie',
    code: '99107',
    fullName: 'République De Lettonie',
  },
  Lituanie: {
    name: 'Lituanie',
    code: '99108',
    fullName: 'République De Lituanie',
  },
  Allemagne: {
    name: 'Allemagne',
    code: '99109',
    fullName: "République Fédérale D'allemagne",
  },
  Autriche: {
    name: 'Autriche',
    code: '99110',
    fullName: "République D'autriche",
  },
  Bulgarie: {
    name: 'Bulgarie',
    code: '99111',
    fullName: 'République De Bulgarie',
  },
  Hongrie: {
    name: 'Hongrie',
    code: '99112',
    fullName: 'République De Hongrie',
  },
  Liechtenstein: {
    name: 'Liechtenstein',
    code: '99113',
    fullName: 'Principauté De Liechtenstein',
  },
  Roumanie: {
    name: 'Roumanie',
    code: '99114',
    fullName: 'Roumanie',
  },
  Tchecoslovaquie: {
    name: 'Tchecoslovaquie',
    code: '99115',
    fullName: 'Tchécoslovaquie',
  },
  Tchequie: {
    name: 'Tchequie',
    code: '99116',
    fullName: 'République Tchèque',
  },
  Slovaquie: {
    name: 'Slovaquie',
    code: '99117',
    fullName: 'République Slovaque',
  },
  'Bosnie-herzegovine': {
    name: 'Bosnie-herzegovine',
    code: '99118',
    fullName: 'République De Bosnie-herzégovine',
  },
  Croatie: {
    name: 'Croatie',
    code: '99119',
    fullName: 'République De Croatie',
  },
  Montenegro: {
    name: 'Montenegro',
    code: '99120',
    fullName: 'République Du Monténégro',
  },
  Serbie: {
    name: 'Serbie',
    code: '99121',
    fullName: 'République De Serbie',
  },
  Pologne: {
    name: 'Pologne',
    code: '99122',
    fullName: 'République De Pologne',
  },
  Russie: {
    name: 'Russie',
    code: '99123',
    fullName: 'Fédération De Russie',
  },
  "Turquie D'europe": {
    name: "Turquie D'europe",
    code: '99124',
    fullName: "Turquie D'europe",
  },
  Albanie: {
    name: 'Albanie',
    code: '99125',
    fullName: "République D'albanie",
  },
  Grece: {
    name: 'Grece',
    code: '99126',
    fullName: 'République Hellénique',
  },
  Italie: {
    name: 'Italie',
    code: '99127',
    fullName: 'République Italienne',
  },
  'Saint-marin': {
    name: 'Saint-marin',
    code: '99128',
    fullName: 'République De Saint-marin',
  },
  'Vatican, Ou Saint-siege': {
    name: 'Vatican, Ou Saint-siege',
    code: '99129',
    fullName: 'ÉTat De La Cité Du Vatican',
  },
  Andorre: {
    name: 'Andorre',
    code: '99130',
    fullName: "Principauté D'andorre",
  },
  Belgique: {
    name: 'Belgique',
    code: '99131',
    fullName: 'Royaume De Belgique',
  },
  'Royaume-uni': {
    name: 'Royaume-uni',
    code: '99132',
    fullName: "Royaume-uni De Grande-bretagne Et D'irlande Du Nord",
  },
  Jersey: {
    name: 'Jersey',
    code: '99132',
    fullName: 'Jersey',
  },
  'Man (Ile)': {
    name: 'Man (Ile)',
    code: '99132',
    fullName: 'Man (ÎLe)',
  },
  Guernesey: {
    name: 'Guernesey',
    code: '99132',
    fullName: 'Guernesey',
  },
  Gibraltar: {
    name: 'Gibraltar',
    code: '99133',
    fullName: 'Gibraltar',
  },
  Espagne: {
    name: 'Espagne',
    code: '99134',
    fullName: "Royaume D'espagne",
  },
  'Pays-bas': {
    name: 'Pays-bas',
    code: '99135',
    fullName: 'Royaume Des Pays-bas',
  },
  Aruba: {
    name: 'Aruba',
    code: '99135',
    fullName: 'Aruba',
  },
  'Irlande, Ou Eire': {
    name: 'Irlande, Ou Eire',
    code: '99136',
    fullName: 'Irlande',
  },
  Luxembourg: {
    name: 'Luxembourg',
    code: '99137',
    fullName: 'Grand-duché De Luxembourg',
  },
  Monaco: {
    name: 'Monaco',
    code: '99138',
    fullName: 'Principauté De Monaco',
  },
  Portugal: {
    name: 'Portugal',
    code: '99139',
    fullName: 'République Portugaise',
  },
  Suisse: {
    name: 'Suisse',
    code: '99140',
    fullName: 'Confédération Suisse',
  },
  'Republique Democratique Allemande': {
    name: 'Republique Democratique Allemande',
    code: '99141',
    fullName: 'République Démocratique Allemande',
  },
  "Republique Federale D'allemagne": {
    name: "Republique Federale D'allemagne",
    code: '99142',
    fullName: "République Fédérale D'allemagne",
  },
  Malte: {
    name: 'Malte',
    code: '99144',
    fullName: 'République De Malte',
  },
  Slovenie: {
    name: 'Slovenie',
    code: '99145',
    fullName: 'République De Slovénie',
  },
  Bielorussie: {
    name: 'Bielorussie',
    code: '99148',
    fullName: 'République De Biélorussie',
  },
  Moldavie: {
    name: 'Moldavie',
    code: '99151',
    fullName: 'République De Moldavie',
  },
  Ukraine: {
    name: 'Ukraine',
    code: '99155',
    fullName: 'Ukraine',
  },
  'Macedoine Du Nord': {
    name: 'Macedoine Du Nord',
    code: '99156',
    fullName: 'Macédoine Du Nord',
  },
  Kosovo: {
    name: 'Kosovo',
    code: '99157',
    fullName: 'République Du Kosovo',
  },
  'Arabie Saoudite': {
    name: 'Arabie Saoudite',
    code: '99201',
    fullName: "Royaume D'arabie Saoudite",
  },
  'Yemen (Republique Arabe Du)': {
    name: 'Yemen (Republique Arabe Du)',
    code: '99202',
    fullName: 'Yémen (République Arabe Du)',
  },
  Iraq: {
    name: 'Iraq',
    code: '99203',
    fullName: "République D'iraq",
  },
  Iran: {
    name: 'Iran',
    code: '99204',
    fullName: "République Islamique D'iran",
  },
  Liban: {
    name: 'Liban',
    code: '99205',
    fullName: 'République Libanaise',
  },
  Syrie: {
    name: 'Syrie',
    code: '99206',
    fullName: 'République Arabe Syrienne',
  },
  Israel: {
    name: 'Israel',
    code: '99207',
    fullName: "ÉTat D'israël",
  },
  Turquie: {
    name: 'Turquie',
    code: '99208',
    fullName: 'République Turque',
  },
  Siberie: {
    name: 'Siberie',
    code: '99209',
    fullName: 'Sibérie',
  },
  'Turkestan Russe': {
    name: 'Turkestan Russe',
    code: '99210',
    fullName: 'Turkestan Russe',
  },
  Kamtchatka: {
    name: 'Kamtchatka',
    code: '99211',
    fullName: 'Kamtchatka',
  },
  Afghanistan: {
    name: 'Afghanistan',
    code: '99212',
    fullName: "ÉTat Islamique D'afghanistan",
  },
  Pakistan: {
    name: 'Pakistan',
    code: '99213',
    fullName: 'République Islamique Du Pakistan',
  },
  Bhoutan: {
    name: 'Bhoutan',
    code: '99214',
    fullName: 'Royaume Du Bhoutan',
  },
  Nepal: {
    name: 'Nepal',
    code: '99215',
    fullName: 'Népal',
  },
  Chine: {
    name: 'Chine',
    code: '99216',
    fullName: 'République Populaire De Chine',
  },
  Japon: {
    name: 'Japon',
    code: '99217',
    fullName: 'Japon',
  },
  Mandchourie: {
    name: 'Mandchourie',
    code: '99218',
    fullName: 'Mandchourie',
  },
  Thailande: {
    name: 'Thailande',
    code: '99219',
    fullName: 'Royaume De Thaïlande',
  },
  Philippines: {
    name: 'Philippines',
    code: '99220',
    fullName: 'République Des Philippines',
  },
  'Possessions Britanniques Au Proche-orient': {
    name: 'Possessions Britanniques Au Proche-orient',
    code: '99221',
    fullName: 'Possessions Britanniques Au Proche-orient',
  },
  Jordanie: {
    name: 'Jordanie',
    code: '99222',
    fullName: 'Royaume Hachémite De Jordanie',
  },
  Inde: {
    name: 'Inde',
    code: '99223',
    fullName: "République De L'inde",
  },
  Goa: {
    name: 'Goa',
    code: '99223',
    fullName: 'Goa',
  },
  Birmanie: {
    name: 'Birmanie',
    code: '99224',
    fullName: 'Union De Birmanie',
  },
  Brunei: {
    name: 'Brunei',
    code: '99225',
    fullName: 'Negara Brunei Darussalam',
  },
  Singapour: {
    name: 'Singapour',
    code: '99226',
    fullName: 'République De Singapour',
  },
  Malaisie: {
    name: 'Malaisie',
    code: '99227',
    fullName: 'Malaisie',
  },
  'Etats Malais Non Federes': {
    name: 'Etats Malais Non Federes',
    code: '99228',
    fullName: 'ÉTats Malais Non Fédérés',
  },
  Maldives: {
    name: 'Maldives',
    code: '99229',
    fullName: 'République Des Maldives',
  },
  'Hong-kong': {
    name: 'Hong-kong',
    code: '99230',
    fullName: 'Hong-kong',
  },
  Indonesie: {
    name: 'Indonesie',
    code: '99231',
    fullName: "République D'indonésie",
  },
  Macao: {
    name: 'Macao',
    code: '99232',
    fullName: 'Macao',
  },
  'Yemen Democratique': {
    name: 'Yemen Democratique',
    code: '99233',
    fullName: 'Yémen Démocratique',
  },
  Cambodge: {
    name: 'Cambodge',
    code: '99234',
    fullName: 'Royaume Du Cambodge',
  },
  'Sri Lanka': {
    name: 'Sri Lanka',
    code: '99235',
    fullName: 'République Démocratique Socialiste Du Sri Lanka',
  },
  Taiwan: {
    name: 'Taiwan',
    code: '99236',
    fullName: 'Taïwan',
  },
  Coree: {
    name: 'Coree',
    code: '99237',
    fullName: 'Corée',
  },
  'Coree (Republique Populaire Democratique De)': {
    name: 'Coree (Republique Populaire Democratique De)',
    code: '99238',
    fullName: 'République Populaire Démocratique De Corée',
  },
  'Coree (Republique De)': {
    name: 'Coree (Republique De)',
    code: '99239',
    fullName: 'République De Corée',
  },
  Koweit: {
    name: 'Koweit',
    code: '99240',
    fullName: 'ÉTat Du Koweït',
  },
  Laos: {
    name: 'Laos',
    code: '99241',
    fullName: 'République Démocratique Populaire Lao',
  },
  Mongolie: {
    name: 'Mongolie',
    code: '99242',
    fullName: 'Mongolie',
  },
  'Viet Nam': {
    name: 'Viet Nam',
    code: '99243',
    fullName: 'République Socialiste Du Viêt Nam',
  },
  'Viet Nam Du Nord': {
    name: 'Viet Nam Du Nord',
    code: '99244',
    fullName: 'Viêt Nam Du Nord',
  },
  'Viet Nam Du Sud': {
    name: 'Viet Nam Du Sud',
    code: '99245',
    fullName: 'Viêt Nam Du Sud',
  },
  Bangladesh: {
    name: 'Bangladesh',
    code: '99246',
    fullName: 'République Populaire Du Bangladesh',
  },
  'Emirats Arabes Unis': {
    name: 'Emirats Arabes Unis',
    code: '99247',
    fullName: 'ÉMirats Arabes Unis',
  },
  Qatar: {
    name: 'Qatar',
    code: '99248',
    fullName: 'ÉTat Du Qatar',
  },
  Bahrein: {
    name: 'Bahrein',
    code: '99249',
    fullName: 'ÉTat De Bahreïn',
  },
  Oman: {
    name: 'Oman',
    code: '99250',
    fullName: "Sultanat D'oman",
  },
  Yemen: {
    name: 'Yemen',
    code: '99251',
    fullName: 'République Du Yémen',
  },
  Armenie: {
    name: 'Armenie',
    code: '99252',
    fullName: "République D'arménie",
  },
  Azerbaidjan: {
    name: 'Azerbaidjan',
    code: '99253',
    fullName: 'République Azerbaïdjanaise',
  },
  Chypre: {
    name: 'Chypre',
    code: '99254',
    fullName: 'République De Chypre',
  },
  Georgie: {
    name: 'Georgie',
    code: '99255',
    fullName: 'République De Géorgie',
  },
  Kazakhstan: {
    name: 'Kazakhstan',
    code: '99256',
    fullName: 'République Du Kazakhstan',
  },
  Kirghizistan: {
    name: 'Kirghizistan',
    code: '99257',
    fullName: 'République Kirghize',
  },
  Ouzbekistan: {
    name: 'Ouzbekistan',
    code: '99258',
    fullName: "République D'ouzbékistan",
  },
  Tadjikistan: {
    name: 'Tadjikistan',
    code: '99259',
    fullName: 'République Du Tadjikistan',
  },
  Turkmenistan: {
    name: 'Turkmenistan',
    code: '99260',
    fullName: 'Turkménistan',
  },
  'Palestine (Etat De)': {
    name: 'Palestine (Etat De)',
    code: '99261',
    fullName: 'ÉTat De Palestine',
  },
  'Timor Oriental': {
    name: 'Timor Oriental',
    code: '99262',
    fullName: 'République Démocratique Du Timor Oriental',
  },
  Egypte: {
    name: 'Egypte',
    code: '99301',
    fullName: "République Arabe D'égypte",
  },
  Liberia: {
    name: 'Liberia',
    code: '99302',
    fullName: 'République Du Liberia',
  },
  'Afrique Du Sud': {
    name: 'Afrique Du Sud',
    code: '99303',
    fullName: "République D'afrique Du Sud",
  },
  Gambie: {
    name: 'Gambie',
    code: '99304',
    fullName: 'République De Gambie',
  },
  'Cameroun Et Togo': {
    name: 'Cameroun Et Togo',
    code: '99305',
    fullName: 'Cameroun Et Togo',
  },
  'Sainte Helene, Ascension Et Tristan Da Cunha': {
    name: 'Sainte Helene, Ascension Et Tristan Da Cunha',
    code: '99306',
    fullName: 'Sainte-hélène, Ascension Et Tristan Da Cunha',
  },
  'Soudan Anglo-egyptien, Kenya, Ouganda': {
    name: 'Soudan Anglo-egyptien, Kenya, Ouganda',
    code: '99307',
    fullName: 'Soudan Anglo-égyptien, Kenya, Ouganda',
  },
  Zanzibar: {
    name: 'Zanzibar',
    code: '99308',
    fullName: 'Zanzibar',
  },
  "Ocean Indien (Territoire Britannique De L')": {
    name: "Ocean Indien (Territoire Britannique De L')",
    code: '99308',
    fullName: "Océan Indien (Territoire Britannique De L')",
  },
  Tanzanie: {
    name: 'Tanzanie',
    code: '99309',
    fullName: 'République Unie De Tanzanie',
  },
  Zimbabwe: {
    name: 'Zimbabwe',
    code: '99310',
    fullName: 'République Du Zimbabwe',
  },
  Namibie: {
    name: 'Namibie',
    code: '99311',
    fullName: 'République De Namibie',
  },
  'Congo (Republique Democratique)': {
    name: 'Congo (Republique Democratique)',
    code: '99312',
    fullName: 'République Démocratique Du Congo',
  },
  "Provinces Espagnoles D'afrique": {
    name: "Provinces Espagnoles D'afrique",
    code: '99313',
    fullName: "Provinces Espagnoles D'afrique",
  },
  Presides: {
    name: 'Presides',
    code: '99313',
    fullName: 'Présides',
  },
  'Canaries (Iles)': {
    name: 'Canaries (Iles)',
    code: '99313',
    fullName: 'Canaries (ÎLes)',
  },
  'Guinee Equatoriale': {
    name: 'Guinee Equatoriale',
    code: '99314',
    fullName: 'République De Guinée ÉQuatoriale',
  },
  Ethiopie: {
    name: 'Ethiopie',
    code: '99315',
    fullName: "République Démocratique Fédérale D'éthiopie",
  },
  Libye: {
    name: 'Libye',
    code: '99316',
    fullName: 'Jamahiriya Arabe Libyenne Populaire Et Socialiste',
  },
  Erythree: {
    name: 'Erythree',
    code: '99317',
    fullName: "ÉTat D'érythrée",
  },
  Somalie: {
    name: 'Somalie',
    code: '99318',
    fullName: 'Somalie',
  },
  'Acores, Madere': {
    name: 'Acores, Madere',
    code: '99319',
    fullName: 'Açores, Madère',
  },
  "Iles Portugaises De L'ocean Indien": {
    name: "Iles Portugaises De L'ocean Indien",
    code: '99320',
    fullName: "ÎLes Portugaises De L'océan Indien",
  },
  Burundi: {
    name: 'Burundi',
    code: '99321',
    fullName: 'République Du Burundi',
  },
  Cameroun: {
    name: 'Cameroun',
    code: '99322',
    fullName: 'République Du Cameroun',
  },
  'Centrafricaine (Republique)': {
    name: 'Centrafricaine (Republique)',
    code: '99323',
    fullName: 'République Centrafricaine',
  },
  Congo: {
    name: 'Congo',
    code: '99324',
    fullName: 'République Du Congo',
  },
  Tanger: {
    name: 'Tanger',
    code: '99325',
    fullName: 'Tanger',
  },
  "Cote D'ivoire": {
    name: "Cote D'ivoire",
    code: '99326',
    fullName: "République De Côte D'ivoire",
  },
  Benin: {
    name: 'Benin',
    code: '99327',
    fullName: 'République Du Bénin',
  },
  Gabon: {
    name: 'Gabon',
    code: '99328',
    fullName: 'République Gabonaise',
  },
  Ghana: {
    name: 'Ghana',
    code: '99329',
    fullName: 'République Du Ghana',
  },
  Guinee: {
    name: 'Guinee',
    code: '99330',
    fullName: 'République De Guinée',
  },
  Burkina: {
    name: 'Burkina',
    code: '99331',
    fullName: 'Burkina Faso',
  },
  Kenya: {
    name: 'Kenya',
    code: '99332',
    fullName: 'République Du Kenya',
  },
  Madagascar: {
    name: 'Madagascar',
    code: '99333',
    fullName: 'République De Madagascar',
  },
  Malawi: {
    name: 'Malawi',
    code: '99334',
    fullName: 'République Du Malawi',
  },
  Mali: {
    name: 'Mali',
    code: '99335',
    fullName: 'République Du Mali',
  },
  Mauritanie: {
    name: 'Mauritanie',
    code: '99336',
    fullName: 'République Islamique De Mauritanie',
  },
  Niger: {
    name: 'Niger',
    code: '99337',
    fullName: 'République Du Niger',
  },
  Nigeria: {
    name: 'Nigeria',
    code: '99338',
    fullName: 'République Fédérale Du Nigéria',
  },
  Ouganda: {
    name: 'Ouganda',
    code: '99339',
    fullName: "République De L'ouganda",
  },
  Rwanda: {
    name: 'Rwanda',
    code: '99340',
    fullName: 'République Rwandaise',
  },
  Senegal: {
    name: 'Senegal',
    code: '99341',
    fullName: 'République Du Sénégal',
  },
  'Sierra Leone': {
    name: 'Sierra Leone',
    code: '99342',
    fullName: 'République De Sierra Leone',
  },
  Soudan: {
    name: 'Soudan',
    code: '99343',
    fullName: 'République Du Soudan',
  },
  Tchad: {
    name: 'Tchad',
    code: '99344',
    fullName: 'République Du Tchad',
  },
  Togo: {
    name: 'Togo',
    code: '99345',
    fullName: 'République Togolaise',
  },
  Zambie: {
    name: 'Zambie',
    code: '99346',
    fullName: 'République De Zambie',
  },
  Botswana: {
    name: 'Botswana',
    code: '99347',
    fullName: 'République Du Botswana',
  },
  Lesotho: {
    name: 'Lesotho',
    code: '99348',
    fullName: 'Royaume Du Lesotho',
  },
  'Soudan Du Sud': {
    name: 'Soudan Du Sud',
    code: '99349',
    fullName: 'République Du Soudan Du Sud',
  },
  Maroc: {
    name: 'Maroc',
    code: '99350',
    fullName: 'Royaume Du Maroc',
  },
  Tunisie: {
    name: 'Tunisie',
    code: '99351',
    fullName: 'République Tunisienne',
  },
  Algerie: {
    name: 'Algerie',
    code: '99352',
    fullName: 'République Algérienne Démocratique Et Populaire',
  },
  'Sahara Occidental': {
    name: 'Sahara Occidental',
    code: '99389',
    fullName: 'Sahara Occidental',
  },
  Maurice: {
    name: 'Maurice',
    code: '99390',
    fullName: 'République De Maurice',
  },
  Eswatini: {
    name: 'Eswatini',
    code: '99391',
    fullName: "Royaume D'eswatini",
  },
  'Guinee-bissau': {
    name: 'Guinee-bissau',
    code: '99392',
    fullName: 'République De Guinée-bissau',
  },
  Mozambique: {
    name: 'Mozambique',
    code: '99393',
    fullName: 'République Du Mozambique',
  },
  'Sao Tome-et-principe': {
    name: 'Sao Tome-et-principe',
    code: '99394',
    fullName: 'République Démocratique De Sao Tomé-et-principe',
  },
  Angola: {
    name: 'Angola',
    code: '99395',
    fullName: "République D'angola",
  },
  'Cap-vert': {
    name: 'Cap-vert',
    code: '99396',
    fullName: 'République Du Cap-vert',
  },
  Comores: {
    name: 'Comores',
    code: '99397',
    fullName: 'République Fédérale Islamique Des Comores',
  },
  Seychelles: {
    name: 'Seychelles',
    code: '99398',
    fullName: 'République Des Seychelles',
  },
  Djibouti: {
    name: 'Djibouti',
    code: '99399',
    fullName: 'République De Djibouti',
  },
  Canada: {
    name: 'Canada',
    code: '99401',
    fullName: 'Canada',
  },
  'Terre-neuve': {
    name: 'Terre-neuve',
    code: '99402',
    fullName: 'Terre-neuve',
  },
  Labrador: {
    name: 'Labrador',
    code: '99403',
    fullName: 'Labrador',
  },
  'Etats-unis': {
    name: 'Etats-unis',
    code: '99404',
    fullName: "ÉTats-unis D'amérique",
  },
  Alaska: {
    name: 'Alaska',
    code: '99404',
    fullName: 'Alaska',
  },
  Mexique: {
    name: 'Mexique',
    code: '99405',
    fullName: 'ÉTats-unis Du Mexique',
  },
  'Costa Rica': {
    name: 'Costa Rica',
    code: '99406',
    fullName: 'République Du Costa Rica',
  },
  Cuba: {
    name: 'Cuba',
    code: '99407',
    fullName: 'République De Cuba',
  },
  'Dominicaine (Republique)': {
    name: 'Dominicaine (Republique)',
    code: '99408',
    fullName: 'République Dominicaine',
  },
  Guatemala: {
    name: 'Guatemala',
    code: '99409',
    fullName: 'République Du Guatemala',
  },
  Haiti: {
    name: 'Haiti',
    code: '99410',
    fullName: "République D'haïti",
  },
  Honduras: {
    name: 'Honduras',
    code: '99411',
    fullName: 'République Du Honduras',
  },
  Nicaragua: {
    name: 'Nicaragua',
    code: '99412',
    fullName: 'République Du Nicaragua',
  },
  Panama: {
    name: 'Panama',
    code: '99413',
    fullName: 'République Du Panama',
  },
  'El Salvador': {
    name: 'El Salvador',
    code: '99414',
    fullName: 'République Du Salvador',
  },
  Argentine: {
    name: 'Argentine',
    code: '99415',
    fullName: 'République Argentine',
  },
  Bresil: {
    name: 'Bresil',
    code: '99416',
    fullName: 'République Fédérative Du Brésil',
  },
  Chili: {
    name: 'Chili',
    code: '99417',
    fullName: 'République Du Chili',
  },
  Bolivie: {
    name: 'Bolivie',
    code: '99418',
    fullName: 'ÉTat Plurinational De Bolivie',
  },
  Colombie: {
    name: 'Colombie',
    code: '99419',
    fullName: 'République De Colombie',
  },
  Equateur: {
    name: 'Equateur',
    code: '99420',
    fullName: "République De L'équateur",
  },
  Paraguay: {
    name: 'Paraguay',
    code: '99421',
    fullName: 'République Du Paraguay',
  },
  Perou: {
    name: 'Perou',
    code: '99422',
    fullName: 'République Du Pérou',
  },
  Uruguay: {
    name: 'Uruguay',
    code: '99423',
    fullName: "République Orientale De L'uruguay",
  },
  Venezuela: {
    name: 'Venezuela',
    code: '99424',
    fullName: 'République Bolivarienne Du Venezuela',
  },
  'Territoires Du Royaume-uni Aux Antilles': {
    name: 'Territoires Du Royaume-uni Aux Antilles',
    code: '99425',
    fullName: 'Territoires Du Royaume-uni Aux Antilles',
  },
  'Vierges Britanniques (Iles)': {
    name: 'Vierges Britanniques (Iles)',
    code: '99425',
    fullName: 'Vierges Britanniques (ÎLes)',
  },
  'Turks Et Caiques (Iles)': {
    name: 'Turks Et Caiques (Iles)',
    code: '99425',
    fullName: 'Turks Et Caïques (ÎLes)',
  },
  Montserrat: {
    name: 'Montserrat',
    code: '99425',
    fullName: 'Montserrat',
  },
  'Caimanes (Iles)': {
    name: 'Caimanes (Iles)',
    code: '99425',
    fullName: 'Caïmanes (ÎLes)',
  },
  Bermudes: {
    name: 'Bermudes',
    code: '99425',
    fullName: 'Bermudes',
  },
  Anguilla: {
    name: 'Anguilla',
    code: '99425',
    fullName: 'Anguilla',
  },
  Jamaique: {
    name: 'Jamaique',
    code: '99426',
    fullName: 'Jamaïque',
  },
  "Terr. Du Royaume-uni Dans L'atlantique Sud": {
    name: "Terr. Du Royaume-uni Dans L'atlantique Sud",
    code: '99427',
    fullName: "Terr. Du Royaume-uni Dans L'atlantique_sud",
  },
  'Georgie Du Sud Et Les Iles Sandwich Du Sud': {
    name: 'Georgie Du Sud Et Les Iles Sandwich Du Sud',
    code: '99427',
    fullName: 'Géorgie Du Sud Et Les ÎLes Sandwich Du Sud',
  },
  'Malouines, Ou Falkland (Iles)': {
    name: 'Malouines, Ou Falkland (Iles)',
    code: '99427',
    fullName: 'Malouines, Ou Falkland (ÎLes)',
  },
  Guyana: {
    name: 'Guyana',
    code: '99428',
    fullName: 'République Coopérative De Guyana',
  },
  Belize: {
    name: 'Belize',
    code: '99429',
    fullName: 'Belize',
  },
  Groenland: {
    name: 'Groenland',
    code: '99430',
    fullName: 'Groenland',
  },
  'Antilles Neerlandaises': {
    name: 'Antilles Neerlandaises',
    code: '99431',
    fullName: 'Antilles Néerlandaises',
  },
  "Terr. Des Etats-unis D'amerique En Amerique": {
    name: "Terr. Des Etats-unis D'amerique En Amerique",
    code: '99432',
    fullName: "Terr. Des ÉTats-unis D'amérique En Amérique",
  },
  'Vierges Des Etats-unis (Iles)': {
    name: 'Vierges Des Etats-unis (Iles)',
    code: '99432',
    fullName: 'Vierges Des ÉTats-unis (ÎLes)',
  },
  'Porto Rico': {
    name: 'Porto Rico',
    code: '99432',
    fullName: 'Porto Rico',
  },
  'Trinite-et-tobago': {
    name: 'Trinite-et-tobago',
    code: '99433',
    fullName: 'République De Trinité-et-tobago',
  },
  Barbade: {
    name: 'Barbade',
    code: '99434',
    fullName: 'Barbade',
  },
  Grenade: {
    name: 'Grenade',
    code: '99435',
    fullName: 'Grenade',
  },
  Bahamas: {
    name: 'Bahamas',
    code: '99436',
    fullName: 'Commonwealth Des Bahamas',
  },
  Suriname: {
    name: 'Suriname',
    code: '99437',
    fullName: 'République Du Suriname',
  },
  Dominique: {
    name: 'Dominique',
    code: '99438',
    fullName: 'Commonwealth De Dominique',
  },
  'Sainte-lucie': {
    name: 'Sainte-lucie',
    code: '99439',
    fullName: 'Sainte-lucie',
  },
  'Saint-vincent-et-les Grenadines': {
    name: 'Saint-vincent-et-les Grenadines',
    code: '99440',
    fullName: 'Saint-vincent-et-les Grenadines',
  },
  'Antigua-et-barbuda': {
    name: 'Antigua-et-barbuda',
    code: '99441',
    fullName: 'Antigua-et-barbuda',
  },
  'Saint-christophe-et-nieves': {
    name: 'Saint-christophe-et-nieves',
    code: '99442',
    fullName: 'Fédération De Saint-christophe-et-niévès',
  },
  'Bonaire, Saint Eustache Et Saba': {
    name: 'Bonaire, Saint Eustache Et Saba',
    code: '99443',
    fullName: 'Bonaire, Saint Eustache Et Saba',
  },
  Curacao: {
    name: 'Curacao',
    code: '99444',
    fullName: 'Curaçao',
  },
  'Saint-martin (Partie Neerlandaise)': {
    name: 'Saint-martin (Partie Neerlandaise)',
    code: '99445',
    fullName: 'Saint-martin (Partie Néerlandaise)',
  },
  Australie: {
    name: 'Australie',
    code: '99501',
    fullName: 'Australie',
  },
  'Norfolk (Ile)': {
    name: 'Norfolk (Ile)',
    code: '99501',
    fullName: 'Norfolk (ÎLe)',
  },
  'Heard Et Macdonald (Iles)': {
    name: 'Heard Et Macdonald (Iles)',
    code: '99501',
    fullName: 'Heard Et Macdonald (ÎLes)',
  },
  'Christmas (Ile)': {
    name: 'Christmas (Ile)',
    code: '99501',
    fullName: 'Christmas (ÎLe)',
  },
  'Cocos Ou Keeling (Iles)': {
    name: 'Cocos Ou Keeling (Iles)',
    code: '99501',
    fullName: 'Cocos Ou Keeling (ÎLes)',
  },
  'Nouvelle-zelande': {
    name: 'Nouvelle-zelande',
    code: '99502',
    fullName: 'Nouvelle-zélande',
  },
  Tokelau: {
    name: 'Tokelau',
    code: '99502',
    fullName: 'Tokelau',
  },
  Niue: {
    name: 'Niue',
    code: '99502',
    fullName: 'Niue',
  },
  'Cook (Iles)': {
    name: 'Cook (Iles)',
    code: '99502',
    fullName: 'Cook (ÎLes)',
  },
  'Pitcairn (Ile)': {
    name: 'Pitcairn (Ile)',
    code: '99503',
    fullName: 'Pitcairn (ÎLe)',
  },
  'Hawaii (Iles)': {
    name: 'Hawaii (Iles)',
    code: '99504',
    fullName: 'Hawaii (ÎLes)',
  },
  "Terr. Des Etats-unis D'amerique En Oceanie": {
    name: "Terr. Des Etats-unis D'amerique En Oceanie",
    code: '99505',
    fullName: "Terr. Des ÉTats-unis D'amérique En Océanie",
  },
  'Mariannes Du Nord (Iles)': {
    name: 'Mariannes Du Nord (Iles)',
    code: '99505',
    fullName: 'Mariannes Du Nord (ÎLes)',
  },
  Guam: {
    name: 'Guam',
    code: '99505',
    fullName: 'Guam',
  },
  'Samoa Americaines': {
    name: 'Samoa Americaines',
    code: '99505',
    fullName: 'Samoa Américaines',
  },
  'Samoa Occidentales': {
    name: 'Samoa Occidentales',
    code: '99506',
    fullName: 'ÉTat Indépendant Des Samoa Occidentales',
  },
  Nauru: {
    name: 'Nauru',
    code: '99507',
    fullName: 'République De Nauru',
  },
  Fidji: {
    name: 'Fidji',
    code: '99508',
    fullName: 'République Des Fidji',
  },
  Tonga: {
    name: 'Tonga',
    code: '99509',
    fullName: 'Royaume Des Tonga',
  },
  'Papouasie-nouvelle-guinee': {
    name: 'Papouasie-nouvelle-guinee',
    code: '99510',
    fullName: 'Papouasie-nouvelle-guinée',
  },
  Tuvalu: {
    name: 'Tuvalu',
    code: '99511',
    fullName: 'Tuvalu',
  },
  'Salomon (Iles)': {
    name: 'Salomon (Iles)',
    code: '99512',
    fullName: 'ÎLes Salomon',
  },
  Kiribati: {
    name: 'Kiribati',
    code: '99513',
    fullName: 'République De Kiribati',
  },
  Vanuatu: {
    name: 'Vanuatu',
    code: '99514',
    fullName: 'République De Vanuatu',
  },
  'Marshall (Iles)': {
    name: 'Marshall (Iles)',
    code: '99515',
    fullName: 'République Des ÎLes Marshall',
  },
  'Micronesie (Etats Federes De)': {
    name: 'Micronesie (Etats Federes De)',
    code: '99516',
    fullName: 'ÉTats Fédérés De Micronésie',
  },
  'Palaos (Iles)': {
    name: 'Palaos (Iles)',
    code: '99517',
    fullName: 'République Des ÎLes Palaos',
  },
} as const;

export const INSEE_COUNTRIES = {
  ...INSEE_FRENCH_COUNTRY,
  ...INSEE_FOREIGN_COUNTRIES,
};

type InseeFrenchCountriesType = typeof INSEE_FRENCH_COUNTRY;
type InseeForeignCountriesType = typeof INSEE_FOREIGN_COUNTRIES;
type InseeFrenchCountryName = keyof InseeFrenchCountriesType;
type InseeForeignCountryName = keyof InseeForeignCountriesType;

export const INSEE_COUNTRY_NAMES = Object.keys(INSEE_COUNTRIES) as (
  | InseeFrenchCountryName
  | InseeForeignCountryName
)[];
export const INSEE_COUNTRIES_CHOICES = INSEE_COUNTRY_NAMES.map(item => ({
  label: item,
  value: item,
}));

export type InseeFrenchCountry =
  InseeFrenchCountriesType[keyof InseeFrenchCountriesType];
export type InseeForeignCountry =
  InseeForeignCountriesType[keyof InseeForeignCountriesType];
export type InseeCountry = InseeFrenchCountry | InseeForeignCountry;
export type InseeCountryName = InseeFrenchCountryName | InseeForeignCountryName;

export const isInseeFrenchCountryName = (
  value: string,
): value is InseeFrenchCountryName => value in INSEE_FRENCH_COUNTRY;

export const isInseeForeignCountryName = (
  value: string,
): value is InseeForeignCountryName => value in INSEE_FOREIGN_COUNTRIES;

export const isInseeFrenchCountry = (
  value: InseeCountry,
): value is InseeFrenchCountry => value.name in INSEE_FRENCH_COUNTRY;

export const inseeCountryDecoder = (value: unknown): InseeCountry => {
  const strValue = string(value);
  if (isInseeFrenchCountryName(strValue)) {
    return INSEE_FRENCH_COUNTRY[strValue];
  } else if (isInseeForeignCountryName(strValue)) {
    return INSEE_FOREIGN_COUNTRIES[strValue];
  } else {
    throw `The value ${strValue} is not a valid country.`;
  }
};
