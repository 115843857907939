import React, { PropsWithChildren } from 'react';

import { Outlet } from 'react-router-dom';

export type LayoutProps<T extends {} = {}> =
  | { outlet: true }
  | PropsWithChildren<T>;

export const RenderLayoutChildren: React.FC<LayoutProps> = props =>
  'outlet' in props ? <Outlet /> : <>{props.children}</>;
