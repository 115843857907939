import React from 'react';

import { css } from '@emotion/css';

import { Icons } from '@/assets/icons';
import Typography from '@/components/typography/Typography';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';

import { StyleType } from './StyleTypes';

type FilePreviewType = {
  name: string;
  idx: number;
  onClick: (i: number) => void;
  deletable?: boolean;
};
export const FilePreview = ({
  name,
  idx,
  onClick,
}: FilePreviewType & StyleType) => {
  const styles = useStyles(makeStyles);

  const handleRemove = () => {
    onClick(idx);
  };

  return (
    <div className={styles.container}>
      <Icons.trash onClick={handleRemove} className={styles.icon}></Icons.trash>
      <Typography className={styles.name}>{name}</Typography>
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    display: inline-flex;
    padding-left: ${Dimensions.XS};
    align-items: center;
  `,
  name: css`
    padding-left: ${Dimensions.XS};
    white-space: nowrap;
  `,
  icon: css`
    cursor: pointer;
  `,
});
