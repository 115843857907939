import React from 'react';

import PropTypes from 'prop-types';

const styleVariant = {
  outlined: 'border rounded focus:border-primary-600',
  filled:
    'border-b-2 border-gray-600 focus:border-primary-600 rounded-t bg-primary-100 placeholder-gray-700',
  standard: '',
};

export default function SearchBar({
  value,
  onChange,
  className = '',
  placeholder = '',
  variant = 'standard',
}) {
  return (
    <input
      value={value}
      onChange={e => onChange(e.target.value)}
      placeholder={placeholder}
      className={styleVariant[variant] + ' ' + className}
    />
  );
}

SearchBar.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
};
