import React, { HTMLProps, MutableRefObject, useEffect, useRef } from 'react';

import { css, cx } from '@emotion/css';
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';

import { useStyles } from '@/hooks/useTheme';

export type ScrollBarProps = {
  thisRef?: MutableRefObject<PerfectScrollbar | null>;
  options?: PerfectScrollbar.Options;
  horizontal?: boolean;
} & HTMLProps<HTMLDivElement>;

export function ScrollBar({
  thisRef = { current: null },
  options = {},
  className,
  horizontal = false,
  children,
  ...props
}: ScrollBarProps): JSX.Element {
  const divRef = useRef<HTMLDivElement>(null);
  const styles = useStyles(makeStyles);
  const currentStyle = horizontal
    ? styles.scrollbarHorizontal
    : styles.scrollbarVertical;

  useEffect(() => {
    if (divRef.current && !thisRef.current) {
      thisRef.current = new PerfectScrollbar(divRef.current, options);
    }
  }, [divRef, options, thisRef]);

  useEffect(() => {
    thisRef.current?.update();
  });

  return (
    <div ref={divRef} className={cx(className, currentStyle)} {...props}>
      {children}
    </div>
  );
}

const makeStyles = () => ({
  scrollbarVertical: css`
    position: relative;
    width: 100%;
  `,
  scrollbarHorizontal: css`
    display: flex;
  `,
});
