import { Contact } from '@/models/ContactModel';
import { Phone } from '@/models/PhoneModel';

export const getFullName = (contact: Contact): string =>
  `${contact.firstname} ${contact.lastname}`;

export const relationships = {
  unspecified: 'models.contact.not_entered',
  partner: 'models.contact.partner',
  grand_parent: 'models.contact.grand_parent',
  parent: 'models.contact.parent',
  parent_in_law: 'models.contact.parent_in_law',
  child: 'models.contact.child',
  brother: 'models.contact.brother',
  cousin: 'models.contact.cousin',
  friend: 'models.contact.friend',
  other: 'models.contact.other',
} as const;

export const jobs = {
  medecin: 'models.medecin',
  medecin_prescripteur: 'models.medical_contact.medecin_prescripteur',
  medecin_telesurveillant: 'models.medical_contact.medecin_telesurveillant',
  diabetologue: 'models.diabetologue',
  infirmier: 'models.infirmier',
  pharmacien: 'models.pharmacien',
  ide: 'models.medical_contact.ide',
  sante: 'models.medical_contact.sante_scolaire',
  psychologue: 'models.medical_contact.psychologue',
  dieteticien: 'models.dieteticien',
  prestataire: 'models.medical_contact.prestataire_de_pompe',
  ems: 'models.medical_contact.educateur_medico_sportif',
  autre: 'common.autre',
} as const;

export const defaultPhone: Phone = {
  description: 'mobile',
  number: '',
} as const;

export type ContactForm = Contact;

export const defaultContact: ContactForm = {
  id: '',
  phone: defaultPhone,
  firstname: '',
  lastname: '',
  email: '',
  comment: '',
  postalAddress: '',
  isEmergencyContact: false,
  type: 'autre',
  relationshipWithPatient: 'other',
  userId: '',
};
