import React, { useContext } from 'react';

import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { usePatientIdFromURL } from '@/hooks';
import { useStyles } from '@/hooks/useTheme';
import { CreateMessage } from '@/models/ChatModel';
import { FileToUpload } from '@/models/FileModel';
import { Queries } from '@/queries/Queries';
import { Dimensions } from '@/theme/dimensions';
import { SupportedMimes } from '@/utils/files';

import { addMessageSchema } from '../AddMessage.schema';
import { Actions } from './Actions';
import { AttachmentContext } from './AttachmentContext';
import { FileListPreview } from './FileListPreview';
import { TextBox } from './TextBox';

export const FooterForm = () => {
  const styles = useStyles(makeStyles);
  const id = usePatientIdFromURL();
  const attachmentContext = useContext(AttachmentContext);

  const { handleSubmit, setValue, watch } = useForm<CreateMessage>({
    defaultValues: {
      visibility: 'practitioners',
      category: 'alert',
      status: 'completed',
    },
    resolver: yupResolver(addMessageSchema),
  });

  const createMessage = Queries.chat.useCreateMessage();

  const onSubmit = (data: CreateMessage) => {
    if (id) {
      const filesFormatted: FileToUpload[] = attachmentContext.files.map(f => ({
        file: f,
        contentType: f.type as SupportedMimes,
        name: f.name,
        size: f.size,
      }));
      data.contentAttachment = filesFormatted;
      createMessage.mutate({ patientId: id, ...data });
      const textArea = document.getElementById('text-area-nestable');
      setValue('contentText', '');
      if (textArea) textArea.innerHTML = '';
      attachmentContext.setFiles?.([]);
      sessionStorage.removeItem(`${id}Draft`);
    }
  };

  return (
    <form className={styles.container} onSubmit={handleSubmit(onSubmit)}>
      {attachmentContext.files.length > 0 && (
        <div className={styles.filePreviewContainer}>
          <FileListPreview></FileListPreview>
        </div>
      )}
      <div className={styles.writeAction}>
        <TextBox setValue={setValue} formValueGetter={watch}></TextBox>
        <Actions
          requestStatus={createMessage.status}
          formValueGetter={watch}
        ></Actions>
      </div>
    </form>
  );
};
const makeStyles = () => ({
  container: css`
    display: flex;
    flex-flow: column;
    padding-bottom: ${Dimensions.S};
  `,
  filePreviewContainer: css`
    padding: ${Dimensions.XS};
  `,
  writeAction: css`
    display: inline-flex;
    padding-left: ${Dimensions.S};
  `,
});
