import React, { ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { useId } from '@floating-ui/react';
import { Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

import { IconType } from '@/assets/icons';
import { BaseTextAreaFormInput } from '@/components/form/inputs/BaseFormInput';
import { InputError, getError } from '@/components/form/inputs/InputError';
import { Col, Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

type LabelProps =
  | string
  | {
      type?: 'above' | 'left';
      label?: string;
      placeholder?: string;
    };

export type TextAreaFormInputProps<T extends FieldValues> = {
  Icon?: IconType;
  containerStyle?: string;
  inputStyle?: string;
  label?: LabelProps;
  labelStyle?: string;
  rows?: number;
  name: Path<T>;
  noReservedErrorSpace?: boolean;
} & RegisterOptions;

export const TextAreaFormInput = <T extends FieldValues>({
  Icon,
  containerStyle,
  name,
  inputStyle,
  label,
  labelStyle,
  rows = 4,
  noReservedErrorSpace,
  ...options
}: TextAreaFormInputProps<T>): ReactElement => {
  const context = useFormContext<T>();
  const styles = useStyles(makeStyles, !!getError({ context, name }));
  const id = useId();

  return (
    <fieldset>
      {label && typeof label !== 'string' && label.type === 'above' ? (
        <legend>
          <Typo type="subtitle" className={cx(styles.label, labelStyle)}>
            {label.label}
          </Typo>
        </legend>
      ) : null}
      <Col grow className={cx(styles.container, containerStyle)}>
        <Row align="center" shrink className={styles.content}>
          {Icon ? <Icon className={styles.icon} /> : null}
          <BaseTextAreaFormInput
            id={id}
            placeholder={
              typeof label === 'string'
                ? `${label} ${options.required ? '*' : ''}`
                : label?.placeholder
            }
            className={cx(styles.input, inputStyle)}
            {...context.register(name, options)}
            rows={rows}
          />
        </Row>
        <InputError<T>
          className={styles.error}
          name={name}
          context={context}
          noReservedSpace={noReservedErrorSpace}
        />
      </Col>
    </fieldset>
  );
};

const makeStyles = (theme: Theme, error: boolean) => ({
  container: css`
    min-width: 0;
  `,
  content: css`
    margin-bottom: ${theme.input.bottomMargin};
    border-color: ${error ? theme.input.error : theme.input.border};
    border-width: ${theme.input.borderWidth};
  `,
  icon: css`
    fill: ${error ? theme.input.error : theme.input.border};
    width: 1em;
    height: 1em;
    margin-right: ${theme.input.paddingHorizontal};
  `,
  input: css`
    padding: ${theme.input.paddingVertical} ${theme.input.paddingHorizontal};
    flex: 1 1 0;
    min-width: 0;
    color: ${theme.input.text};
    ::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  `,
  error: css`
    height: 2.5em;
    color: ${theme.input.error};
  `,
  label: css`
    margin-bottom: ${theme.input.paddingVertical};
  `,
});
