import { ObjectSchema } from 'yup';

import { Profession, professionsList } from '@/models/ProfessionModel';
import yup from '@/yup/yup';

export const editPractitionerSchema: ObjectSchema<EditPractitionerData> = yup
  .object()
  .shape({
    givenName: yup.string().required('components.form.firstname_required'),
    familyName: yup.string().required('components.form.lastname_required'),
    qualification: yup
      .mixed<Profession>()
      .oneOf(professionsList)
      .required('components.form.required'),
  });

export type EditPractitionerData = {
  givenName: string;
  familyName: string;
  qualification: Profession;
};
