import React from 'react';

import { useTranslation } from 'react-i18next';

import ModalLayout from '@/components/modals/ModalLayoutComponent';
import ModalWrapper from '@/components/modals/ModalWrapperComponent';
import { toastError } from '@/components/toast/ToastComponent';

type ConfirmationModalProps<ArgsType> = {
  title: string;
  close: () => void;
  confirmationCallback: (...args: ArgsType[]) => void | Promise<void>;
  isOpen: boolean;
};

export default function ConfirmationModal<CallbackType>({
  title,
  close,
  confirmationCallback,
  isOpen,
}: ConfirmationModalProps<CallbackType>) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);

  const resolveConfirmation = async () => {
    try {
      setIsLoading(true);
      await confirmationCallback();
      close();
    } catch (err) {
      toastError(err as string);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalWrapper open={isOpen} close={close}>
      <ModalLayout className="space-y-8">
        <ModalLayout.Title>{title}</ModalLayout.Title>

        <ModalLayout.Buttons
          isLoading={isLoading}
          close={close}
          resolve={resolveConfirmation}
          textSubmit={t('common.oui_je_confirme')}
        />
      </ModalLayout>
    </ModalWrapper>
  );
}
