import React, { useContext } from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useTheme';
import { RadioGroup } from '@/uiKit/input/radio/RadioGroup';

import { ChatFilterContext } from '../ChatContext';
import { ExpiredTag } from './ExpiredTag';

export const ChatFilter: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const filterContext = useContext(ChatFilterContext);
  const filters = [
    {
      label: t('components.chat.all'),
      name: 'filterOption',
      value: 'all',
      checked: true,
    },
    {
      label: t('components.chat.medicalStaff'),
      name: 'filterOption',
      value: 'practitioners',
      checked: false,
    },
  ];

  const setFilterHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value === 'all' || event.target.value === 'practitioners')
      filterContext.setFilter?.(event.target.value);
  };

  return (
    <div className={styles.container}>
      <RadioGroup options={filters} onChange={setFilterHandler} />
      <ExpiredTag />
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    display: inline-flex;
  `,
});
