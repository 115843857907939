import { ColorsStructure } from './types';

export const Colors = {
  'steto-blue': {
    100: '#e8eeff',
    200: '#dde5ff',
    300: '#bacaff',
    500: '#516cc3',
    600: '#4558ae',
    700: '#364882',
    800: '#20274c',
    900: '#1E1E32',
  },
  green: {
    100: '#e6fbf3',
    200: '#D9FFF1',
    300: '#b3f2db',
    600: '#00d185',
    700: '#00b271',
    900: '#064C33',
  },
  red: {
    100: '#ffe9ea',
    200: '#ffdddf',
    300: '#ffbbbe',
    600: '#ff1d25',
    700: '#d9181f',
    900: '#991116',
  },
  yellow: {
    100: '#fff7e9',
    200: '#fff3dd',
    300: '#ffe6bb',
    600: '#ffa91d',
    700: '#d99018',
    900: '#996511',
  },
  white: '#ffffff',
  black: '#13151a',
  blue: {
    100: '#F7F8FB',
    300: '#CFE0FF',
    700: '#0f6fff',
    800: '#647BDE',
    900: '#0A0A56',
  },
  orange: {
    100: '#fff2ec',
    200: '#ffebe2',
    300: '#ffd6c4',
    600: '#ff7538',
    700: '#d9632f',
    900: '#994621',
  },
  gray: {
    100: '#f7f8fb',
    200: '#eff0f7',
    300: '#DFE0EC',
    600: '#b9bbcb',
    700: '#888c99',
    900: '#141821',
  },
  purple: {
    100: '#f0e6ec',
    200: '#e8d9e3',
    300: '#d0b3c6',
    600: '#600040',
    700: '#510036',
    900: '#390026',
  },
  brown: {
    200: '#8F4627',
    700: '#F4EBE6',
  },
  transparent: '#00FFFFFF',
} satisfies ColorsStructure;
