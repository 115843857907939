import React from 'react';

import { css } from '@emotion/css';

import usePatientIdFromURL from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useTheme';
import { PatientsTagsList } from '@/pages/patients/PatientsTagsList';
import { Queries } from '@/queries/Queries';

export const ExpiredTag = () => {
  const patientId = usePatientIdFromURL();
  const patientQuery = Queries.practitioner.useObservedPatient(patientId ?? '');
  const styles = useStyles(makeStyles);
  let patient = null;
  if (patientQuery.isSuccess) {
    patient = patientQuery.data;
  }
  return (
    <div className={styles.container}>
      {patient && !patient.telemonitoring ? (
        <PatientsTagsList
          patient={patient}
          isNurse={false}
          hovering={false}
          className={styles.tag}
        ></PatientsTagsList>
      ) : null}
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    align-self: center;
  `,
  tag: css`
    align-self: center;
  `,
});
