import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import {
  ChatFilterParametersType,
  CreateMessage,
  Message,
  UpdateMessage,
  messageDecoder,
  updateMessageDecoder,
} from '@/models/ChatModel';
import { Result } from '@/utils/Result';
import { forcedArray } from '@/utils/decoderUtils';

import { ApiDataSourceSpec, apiDataSource } from '../datasource/ApiDatasource';

export class ChatRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  getMessages = (
    subjectId: string,
    visibility: ChatFilterParametersType,
  ): Promise<Result<Message[], ApiErrorResponse>> => {
    return this.datasource.get(
      `/messaging/${subjectId}/`,
      forcedArray(messageDecoder),
      {
        params: {
          visibility: visibility,
        },
      },
    );
  };

  createMessage = (
    patientId: string,
    data: CreateMessage,
  ): Promise<Result<CreateMessage, ApiErrorResponse>> => {
    return this.datasource.post(
      `messaging/${patientId}/`,
      messageDecoder,
      this._prepareMessageData(data),
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      },
    );
  };

  _prepareMessageData = (data: CreateMessage) => {
    const { contentAttachment, ...restData } = data;
    const formData = new FormData();

    if (contentAttachment) {
      for (const i of contentAttachment) {
        if (i.file) formData.append('attachment', i.file);
      }
    }

    for (const itemIndex in restData) {
      const itemData = restData[itemIndex as keyof typeof restData];
      if (itemData) {
        formData.append(itemIndex, itemData);
      }
    }

    return formData;
  };

  updateMessage = (
    patientId: string,
    messageId: string,
    data: UpdateMessage,
  ): Promise<Result<UpdateMessage, ApiErrorResponse>> => {
    return this.datasource.put(
      `/messaging/${patientId}/${messageId}/`,
      updateMessageDecoder,
      data,
    );
  };
}
