import React from 'react';

import { css } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Colors } from '@/theme/colors';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

type separatorType = {
  date: string;
};

export const DateSeparator = ({ date }: separatorType) => {
  const styles = useStyles(makeStyle);

  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  const toDate: Date = new Date(date);
  const formatDate: string = toDate.toLocaleDateString(undefined, options);

  return (
    <div className={styles.container}>
      <div className={styles.separator}></div>
      <span className={styles.date}>
        <span className={styles.dateText}>{formatDate}</span>
      </span>
    </div>
  );
};

const makeStyle = (theme: Theme) => ({
  container: css`
    display: flex;
    width: ${Dimensions.full};
    height: ${Dimensions.S};
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    position: relative;
  `,
  separator: css`
    width: ${Dimensions.full};
    height: ${Dimensions.XXXS};
    flex-shrink: 0;
    background: ${Colors.gray[200]};
    position: absolute;
  `,
  date: css`
    display: inline-flex;
    height: ${Dimensions.S};
    padding: ${Dimensions.None} ${Dimensions.XXS};
    justify-content: center;
    align-items: center;
    gap: ${Dimensions.XS};
    flex-shrink: 0;
    background: ${theme.sideSheet.contentBackground};
    position: absolute;
    z-index: 2;
  `,
  dateText: css`
    text-transform: capitalize;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.2px;
  `,
});
