import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { FlexBox } from '@/components/box';
import ConfirmationModal from '@/components/modals/confirmation/ConfirmationModal';
import Typography from '@/components/typography/Typography';
import { useComponentContext, usePatientIdFromURL } from '@/hooks';
import { useApi } from '@/hooks/useApi';
import { Queries } from '@/queries/Queries';
import { deletePatientContact } from '@/state/contacts/thunks';
import { getFullName, relationships } from '@/state/contacts/utils';
import { isEmpty } from '@/utils/common';

import './ContactPatientViewComponent.scss';

const ContactPatientViewContext = React.createContext();
const useContactPatientViewContext = () =>
  useComponentContext(ContactPatientViewContext);

export default function ContactPatientView({
  contact,
  onEdit,
  onDelete,
  authorizeEditDelete = true,
  addClassName = '',
}) {
  const { dispatchActionWithAuth } = useApi();
  const id = usePatientIdFromURL();

  const { t } = useTranslation();

  const [deleteConfirmation, setDeleteConfirmation] = React.useState(false);

  const onDeleteApi = async () => {
    await dispatchActionWithAuth(deletePatientContact, {
      id,
      contactId: contact.id,
    });
    Queries.practitioner.invalidateObservedPatient(id);
    setDeleteConfirmation(false);
    onDelete();
  };

  return (
    contact && (
      <ContactPatientViewContext.Provider value={{ contact, t }}>
        <div className={`contactView ${addClassName} hover `}>
          <div className="px-1 pt-3 mr-12 font-hairline border-t border-gray-200">
            <FlexBox className="items-center justify-between">
              <FullName />
              {authorizeEditDelete && (
                <FlexBox>
                  <FlexBox
                    className="withHover Oval bg-primary-100 ml-5"
                    onClick={onEdit}
                  >
                    <Icons.edit alt="edit" />
                  </FlexBox>
                  <FlexBox
                    className="ml-1 Oval bg-primary-100"
                    onClick={() => setDeleteConfirmation(true)}
                  >
                    <Icons.trash className="h-5 text-gray-400" fill="#174EFF" />
                  </FlexBox>
                  <ConfirmationModal
                    title={t('components.contact.delete_contact')}
                    close={() => setDeleteConfirmation(false)}
                    isOpen={deleteConfirmation}
                    confirmationCallback={onDeleteApi}
                  />
                </FlexBox>
              )}
            </FlexBox>
            <div className="text-gray-700">
              <Relationship />
              <EmergencyContact />
              <PhoneList />
              <Email />
              <Comment />
            </div>
          </div>
        </div>
      </ContactPatientViewContext.Provider>
    )
  );
}

const FullName = () => {
  const { contact, t } = useContactPatientViewContext();

  return isEmpty(contact.firstname) && isEmpty(contact.lastname) ? (
    <Typography fontWeight="bold" italic>
      {t('components.contact.view.aucun_nom_saisi')}
    </Typography>
  ) : (
    <Typography fontWeight="bold">{getFullName(contact)}</Typography>
  );
};

const Relationship = () => {
  const { contact, t } = useContactPatientViewContext();

  return isEmpty(contact.relationshipWithPatient) ? (
    <Typography fontWeight="thin" italic>
      {t('components.contact.aucun_lien_avec_le_patient_saisi')}
    </Typography>
  ) : (
    <Typography element="p" fontWeight="thin">
      {t(relationships[contact.relationshipWithPatient])}
    </Typography>
  );
};

const EmergencyContact = () => {
  const { contact, t } = useContactPatientViewContext();

  return contact.isEmergencyContact ? (
    <Typography element="p" className="mb-4 text-sm text-teal-500">
      {t('components.contact.a_contacter_en_cas_d_urgence').slice(0, -1)}
    </Typography>
  ) : (
    ''
  );
};

const PhoneList = () => {
  const { contact, t } = useContactPatientViewContext();

  const renderPhone = () => (
    <FlexBox className="mb-2">
      <Icons.phone className="w-5 h-5 fill-current text-primary-600" />
      <Typography className="ml-4" italic={isEmpty(contact.phone?.number)}>
        {contact.phone?.number || t('components.telephone_non_renseigne')}
      </Typography>
    </FlexBox>
  );

  return contact.phone ? (
    <FlexBox className="flex-col flex-wrap justify-between">
      {renderPhone()}
    </FlexBox>
  ) : (
    <FlexBox className="mb-2">
      <Icons.phone className="w-5 h-5 fill-current text-primary-600" />
      <Typography className="ml-4" italic>
        {t('components.aucun_numero_de_telephone_saisi')}
      </Typography>
    </FlexBox>
  );
};

const Email = () => {
  const { contact, t } = useContactPatientViewContext();

  return (
    <FlexBox className="items-center mb-2 font-regular">
      <Icons.mail className="w-5 fill-current text-primary-600" />
      {isEmpty(contact.email) ? (
        <Typography className="ml-4" italic>
          {t('components.aucune_adresse_email_saisie')}
        </Typography>
      ) : (
        <Typography className="ml-4">{contact.email}</Typography>
      )}
    </FlexBox>
  );
};

const Comment = () => {
  const { contact, t } = useContactPatientViewContext();

  return (
    <FlexBox className="items-center mb-4">
      {contact.comment === null || isEmpty(contact.comment) ? (
        <Typography fontWeight="thin" italic>
          {t('components.aucun_commentaire_saisi')}
        </Typography>
      ) : (
        <Typography fontWeight="thin" italic>
          {contact.comment}
        </Typography>
      )}
    </FlexBox>
  );
};
