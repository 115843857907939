import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';

import { css } from '@emotion/css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { CardTitle } from '@/components/card/CardTitle';
import { DailyGraph } from '@/components/data-representations';
import { Dialog } from '@/components/floating/Dialog';
import { Col } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { SideSheetHeader } from '@/components/sidesheet/SideSheetHeader';
import { Typo } from '@/components/typography/Text';
import { usePatientIdFromURL, useQueryString } from '@/hooks';
import { useStyles } from '@/hooks/useTheme';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Dataviz } from '@/models/Dataviz';
import { Queries } from '@/queries/Queries';
import { GlycemiaTypeEnum } from '@/state/diabetes/types';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

export const GlycemiaProfiles: React.FC = () => {
  const { from, to } = useQueryString();
  const { t } = useTranslation();
  const dataviz = Queries.diabetes.useInfiniteDataviz({
    patientId: usePatientIdFromURL() ?? '',
    from: from,
    to: moment(to).isSame(moment(), 'day')
      ? to
      : moment(to).subtract(1, 'day').format(),
  });
  const [previousScroll, setPreviousScroll] = useState(0);

  const styles = useStyles(makeStyles);

  useEffect(() => {
    dataviz.refetch();
    setPreviousScroll(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  useEffect(() => {
    const handleScroll = () => {
      if (previousScroll > 0 && window.scrollY < 1000) {
        setPreviousScroll(0);
      }

      if (
        window.innerHeight + window.scrollY >=
          (document.scrollingElement?.scrollHeight ?? 0) * 0.9 &&
        previousScroll < window.scrollY
      ) {
        setPreviousScroll(window.scrollY);
        dataviz.fetchNextPage();
      } else if (previousScroll === 0 && window.scrollY > 1000) {
        setPreviousScroll(window.scrollY);
      }
    };
    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previousScroll]);

  return (
    <Col>
      {previousScroll > 0 ? (
        <div className={styles.backToTop}>
          <IconButton
            buttonType="primary"
            icon={Icons.chevronUp}
            placement={'left'}
            onClick={() => window.scroll({ top: 0, behavior: 'smooth' })}
          />
        </div>
      ) : null}
      <Typo type="title" className={styles.title}>
        {t('pages.patient_monitoring.daily_glycemia_profiles')}
      </Typo>
      <div className={styles.list}>
        {dataviz.hasPreviousPage ? (
          <IconButton
            buttonType="secondary"
            icon={Icons.chevronUp}
            placement="left"
            className={styles.moreContentButton}
            onClick={() => dataviz.fetchPreviousPage()}
          >
            {t('pages.patient_monitoring.load_more_recent')}
          </IconButton>
        ) : null}
        {dataviz.data?.pages
          .flatMap(a => a)
          .map(data => (
            <GlycemiaProfileOverview key={data.date} data={data} />
          ))}
        {dataviz.hasNextPage ? (
          <div className={styles.loader}>
            <Loader size="M" />
          </div>
        ) : null}
      </div>
    </Col>
  );
};

const GlycemiaProfileOverview = ({ data }: { data: Dataviz }) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Card elevation={0}>
      <CardTitle className={styles.itemTitle}>
        <Typo type="paragraph">
          {moment(data.date).format('dddd DD MMM YYYY').capitalize()}
        </Typo>
        {data.reports.length > 0 ? <Icons.alertCircle /> : null}
        <Dialog
          placement="center"
          animation={{
            duration: 300,
            initial: { scale: '0' },
          }}
          floaterClassName={styles.floater}
          content={setIsOpen => (
            <Card className={styles.fullscreenCard} elevation={0}>
              <SideSheetHeader
                className={styles.fullscreenHeader}
                title={t('pages.patient_monitoring.daily_glycemia_profiles')}
                exit={() => setIsOpen(false)}
              />
              <CardBody className={styles.fullscreenContainer}>
                <GlycemiaGraph data={data} size="full" />
              </CardBody>
            </Card>
          )}
        >
          <Icons.fullscreen className={styles.fullScreenIcon} />
        </Dialog>
      </CardTitle>
      <CardBody className={styles.itemBody}>
        <GlycemiaGraph data={data} size="compact" />
      </CardBody>
    </Card>
  );
};

type GlycemiaGraphProps = {
  data: Dataviz;
  size: 'compact' | 'full';
};

const GlycemiaGraph: React.FC<GlycemiaGraphProps> = ({ data, size }) => {
  const [width, setWidth] = React.useState(0);
  const divRef = React.useRef<HTMLDivElement>(null);
  const styles = useStyles(makeStyles);
  const windowSize = useWindowSize();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth);
    }
  }, [windowSize.width]);

  const dailyData = useMemo(() => {
    return {
      ...data,
      glycemiaTypes: {
        glycemiaSensor: data.glycemia
          .filter(glycemia => glycemia.type === GlycemiaTypeEnum.INTERSTITIAL)
          .sort((a, b) => moment(a.date).diff(moment(b.date))), //generateGlyc(),
        glycemiaReader: data.glycemia.filter(
          glycemia => glycemia.type === GlycemiaTypeEnum.CAPILLARY,
        ),
        glycemiaManual: [],
      },
    };
  }, [data]);

  const hasData = useMemo(() => {
    return (
      size === 'full' ||
      data.reports.length > 0 ||
      data.glycemia.length > 0 ||
      data.food.length > 0 ||
      data.activity.length > 0 ||
      data.insulin.length > 0
    );
  }, [size, data]);

  return (
    <div className={styles.graphContainer} ref={divRef}>
      {hasData ? (
        <DailyGraph data={dailyData} width={width} size={size} />
      ) : (
        <Typo type="paragraphLarge">
          {t('pages.patient_monitoring.no_data')}
        </Typo>
      )}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  title: css`
    margin: ${Dimensions.S} 0;
  `,
  list: css`
    display: flex;
    flex-direction: column;
    gap: ${Dimensions.XS};
  `,
  itemTitle: css`
    padding: ${Dimensions.XXXS} ${theme.card.title.padding};
    justify-content: space-between;
  `,
  itemBody: css`
    padding: 0 ${theme.card.body.padding} ${Dimensions.XXS}
      ${theme.card.body.padding};
  `,
  graphContainer: css`
    max-width: 100%;
  `,
  loader: css`
    width: 100%;
    display: flex;
    justify-content: center;
  `,
  moreContentButton: css`
    width: fit-content;
  `,
  backToTop: css`
    position: fixed;
    bottom: ${Dimensions.M};
    align-self: flex-end;
    stroke: ${theme.button.icon.fill};
  `,
  fullScreenIcon: css`
    width: ${Dimensions.font.M};
    height: ${Dimensions.font.M};
  `,
  fullscreenCard: css`
    width: 100%;
    height: 100%;
  `,
  fullscreenHeader: css`
    padding: ${Dimensions.M};
  `,
  fullscreenContainer: css`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  `,
  floater: css`
    width: 80%;
    height: 90%;
  `,
});
