import React from 'react';

import { css } from '@emotion/css';
import { useNavigate } from 'react-router';
import { To } from 'react-router-dom';

import { Icons } from '@/assets/icons';
import { TextTooltip } from '@/components/floating/Tooltip';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

export type NavItemProps = {
  isCurrent?: boolean;
  to: To;
  tooltip?: string;
};

export const NavItem: React.FC<NavItemProps> = ({
  tooltip,
  to,
  isCurrent = false,
}) => {
  const styles = useStyles(makeStyles, isCurrent);
  const navigate = useNavigate();
  return (
    <div onClick={() => navigate(to)}>
      <TextTooltip text={tooltip} placement="right">
        <div className={styles.container}>
          <Icons.users className={styles.icon} />
        </div>
      </TextTooltip>
    </div>
  );
};

const makeStyles = (theme: Theme, isCurrent: boolean) => ({
  container: css`
    display: flex;
    aspect-ratio: 1/1;
    justify-content: center;
    align-items: center;
    margin: ${Dimensions.XS} 0;
    padding: calc(${Dimensions.M} / 2);
    border-radius: ${Dimensions.S};
    background-color: ${isCurrent
      ? theme.sideBar.currentBackground
      : theme.sideBar.notCurrentBackground};
  `,
  icon: css`
    cursor: pointer;
    fill: ${isCurrent ? theme.sideBar.current : theme.sideBar.notCurrent};

    width: ${Dimensions.S};
    height: ${Dimensions.S};
  `,
});
