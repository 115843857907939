import React from 'react';

import { css } from '@emotion/css';
import { Link } from 'react-router-dom';

import LogoSrc from '@/assets/icons/logo-steto-smallround.png';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';

export type LogoProps = {
  to?: string;
};

export const Logo: React.FC<LogoProps> = ({ to = '/' }) => {
  const styles = useStyles(makeStyles);
  return (
    <Link to={to} data-testid="navbar-logo" className={styles.container}>
      <img src={LogoSrc} alt="STETO" />
    </Link>
  );
};

const makeStyles = () => ({
  container: css`
    display: flex;
    margin-top: ${Dimensions.S};
    margin-bottom: ${Dimensions.M};
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
  `,
});
