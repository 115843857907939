import React from 'react';

import { AxisLeft } from '@visx/axis';
import { curveMonotoneX } from '@visx/curve';
import { localPoint } from '@visx/event';
import { Group } from '@visx/group';
import { LinePath } from '@visx/shape';

import { ReactComponent as Flag } from '@/assets/icons/evenement.svg';
import { Badge } from '@/components/data-representations/DailyGraph/Badge';
import { DiabetesParameters } from '@/models/DiabetesDataModel';
import { GlycemiaDataPoint } from '@/state/diabetes/types';

import { HandleMouseEnterProps, ScaleLinear } from './types';

export const glycemiaCircleRadius = 8;

type GlycemiaAxisProps = {
  parameters: DiabetesParameters;
  yScaleGlucose: ScaleLinear<number, number>;
  left?: number;
};

export const GlycemiaAxis = ({
  parameters,
  yScaleGlucose,
  left = 0,
}: GlycemiaAxisProps) => {
  return (
    <AxisLeft
      hideAxisLine
      left={left}
      scale={yScaleGlucose}
      tickValues={[
        parameters.thresholdHypoglycemia,
        parameters.thresholdHyperglycemia,
      ]}
      hideTicks
      tickLabelProps={() => ({
        dx: '0.25em',
        dy: '0.25em',
        fill: '#00b271',
        fontSize: 14,
        textAnchor: 'end',
      })}
    />
  );
};

type GlycemiaThresholdsProps = {
  parameters: DiabetesParameters;
  yScaleGlucose: ScaleLinear<number, number>;
  xScale: ScaleLinear<number, number, never>;
  left?: number;
};

export const GlycemiaThresholds = ({
  parameters,
  xScale,
  yScaleGlucose,
  left = 0,
}: GlycemiaThresholdsProps) => {
  const props = {
    height:
      parameters && yScaleGlucose
        ? yScaleGlucose(parameters.thresholdHypoglycemia) -
          yScaleGlucose(parameters.thresholdHyperglycemia)
        : 0,
  };
  return (
    <>
      <Group top={yScaleGlucose(parameters.thresholdHyperglycemia)} left={left}>
        <rect
          width={xScale(24)}
          height={props.height}
          fill="rgba(0, 209, 133, 0.1)"
        />
      </Group>
    </>
  );
};

export type GlycemiaDataPointsProps = {
  parameters: DiabetesParameters;
  glycemiaReader: GlycemiaDataPoint[];
  glycemiaManual: GlycemiaDataPoint[];
  xScale: ScaleLinear<number, number, never>;
  yScaleGlucose: ScaleLinear<number, number>;
  getHour: (date: string | moment.Moment) => number;
  handleMouseEnter: (props: HandleMouseEnterProps) => void;
  handleMouseLeave: () => void;
  left?: number;
  className?: string;
  glycemiaRadius?: number;
  small: boolean;
};

export const GlycemiaDataPoints = ({
  parameters,
  glycemiaReader,
  glycemiaManual,
  xScale,
  yScaleGlucose,
  getHour,
  handleMouseEnter,
  handleMouseLeave,
  left = 0,
  className = '',
  glycemiaRadius = glycemiaCircleRadius,
  small,
}: GlycemiaDataPointsProps) => {
  // TEMPORARY MOCK, WAITING NOTIFICATIONS
  const notifications = false;
  //ugly hack
  const glycemiaType = [
    ...Array(glycemiaReader.length).fill('Lecteur'),
    ...Array(glycemiaManual.length).fill('Manuel'),
  ];
  const glycemia = [...glycemiaReader, ...glycemiaManual];

  const data = glycemia.map(datum => ({
    cy: yScaleGlucose(datum.value),
  }));
  return (
    <Group>
      {data.map(({ cy }, i) => {
        const cx = xScale(getHour(glycemia[i].date));
        return (
          <Group
            className={className}
            key={i}
            onMouseEnter={() => {
              handleMouseEnter({
                x: cx,
                y: cy,
                title: `${glycemia[i].value} mg/dL`,
                content: 'Glucose',
                date: glycemia[i].date,
                dataInputMethod: glycemiaType[i],
                icon: notifications ? Flag : null,
              });
            }}
            onMouseLeave={handleMouseLeave}
            left={left}
          >
            {notifications && (
              /*TODO: Find correct top*/
              <Group top={-40}>
                <circle
                  r={20}
                  cx={glycemiaRadius + cx}
                  cy={cy}
                  fill="#ffdddf"
                />
                {/*TODO: Find correct top*/}
                <Group top={-12}>
                  <Group
                    left={glycemiaRadius + cx - 12}
                    top={cy}
                    className="justify-center"
                  >
                    <Flag width={24} height={24} />
                  </Group>
                </Group>
              </Group>
            )}
            {small ? (
              <circle
                r={glycemiaRadius}
                cy={cy}
                cx={glycemiaRadius + cx} // begin circle at cx (not middle circle at cx)
                fill={
                  glycemia[i].value > parameters.thresholdHyperglycemiaSevere
                    ? '#ff8f00'
                    : glycemia[i].value > parameters.thresholdHyperglycemia
                    ? '#ffa91d'
                    : glycemia[i].value < parameters.thresholdHypoglycemiaSevere
                    ? '#b30047'
                    : glycemia[i].value < parameters.thresholdHypoglycemia
                    ? '#ff1d25'
                    : '#00d185'
                }
              />
            ) : (
              <Badge
                key={i}
                text={`${Math.round(glycemia[i].value * 100) / 100}`}
                Icon={null}
                top={cy - 12}
                left={cx}
                width={40}
                backgroundColor={
                  glycemia[i].value > parameters.thresholdHyperglycemiaSevere
                    ? '#ffd6c4'
                    : glycemia[i].value > parameters.thresholdHyperglycemia
                    ? '#ffe6bb'
                    : glycemia[i].value < parameters.thresholdHypoglycemiaSevere
                    ? '#d0b3c6'
                    : glycemia[i].value < parameters.thresholdHypoglycemia
                    ? '#ffbbbe'
                    : '#b3f2db'
                }
                colorText={'#111'}
              />
            )}
          </Group>
        );
      })}
    </Group>
  );
};

type GlycemiaCurveProps = {
  parameters: DiabetesParameters;
  glycemiaGroups: GlycemiaDataPoint[][];
  xScale: ScaleLinear<number, number, never>;
  yScaleGlucose: ScaleLinear<number, number>;
  getHour: (date: string | moment.Moment) => number;
  handleMouseEnter: (props: HandleMouseEnterProps) => void;
  handleMouseLeave: () => void;
  marginLeft?: number;
};

const GlycemiaCurve = ({
  parameters,
  glycemiaGroups,
  xScale,
  yScaleGlucose,
  getHour,
  handleMouseEnter,
  handleMouseLeave,
  marginLeft = 0,
}: GlycemiaCurveProps) => {
  const range = yScaleGlucose.range();
  //stops for linear gradient

  const offset_hyper =
    (yScaleGlucose(parameters.thresholdHyperglycemia) - range[0]) /
    (range[1] - range[0]);
  const offset_hypo =
    (yScaleGlucose(parameters.thresholdHypoglycemia) - range[0]) /
    (range[1] - range[0]);

  const dataColor = [
    {
      offset: offset_hyper,
      stopColor: '#ffa91d',
    },
    {
      offset: offset_hyper,
      stopColor: '#00d185',
    },
    {
      offset: offset_hypo,
      stopColor: '#00d185',
    },
    {
      offset: offset_hypo,
      stopColor: '#ff1d25',
    },
  ];

  const onMouseEnter =
    (data: GlycemiaDataPoint[]) =>
    (event: React.MouseEvent<SVGPathElement, MouseEvent>) => {
      //find nearest glycemia point
      const findNearestPoint = () => {
        const { x } = localPoint(event) || { x: 0 };
        const hourFromX = Math.max(0, xScale.invert(x - marginLeft)); // x0 must be greater than 0
        const dataHours = data.map(d => getHour(d.date));
        let index = dataHours.findIndex(hour => hourFromX < hour);

        //edge case
        index = index === 0 ? 1 : index;
        index = index === -1 ? dataHours.length - 1 : index;

        const prevPoint = data[index - 1];
        const nextPoint = data[index];

        let nearestPoint = prevPoint;
        if (nextPoint && nextPoint.date) {
          nearestPoint =
            hourFromX - getHour(prevPoint.date) >
            getHour(nextPoint.date) - hourFromX
              ? nextPoint
              : prevPoint;
        }
        return nearestPoint;
      };

      const nearestGlycemiaPoint = findNearestPoint();
      handleMouseEnter({
        x: xScale(getHour(nearestGlycemiaPoint.date)),
        y: yScaleGlucose(nearestGlycemiaPoint.value),
        title: `${nearestGlycemiaPoint.value} mg/dL`,
        content: 'Glucose',
        date: nearestGlycemiaPoint.date,
        dataInputMethod: 'Capteur',
        data: null,
      });
    };

  const id = Math.random().toString(36).substring(2);
  return (
    <Group>
      <defs>
        <linearGradient
          id={`linear-gradient-glycemia-${id}`}
          x1={0}
          x2={0}
          y1={range[0]}
          y2={range[1]}
          gradientUnits="userSpaceOnUse"
        >
          {dataColor.map((col, idx) => (
            <stop {...col} key={idx} />
          ))}
        </linearGradient>
      </defs>
      {glycemiaGroups.map((glycemiaGroup, idx) => (
        <LinePath
          key={idx}
          strokeLinejoin="round"
          strokeLinecap="round"
          strokeWidth={2}
          curve={curveMonotoneX}
          data={glycemiaGroup}
          x={dataPoint => xScale(getHour(dataPoint.date))}
          y={dataPoint => yScaleGlucose(dataPoint.value)}
          stroke={`url(#linear-gradient-glycemia-${id})`}
          onMouseEnter={onMouseEnter(glycemiaGroup)}
          onMouseLeave={handleMouseLeave}
          pointerEvents="visibleStroke"
        />
      ))}
    </Group>
  );
};

type GlycemiaElementsProps = {
  parameters?: DiabetesParameters;
  xScale: ScaleLinear<number, number, never>;
  yScaleGlucose?: ScaleLinear<number, number>;
  glycemiaManual: GlycemiaDataPoint[];
  glycemiaReader: GlycemiaDataPoint[];
  glycemiaSensor: GlycemiaDataPoint[][];
  getHour: (date: string | moment.Moment) => number;
  handleMouseEnter: (props: HandleMouseEnterProps) => void;
  handleMouseEnterSensor: (props: HandleMouseEnterProps) => void;
  handleMouseLeave: () => void;
  marginLeft?: number;
  datapointsProps?: {
    [P in keyof GlycemiaDataPointsProps]?: GlycemiaDataPointsProps[P];
  };
  small: boolean;
};

export const GlycemiaElements = ({
  parameters,
  xScale,
  yScaleGlucose,
  glycemiaManual,
  glycemiaReader,
  glycemiaSensor,
  getHour,
  handleMouseEnter,
  handleMouseEnterSensor,
  handleMouseLeave,
  marginLeft = 0,
  datapointsProps = {} as GlycemiaDataPointsProps,
  small,
}: GlycemiaElementsProps) => {
  return (
    <React.Fragment>
      {parameters && yScaleGlucose && (
        <GlycemiaAxis
          {...{
            parameters,
            yScaleGlucose,
          }}
        />
      )}

      {parameters && yScaleGlucose && (
        <GlycemiaThresholds
          {...{
            parameters,
            xScale,
            yScaleGlucose,
          }}
        />
      )}

      {parameters &&
        glycemiaSensor &&
        yScaleGlucose &&
        handleMouseEnterSensor && (
          <GlycemiaCurve
            {...{
              parameters,
              glycemiaGroups: glycemiaSensor,
              xScale,
              yScaleGlucose,
              getHour,
              handleMouseEnter: handleMouseEnterSensor,
              handleMouseLeave,
              marginLeft,
            }}
          />
        )}

      {parameters && glycemiaManual && glycemiaReader && yScaleGlucose && (
        <GlycemiaDataPoints
          {...{
            ...datapointsProps,
            parameters,
            glycemiaManual,
            glycemiaReader,
            xScale,
            yScaleGlucose,
            getHour,
            handleMouseEnter,
            handleMouseLeave,
            small,
          }}
        />
      )}
    </React.Fragment>
  );
};
