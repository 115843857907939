import React, { PropsWithChildren, useMemo, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Middleware } from '@floating-ui/dom';
import {
  ArrowOptions,
  FlipOptions,
  FloatingContext,
  FloatingPortal,
  ReferenceType,
  ShiftOptions,
  UseClickProps,
  UseDismissProps,
  UseHoverProps,
  UseRoleProps,
  UseTransitionStylesProps,
  arrow,
  autoUpdate,
  flip,
  offset,
  safePolygon,
  shift,
  useClick,
  useDismiss,
  useFloating,
  useHover,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import { Placement } from '@floating-ui/utils';

import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';
import { ZIndex } from '@/theme/zIndex';

export type HoverTooltipType<T extends ReferenceType = HTMLDivElement> =
  | 'hover'
  | ({ type: 'hover' } & UseHoverProps<T>);

export type ClickTooltipType = 'click' | ({ type: 'click' } & UseClickProps);

export type AllTooltipType<T extends ReferenceType = HTMLDivElement> =
  | 'all'
  | { type: 'all'; click: UseClickProps; hover: UseHoverProps<T> };

export type TooltipType<T extends ReferenceType = HTMLDivElement> =
  | HoverTooltipType<T>
  | ClickTooltipType
  | AllTooltipType<T>;

export type BaseTooltipProps = PropsWithChildren<{
  type: TooltipType;
  placement: Placement;
  role: UseRoleProps['role'];
  autoOpen?: boolean;
  closeOnInteract?: boolean;
  dismiss?: UseDismissProps | boolean;
  offset?: number;
  flip?: FlipOptions | boolean;
  shift?: ShiftOptions | boolean;
  arrow?: ArrowOptions;
  animation?: UseTransitionStylesProps;
  className?: string;
  wrapperClassName?: string;
  floaterClassName?: string;
  content: React.ReactNode;
}>;

export const BaseTooltip: React.FC<BaseTooltipProps> = ({
  type,
  placement,
  role,
  autoOpen = false,
  closeOnInteract = true,
  dismiss: dismissValue,
  offset: offsetValue = 10,
  flip: flipValue,
  shift: shiftValue,
  arrow: arrowValue,
  animation = {
    duration: 150,
    initial: { transform: 'scale(0.2)' },
    common: ({ placement }) => ({
      transformOrigin: {
        top: 'bottom',
        bottom: 'top',
        left: 'right',
        right: 'left',
        'top-start': 'bottom left',
        'bottom-start': 'top left',
        'left-start': 'top right',
        'right-start': 'top left',
        'top-end': 'bottom right',
        'bottom-end': 'top right',
        'left-end': 'bottom right',
        'right-end': 'bottom left',
      }[placement],
    }),
  },
  className,
  floaterClassName,
  wrapperClassName,
  content,
  children,
}) => {
  const styles = useStyles(makeStyles);
  const flipMiddleware = useMiddleware(true, flip, flipValue);
  const shiftMiddleware = useMiddleware(true, shift, shiftValue);
  const arrowMiddleware = useOptMiddleware(arrow, arrowValue);
  const [isOpen, setIsOpen] = useState(autoOpen);
  const { refs, floatingStyles, context } = useFloating<HTMLDivElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement,
    whileElementsMounted: autoUpdate,
    middleware: [
      offset(offsetValue),
      ...flipMiddleware,
      ...shiftMiddleware,
      ...arrowMiddleware,
    ],
  });
  const interactions = useTooltipInteraction(type, context, role, dismissValue);
  const { isMounted, styles: animationStyle } = useTransitionStyles(
    context,
    animation,
  );

  return (
    <div className={cx(styles.container, className)}>
      <div
        ref={refs.setReference}
        className={cx(styles.wrapper, wrapperClassName)}
        {...interactions.getReferenceProps()}
      >
        {children}
      </div>
      {isMounted ? (
        <FloatingPortal>
          <div
            ref={refs.setFloating}
            style={floatingStyles}
            {...interactions.getFloatingProps()}
            className={styles.floatManager}
          >
            <div
              onClick={() => closeOnInteract && setIsOpen(state => !state)}
              style={animationStyle}
              className={cx(styles.floater, floaterClassName)}
            >
              {content}
            </div>
          </div>
        </FloatingPortal>
      ) : null}
    </div>
  );
};

/**
 * Create a @floating-ui middleware
 *
 * @param defaultEnabled If set to true: undefined => create middleware
 * @param middleware The middleware to create
 * @param value The value to determine how and if to create the middleware
 */
const useMiddleware = <T,>(
  defaultEnabled: boolean,
  middleware: (opt?: T) => Middleware,
  value: undefined | boolean | T,
): [Middleware] | [] => {
  return useMemo(() => {
    if (value === undefined) {
      return defaultEnabled ? [middleware()] : [];
    } else if (typeof value === 'boolean') {
      return value ? [middleware()] : [];
    } else {
      return [middleware(value)];
    }
  }, [defaultEnabled, middleware, value]);
};
/**
 * Create a @floating-ui middleware with required options
 *
 * @param middleware The middleware to create
 * @param value The value to determine how and if to create the middleware
 */
const useOptMiddleware = <T,>(
  middleware: (opt: T) => Middleware,
  value: undefined | T,
): [Middleware] | [] => {
  return useMemo(() => {
    if (value === undefined) {
      return [];
    } else {
      return [middleware(value)];
    }
  }, [middleware, value]);
};

const useTooltipInteraction = (
  type: TooltipType,
  context: FloatingContext<HTMLDivElement>,
  roleValue: UseRoleProps['role'],
  dismissProps?: UseDismissProps | boolean,
) => {
  const opt = typeof type !== 'string' ? type : undefined;
  const action = typeof type === 'string' ? type : type.type;
  const hover = useHover<HTMLDivElement>(context, {
    enabled: action === 'hover' || action === 'all',
    handleClose: safePolygon({ requireIntent: false }),
    ...opt,
  });
  const click = useClick<HTMLDivElement>(context, {
    enabled: action === 'click' || action === 'all',
    ...opt,
  });
  const role = useRole(context, { role: roleValue });
  const dismiss = useDismiss(
    context,
    typeof dismissProps === 'boolean'
      ? {
          enabled: dismissProps,
        }
      : dismissProps,
  );

  return useInteractions([hover, click, dismiss, role]);
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  wrapper: css``,
  floatManager: css`
    z-index: ${ZIndex.tooltip};
  `,
  floater: css`
    background-color: ${theme.tooltip.background};
    border-radius: ${theme.tooltip.borderRadius};
    padding: ${theme.tooltip.padding};
    box-shadow: 1px 1px 2px 0.5px ${theme.tooltip.shadow};
    overflow: hidden;
  `,
});
