import React, { AllHTMLAttributes } from 'react';

import { css, cx } from '@emotion/css';

type HtmlTag = {
  [key in keyof JSX.IntrinsicElements]: JSX.IntrinsicElements[key] extends AllHTMLAttributes<HTMLDivElement>
    ? key
    : never;
}[keyof JSX.IntrinsicElements];

type JustifyContent =
  | 'space-around'
  | 'space-between'
  | 'space-evenly'
  | 'stretch'
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'start';

type AlignItems =
  | 'center'
  | 'end'
  | 'flex-end'
  | 'flex-start'
  | 'self-end'
  | 'self-start'
  | 'start'
  | 'baseline'
  | 'normal'
  | 'stretch';

type FlexLayoutProps = AllHTMLAttributes<HTMLDivElement> & {
  justify?: JustifyContent;
  align?: AlignItems;
  shrink?: number | boolean;
  grow?: number | boolean;
  Component?: HtmlTag;
  htmlRef?: React.ForwardedRef<HTMLDivElement>;
};

export const Col: React.FC<FlexLayoutProps> = props => (
  <Flex direction="column" {...props} />
);

export const Row: React.FC<FlexLayoutProps> = props => (
  <Flex direction="row" {...props} />
);

/************************
 *         BASE         *
 ************************/

type GenericFlexLayoutProps = AllHTMLAttributes<HTMLDivElement> &
  FlexLayoutProps & {
    direction: 'row' | 'column';
  };

const _Flex: React.FC<GenericFlexLayoutProps> = ({
  className,
  direction,
  align = '',
  justify = '',
  shrink = true,
  grow = false,
  Component = 'div',
  htmlRef,
  ...props
}): JSX.Element => {
  const flexGrow = typeof grow === 'number' ? grow : grow ? 1 : 0;
  const flexShrink = typeof shrink === 'number' ? shrink : shrink ? 1 : 0;

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <Component
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      ref={htmlRef}
      className={cx(
        css`
          display: flex;
          flex-direction: ${direction};
          justify-content: ${justify};
          align-items: ${align};
          flex-grow: ${flexGrow};
          flex-shrink: ${flexShrink};
        `,
        className,
      )}
      {...props}
    />
  );
};

const Flex = React.memo(_Flex);
