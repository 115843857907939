/* eslint-disable */
// @ts-nocheck
import React from 'react';

import { toastError } from '@/components/toast/ToastComponent';
import { useKeycloak } from '@/keycloak';

import { useTypedDispatch } from './useTypedDispatch';

// This hook abstracts away the complexity of dealing with
// Auth0 (for token retrieval) and Redux (for dispatching
// actions).

// Usage is fairly straightforward. Here's an example:
//
// import { getUserProfile } from '@/state/actions';
// export default function MyComponent() {
//   const { isAuthenticated, dispatchAction } = useApi();
//
//   React.useEffect(() => {
//     if (isAuthenticated) dispatchAction(getUserProfile);
//   }, [isAuthenticated, dispatchAction]);

// Note that we can also use this to dispatch from another
// functions linked to a toggle or a button. For example:
//
// const toggleStatus = function () {
//   dispatchAction(patchHealthProfessional, user.user, {
//     status: healthProfessional.status === 'job' ? 'away' : 'job',
//   });
// };

type DPTCH = (value: any) => any;

export const useApi = () => {
  //  const { getAccessTokenSilently, isAuthenticated, isLoading } = useAuth0();
  const keycloak = useKeycloak();

  const dispatch = useTypedDispatch();

  const dispatchAction = React.useCallback(
    async function (dispatchAction: DPTCH, ...rest: unknown[]) {
      try {
        const accessToken = keycloak.token;
        return dispatch(dispatchAction(accessToken, ...rest));
      } catch (error) {
        toastError(
          error && typeof error === 'object' && 'message' in error
            ? (error.message as string)
            : '',
        );
        return undefined;
      }
    },
    [dispatch, keycloak.token],
  );

  const dispatchActionWithAuth = React.useCallback(
    async function (dispatchAction: DPTCH, actionPayload = {}) {
      try {
        const accessToken = keycloak.token;
        return dispatch(
          dispatchAction({ token: accessToken, ...actionPayload }),
        );
      } catch (error) {
        toastError(
          error && typeof error === 'object' && 'message' in error
            ? (error.message as string)
            : '',
        );
        return undefined;
      }
    },
    [dispatch, keycloak.token],
  );

  return { dispatchAction, dispatchActionWithAuth };
  //  return { isAuthenticated, isLoading, dispatchAction, dispatchActionWithAuth };
};
