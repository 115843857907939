import React from 'react';

import { css } from '@emotion/css';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { CardTitle } from '@/components/card/CardTitle';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { PatientWithCare } from '@/models/PatientWithCareModel';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

type PatientOverviewProps = {
  patient: PatientWithCare;
  className?: string;
};

export const PatientOverview: React.FC<PatientOverviewProps> = ({
  patient,
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const navigate = useNavigate();

  return (
    <Card className={className} elevation={0}>
      <CardTitle style={{ justifyContent: 'space-between' }}>
        <Typo Component="h3" type="subtitle">
          {t('pages.patient_monitoring.patient_overview.informations_patient')}
        </Typo>
        <div>
          <IconButton
            style={{ marginRight: 0, marginLeft: 0, paddingRight: 0 }}
            icon={Icons.externalLink}
            buttonType="link"
            placement="left"
            onClick={() => navigate(`/patients/${patient.id}/medical-file`)}
          />
        </div>
      </CardTitle>
      <CardBody className={styles.body}>
        <Age patient={patient} />
        <DiabetesTypeComponent patient={patient} />
        <InjectionSchemeComponent patient={patient} />
      </CardBody>
    </Card>
  );
};

type ItemProps = {
  patient: PatientWithCare;
};

const Age: React.FC<ItemProps> = ({ patient }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Typo type="paragraphSmall" className={styles.text}>
      {`${moment().diff(moment(patient.birthDate), 'years')} ${t(
        'pages.patient_monitoring.patient_overview.ans',
      )}`}
    </Typo>
  );
};

const DiabetesTypeComponent: React.FC<ItemProps> = ({ patient }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return patient.currentCarePlan ? (
    <Typo type="paragraphSmall" className={styles.text}>
      {t(
        `pages.patient_monitoring.patient_overview.diabete_${patient.currentCarePlan.diabetesType}`,
      )}
    </Typo>
  ) : (
    <Typo type="novalue" className={styles.text}>
      {t(
        'pages.patient_monitoring.patient_overview.type_diabete_non_renseigne',
      )}
    </Typo>
  );
};

const InjectionSchemeComponent: React.FC<ItemProps> = ({ patient }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return patient.currentCarePlan ? (
    <Typo type="paragraphSmall" className={styles.text}>
      {t(
        `pages.patient_monitoring.patient_overview.${patient.currentCarePlan.insulinTreatment}`,
      )}
    </Typo>
  ) : (
    <Typo type="novalue" className={styles.text}>
      {t(
        'pages.patient_monitoring.patient_overview.schema_insulinique_non_renseigne',
      )}
    </Typo>
  );
};

const makeStyles = (theme: Theme) => ({
  body: css``,
  text: css`
    margin: ${Dimensions.XXS} 0;
  `,
  footer: css`
    justify-content: flex-end;
    padding-bottom: ${theme.card.footer.paddingTop};
  `,
});
