import React from 'react';

import { css, cx } from '@emotion/css';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { MenuTooltip } from '@/components/floating/MenuTooltip';
import { Col, Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Patient } from '@/models/PatientModel';
import {
  inseeTownshipToString,
  isBornInFrance,
} from '@/models/identity/FrenchIdentity';
import { Dimensions } from '@/theme/dimensions';

type PatientProfileProps = {
  patient: Patient;
};

export const PatientProfile: React.FC<PatientProfileProps> = ({ patient }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card className={styles.container} elevation={0}>
      <TextCardTitle title={t('pages.informations_generales')} />
      <CardBody className={styles.body}>
        <Col className={styles.content}>
          <Row className={styles.multiCol}>
            <Col className={styles.column}>
              <NameInfo patient={patient} />
              <SexInfo patient={patient} />
              <BirthInfo patient={patient} />
            </Col>
            <Col className={styles.column}>
              <PhoneInfo patient={patient} />
              <EmailInfo patient={patient} />
              <AddressInfo patient={patient} />
            </Col>
          </Row>
          <MenuTooltip
            placement="bottom-start"
            openOnHover
            className={styles.moreInfo}
            content={
              <Row className={cx(styles.multiCol, styles.tooltip)}>
                <Col className={styles.column}>
                  <Typo type="paragraph">{`${t('profile.firstname')} : ${
                    patient.givenName ?? t('profile.not_given')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.lastname')} : ${
                    patient.familyName ?? t('profile.not_given')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t(
                    'profile.identityFirstnames',
                  )} : ${
                    patient.identityFirstnames?.join(', ') ??
                    t('profile.not_given')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.identityLastname')} : ${
                    patient.identityLastname ?? t('profile.not_given')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.gender')} : ${
                    patient.gender == 'male'
                      ? t('common.male')
                      : patient.gender == 'female'
                      ? t('common.female')
                      : t('profile.not_given')
                  }`}</Typo>
                </Col>
                <Col className={styles.column}>
                  <BirthDateDetailsInfo patient={patient} />
                  <BirthLocationDetailsInfo patient={patient} />
                  <Typo type="paragraph">{`${t('profile.ins')} : ${
                    patient.regulatoryCountry === 'France' && patient.ins
                      ? patient.ins
                      : t('profile.not_given')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.oid')} : ${
                    patient.regulatoryCountry === 'France' && patient.oid
                      ? patient.oid
                      : t('profile.not_given')
                  }`}</Typo>
                  <Typo type="paragraph">{`${t('profile.ssn')} : ${
                    patient.socialSecurityNumber ?? t('profile.not_given')
                  }`}</Typo>
                </Col>
              </Row>
            }
          >
            <Row className={styles.itemRow}>
              <Typo type="paragraph">{t('profile.moreInfo')}</Typo>
              <Icons.chevronDown className={styles.icon} />
            </Row>
          </MenuTooltip>
        </Col>
      </CardBody>
    </Card>
  );
};

const NameInfo: React.FC<PatientProfileProps> = ({ patient }) => {
  if (!patient.givenName || !patient.familyName) {
    return null;
  }

  return (
    <Row>
      <Typo type="paragraph">{`${patient.givenName} ${patient.familyName}`}</Typo>
    </Row>
  );
};

const SexInfo: React.FC<PatientProfileProps> = ({ patient }) => {
  const { t } = useTranslation();

  if (!patient.gender) {
    return null;
  }
  return (
    <Typo type="paragraph">
      {patient.gender == 'male' ? t('common.male') : t('common.female')}
    </Typo>
  );
};

const BirthInfo: React.FC<PatientProfileProps> = ({ patient }) => {
  const { t } = useTranslation();

  if (!patient.birthDate || !patient.birthCountry) {
    return null;
  }
  const age = moment().diff(moment(patient.birthDate), 'years');
  const birthLocation =
    patient.regulatoryCountry === 'France' && isBornInFrance(patient)
      ? `${t('components.patient_info.place_of_birth', {
          context: patient.gender,
        })} ${inseeTownshipToString(patient.birthLocation)}, ${
          patient.birthCountry.name
        }`
      : `${t('components.patient_info.country_of_birth', {
          context: patient.gender,
        })} ${patient.birthCountry.name}`;

  return (
    <Typo type="paragraph">
      {`${age} ${t(
        'pages.patient_monitoring.patient_overview.ans',
      )}, ${birthLocation}`}
    </Typo>
  );
};

const PhoneInfo: React.FC<PatientProfileProps> = ({ patient }) => {
  const styles = useStyles(makeStyles);

  if (!patient.contactPhone) {
    return null;
  }
  return (
    <Row className={styles.itemRow}>
      <Icons.phone className={styles.icon} />
      <Typo type="paragraph">{patient.contactPhone}</Typo>
    </Row>
  );
};
const EmailInfo: React.FC<PatientProfileProps> = ({ patient }) => {
  const styles = useStyles(makeStyles);

  return (
    <Row className={styles.itemRow}>
      <Icons.email className={styles.icon} />
      <Typo type="paragraph">{patient.user.email}</Typo>
    </Row>
  );
};

const AddressInfo: React.FC<PatientProfileProps> = ({ patient }) => {
  const styles = useStyles(makeStyles);
  if (
    !patient.addressLine ||
    !patient.addressPostalCode ||
    !patient.addressCity
  ) {
    return null;
  }

  return (
    <Row className={styles.itemRow}>
      <Icons.mail className={styles.icon} />
      <Typo type="paragraph">
        {`${patient.addressLine}, ${patient.addressPostalCode} ${patient.addressCity}`}
      </Typo>
    </Row>
  );
};

const BirthDateDetailsInfo: React.FC<PatientProfileProps> = ({ patient }) => {
  const { t } = useTranslation();
  if (!patient.birthDate) {
    return (
      <Typo type="paragraph">{`${t('profile.birthDate')} : ${t(
        'profile.not_given',
      )}`}</Typo>
    );
  }
  const age = moment().diff(moment(patient.birthDate), 'years');
  const birthDate = moment(patient.birthDate).format('DD/MM/YYYY');
  const ageText = `(${age} ${t(
    'pages.patient_monitoring.patient_overview.ans',
  )})`;

  return (
    <Typo type="paragraph">{`${t(
      'profile.birthDate',
    )} : ${birthDate} ${ageText}`}</Typo>
  );
};

const BirthLocationDetailsInfo: React.FC<PatientProfileProps> = ({
  patient,
}) => {
  const { t } = useTranslation();
  if (!patient.regulatoryCountry || !patient.birthCountry) {
    return (
      <Typo type="paragraph">{`${t('profile.birthPlace')} : ${t(
        'profile.not_given',
      )}`}</Typo>
    );
  }
  const birthLocation =
    patient.regulatoryCountry === 'France' && isBornInFrance(patient)
      ? `${t('components.patient_info.place_of_birth', {
          context: patient.gender,
        })} ${inseeTownshipToString(patient.birthLocation)}, ${
          patient.birthCountry.name
        }`
      : `${t('components.patient_info.country_of_birth', {
          context: patient.gender,
        })} ${patient.birthCountry.name}`;

  return (
    <Typo type="paragraph">{`${t(
      'profile.birthPlace',
    )} : ${birthLocation}`}</Typo>
  );
};

const makeStyles = () => ({
  container: css`
    margin-top: ${Dimensions.M};
  `,
  body: css``,
  content: css`
    width: 100%;
  `,
  multiCol: css`
    gap: ${Dimensions.XL};
  `,
  column: css`
    gap: ${Dimensions.XS};
  `,
  itemRow: css`
    align-items: center;
  `,
  moreInfo: css`
    margin-top: ${Dimensions.M};
    width: fit-content;
  `,
  tooltip: css`
    margin: ${Dimensions.M};
  `,
  icon: css`
    width: ${Dimensions.font.S};
    height: ${Dimensions.font.S};
    margin: 0 ${Dimensions.XS};
  `,
});
