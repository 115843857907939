import React, { useContext, useEffect, useState } from 'react';

import { css } from '@emotion/css';

import usePatientIdFromURL from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useTheme';
import { Message } from '@/models/ChatModel';
import { Queries } from '@/queries/Queries';

import { Loader } from '../loading/Loader';
import { ChatFilterContext, MessagesByStateContext } from './ChatContext';
import { ChatBody } from './body/ChatBody';
import { ChatFooter } from './footer/ChatFooter';
import { ChatHeader } from './header/ChatHeader';

export const ChatContent = () => {
  const selectedFilter = useContext(ChatFilterContext);
  const patientId = usePatientIdFromURL() ?? '';
  const [messages, setMessages] = useState<Message[]>([]);
  const styles = useStyles(makeStyles);

  const messageRequest = Queries.chat.useChat({
    subjectId: patientId,
    visibility: selectedFilter?.filter,
  });

  useEffect(() => {
    const messages = messageRequest.isSuccess ? messageRequest.data : [];
    setMessages(messages);
  }, [messageRequest.data, messageRequest.isSuccess]);

  return (
    <>
      <ChatHeader />
      <MessagesByStateContext.Provider value={{ messages, setMessages }}>
        {messageRequest.isSuccess && <ChatBody />}
        {messageRequest.isLoading && (
          <div className={styles.loader}>
            <Loader size="L" />
          </div>
        )}
        <ChatFooter></ChatFooter>
      </MessagesByStateContext.Provider>
    </>
  );
};

const makeStyles = () => ({
  loader: css`
    width: 100%;
    aspect-ratio: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
});
