import { ObservationsRepository } from '@/io/repository/ObservationsRepository';
import {
  CreateNurseNotice,
  DeleteNurseNotice,
  NurseNotice,
} from '@/models/NurseNoticeModel';
import { Observation } from '@/models/ObservationModel';
import { PractitionerQueries } from '@/queries/PractitionerQueries';
import {
  createAppMutation,
  createAppQuery,
  stripQueryResult,
} from '@/queries/QueryClient';

export class ObservationsQueries {
  constructor(
    private readonly practitionerQueries: PractitionerQueries = new PractitionerQueries(),
    private readonly observations: ObservationsRepository = new ObservationsRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useObservations = createAppQuery<
    Observation[],
    { practitionerId: string; patientId: string }
  >({
    queryKey: variables => [
      'observations',
      variables.patientId,
      variables.practitionerId,
    ],
    queryFn: async ({ patientId, practitionerId }) => {
      const observations = stripQueryResult(
        await this.observations.getObservations(patientId),
      );
      const countObservationToRead = observations.filter(
        obs => !obs.read && practitionerId !== obs.author.id && obs.description,
      ).length;
      if (countObservationToRead > 0) {
        const readObservations = stripQueryResult(
          await this.observations.readObservations(patientId),
        ).reduce((acc, obs) => {
          acc.set(obs.id, obs);
          return acc;
        }, new Map<number, Observation>());
        await this.practitionerQueries.manuallyUpdatePatientsList(patients =>
          patients?.map(patient =>
            patient.id === patientId
              ? { ...patient, notifications: 0 }
              : patient,
          ),
        );
        return observations.map(obs => readObservations.get(obs.id) ?? obs);
      }

      return observations;
    },
  });

  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useCreateNotice = createAppMutation<NurseNotice, CreateNurseNotice>({
    mutationFn: async data =>
      stripQueryResult(await this.observations.createNotice(data)),
    onSuccess: notice => {
      this.practitionerQueries.invalidatePatientsList();
      this.practitionerQueries.manuallyUpdateObservedPatient(
        notice.subjectId,
        patient => {
          if (!patient) {
            return patient;
          } else {
            return {
              ...patient,
              nurseNotice: notice,
              hasNurseNotice: true,
            };
          }
        },
      );
    },
  });

  useDeleteNotice = createAppMutation<NurseNotice, DeleteNurseNotice>({
    mutationFn: async data =>
      stripQueryResult(await this.observations.deleteNotice(data)),
    onSuccess: notice => {
      this.practitionerQueries.invalidatePatientsList();
      this.practitionerQueries.invalidateObservedPatient(notice.subjectId);
    },
  });

  useMitigateNotice = createAppMutation<NurseNotice, DeleteNurseNotice>({
    mutationFn: async data =>
      stripQueryResult(await this.observations.mitigateNotice(data)),
    onSuccess: notice => {
      this.practitionerQueries.invalidatePatientsList();
      this.practitionerQueries.invalidateObservedPatient(notice.subjectId);
    },
  });

  /*********************************/
  /************ HELPERS ************/
  /*********************************/
}
