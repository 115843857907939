import React from 'react';

import { cx } from '@emotion/css';

import { FlexBox } from '@/components/box';
import Typography from '@/components/typography/Typography';
import { isEmpty } from '@/utils';

type UserPictureProps = {
  givenName?: string;
  familyName?: string;

  picture?: React.ReactElement;
  height?: number;
  width?: number;
  lettersSize?: string;
  className?: string;
};
export const UserPicture: React.FC<UserPictureProps> = ({
  givenName,
  familyName,
  picture,
  height = 10,
  width = 10,
  lettersSize = 'base',
  className,
}) => {
  const initials =
    givenName && familyName
      ? givenName.slice(0, 1) + familyName.slice(0, 1)
      : '';

  return (
    <FlexBox
      className={cx(
        `w-${width} h-${height} bg-primary-200 rounded-full justify-center items-center`,
        className,
      )}
    >
      {picture ? (
        picture
      ) : (
        <Typography
          fontWeight="bold"
          className={`text-primary-600 text-${lettersSize} uppercase`}
        >
          {isEmpty(initials) ? '' : initials}
        </Typography>
      )}
    </FlexBox>
  );
};
