import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import {
  CreateNurseNotice,
  DeleteNurseNotice,
  NurseNotice,
  nurseNoticeDecoder,
} from '@/models/NurseNoticeModel';
import { Observation, observationDecoder } from '@/models/ObservationModel';
import { Result } from '@/utils/Result';
import { forcedArray } from '@/utils/decoderUtils';

export class ObservationsRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  /*
  For nurse only
   */
  createNotice = (
    data: CreateNurseNotice,
  ): Promise<Result<NurseNotice, ApiErrorResponse>> => {
    return this.datasource.post(
      `nurse-notices/${data.patientId}/`,
      nurseNoticeDecoder,
      {
        detail: data.detail,
      },
    );
  };

  /*
  For nurse only
   */
  deleteNotice = (
    data: DeleteNurseNotice,
  ): Promise<Result<NurseNotice, ApiErrorResponse>> => {
    return this.datasource.delete(
      `nurse-notices/${data.patientId}/${data.noticeId}/`,
      nurseNoticeDecoder,
    );
  };

  /*
  For doctor only
  Currently same as deleteNotice. Might change in the future.
   */
  mitigateNotice = (
    data: DeleteNurseNotice,
  ): Promise<Result<NurseNotice, ApiErrorResponse>> => {
    return this.datasource.delete(
      `nurse-notices/${data.patientId}/${data.noticeId}/`,
      nurseNoticeDecoder,
    );
  };

  getObservations = (
    id: string,
  ): Promise<Result<Observation[], ApiErrorResponse>> => {
    return this.datasource.get(
      `observations/${id}/`,
      forcedArray(observationDecoder),
    );
  };

  readObservations = (
    id: string,
  ): Promise<Result<Observation[], ApiErrorResponse>> => {
    return this.datasource.patch(
      `observations/${id}/read/`,
      forcedArray(observationDecoder),
    );
  };
}
