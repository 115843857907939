/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment';

import { toString } from '.';
import { request, requestMultiple } from '../protocols/hid';
import { executeHidRequest } from '../protocols/hid';
import { Command } from '../types';

const CRLF = '\r\n';
const CR = '\r';

enum RESPONSE {
  RECORD_ID = 0,
  RECORD_TYPE = 1,
  MONTH = 2,
  DAY = 3,
  YEAR = 4,
  HOUR = 5,
  MINUTE = 6,
  SECONDE = 7,
  READING_TYPE = 9,
  MANU_VALUE = 12,
  HISTORY_VALUE = 13,
  SENSOR_RUNTIME_MINUTES = 14,
  HISTORY_ERROR_BITFIELD = 15,
  SPORT_FLAG = 15,
  MEDICATION_FLAG = 16,
  RAPID_ACTING_INSULIN_FLAG = 17,
  RAPID_ACTING_INSULIN_VALUE = 43,
  LONG_ACTING_INSULIN_FLAG = 18,
  LONG_ACTING_INSULIN_VALUE = 23,
  CUSTOM_COMMENTS_BITFIELD = 19,
  FOOD_FLAG = 25,
  FOOD_CARBS_GRAMS_VALUE = 26,
  MANU_ERROR_BITFIELD = 28,
  CUSTOM_COMMENTS_1 = 29,
  CUSTOM_COMMENTS_2 = 30,
  CUSTOM_COMMENTS_3 = 31,
  CUSTOM_COMMENTS_4 = 32,
  CUSTOM_COMMENTS_5 = 33,
  CUSTOM_COMMENTS_6 = 34,
}

export async function freestylelibre(vendorId: number, productId: number) {
  return executeHidRequest(vendorId, productId, async (device: any) => {
    const init = async () => {
      await request({ device, command: Command.INIT_REQUEST_1 });
      await request({ device, command: Command.INIT_REQUEST_4 });
    };

    const getBrandName = async () => {
      const value: Uint8Array = await request({
        device,
        command: Command.TEXT_REQUEST,
        data: '$brandname?',
      });
      const str = toString(value);
      return str.substring(2, str.search(CRLF));
    };

    const getSerialNumber = async () => {
      const value = await request({
        device,
        command: Command.TEXT_REQUEST,
        data: '$sn?',
      });
      return toString(value).substring(2, 15);
    };

    const getSoftwareVersion = async () => {
      const str = toString(
        await request({
          device,
          command: Command.TEXT_REQUEST,
          data: '$swver?',
        }),
      );
      return str.substring(2, str.search(CRLF));
    };

    const getRecordsCount = async () => {
      const str = toString(
        await request({
          device,
          command: Command.TEXT_REQUEST,
          data: '$dbrnum?',
        }),
      );
      const index = str.search('=') + 1;
      return parseInt(str.substring(index));
    };

    const u8ArrayToString = (frame: Uint8Array): string =>
      frame.reduce((acc, cur) => acc + String.fromCharCode(cur), '');

    const parseDate = (frame: any) => {
      const date: moment.MomentInput = {
        year: parseInt(`20${frame.year}`),
        month: frame.month - 1,
        date: frame.day,
        hour: parseInt(frame.hour, 10),
        minute: frame.min,
        second: frame.sec,
      };
      return moment(date).format();
    };

    const parseHistory = (history: string[]) => {
      if (history.length !== 16) return null;
      const obj = {
        sequenceNumber: history[RESPONSE.RECORD_ID],
        month: history[RESPONSE.MONTH],
        day: history[RESPONSE.DAY],
        year: history[RESPONSE.YEAR],
        hour: history[RESPONSE.HOUR],
        min: history[RESPONSE.MINUTE],
        sec: history[RESPONSE.SECONDE],
        glucoseConcentration:
          parseInt(history[RESPONSE.HISTORY_VALUE]) / 100000,
        sensorRuntimeMinutes: history[RESPONSE.SENSOR_RUNTIME_MINUTES],
        errorBitField: history[RESPONSE.HISTORY_ERROR_BITFIELD],
      };
      if (obj.errorBitField !== '0') return null;
      return obj;
    };

    const parseArresult = (arresult: string[]) => {
      if (arresult.length !== 35) return null;
      const obj = {
        sequenceNumber: arresult[RESPONSE.RECORD_ID],
        recordType: arresult[RESPONSE.RECORD_TYPE],
        month: arresult[RESPONSE.MONTH],
        day: arresult[RESPONSE.DAY],
        year: arresult[RESPONSE.YEAR],
        hour: arresult[RESPONSE.HOUR],
        min: arresult[RESPONSE.MINUTE],
        sec: arresult[RESPONSE.SECONDE],
        readingType: arresult[RESPONSE.READING_TYPE],
        glucoseConcentration: parseInt(arresult[RESPONSE.MANU_VALUE]) / 100000,
        sportFlag: arresult[RESPONSE.SPORT_FLAG],
        medicationFlag: arresult[RESPONSE.MEDICATION_FLAG],
        rapidActingInsulinFlag: arresult[RESPONSE.RAPID_ACTING_INSULIN_FLAG],
        rapidActingInsulinValue: arresult[RESPONSE.RAPID_ACTING_INSULIN_VALUE],
        longActingInsulinFlag: arresult[RESPONSE.LONG_ACTING_INSULIN_FLAG],
        longActingInsulinValue: arresult[RESPONSE.LONG_ACTING_INSULIN_VALUE],
        foodFlag: arresult[RESPONSE.FOOD_FLAG],
        foodValue: arresult[RESPONSE.FOOD_CARBS_GRAMS_VALUE],
        customCommentsBitField: arresult[RESPONSE.CUSTOM_COMMENTS_BITFIELD],
        customComment1: arresult[RESPONSE.CUSTOM_COMMENTS_1],
        customComment2: arresult[RESPONSE.CUSTOM_COMMENTS_2],
        customComment3: arresult[RESPONSE.CUSTOM_COMMENTS_3],
        customComment4: arresult[RESPONSE.CUSTOM_COMMENTS_4],
        customComment5: arresult[RESPONSE.CUSTOM_COMMENTS_5],
        customComment6: arresult[RESPONSE.CUSTOM_COMMENTS_6],
        errorBitField: arresult[RESPONSE.MANU_ERROR_BITFIELD],
      };
      if (obj.glucoseConcentration === 0) return null;
      return obj;
    };

    const getHistory = async (serial: string) => {
      const frames = await requestMultiple({
        device,
        command: Command.TEXT_REQUEST,
        data: '$history?',
      });
      const stringifyFrame = frames.map(frame => u8ArrayToString(frame));
      const historyFrame = stringifyFrame.map(item =>
        item.split(CRLF)[0].slice(2),
      );
      const history = historyFrame
        .map(item => parseHistory(item.split(',')))
        .filter(item => item)
        .map(item => ({
          ...item,
          device: serial,
          baseTime: parseDate(item),
          flags: { glucoseConcentrationInMol: 0 },
          type: 'Interstitial',
        }));
      return history;
    };

    const getArresult = async (serial: string) => {
      const frames = await requestMultiple({
        device,
        command: Command.TEXT_REQUEST,
        data: '$arresult?',
      });
      const stringifyFrame = frames.map(frame => u8ArrayToString(frame));
      const globalString = stringifyFrame.reduce((acc, curr) => acc + curr, '');
      const arresultFrames = globalString
        .split('`>')
        .map(item => item.split(CR)[0]);

      const splittedArresultFrames = arresultFrames
        .map(item => item.split(','))
        .map(item => parseArresult(item))
        .filter(item => item)
        .map((item: any) => {
          if (
            item &&
            item.recordType === '2' &&
            ['0', '2'].includes(item.readingType) &&
            item.glucoseConcentration !== '0'
          ) {
            item.type = item.readingType === '0' ? 'Capillary' : 'Interstitial';
          }
          return {
            ...item,
            device: serial,
            baseTime: parseDate(item),
            flags: { glucoseConcentrationInMol: 0 },
            type: 'Interstitial',
          };
        });
      return splittedArresultFrames;
    };

    await init();
    const obj: any = {};
    obj.name = await getBrandName();
    const serial = await getSerialNumber();
    obj.serial = serial;
    obj.softwareVersion = await getSoftwareVersion();
    obj.recordsCount = await getRecordsCount();
    const history = await getHistory(serial);
    const arresult = await getArresult(serial);
    obj.measurements = history.concat(arresult);
    return obj;
  });
}
