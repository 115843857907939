import React from 'react';

import moment from 'moment';
import ReactCalendar, {
  CalendarTileProperties,
  CalendarProps as ReactCalendarProps,
} from 'react-calendar';

import { Icons } from '@/assets/icons';
import Typography from '@/components/typography/Typography';
import { capitalizeChar, isSameDate } from '@/utils';

import './CalendarComponent.scss';

type CalendarProps = ReactCalendarProps & {
  date: Date;
  onDateChange: (date: Date) => void;
  refDate?: Date;
  className?: string;
};

export default function Calendar({
  date,
  onDateChange,
  refDate,
  className = '',
  ...rest
}: CalendarProps) {
  const { current: today } = React.useRef(moment().startOf('d').toDate());

  const formatWeekday = (_: unknown, date: Date) =>
    capitalizeChar(moment(date).format('dd').charAt(0));

  return (
    <ReactCalendar
      className={'max-w-3xs ' + className}
      value={date}
      onClickDay={value => onDateChange(value)}
      navigationLabel={({ label }) => <NavigationLabel label={label} />}
      prevLabel={<Icons.chevronLeft className="calendar-custom-navigator" />}
      nextLabel={<Icons.chevronRight className="calendar-custom-navigator" />}
      prev2Label={null}
      next2Label={null}
      formatShortWeekday={formatWeekday}
      tileClassName={tile =>
        `calendar-custom-tile ${tileStyle({
          tile,
          date,
          today,
          refDate,
          disabledPredicate: rest.tileDisabled,
        })}`
      }
      defaultView="month"
      {...rest}
    />
  );
}

type TileStylesArgs = {
  tile: CalendarTileProperties;
  date: Date;
  today: Date;
  refDate?: Date;
  disabledPredicate?: (props: CalendarTileProperties) => boolean;
};

const tileStyle = ({
  tile,
  date,
  today,
  refDate,
  disabledPredicate,
}: TileStylesArgs) => {
  if (isSameDate(tile.date, refDate)) {
    return 'text-primary-600 bg-primary-200';
  }
  if (isSameDate(tile.date, date)) {
    return 'text-white bg-primary-600';
  }
  if (disabledPredicate) {
    if (disabledPredicate(tile)) {
      return 'text-gray-300';
    }
  } else if (tile.date < today) {
    return 'text-gray-300';
  }
  return '';
};

const NavigationLabel = ({ label }: { label: string }) => (
  <Typography className="mb-4" fontWeight="bold">
    {capitalizeChar(label)}
  </Typography>
);
