import React from 'react';

import LogoSteto from '@/assets/icons/logo-steto-smallround.png';
import { ReactComponent as PumpIcon } from '@/assets/icons/pump.svg';
import { ReactComponent as ReaderIcon } from '@/assets/icons/reader.svg';
import { ReactComponent as SensorIcon } from '@/assets/icons/sensor.svg';
import { DeviceType } from '@/state/devices/types';

import styles from './manufacturerLogo.module.scss';

type ManufacturerLogoProps = {
  deviceType: DeviceType;
};

export default function DeviceTypeLogo({ deviceType }: ManufacturerLogoProps) {
  switch (deviceType) {
    case DeviceType.MANUAL:
      return <div className={styles.deviceType} />;
    case DeviceType.PUMP:
      return <PumpIcon className={styles.deviceIcons} />;
    case DeviceType.READER:
      return <ReaderIcon className={styles.deviceIcons} />;
    case DeviceType.REMOTE_MONITORING:
      return <img src={LogoSteto} alt="STETO" className={styles.deviceIcons} />;
    case DeviceType.SENSOR:
      return <SensorIcon className={styles.deviceIcons} />;
    default:
      return null;
  }
}
