import {
  DiabetesParameters,
  GlycemiaMeasureType,
  InsulinType,
  ReportCategory,
} from '@/models/DiabetesDataModel';

export type Periodicity = {
  durationInDays: number;
  label: string;
  index: number;
};
export type Periodicities = Periodicity[];

type BaseDataPoint = {
  id: number;
  date: string;
  patientId: string;
};

export enum GlycemiaTypeEnum {
  CAPILLARY = 'capillary',
  INTERSTITIAL = 'interstitial',
}

export type GlycemiaDataPoint = BaseDataPoint & {
  value: number;
  type: GlycemiaMeasureType;
};

export type GlycemiaTypes = {
  glycemiaTypes: {
    glycemiaReader: GlycemiaDataPoint[];
    glycemiaSensor: GlycemiaDataPoint[];
    glycemiaManual: GlycemiaDataPoint[];
  };
};

export type InsulinContext =
  | 'before_meal'
  | 'after_meal'
  | 'correction'
  | 'instant'
  | 'extended';

export type InsulinDataPoint = BaseDataPoint & {
  quantity: number;
  type?: InsulinType;
  is_temp?: boolean;
  context?: InsulinContext;
};

export type MealSize = 'light' | 'big' | 'unknown';

export type FoodDataPoint = BaseDataPoint & {
  size: MealSize;
};

export type ActivityDataPoint = BaseDataPoint & {
  intensity: 'low' | 'medium' | 'high';
  duration: number;
};

export type ReportsDataPoint = BaseDataPoint & {
  category: ReportCategory;
  message: string;
};

export type PatientDiabetesDailyData = {
  date: string;
  parameters: DiabetesParameters;
  glycemia: GlycemiaDataPoint[];
  insulin: InsulinDataPoint[];
  food: FoodDataPoint[];
  activity: ActivityDataPoint[];
  reports: ReportsDataPoint[];
};
