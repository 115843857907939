import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

import { Row } from '../layout/Flex';

export type SidePeekBodyProps = PropsWithChildren<{
  className?: string;
}>;

const SidePeekFooter = ({ className, children }: SidePeekBodyProps) => {
  const styles = useStyles(makeStyles);
  return (
    <Row
      justify="flex-end"
      align="flex-end"
      shrink
      className={cx(styles.actions, className)}
    >
      {children}
    </Row>
  );
};

export default SidePeekFooter;

const makeStyles = (theme: Theme) => ({
  actions: css`
    column-gap: ${theme.sideSheet.actionsSpacing};
    justify-content: center;
  `,
});
