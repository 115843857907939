import i18n from 'i18next';
import detector from 'i18next-browser-languagedetector';
import moment from 'moment-timezone';
import { initReactI18next } from 'react-i18next';

import enlangJSON from '@/locales/en-US.json';
import frlangJSON from '@/locales/fr-FR.json';

export enum LangsEnum {
  EN = 'en',
  FR = 'fr',
}

const LANGUAGES = {
  en: { translation: enlangJSON },
  fr: { translation: frlangJSON },
} as const;

i18n
  .use(detector)
  .use(initReactI18next)
  .init({
    resources: LANGUAGES,
    lng: LangsEnum.FR,
    fallbackLng: LangsEnum.FR,

    interpolation: {
      escapeValue: false,
    },
  })
  .then(() => {
    moment.locale(i18n.language);
    document.documentElement.lang = i18n.language;
  });

i18n.on('languageChanged', lng => {
  moment.locale(lng);
  document.documentElement.lang = lng;
});

export default i18n;
