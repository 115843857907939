import { AlertsQueries } from '@/queries/AlertsQueries';
import { ChatQueries } from '@/queries/ChatQueries';
import { DiabetesQueries } from '@/queries/DiabetesQueries';
import { ObservationsQueries } from '@/queries/ObservationsQueries';
import { PatientQueries } from '@/queries/PatientQueries';
import { PractitionerQueries } from '@/queries/PractitionerQueries';
import { UserQueries } from '@/queries/UserQueries';

const practitioner = new PractitionerQueries();

export const Queries = {
  alerts: new AlertsQueries(practitioner),
  observations: new ObservationsQueries(practitioner),
  patient: new PatientQueries(),
  practitioner,
  user: new UserQueries(),
  diabetes: new DiabetesQueries(),
  chat: new ChatQueries(),
};
