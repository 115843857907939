import React from 'react';

import { css } from '@emotion/css';
import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/button/Button';
import { Card } from '@/components/card/Card';
import { Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Patient } from '@/models/PatientModel';
import {
  FrenchIdentity,
  isBornInFrance,
} from '@/models/identity/FrenchIdentity';
import { ValidateOrRejectIdentity } from '@/models/identity/Identity';
import { Dimensions } from '@/theme/dimensions';

export type ValidatePatientCardProps = {
  patient: Patient;
  identity: FrenchIdentity;
  submitting: boolean;
  validateOrReject: (action: ValidateOrRejectIdentity) => void;
};

export const ValidatePatientCard: React.FC<ValidatePatientCardProps> = ({
  patient,
  identity,
  submitting,
  validateOrReject,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const birthLocation = isBornInFrance(identity)
    ? `${identity.birthLocation.town} (${identity.birthLocation.postCode.slice(
        0,
        2,
      )})`
    : identity.birthCountry.name;

  return (
    <Card elevation={2} className={styles.item}>
      <Typo type="subtitle" className={styles.itemTitle}>{`${
        patient.givenName ?? ''
      } ${patient.familyName}`}</Typo>
      <table>
        <tbody>
          <PatientLine
            labelClassname={styles.lineLabel}
            label={t('pages.identityValidation.firstnames')}
            value={identity.identityFirstnames.join(', ')}
          />
          <PatientLine
            labelClassname={styles.lineLabel}
            label={t('pages.identityValidation.lastname')}
            value={identity.identityLastname}
          />
          <PatientLine
            labelClassname={styles.lineLabel}
            label={t('pages.identityValidation.sex')}
            value={t(
              'common.' + (identity.gender === 'male' ? 'homme' : 'femme'),
            )}
          />
          <PatientLine
            labelClassname={styles.lineLabel}
            label={t('pages.identityValidation.birthDate')}
            value={moment(identity.birthDate).format('DD/MM/YYYY')}
          />
          <PatientLine
            labelClassname={styles.lineLabel}
            label={t('pages.identityValidation.birthPlace')}
            value={birthLocation}
          />
        </tbody>
      </table>
      <Row className={styles.actions}>
        <Button
          status={submitting ? 'pending' : 'idle'}
          buttonType="critical"
          onClick={() => validateOrReject('reject')}
        >
          {t('pages.identityValidation.reject')}
        </Button>
        <Button
          status={submitting ? 'pending' : 'idle'}
          buttonType="primary"
          onClick={() => validateOrReject('validate')}
        >
          {t('pages.identityValidation.validate')}
        </Button>
      </Row>
    </Card>
  );
};

export type PatientLineProps = {
  label: string;
  value: string;
  labelClassname?: string;
};
const PatientLine: React.FC<PatientLineProps> = ({
  label,
  value,
  labelClassname,
}) => {
  return (
    <tr>
      <td className={labelClassname}>
        <Typo type="paragraph">{label}</Typo>
      </td>
      <td>
        <Typo type="paragraph">{value}</Typo>
      </td>
    </tr>
  );
};

const makeStyles = () => ({
  item: css`
    padding: ${Dimensions.M};
  `,
  itemTitle: css`
    padding-bottom: ${Dimensions.S};
  `,
  lineLabel: css`
    padding-right: ${Dimensions.M};
  `,
  list: css`
    padding: ${Dimensions.S} ${Dimensions.XXS};
    gap: ${Dimensions.S};
  `,
  actions: css`
    padding-top: ${Dimensions.M};
    gap: ${Dimensions.M};
    justify-content: flex-end;
  `,
  reject: css``,
});
