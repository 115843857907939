import React from 'react';

import { css } from '@emotion/css';

import { Icons } from '@/assets/icons';
import { Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

type PdfOverlayProps = {
  currentPage: number;
  numPages: number;
  changePage: (action: 'next' | 'prev') => void;
  visible: boolean;
};

export const PdfOverlay: React.FC<PdfOverlayProps> = ({
  currentPage,
  numPages,
  changePage,
  visible,
}) => {
  const styles = useStyles(makeStyles, visible);

  return (
    <div className={styles.overlay}>
      <Row className={styles.nav}>
        <div
          className={styles.iconWrapper(currentPage === 1)}
          onClick={() => changePage('prev')}
        >
          <Icons.arrowLeft className={styles.icon} />
        </div>
        <Typo
          type="paragraph"
          className={styles.text}
        >{`${currentPage} / ${numPages}`}</Typo>
        <div
          className={styles.iconWrapper(currentPage === numPages)}
          onClick={() => changePage('next')}
        >
          <Icons.arrowRight className={styles.icon} />
        </div>
      </Row>
    </div>
  );
};

const makeStyles = (theme: Theme, visible: boolean) => ({
  overlay: css`
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: ${visible ? 'flex' : 'none'};
    justify-content: center;
    align-items: flex-end;
    z-index: 10;
  `,
  nav: css`
    background-color: ${theme.backgroundColor};
    margin-bottom: ${Dimensions.M};
    border-radius: ${Dimensions.XS};
    align-items: center;
  `,
  iconWrapper: (disabled: boolean) => css`
    padding: ${Dimensions.XXS};
    border-radius: ${Dimensions.XS};
    fill: ${disabled ? 'unset' : theme.typography.light};
    cursor: pointer;
    :hover {
      background-color: ${theme.input.selectedBackground};
    }
  `,
  text: css`
    user-select: none;
  `,
  icon: css`
    width: ${Dimensions.font.L};
    height: ${Dimensions.font.L};
  `,
});
