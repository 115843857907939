import React, { useContext, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';
import Chip from '@/uiKit/Chip';

import { AttachmentContext } from './AttachmentContext';

type RecipientToggleProps = {
  onUpdate: (x: string) => void;
};

export const RecipientToggle = ({ onUpdate }: RecipientToggleProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const inputFile = useRef<HTMLInputElement | null>(null);
  const attachmentContext = useContext(AttachmentContext);

  const medicalStaffLabel = t('components.chat.medicalStaff');
  const everybodyLabel = t('common.everybody');
  const [label, setLabel] = useState(medicalStaffLabel);

  const onButtonClick = (e: React.MouseEvent) => {
    e.preventDefault();
    inputFile.current?.click();
  };

  const updateRecipientHandler = () => {
    let newLabel;
    if (label === medicalStaffLabel) {
      newLabel = everybodyLabel;
    } else {
      newLabel = medicalStaffLabel;
    }
    setLabel(newLabel);
    onUpdate(newLabel);
  };

  return (
    <div className={styles.container}>
      <Typo type={'subtitle'} className={styles.label}>
        {t('components.chat.recipient')}:
      </Typo>
      <Chip
        onClick={updateRecipientHandler}
        className={cx(
          label === medicalStaffLabel ? styles.staffBackgound : '',
          styles.chip,
        )}
      >
        <Typo type={'button'} className={styles.label}>
          {label}
        </Typo>
        <div>
          <Icons.chevronDown></Icons.chevronDown>
        </div>
      </Chip>

      <input
        multiple
        type="file"
        id="file"
        ref={inputFile}
        style={{ display: 'none' }}
        accept={[
          'application/pdf',
          'image/png',
          'image/jpeg',
          'image/jpg',
          'image/bmp',
          'image/webp',
          'image/svg+xml',
          'text/plain',
          'text/html',
          'text/csv',
          'text/calendar',
          'text/markdown',
          'audio/mpeg',
          'video/mp4',
          'application/zip',
        ].join(', ')}
        onChange={() => {
          if (inputFile.current && inputFile.current.files) {
            attachmentContext.setFiles?.(Array.from(inputFile.current.files));
          }
        }}
      />
      <div className={styles.uploadButtonContainer}>
        <Icons.fileUpload
          className={styles.uploadButton}
          onClick={onButtonClick}
        />
      </div>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  staffBackgound: css`
    background: ${theme.chat.color.sentBackground};
  `,
  chip: css`
    width: ${Dimensions.UL};
  `,
  container: css`
    display: inline-flex;
    align-items: center;
    gap: ${Dimensions.XS};
    padding-left: ${Dimensions.S};
    padding-top: ${Dimensions.S};
  `,
  label: css`
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 16px; /* 133.333% */
    letter-spacing: 0.2px;
    margin-right: auto;
  `,
  uploadButton: css`
    fill: ${theme.button.icon.fill};
    cursor: pointer;
  `,
  uploadButtonContainer: css`
    padding-left: ${Dimensions.S};
  `,
});
