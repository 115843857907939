import React from 'react';

import { AxisBottom, AxisLeft, AxisRight } from '@visx/axis';
import { GridRows } from '@visx/grid';
import { Group } from '@visx/group';
import { Text } from '@visx/text';

import { DiabetesParameters } from '@/models/DiabetesDataModel';

import { MarginProps, ScaleLinear } from '../DailyGraph/types';
import styles from './AgpProfile.module.scss';
import * as GRAPH from './constants';
import { TemplateProps } from './types';

type DecorationsPropsCommon = {
  height: number;
  margin: MarginProps;
  xScale: ScaleLinear<number, number, never>;
};
type DecorationsProps = DecorationsPropsCommon & {
  maxY: number;
};

type DecorationsProps2Common = {
  width: number;
  margin: MarginProps;
  yScale: ScaleLinear<number, number, never>;
};
type DecorationsProps2 = DecorationsProps2Common & {
  maxY: number;
  parameters: DiabetesParameters;
};

export const HorizontalGrids = ({
  width,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  margin,
  yScale,
  parameters,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  maxY,
}: DecorationsProps2) => {
  return (
    <GridRows
      scale={yScale}
      tickValues={[
        0,
        parameters.thresholdHypoglycemiaSevere,
        parameters.thresholdHypoglycemia,
        parameters.thresholdHyperglycemia,
        parameters.thresholdHyperglycemiaSevere,
      ]}
      width={width}
      offset={0}
      stroke="#f7f8fb"
      strokeWidth={2}
    />
  );
};
export default function Template({
  lastData,
  thresholds,
  parentHeight,
  scales,
  children,
  maxY,
}: TemplateProps) {
  const { rescaleX, rescaleY } = scales;

  return (
    <>
      <Text
        verticalAnchor="middle"
        dy={'0.5em'}
        fill="#838189"
        fontSize="0.875em"
      >
        Glycémie en mg/dL
      </Text>
      <AgpAxisLeft
        parameters={thresholds}
        yScale={rescaleY}
        maxY={maxY}
        margin={GRAPH.margin}
      />
      <AgpAxisRight
        percentiles={GRAPH.percentiles}
        values={lastData}
        yScale={rescaleY}
        left={rescaleX(GRAPH.HOURS_SCALING - 1)}
      />
      <BottomAxis
        height={parentHeight}
        margin={GRAPH.margin}
        xScale={rescaleX}
        maxY={maxY}
      />
      <Group left={GRAPH.margin.left} top={0}>
        <HorizontalGrids
          yScale={rescaleY}
          width={rescaleX(GRAPH.HOURS_SCALING - 1) - rescaleX(0)}
          margin={GRAPH.margin}
          parameters={thresholds}
          maxY={maxY}
        />
        <Group top={rescaleY(thresholds.thresholdHyperglycemia)}>
          <rect
            width={rescaleX(GRAPH.HOURS_SCALING - 1) - rescaleX(0)}
            height={
              rescaleY(thresholds.thresholdHypoglycemia) -
              rescaleY(thresholds.thresholdHyperglycemia)
            }
            className={styles.agpRange}
          />
        </Group>
      </Group>

      {children}
    </>
  );
}
export const BottomAxis = ({ height, margin, xScale }: DecorationsProps) => {
  return (
    <AxisBottom
      top={height - (margin?.bottom ?? 0)}
      stroke="#eff0f7"
      scale={xScale}
      hideAxisLine
      tickStroke="#eff0f7"
      tickLabelProps={(value, i) => ({
        dy: '0em',
        dx:
          i === 0
            ? '0em'
            : i === Math.round(GRAPH.HOURS_SCALING / 2) - 1
            ? '-1.5em'
            : '-0.5em',
        fill: '#838189',
        fontFamily: 'Arial',
        fontSize: '0.775em',
        textAnchor: 'start',
      })}
      tickFormat={e => ((e + '').length === 1 ? '0' + e + ' h' : e + ' h')}
    />
  );
};

type AgpAxisProps = {
  parameters: DiabetesParameters;
  yScale: ScaleLinear<number, number>;
  left?: number;
  maxY: number;
  margin: MarginProps;
};
export const AgpAxisLeft = ({
  parameters,
  yScale,
  maxY,
  margin,
  left = 0,
}: AgpAxisProps) => {
  return (
    <Group left={margin.left}>
      <AxisLeft
        hideAxisLine
        left={left}
        scale={yScale}
        tickValues={[
          0,
          parameters.thresholdHypoglycemiaSevere,
          parameters.thresholdHypoglycemia,
          parameters.thresholdHyperglycemia,
          parameters.thresholdHyperglycemiaSevere,
          maxY,
        ]}
        hideTicks
        labelProps={{ fill: '#838189' }}
        tickLabelProps={value => ({
          dx: '0.2em',
          dy: '0.35em',
          fill: [
            parameters.thresholdHypoglycemia,
            parameters.thresholdHyperglycemia,
          ].includes(value as number)
            ? '#00b271'
            : '#838189',
          fontSize: '0.8em',
          textAnchor: 'end',
        })}
      />
    </Group>
  );
};

type AgpAxisRightProps = {
  percentiles: number[];
  yScale: ScaleLinear<number, number>;
  left?: number;
  values: Record<string, number>;
};

export const AgpAxisRight = ({
  percentiles,
  yScale,
  values,
  left = 0,
}: AgpAxisRightProps) => {
  return (
    <AxisRight
      hideAxisLine
      left={left}
      scale={yScale}
      tickValues={Object.values(values)}
      tickFormat={(value, i) => `${percentiles[i]}%`}
      tickStroke="#174eff"
      hideTicks
      tickLabelProps={() => ({
        dx: '0.1em',
        dy: '0.3em',
        fill: '#174eff',
        fontSize: '0.8em',
        textAnchor: 'start',
      })}
    />
  );
};
