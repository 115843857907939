import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Container, FlexBox } from '@/components/box';
import ButtonWithIcon from '@/components/button-with-icon/ButtonWithIconComponent';
import ContactPatient from '@/components/contact-patient/ContactPatientComponent';
import { Col, Row } from '@/components/layout/Flex';
import Typography from '@/components/typography/Typography';
import { usePatientIdFromURL } from '@/hooks';
import usePatientMedicalFileData from '@/hooks/data/usePatientMedicalFileData';
import { Contact } from '@/models/ContactModel';
import { DiabetesInfo } from '@/pages/patient-medical-file/DiabetesInfo';
import { PatientProfile } from '@/pages/patient-medical-file/PatientProfile';
import { Queries } from '@/queries/Queries';
import { defaultContact } from '@/state/contacts/utils';
import { Colors } from '@/theme/colors';

import MedicalDevices from './MedicalDevices';

export const PatientMedicalFile: React.FC = () => {
  const { medicalDevices } = usePatientMedicalFileData();
  const patientId = usePatientIdFromURL();
  const patientQuery = Queries.practitioner.useObservedPatient(patientId ?? '');
  return (
    <Row align="stretch">
      <Col className="space-y-4 w-full">
        <Title />
        {patientQuery.data ? (
          <PatientProfile patient={patientQuery.data} />
        ) : null}
        {patientQuery.data && medicalDevices ? (
          <MedicalDevices medicalDevices={medicalDevices} />
        ) : null}
        {patientQuery.data ? (
          <DiabetesInfo patient={patientQuery.data} />
        ) : null}
        {patientQuery.data ? (
          <Entourage contacts={patientQuery.data.contacts} />
        ) : null}
      </Col>
    </Row>
  );
};

const Title = () => {
  const { t } = useTranslation();

  return (
    <Typography element="h2" fontWeight="bold" className="subtitle">
      {t('components.patient_header.dossier_medical')}
    </Typography>
  );
};

const Entourage = ({ contacts }: { contacts: Contact[] }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = React.useState(false);
  const [showAddContact, setShowAddContact] = React.useState(false);

  const addContact = () => {
    setEditing(true);
    setShowAddContact(true);
  };

  return (
    <Container>
      <Container.Title>{t('pages.contacts.entourage')}</Container.Title>
      <Container.Body>
        <FlexBox className="flex-col flex-1 ">
          <FlexBox className="flex-wrap">
            {contacts.map(contact => (
              <ContactPatient
                authorizeEditDelete={!editing}
                key={contact.id}
                contact={contact}
                addClassNameToView="ml-6"
                addClassNameToForm="w-1/3 my-6 ml-6"
                onFinish={() => {
                  setShowAddContact(false);
                  setEditing(false);
                }}
                onEdit={() => setEditing(true)}
              />
            ))}
            {showAddContact ? (
              <ContactPatient
                authorizeEditDelete={!editing}
                contact={defaultContact}
                addClassNameToView="ml-6"
                addClassNameToForm="w-1/3 my-6 ml-6"
                onFinish={() => {
                  setShowAddContact(false);
                  setEditing(false);
                }}
              />
            ) : null}
          </FlexBox>
          <FlexBox className="justify-end">
            {!editing ? (
              <ButtonWithIcon
                text={t('pages.ajouter_une_personne')}
                iconSrc={<Icons.filePlus fill={Colors.white} />}
                className="p-2 m-6 bg-primary-600"
                onClick={addContact}
              />
            ) : null}
          </FlexBox>
        </FlexBox>
      </Container.Body>
    </Container>
  );
};
