import {
  DecoderFunction,
  boolean,
  field,
  string,
} from 'typescript-json-decoder';

import {
  decodeId,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type ConsentType =
  | 'telemedecine'
  | 'accompagnement'
  | 'hebergement_donnees'
  | 'traitement_donnees'
  | 'partage_donnees'
  | 'protocol_cooperation';

export type Consent = {
  id: string;
  type: ConsentType;
  isGiven: boolean;
  dateGiven?: string;
  userGiven?: string;
  dateRemoved?: string;
  userRemoved?: string;
  motiveRemoved?: string;
};

export const consentDecoder: DecoderFunction<Consent> = recordWithContext(
  'Consent',
  {
    id: decodeId,
    type: stringUnion(
      'telemedecine',
      'accompagnement',
      'hebergement_donnees',
      'traitement_donnees',
      'partage_donnees',
      'protocol_cooperation',
    ),
    isGiven: field('is_given', boolean),
    dateGiven: field('date_given', nullOrUndef(string)),
    userGiven: field('user_given', nullOrUndef(string)),
    dateRemoved: field('date_removed', nullOrUndef(string)),
    userRemoved: field('user_removed', nullOrUndef(string)),
    motiveRemoved: field('motive_removed', nullOrUndef(string)),
  },
);
