import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import AlertConfigComponent from '@/components/alert-config/AlertConfigComponent';
import { Container, FlexBox } from '@/components/box';
import { ScrollBar } from '@/components/scroll/ScrollBar';
import Typography from '@/components/typography/Typography';
import { useApi, usePatientIdFromURL, useTypedSelector } from '@/hooks';
import { selectPatientAlertsConfigs } from '@/state/alert-configs/selectors';
import {
  createPatientAlertConfigs,
  deletePatientAlertConfigs,
  getPatientAlertConfigs,
  patchPatientAlertConfigs,
} from '@/state/alert-configs/thunks';

export default function Notifications({ ...rest }) {
  const { t } = useTranslation();
  const { dispatchActionWithAuth } = useApi();
  const patientId = usePatientIdFromURL();
  const alertConfigs = useTypedSelector(state =>
    selectPatientAlertsConfigs(state, patientId),
  );
  const [newConfig, setNewConfig] = React.useState(null);

  React.useEffect(() => {
    if (patientId) {
      dispatchActionWithAuth(getPatientAlertConfigs, { id: patientId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patientId]);

  return (
    <Container {...rest}>
      <Container.Title>
        {t('pages.remote_monitoring.notifications')}
      </Container.Title>
      <Container.Body className="flex-col">
        <TableHead />

        <ScrollBar className="h-92">
          <AlertConfigs
            alertConfigs={alertConfigs}
            setNewConfig={setNewConfig}
            newConfig={newConfig}
          />
        </ScrollBar>

        <AddAlertButton setNewConfig={setNewConfig} newConfig={newConfig} />
      </Container.Body>
    </Container>
  );
}

const AlertConfigs = ({ alertConfigs, setNewConfig, newConfig }) => {
  const patientId = usePatientIdFromURL();
  const { dispatchActionWithAuth } = useApi();

  const handlePatch = (alertConfigId, patch) => {
    dispatchActionWithAuth(patchPatientAlertConfigs, {
      id: patientId,
      alertConfigId,
      data: patch,
    });
  };
  const handleDelete = alertConfigId => {
    dispatchActionWithAuth(deletePatientAlertConfigs, {
      id: patientId,
      alertConfigId,
    });
  };
  const handleCreate = config => {
    setNewConfig(null);
    dispatchActionWithAuth(createPatientAlertConfigs, {
      id: patientId,
      data: config,
    });
  };

  return (
    <>
      {alertConfigs.map(alertConfig => (
        <AlertConfigComponent
          key={alertConfig.id}
          alertConfig={alertConfig}
          onPatch={handlePatch}
          onDelete={handleDelete}
        />
      ))}
      {newConfig ? (
        <AlertConfigComponent
          key={newConfig.id}
          alertConfig={newConfig}
          onCreated={handleCreate}
        />
      ) : null}
    </>
  );
};

const TableHead = () => {
  const { t } = useTranslation();

  return (
    <FlexBox className="flex-row mt-2 mb-4 text-center">
      <Typography className="w-3/12 text-sm">
        {t('pages.account.type_de_notification').toUpperCase()}
      </Typography>
      <Typography className="w-4/12 text-sm">
        {t('common.niveau_d_intervention').toUpperCase()}
      </Typography>
      <Typography className="w-5/12 text-sm">
        {t('common.conditions').toUpperCase()}
      </Typography>
    </FlexBox>
  );
};

const AddAlertButton = ({ setNewConfig, newConfig }) => {
  const { t } = useTranslation();

  const handleAddAlert = () => {
    const addedAlert = {
      id: 0,
      criticity: 'high',
      is_active: true,
      category: '',
      parameters: {},
      last_alert: 0,
      patient: 0,
    };
    setNewConfig(addedAlert);
  };

  return (
    <button
      disabled={newConfig !== null}
      onClick={handleAddAlert}
      className="flex flex-row items-center self-end justify-center mt-4 mb-8 mr-6 button-primary space-x-1 w-52"
    >
      <Icons.plus className="fill-current" />
      <Typography fontWeight="semibold">
        {t('pages.account.ajouter_une_notification')}
      </Typography>
    </button>
  );
};
