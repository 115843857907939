import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';
import { Link, matchPath } from 'react-router-dom';

import { Icons } from '@/assets/icons';
import { MenuTooltip } from '@/components/floating/MenuTooltip';
import { Typo } from '@/components/typography/Text';
import { usePatientIdFromURL } from '@/hooks';
import { useStyles } from '@/hooks/useTheme';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

import { Col, Row } from '../layout/Flex';

export type PatientMenuProps = {
  // Empty
};

const MenuItems = [
  {
    paths: ['/patients/:id'],
    title: 'components.patient_header.suivi_diabete',
    link: (id: string | undefined) => `/patients/${id}`,
  },
  {
    paths: ['/patients/:id/settings'],
    title: 'components.patient_header.telesurveillance',
    link: (id: string | undefined) => `/patients/${id}/settings`,
  },
  {
    paths: ['/patients/:id/medical-file'],
    title: 'components.patient_header.dossier_medical',
    link: (id: string | undefined) => `/patients/${id}/medical-file`,
  },
] as const;

export const PatientMenu: React.FC<PatientMenuProps> = () => {
  const { screenSize } = useWindowSize();

  switch (screenSize) {
    case 'SM':
      return <PatientMenuSmall />;
    case 'MD':
    case 'LG':
    case 'XL':
    case 'XXL':
      return <PatientMenuFull />;
  }
};

const getCurrentItem = (pathname: string) => {
  const item = MenuItems.find(item => isSelected(pathname, item.paths));
  return item;
};

const PatientMenuSmall: React.FC<PatientMenuProps> = () => {
  const styles = useStyles(makeStyles);
  const id = usePatientIdFromURL();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const selectedItem = getCurrentItem(pathname);

  return (
    <Row align="center">
      {selectedItem ? (
        <MenuItem
          selected={true}
          link={selectedItem.link(id)}
          title={t(selectedItem.title)}
        ></MenuItem>
      ) : null}
      <MenuTooltip
        placement="bottom-start"
        content={MenuItems.map(item =>
          item.title !== selectedItem?.title ? (
            <SmallMenuItem
              key={item.title}
              title={t(item.title)}
              link={item.link(id)}
            />
          ) : null,
        )}
      >
        <Icons.hamburger className={styles.icon} />
      </MenuTooltip>
    </Row>
  );
};

const PatientMenuFull: React.FC<PatientMenuProps> = () => {
  const styles = useStyles(makeStyles);
  const id = usePatientIdFromURL();
  const { t } = useTranslation();
  const { pathname } = useLocation();

  return (
    <Row className={styles.container}>
      {MenuItems.map(item => (
        <MenuItem
          key={item.title}
          selected={isSelected(pathname, item.paths)}
          title={t(item.title)}
          link={item.link(id)}
        />
      ))}
    </Row>
  );
};

type MenuItemProps = {
  title: string;
  link: string;
  selected: boolean;
};
const MenuItem: React.FC<MenuItemProps> = ({ title, link, selected }) => {
  const styles = useStyles(makeStyles);

  return (
    <Col>
      <Link to={link}>
        <Typo type="paragraph" className={styles.text}>
          {title}
        </Typo>
      </Link>
      {selected ? (
        <div className={styles.selected} />
      ) : (
        <div className={styles.notSelected} />
      )}
    </Col>
  );
};

type SmallMenuItemProps = {
  title: string;
  link: string;
};

const SmallMenuItem: React.FC<SmallMenuItemProps> = ({ title, link }) => {
  const styles = useStyles(makeStyles);

  return (
    <Row className={styles.smallItem}>
      <Link to={link}>
        <Typo type="paragraph" className={styles.text}>
          {title}
        </Typo>
      </Link>
    </Row>
  );
};

const isSelected = (pathname: string, paths: readonly string[]) => {
  return paths.some(path => matchPath(path, pathname) !== null);
};

const makeStyles = (theme: Theme) => ({
  container: css`
    gap: ${Dimensions.M};
  `,
  selected: css`
    justify-self: stretch;
    border: ${theme.sideBar.currentBackground} 2px solid;
  `,
  notSelected: css`
    justify-self: stretch;
    border: transparent 2px solid;
  `,
  tooltipAction: css`
    display: flex;
    padding: ${Dimensions.XS} ${Dimensions.S};
    cursor: pointer;
    &:hover {
      background-color: ${theme.sideBar.backgroundHover};
    }
  `,
  menu: css`
    padding: ${Dimensions.XS};
  `,
  text: css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
  icon: css`
    margin-left: ${Dimensions.S};
    width: ${Dimensions.font.L};
    height: ${Dimensions.font.L};
    color: ${theme.typography.primary};
  `,
  smallItem: css`
    padding: ${Dimensions.XS} ${Dimensions.S};
    cursor: pointer;
    &:hover {
      background-color: ${theme.sideBar.backgroundHover};
    }
  `,
});
