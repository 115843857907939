import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';

import { Col } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

export type CardBodyProps = PropsWithChildren<{
  className?: string;
  htmlRef?: React.ForwardedRef<HTMLDivElement>;
}>;

export const CardBody: React.FC<CardBodyProps> = ({
  children,
  className,
  htmlRef,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Col htmlRef={htmlRef} className={cx(styles.container, className)}>
      {children}
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    padding: ${theme.card.body.padding};
    width: ${Dimensions.full};
    color: white;
    word-wrap: break-word;
  `,
});
