import React from 'react';

import { ReactComponent as CE } from '@/assets/icons/CE.svg';
import { ReactComponent as AlertCircle } from '@/assets/icons/alert-circle.svg';
import { ReactComponent as AlertTriangle } from '@/assets/icons/alert-triangle.svg';
import { ReactComponent as ArrowLeft } from '@/assets/icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '@/assets/icons/arrow-right.svg';
import { ReactComponent as Bell } from '@/assets/icons/bell.svg';
import { ReactComponent as Calendar } from '@/assets/icons/calendar.svg';
import { ReactComponent as Check } from '@/assets/icons/check.svg';
import { ReactComponent as ChevronDown } from '@/assets/icons/chevron-down.svg';
import { ReactComponent as ChevronLeft } from '@/assets/icons/chevron-left.svg';
import { ReactComponent as ChevronRight } from '@/assets/icons/chevron-right.svg';
import { ReactComponent as ChevronUp } from '@/assets/icons/chevron-up.svg';
import { ReactComponent as Clock } from '@/assets/icons/clock.svg';
import { ReactComponent as Download } from '@/assets/icons/download.svg';
import { ReactComponent as Edit } from '@/assets/icons/edit.svg';
import { ReactComponent as EditNote } from '@/assets/icons/edit_note.svg';
import { ReactComponent as Email } from '@/assets/icons/email.svg';
import { ReactComponent as Empty } from '@/assets/icons/empty-key.svg';
import { ReactComponent as externalLink } from '@/assets/icons/external-link.svg';
import { ReactComponent as Eye } from '@/assets/icons/eye.svg';
import { ReactComponent as FilePlus } from '@/assets/icons/file-plus.svg';
import { ReactComponent as FileUpload } from '@/assets/icons/file-upload.svg';
import { ReactComponent as Forward } from '@/assets/icons/forward.svg';
import { ReactComponent as FullScreen } from '@/assets/icons/full-screen.svg';
import { ReactComponent as Hamburger } from '@/assets/icons/hamburger.svg';
import { ReactComponent as CloseBold } from '@/assets/icons/ic-close-bold.svg';
import { ReactComponent as Close } from '@/assets/icons/ic-close.svg';
import { ReactComponent as Mail } from '@/assets/icons/ic-mail.svg';
import { ReactComponent as Phone } from '@/assets/icons/ic-phone.svg';
import { ReactComponent as ThreePointMenu } from '@/assets/icons/ic-three-point-menu.svg';
import { ReactComponent as EmptyTickBox } from '@/assets/icons/ic-tick-empty.svg';
import { ReactComponent as TickBox } from '@/assets/icons/ic-tick.svg';
import { ReactComponent as Information } from '@/assets/icons/information.svg';
import { ReactComponent as Letter } from '@/assets/icons/letter.svg';
import { ReactComponent as PhoneOutline } from '@/assets/icons/phone.svg';
import { ReactComponent as Plus } from '@/assets/icons/plus.svg';
import { ReactComponent as QuestionCircle } from '@/assets/icons/question-circle.svg';
import { ReactComponent as Question } from '@/assets/icons/question.svg';
import { ReactComponent as SendMessage } from '@/assets/icons/send-message.svg';
import { ReactComponent as Stethoscope } from '@/assets/icons/stethoscope.svg';
import { ReactComponent as Trash } from '@/assets/icons/trash.svg';
import { ReactComponent as UserPlus } from '@/assets/icons/user-plus.svg';
import { ReactComponent as Users } from '@/assets/icons/users.svg';

export type IconType = React.FunctionComponent<React.SVGProps<SVGSVGElement>>;

export const Icons = {
  alertCircle: AlertCircle,
  alertTriangle: AlertTriangle,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  bell: Bell,
  calendar: Calendar,
  ce: CE,
  check: Check,
  chevronDown: ChevronDown,
  chevronLeft: ChevronLeft,
  chevronRight: ChevronRight,
  chevronUp: ChevronUp,
  clock: Clock,
  closeBoldIcon: CloseBold,
  closeIcon: Close,
  download: Download,
  edit: Edit,
  editNote: EditNote,
  email: Email,
  empty: Empty,
  emptyTickBox: EmptyTickBox,
  eye: Eye,
  externalLink: externalLink,
  filePlus: FilePlus,
  fileUpload: FileUpload,
  forward: Forward,
  fullscreen: FullScreen,
  hamburger: Hamburger,
  information: Information,
  letter: Letter,
  mail: Mail,
  phone: Phone,
  phoneOutline: PhoneOutline,
  plus: Plus,
  question: Question,
  questionCircle: QuestionCircle,
  sendMessage: SendMessage,
  stethoscope: Stethoscope,
  threePointMenu: ThreePointMenu,
  tickBox: TickBox,
  trash: Trash,
  userPlus: UserPlus,
  users: Users,
};
