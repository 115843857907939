import React from 'react';

import { css, cx, keyframes } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';
import { ThemeDim } from '@/theme/types';

type LoaderSize = 'S' | 'M' | 'L';

export type LoaderProps = {
  size: LoaderSize;
  thickness?: number;
  className?: string;
};

export const Loader: React.FC<LoaderProps> = ({
  size,
  thickness = 1,
  className,
}) => {
  const styles = useStyles(makeStyles, SizeMatch[size], thickness);

  return (
    <div
      className={cx(styles.loader, className)}
      aria-label="loader"
      aria-busy="true"
      role="progressbar"
    ></div>
  );
};

const SizeMatch: { [K in LoaderSize]: ThemeDim } = {
  S: Dimensions.S,
  M: Dimensions.M,
  L: Dimensions.L,
};
const makeStyles = (theme: Theme, size: ThemeDim, thickness: number) => ({
  loader: css`
    height: ${size};
    width: ${size};
    border: calc(${size} / 10 * ${thickness}) solid
      ${theme.loading.loaderBackground};
    border-top: calc(${size} / 10 * ${thickness}) solid ${theme.loading.loader};
    border-radius: 50%;
    animation: ${spin} 1s cubic-bezier(0.8, 0.3, 0.2, 0.7) infinite;
  `,
});

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }  
`;
