import { InferType } from 'yup';

import yup from '@/yup/yup';

export const addPatientInfoSchema = yup.object().shape({
  firstname: yup.string().required('components.form.firstname_required'),
  lastname: yup.string().required('components.form.lastname_required'),
  email: yup
    .string()
    .email('components.email_not_valid')
    .required('components.form.email_required'),
  phone: yup
    .string()
    .phone(['FR'], 'components.phone_number_not_valid')
    .required('components.form.phone_required'),
});

export type AddPatientInfoData = InferType<typeof addPatientInfoSchema>;
