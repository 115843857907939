import React, { useMemo } from 'react';

import { css } from '@emotion/css';
import { useHover } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { IconType, Icons } from '@/assets/icons';
import { ShowApiError } from '@/components/error/ShowApiError';
import { Col, Row } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { Tag } from '@/components/tag/Tag';
import { Typo } from '@/components/typography/Text';
import { UserPicture } from '@/components/user-picture/UserPicture';
import { useStyles } from '@/hooks/useTheme';
import { MessageCountType, Patient } from '@/models/PatientModel';
import { PatientAlert } from '@/pages/patients/PatientAlert';
import { Queries } from '@/queries/Queries';
import { Colors } from '@/theme/colors';
import { Dimensions } from '@/theme/dimensions';

import { PatientsTagsList } from './PatientsTagsList';

export type PatientsTableProps = {
  search: string;
};

export const PatientsTable: React.FC<PatientsTableProps> = ({ search }) => {
  const styles = useStyles(makeStyles);
  const patients = Queries.practitioner.usePatientsList();

  const currentPractitioner = Queries.practitioner.usePractitioner();
  const isNurse = currentPractitioner.data?.qualification === 'nurse';

  const filteredPatients = useMemo(
    () =>
      // TODO Better search
      search && patients.data
        ? patients.data.filter(patient =>
            [
              patient.familyName?.toLowerCase(),
              patient.givenName?.toLowerCase(),
              patient.familyName?.toLowerCase(),
            ]
              .join(' ')
              .includes(search.toLowerCase()),
          )
        : patients.data,
    [patients.data, search],
  );

  switch (patients.status) {
    case 'success':
      return (
        <Col className={styles.container}>
          <PatientsListHead isNurse={isNurse} />
          {filteredPatients?.map(patient => (
            <PatientRow isNurse={isNurse} key={patient.id} patient={patient} />
          ))}
        </Col>
      );
    case 'error':
      return <ShowApiError errorMapping={{}} error={patients.error.error} />;
    case 'pending':
      return (
        <div className={styles.loader}>
          <Loader size="L" />
        </div>
      );
  }
};

export type PractionerTypeProps = {
  isNurse: boolean;
};

const PatientsListHead = ({ isNurse }: PractionerTypeProps) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Row className={styles.header}>
      <div className={styles.infosCol} />
      <div className={styles.messageCol}>{t('pages.patients.messages')}</div>
      <div className={styles.nurseNoticeCol}>
        {t('pages.patients.to_consult')}
      </div>
      {isNurse && <div className={styles.doctorCol}>{t('models.medecin')}</div>}
      <div className={styles.notificationCol}>
        {t('pages.patients.traiter_les_notifications')}
      </div>
    </Row>
  );
};

type PatientRowProps = {
  patient: Patient;
};
export const PatientRow = ({
  patient,
  isNurse,
}: PatientRowProps & PractionerTypeProps) => {
  const styles = useStyles(makeStyles);
  const navigate = useNavigate();
  const [ref, hovering] = useHover();

  return (
    <div
      ref={ref}
      onClick={() => navigate(`/patients/${patient.id}`)}
      className={styles.row}
    >
      <Row align="center" className={styles.infosCol}>
        <div className={styles.patientChip}>
          <UserPicture height={8} width={8} {...patient} />
          {patient.roleForPatient === 'doctor' ? null : (
            <PatientAlert patient={patient} />
          )}
        </div>
        <Typo type="paragraph" className={styles.name}>
          {`${patient.familyName ?? ''} ${patient.givenName ?? ''}`.trim()}
        </Typo>
      </Row>
      <MessageRow
        practitioner={patient.message.practitioner}
        patient={patient.message.patient}
        isNurse={isNurse}
        count={patient.message.count}
      />
      <Row className={styles.nurseNoticeCol}>
        {patient.hasNurseNotice ? (
          <div className={styles.nurseNoticeItem}>
            {patient.roleForPatient === 'doctor' ? (
              <Tag type="icon" color="blue" icon={Icons.stethoscope} />
            ) : (
              <Tag type="icon" color="grey" icon={Icons.forward} />
            )}
          </div>
        ) : null}
      </Row>
      {isNurse && <DoctorColumn patient={patient} />}
      <div className={styles.notificationCol}>
        <PatientsTagsList
          patient={patient}
          hovering={hovering}
          isNurse={isNurse}
        />
      </div>
    </div>
  );
};

const DoctorColumn = ({ patient }: PatientRowProps) => {
  const styles = useStyles(makeStyles);
  const doctor = patient.doctor;
  const doctorLabel = doctor
    ? `${doctor?.givenName.charAt(0)}. ${doctor?.familyName}`
    : '';
  return <Row className={styles.doctorCol}>{doctorLabel}</Row>;
};

const MessageRow = ({
  patient,
  practitioner,
  isNurse,
  count,
}: MessageCountType & PractionerTypeProps) => {
  const styles = useStyles(makeStyles);
  let showIcon = false;
  let icons: IconType[] = [];
  let messageCount = count.toString();

  if (isNurse) {
    if (patient || practitioner) {
      showIcon = true;
      if (patient && !practitioner) {
        icons = [Icons.letter];
      } else {
        icons = [Icons.letter, Icons.stethoscope];
      }
    }
  } else {
    if (practitioner) {
      showIcon = true;
      icons = [Icons.letter, Icons.stethoscope];
    }
    messageCount = practitioner.toString();
  }

  return (
    <Row className={styles.messageCol}>
      {showIcon && (
        <Row className={styles.messageItem}>
          <Tag
            type="hybrid"
            color="blue"
            icon={icons}
            text={messageCount}
            placement="left"
          />
        </Row>
      )}
    </Row>
  );
};

const makeStyles = () => ({
  container: css``,
  header: css`
    border-bottom-width: 1px;
    width: 100%;
  `,
  infosCol: css`
    flex-grow: 1;
  `,
  messageCol: css`
    width: 200px;
  `,
  messageItem: css`
    width: 37%;
    display: flex;
    justify-content: center;
  `,
  nurseNoticeCol: css`
    width: 200px;
  `,
  doctorCol: css`
    width: 200px;
  `,
  nurseNoticeItem: css`
    width: 50%;
    display: flex;
    justify-content: center;
  `,
  notificationCol: css`
    width: 200px;
    text-align: left;
  `,
  loader: css`
    width: 100%;
    aspect-ratio: 3;
    display: flex;
    align-items: center;
    justify-content: center;
  `,
  row: css`
    width: 100%;
    display: flex;
    flex-direction: row;
    padding-top: 1rem;
    padding-bottom: 1rem;
    align-items: center;
    border-bottom-width: 1px;
    &:hover {
      background-color: ${Colors.gray['300']};
    }
    cursor: pointer;
  `,
  toggle: css`
    opacity: 0.5;
  `,
  patientChip: css`
    position: relative;
    margin: 0 ${Dimensions.M};
  `,
  name: css`
    font-weight: bold;
  `,
});
