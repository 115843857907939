import { DecoderFunction, string } from 'typescript-json-decoder';

import { FileModel, fileDecoder } from '@/models/FileModel';
import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type DiabetesType = 'type1' | 'type2' | 'gestational';
export type InsulinTreatmentType = 'mono' | 'multi';
export type TelemonitoringCriteria = 'base' | 'level1' | 'level2';

export type CarePlan = {
  id: string;
  status: 'active' | 'completed' | 'revoked';
  periodStart: string;
  periodEnd?: string;
  description?: string;
  descriptionAttachment?: FileModel;
  diabetesType: DiabetesType;
  insulinTreatment: InsulinTreatmentType;
  telemonitoringCriteria: TelemonitoringCriteria;
  prescription?: FileModel;
  careTeam: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
};

export const carePlanDecoder: DecoderFunction<CarePlan> = recordWithContext(
  'CarePlan',
  {
    id: string,
    status: stringUnion('active', 'completed', 'revoked'),
    periodStart: string,
    periodEnd: nullOrUndef(string),
    description: nullOrUndef(string),
    descriptionAttachment: nullOrUndef(fileDecoder),
    diabetesType: stringUnion('type1', 'type2', 'gestational'),
    insulinTreatment: stringUnion('mono', 'multi'),
    telemonitoringCriteria: stringUnion('base', 'level1', 'level2'),
    prescription: nullOrUndef(fileDecoder),
    careTeam: string,
    subject: string,
    createdAt: string,
    updatedAt: string,
  },
);
