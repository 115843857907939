import { DimensionsStructure } from './types';

export const Dimensions = {
  None: '0',
  XXXS: '2px',
  XXS: '4px',
  XS: '8px',
  S: '16px',
  M: '24px',
  L: '40px',
  XL: '60px',
  XXL: '80px',
  XXXL: '120px',
  UL: '200px',
  full: '100%',
  half: '50%',
  font: {
    UUUS: '0.6rem',
    US: '0.75rem',
    XXXS: '0.80rem',
    XXS: '0.90rem',
    XS: '0.95em',
    S: '1rem',
    M: '1.05rem',
    L: '1.1rem',
    XL: '1.2rem',
    XXL: '1.4rem',
    XXXL: '1.7rem',
    UL: '2rem',
  },
} satisfies DimensionsStructure;
