import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '@/api';
import { Contact } from '@/models/ContactModel';

type AsyncThunkBaseParams = {
  token: string;
  id: number;
};

type AsyncThunkPostParams = AsyncThunkBaseParams & {
  data: Contact;
};

type AsyncThunkPatchParams = AsyncThunkBaseParams & {
  data: Partial<Contact>;
  contactId: number;
};

type AsyncThunkDeleteParams = AsyncThunkBaseParams & {
  contactId: number;
};

// Contacts
export const getPatientContacts = createAsyncThunk(
  'patients/getPatientContacts',
  async ({ token, id }: AsyncThunkBaseParams) => {
    const response = await API(token).get(`patients/${id}/contacts/`);
    return { patientId: id, contacts: response.data };
  },
);

export const createPatientContact = createAsyncThunk(
  'patients/createPatientContact',
  async ({ token, id, data }: AsyncThunkPostParams) => {
    const response = await API(token).post(`contacts/${id}/`, {
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      postal_address: data.postalAddress,
      phones: [{ number: data.phone, description: 'mobile' }],
      comment: data.comment,
      is_emergency_contact: data.isEmergencyContact,
      type: data.type,
      relationship_with_patient: data.relationshipWithPatient,
    });
    return { patientId: id, contact: response.data };
  },
);

export const patchPatientContact = createAsyncThunk(
  'patients/patchPatientContact',
  async ({ token, id, contactId, data }: AsyncThunkPatchParams) => {
    const response = await API(token).patch(`contacts/${id}/${contactId}/`, {
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      postal_address: data.postalAddress,
      phones: [{ number: data.phone, description: 'mobile' }],
      comment: data.comment,
      is_emergency_contact: data.isEmergencyContact,
      type: data.type,
      relationship_with_patient: data.relationshipWithPatient,
    });
    return { patientId: id, contact: response.data };
  },
);

export const deletePatientContact = createAsyncThunk(
  'patients/deletePatientContact',
  async ({ token, id, contactId }: AsyncThunkDeleteParams) => {
    await API(token).delete(`contacts/${id}/${contactId}/`);
    return { patientId: id, contactId };
  },
);
