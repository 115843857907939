import React from 'react';

import { css } from '@emotion/css';

import { FlexBox } from '@/components/box';
import { Typo } from '@/components/typography/Text';
import Typography from '@/components/typography/Typography';
import { Dimensions } from '@/theme/dimensions';

import './DataLiteral.scss';

type DataLiteralProps = {
  children: React.ReactNode;
  className?: string;
};
const _DataLiteral: React.FC<DataLiteralProps> = ({
  children,
  className = '',
}) => {
  return <FlexBox className={`flex-col ${className}`}>{children}</FlexBox>;
};

const Header: React.FC<DataLiteralProps> = ({ children, className = '' }) => {
  return (
    <FlexBox
      className={`flex-row text-gray-700 text-sm items-baseline ${className}`}
    >
      {children}
    </FlexBox>
  );
};

const SubBody: React.FC<DataLiteralProps> = ({ children, className = '' }) => {
  return (
    <FlexBox
      className={`flex-row items-center text-gray-700 text-sm ${className}`}
    >
      {children}
    </FlexBox>
  );
};

type BodyProps = {
  data: string | number;
  unit?: string;
  size?: 'XXL' | 'UL';
  className?: string;
};

const Body: React.FC<BodyProps> = ({
  data,
  unit,
  size = 'XXL',
  className = '',
}) => {
  return (
    <FlexBox className={`flex-row items-baseline ${className}`}>
      <Typo
        type="paragraph"
        weight="bold"
        className={css`
          font-size: ${Dimensions.font[size]};
        `}
      >
        {`${data}`}
      </Typo>
      {unit ? (
        <Typography className="text-gray-700 text-sm ml-1">{unit}</Typography>
      ) : null}
    </FlexBox>
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const TempDataLiteral: any = _DataLiteral;

TempDataLiteral.Header = Header;
TempDataLiteral.Body = Body;
TempDataLiteral.SubBody = SubBody;

const DataLiteral: typeof _DataLiteral & {
  Header: typeof Header;
  Body: typeof Body;
  SubBody: typeof SubBody;
} = TempDataLiteral;

export default DataLiteral;
