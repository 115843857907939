import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';

import alertConfigsReducer from './alert-configs/alertConfigsSlice';
import alertsReducer from './alerts/alertsSlice';
import contactsReducer from './contacts/contactsSlice';
import devicesReducer from './devices/devicesSlice';
import observationsReducer from './observations/observationsSlice';

export const store = configureStore({
  reducer: combineReducers({
    alerts: alertsReducer,
    alertConfigs: alertConfigsReducer,
    observations: observationsReducer,
    contacts: contactsReducer,
    devices: devicesReducer,
  }),
});

export type RootState = ReturnType<typeof store.getState>;

export type SliceState<T> = {
  data: T;
  status?: 'idle' | 'loading' | 'succeeded' | 'failed';
  error?: string | null | undefined;
};

// from https://redux-toolkit.js.org/usage/usage-with-typescript#getting-the-dispatch-type
export type AppDispatch = typeof store.dispatch;

export enum RequestStatus {
  REJECTED = 'rejected',
  FULFILLED = 'fulfilled',
}
