import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SliceState } from '@/state/store';

import {
  createPatientAlertConfigs,
  deletePatientAlertConfigs,
  getPatientAlertConfigs,
  patchPatientAlertConfigs,
} from './thunks';
import {
  AlertConfigData,
  PatientAlertConfig,
  PatientAlertConfigDelete,
  PatientAlertConfigs,
} from './types';

const initialState: SliceState<AlertConfigData> = {
  status: 'idle',
  error: null,
  data: {},
};

const alertConfigsSlice = createSlice({
  name: 'alertConfigs',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPatientAlertConfigs.pending, state => {
        state.status = 'loading';
      })
      .addCase(getPatientAlertConfigs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        getPatientAlertConfigs.fulfilled,
        (state, action: PayloadAction<PatientAlertConfigs>) => {
          const { patientId, alertConfigs } = action.payload;

          state.status = 'succeeded';
          state.data[patientId] = alertConfigs;
        },
      )

      .addCase(createPatientAlertConfigs.pending, state => {
        state.status = 'loading';
      })
      .addCase(createPatientAlertConfigs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        createPatientAlertConfigs.fulfilled,
        (state, action: PayloadAction<PatientAlertConfig>) => {
          const { patientId, alertConfig } = action.payload;

          state.status = 'succeeded';
          if (patientId in state.data) {
            state.data[patientId] = [...state.data[patientId], alertConfig];
          } else {
            state.data[patientId] = [alertConfig];
          }
        },
      )

      .addCase(patchPatientAlertConfigs.pending, state => {
        state.status = 'loading';
      })
      .addCase(patchPatientAlertConfigs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        patchPatientAlertConfigs.fulfilled,
        (state, action: PayloadAction<PatientAlertConfig>) => {
          const { patientId, alertConfig } = action.payload;
          const patchedAlertConfigs = state.data[patientId].map(ac =>
            ac.id === alertConfig.id ? alertConfig : ac,
          );

          state.status = 'succeeded';
          state.data[patientId] = patchedAlertConfigs;
        },
      )

      .addCase(deletePatientAlertConfigs.pending, state => {
        state.status = 'loading';
      })
      .addCase(deletePatientAlertConfigs.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        deletePatientAlertConfigs.fulfilled,
        (state, action: PayloadAction<PatientAlertConfigDelete>) => {
          const { patientId, alertConfigId } = action.payload;
          const filteredAlertConfigs = state.data[patientId].filter(
            ac => ac.id !== alertConfigId,
          );

          state.status = 'succeeded';
          state.data[patientId] = filteredAlertConfigs;
        },
      );
  },
});

export default alertConfigsSlice.reducer;
