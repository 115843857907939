import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '@/api';

type AsyncThunkBaseParams = {
  token: string;
  id: number;
};

// Contacts
export const getPatientMedicalDevices = createAsyncThunk(
  'devices/getPatientMedicalDevices',
  async ({ token, id }: AsyncThunkBaseParams) => {
    const response = await API(token).get(`devices/${id}/`);
    return { patientId: id, medicalDevices: response.data };
  },
);
