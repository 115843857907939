import React, { ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

import Check from '@/assets/icons/radio_check.svg';
import {
  BaseRadioFormInput,
  OptionItem,
} from '@/components/form/inputs/BaseRadioFormInput';
import { InputError, getError } from '@/components/form/inputs/InputError';
import { Col, Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

export type RadioFormInputProps<T extends FieldValues> = {
  options: OptionItem<T>[];
  containerStyle?: string;
  labelStyle?: string;
  label?: string;
  name: Path<T>;
  noReservedErrorSpace?: boolean;
} & RegisterOptions<T>;

export const RadioFormInput = <T extends FieldValues>({
  options,
  containerStyle,
  name,
  label,
  labelStyle,
  noReservedErrorSpace,
  ...inputProps
}: RadioFormInputProps<T>): ReactElement => {
  const context = useFormContext<T>();
  const styles = useStyles(makeStyles, !!getError({ context, name }));
  return (
    <Col grow className={cx(styles.container, containerStyle)}>
      <Row align="center" shrink className={styles.content}>
        <BaseRadioFormInput<T>
          inputProps={inputProps}
          options={options}
          context={context}
          name={name}
          label={label}
          className={styles.input}
          labelStyle={labelStyle}
          radioContainerStyle={styles.radio}
          radioInputStyle={styles.radioInput}
          radioLabelStyle={styles.radioLabel}
        />
      </Row>
      <InputError<T>
        className={styles.error}
        name={name}
        context={context}
        noReservedSpace={noReservedErrorSpace}
      />
    </Col>
  );
};

const makeStyles = (theme: Theme, error: boolean) => ({
  container: css`
    min-width: 0;
  `,
  content: css`
    position: relative;
    display: flex;
    margin-bottom: ${theme.input.bottomMargin};
  `,
  input: css`
    display: flex;
    flex-direction: row;
    width: 100%;
    min-width: 0;
    flex-wrap: wrap;
  `,
  radio: css`
    margin: ${Dimensions.XS} ${Dimensions.M} ${Dimensions.XS} 0;

    & > input:checked + label {
      color: ${theme.input.selected};
      background-color: ${theme.input.selectedBackground};
      :before {
        content: url(${Check});
        align-items: flex-end;
        justify-content: center;
      }
    }
  `,
  radioInput: css`
    display: none;
  `,
  radioLabel: css`
    white-space: nowrap;
    border-color: ${error ? theme.input.error : theme.input.border};
    border-width: ${theme.input.borderWidth};
    border-radius: ${Dimensions.M};
    padding: ${Dimensions.XXS} ${Dimensions.XS};
    color: ${theme.input.text};
    cursor: pointer;
    :before {
      width: 1.8rem;
      display: inline flex;
      content: ' ';
    }
    :after {
      width: 1.8rem;
      display: inline flex;
      content: ' ';
    }
  `,
  error: css`
    height: 2.5em;
    color: ${theme.input.error};
  `,
});
