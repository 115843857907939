import { DecoderFunction, field, string } from 'typescript-json-decoder';

import {
  FrenchIdentity,
  FrenchIdentityDTO,
  frenchIdentityDecoder,
} from '@/models/identity/FrenchIdentity';
import { nullOrUndef, stringUnion } from '@/utils/decoderUtils';

export type SupportedCountries = 'France';
export type IdentityStatus = 'notValidated' | 'validated' | 'rejected';
export type ValidateOrRejectIdentity = 'validate' | 'reject';

export type FrenchId = {
  regulatoryCountry: 'France';
} & FrenchIdentity;

export type CountryIdentities =
  | FrenchId
  | {
      regulatoryCountry: undefined;
      identityLastname?: undefined;
      identityFirstnames?: undefined;
      gender?: undefined;
      franceBirthLocation?: undefined;
      franceBirthCountry?: undefined;
      birthDate?: undefined;
    };

export type Identity = {
  status: IdentityStatus;
} & CountryIdentities;

export type IdentityDTO = {
  regulatory_country: SupportedCountries;
} & FrenchIdentityDTO;

export const identityDecoder: DecoderFunction<Identity> = (value: unknown) => {
  const status: IdentityStatus = field(
    'identityStatus',
    stringUnion('notValidated', 'validated', 'rejected'),
  )(value);
  const identityLastname: string | undefined = field(
    'identityLastname',
    nullOrUndef(string),
  )(value);
  if (identityLastname === undefined) {
    return {
      status,
      regulatoryCountry: undefined,
    };
  }
  const regulatoryCountry: SupportedCountries | undefined = field(
    'regulatoryCountry',
    nullOrUndef(stringUnion('France')),
  )(value);
  switch (regulatoryCountry) {
    case 'France':
      return {
        status,
        regulatoryCountry,
        ...frenchIdentityDecoder(value),
      };
    case undefined:
      return {
        status,
        regulatoryCountry: undefined,
      };
  }
};
