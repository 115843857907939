import React from 'react';

import { css, cx } from '@emotion/css';

import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

import { StyleType } from '../../StyleTypes';
import { InputElementProps } from './InputInterface';

export const Radio = ({
  label,
  id,
  disabled = false,
  className,
  ...props
}: InputElementProps & StyleType) => {
  const styles = useStyles(makeStyles);
  return (
    <div className={styles.radio}>
      <input
        type="radio"
        id={id}
        disabled={disabled}
        {...props}
        className={styles.radioInput}
      />
      <Typo
        type="paragraph"
        Component="label"
        htmlFor={id}
        className={cx(styles.radioLabel, className)}
      >
        {label}
      </Typo>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  radio: css`
    & > input:checked + label {
      background: ${theme.button.radio.checked};
      :before {
        justify-content: center;
      }
    }
  `,
  radioInput: css`
    display: none;
  `,
  radioLabel: css`
    white-space: nowrap;
    border-radius: 80px;
    padding: ${Dimensions.XXS} ${Dimensions.S};
    cursor: pointer;
    color: ${theme.typography.primary};
    font-variant-numeric: lining-nums tabular-nums;

    /* body/sm-regular */
    font-style: normal;
    line-height: 24px; /* 171.429% */
  `,
});
