/*
 ** This file uses the "Compound Component" pattern.
 ** More info:
 **   - ComponentsPattern.md
 **   - https://kentcdodds.com/blog/compound-components-with-react-hooks/
 */
import React from 'react';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FlexBox } from '@/components/box';
import Typography from '@/components/typography/Typography';
import { useComponentContext } from '@/hooks';

type ContextProps = {
  isLoading: boolean;
};
const FormButtonsContext = React.createContext({} as ContextProps);

type FormButtonsProps = {
  isLoading: boolean;
  children?: React.ReactNode;
  position?: 'start' | 'center' | 'end';
  className?: string;
};

function FormButtons({
  isLoading,
  children,
  position = 'end',
  className = '',
}: FormButtonsProps) {
  return (
    <FormButtonsContext.Provider value={{ isLoading }}>
      <FlexBox className={`self-${position} ${className}`}>{children}</FlexBox>
    </FormButtonsContext.Provider>
  );
}

type CancelProps = {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  className?: string;
  children?: React.ReactNode;
};
function Cancel({ className = '', onClick, children }: CancelProps) {
  const { isLoading } = useComponentContext(FormButtonsContext);

  return (
    <button
      type="button"
      onClick={onClick}
      disabled={isLoading}
      className={`button-gray ${className}`}
    >
      {children}
    </button>
  );
}

type SubmitProps = {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  children?: React.ReactNode;
  disabledPredicate?: () => boolean;
};
function Submit({
  children,
  onClick = () => null,
  disabledPredicate = () => false,
}: SubmitProps) {
  const { isLoading } = useComponentContext(FormButtonsContext);
  const isDisabled = isLoading || disabledPredicate();

  return (
    <button
      type="submit"
      onClick={onClick}
      disabled={isDisabled}
      className={
        'button-primary ml-6' +
        (!isDisabled ? ' hover:bg-primary-700 hover:border-primary-700' : '')
      }
    >
      {isLoading ? (
        <FontAwesomeIcon icon={faSpinner} className="animate-spin" />
      ) : (
        <Typography>{children}</Typography>
      )}
    </button>
  );
}

FormButtons.Cancel = Cancel;
FormButtons.Submit = Submit;

export default FormButtons;
