import React, { PropsWithChildren } from 'react';

import { ScrollBar } from '@/components/scroll/ScrollBar';

export type SideSheetBodyProps = PropsWithChildren<{
  className?: string;
}>;

export const SideSheetBody: React.FC<SideSheetBodyProps> = ({
  className,
  children,
}) => {
  return (
    <ScrollBar
      className={className}
      options={{ suppressScrollX: true, wheelPropagation: false }}
    >
      {children}
    </ScrollBar>
  );
};
