import { DecoderFunction, string } from 'typescript-json-decoder';

import {
  CareTeamParticipant,
  careTeamParticipantDecoder,
} from '@/models/CareTeamParticipantModel';
import { NurseNotice, nurseNoticeDecoder } from '@/models/NurseNoticeModel';
import {
  forcedArray,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type CareTeam = {
  id: string;
  name: string;
  status: 'active' | 'suspended';
  reason: 'telemonitoring';
  managingOrganization: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
  participants: CareTeamParticipant[];
  nurseNotice?: NurseNotice;
};

export const careTeamDecoder: DecoderFunction<CareTeam> = recordWithContext(
  'CareTeam',
  {
    id: string,
    name: string,
    status: stringUnion('active', 'suspended'),
    reason: stringUnion('telemonitoring'),
    managingOrganization: string,
    subject: string,
    createdAt: string,
    updatedAt: string,
    participants: forcedArray(careTeamParticipantDecoder),
    nurseNotice: nullOrUndef(nurseNoticeDecoder),
  },
);
