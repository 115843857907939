import React, { useContext } from 'react';

import { css } from '@emotion/css';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import usePatientIdFromURL from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';
import { Status } from '@/utils/ReactQueryUtils';

import { AttachmentContext } from './AttachmentContext';

type ActionType = {
  requestStatus: Status;
  formValueGetter: (key: string) => string;
};

export const Actions = ({ requestStatus, formValueGetter }: ActionType) => {
  const styles = useStyles(makeStyles);
  const attachmentContext = useContext(AttachmentContext);
  const patientId = usePatientIdFromURL() ?? '';

  const contentText = formValueGetter('contentText');
  const status = requestStatus;

  const sendDisable =
    attachmentContext.files?.length < 1 &&
    (!contentText || contentText.length < 1) &&
    sessionStorage.getItem(`${patientId}Draft`) === null;

  const sendButtonIconClass = sendDisable ? styles.disabledSendButtonIcon : '';
  const sendButtonClass = sendDisable
    ? styles.disabledSendButton
    : styles.button;

  return (
    <div className={styles.container}>
      <IconButton
        className={sendButtonClass}
        iconClassname={sendButtonIconClass}
        status={status}
        icon={Icons.sendMessage}
        placement="left"
        buttonType="primary"
        type="submit"
        disabled={sendDisable}
      ></IconButton>
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    margin-left: auto;
    margin-top: auto;
    padding-left: ${Dimensions.XXS};
  `,
  button: css`
    border-radius: ${Dimensions.M};
    padding-left: ${Dimensions.XS};
    padding-right: ${Dimensions.XS};
  `,
  disabledSendButtonIcon: css`
    stroke: ${theme.button.icon.disabled.stroke};
  `,
  disabledSendButton: css`
    border-color: ${theme.button.disabled.borderColor};
    border-radius: ${Dimensions.M};
    padding-left: ${Dimensions.XS};
    padding-right: ${Dimensions.XS};
  `,
});
