import { record } from 'typescript-json-decoder';

import { CarePlan, carePlanDecoder } from '@/models/CarePlanModel';
import { CareTeam, careTeamDecoder } from '@/models/CareTeamModel';
import { NurseNotice, nurseNoticeDecoder } from '@/models/NurseNoticeModel';
import { Patient, patientDecoder } from '@/models/PatientModel';
import {
  forcedArray,
  intersectionWithContext,
  nullOrUndef,
} from '@/utils/decoderUtils';

export type PatientWithCare = {
  careTeam?: CareTeam;
  currentCarePlan?: CarePlan;
  upcomingCarePlan?: CarePlan;
  nurseNotice?: NurseNotice;
  carePlans: CarePlan[];
} & Patient;

export const patientWithCareDecoder = intersectionWithContext(
  'PatientWithCare',
  record({
    careTeam: careTeamDecoder,
    currentCarePlan: nullOrUndef(carePlanDecoder),
    upcomingCarePlan: nullOrUndef(carePlanDecoder),
    carePlans: forcedArray(carePlanDecoder),
    nurseNotice: nullOrUndef(nurseNoticeDecoder),
  }),
  patientDecoder,
);
