import React from 'react';

import { css, keyframes } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { FlexBox } from '@/components/box';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

export type LoadingScreenProps = {
  // Empty
};

export const LoadingScreen: React.FC<LoadingScreenProps> = ({}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <FlexBox className={styles.container}>
      {t('common.chargement')}
      <div className={styles.lineProgressBar}></div>
    </FlexBox>
  );
};

const growAnimation = keyframes`
  50% {
    width: 100%;
  }
  100% {
    width: 0;
    right: 0;
    left: unset;
  }
`;

const makeStyles = (theme: Theme) => ({
  container: css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: ${theme.loading.fontSize};
    line-height: ${theme.loading.lineHeight};
    color: ${theme.loading.color};
    background-color: ${theme.loading.background};
  `,
  lineProgressBar: css`
    width: ${theme.loading.barWidth};
    height: ${theme.loading.barHeight};
    background: rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    position: relative;
    overflow: hidden;
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 0;
      height: 100%;
      background: ${theme.loading.barColor};
      animation: ${growAnimation} 1s ease-in-out infinite;
    }
  `,
});
