import React, { ReactElement, ReactNode } from 'react';

import { css, cx } from '@emotion/css';
import { Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

import { IconType } from '@/assets/icons';
import { BaseFileFormInput } from '@/components/form/inputs/BaseFileFormInput';
import { InputError } from '@/components/form/inputs/InputError';
import { Col, Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';
import { SupportedMimes } from '@/utils/files';

export type FileFormInputProps<T extends FieldValues> = {
  containerStyle?: string;
  labelStyle?: string;
  label?: string | ReactElement;
  labelTooltip?: ReactNode;
  Icon?: IconType;
  iconStyle?: string;
  name: Path<T>;
  accepts: SupportedMimes[];
  noReservedErrorSpace?: boolean;
  noFileText?: string;
} & RegisterOptions<T>;

export const FileFormInput = <T extends FieldValues>({
  containerStyle,
  name,
  label,
  labelTooltip,
  labelStyle,
  Icon,
  iconStyle,
  accepts,
  noReservedErrorSpace,
  noFileText,
  ...inputProps
}: FileFormInputProps<T>) => {
  const context = useFormContext<T>();
  const styles = useStyles(makeStyles);
  return (
    <Col grow className={cx(styles.container, containerStyle)}>
      <Row align="center" shrink className={styles.content}>
        <BaseFileFormInput<T>
          inputProps={inputProps}
          context={context}
          name={name}
          label={label}
          labelTooltip={labelTooltip}
          accepts={accepts}
          labelStyle={labelStyle}
          Icon={Icon}
          iconStyle={iconStyle}
          noFileText={noFileText}
        ></BaseFileFormInput>
      </Row>
      <InputError<T>
        className={styles.error}
        name={name}
        context={context}
        noReservedSpace={noReservedErrorSpace}
      />
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  content: css``,
  error: css`
    height: 2.5em;
    color: ${theme.input.error};
  `,
});
