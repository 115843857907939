import { PatientRepository } from '@/io/repository/PatientRepository';
import { SelfPatient } from '@/models/SelfPatientModel';
import { createAppQuery, stripQueryResult } from '@/queries/QueryClient';

export class PatientQueries {
  constructor(
    private readonly patient: PatientRepository = new PatientRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  usePatient = createAppQuery<SelfPatient>({
    queryKey: 'use-patient',
    queryFn: async () => stripQueryResult(await this.patient.getPatient()),
  });
}

export const patientQueries = new PatientQueries();
