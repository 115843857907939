/*
 ** This file uses the "Compound Component" pattern.
 ** More info:
 **   - ComponentsPattern.md
 **   - https://kentcdodds.com/blog/compound-components-with-react-hooks/
 */
import React from 'react';

import { FormProvider, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import CustomTag from '@/components/custom-tag/CustomTagComponent';
import Typography from '@/components/typography/Typography';
import { useComponentContext } from '@/hooks';

function Form({ children, methods, onSubmit, ...rest }) {
  const { handleSubmit } = methods;

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} {...rest}>
        {children}
      </form>
    </FormProvider>
  );
}

const GroupContext = React.createContext({ id: '' });

function Group(props) {
  const { children, id, ...rest } = props;
  const ctx = React.useMemo(() => ({ id: id }), [id]);

  return (
    <GroupContext.Provider value={ctx}>
      <div {...rest}>{children}</div>
    </GroupContext.Provider>
  );
}

function Label({ children, ...rest }) {
  const { id } = useComponentContext(GroupContext);

  return (
    <label {...rest} htmlFor={id}>
      {children}
    </label>
  );
}

function Control(props) {
  const { element = 'input', name, children, ...rest } = props;
  const { id } = useComponentContext(GroupContext);
  const { register } = useFormContext();

  return (
    <CustomTag
      tag={element}
      id={id}
      {...rest}
      {...(name ? register(name) : {})}
    >
      {children}
    </CustomTag>
  );
}

function Error({ name, className = '', ...rest }) {
  const { t } = useTranslation();
  const {
    formState: { errors },
  } = useFormContext();
  const error = Object.valueByPath(errors, name);

  return error ? (
    <Typography className={'block mt-1 text-red-400 ' + className} {...rest}>
      {t(error)}
    </Typography>
  ) : null;
}

Form.Group = Group;
Form.Label = Label;
Form.Control = Control;
Form.Error = Error;

export default Form;
