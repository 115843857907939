import React from 'react';

import { css, cx } from '@emotion/css';

import { Icons } from '@/assets/icons';
import { Row } from '@/components/layout/Flex';
import Typography from '@/components/typography/Typography';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

export type SideSheetHeaderProps = {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  exit?: () => void;
  className?: string;
};

export const SidePeekHeader: React.FC<SideSheetHeaderProps> = ({
  exit,
  title,
  subtitle,
  className,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Row className={cx(styles.container, className)}>
      {typeof title === 'string' ? (
        <Typography fontWeight="bold" className="text-2xl">
          {title}
        </Typography>
      ) : (
        title
      )}
      {typeof subtitle === 'string' ? (
        <Typography className="text-lg">{subtitle}</Typography>
      ) : (
        subtitle
      )}
      <div className={styles.buttonContainer}>
        <Icons.closeBoldIcon onClick={exit} className={styles.closeButton} />
      </div>
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  closeButton: css`
    display: flex;
    width: 32px;
    height: 32px;
    justify-self: center;
    flex-shrink: 0;
    border-radius: ${Dimensions.M};
    background: ${theme.sideSheet.buttonBackground};
    padding: ${Dimensions.XS};
    float: right;
    cursor: pointer;
  `,
  buttonContainer: css`
    margin-left: auto;
  `,
  container: css`
    padding-left: ${Dimensions.XS};
    padding-right: ${Dimensions.M};
    padding-top: ${Dimensions.S};
    align-items: center;
  `,
});
