import React from 'react';

import { useTranslation } from 'react-i18next';

import { GlycemiaBars } from '@/components/data-representations';
import { Typo } from '@/components/typography/Text';
import { TargetDurationStats } from '@/models/TargetDurationStatsModel';

export type GlycemiaGraphicsProps = {
  targetDurationStats: TargetDurationStats;
  dataset: 'sensor' | 'reader';
  className?: string;
};

export const GlycemiaGraphics: React.FC<GlycemiaGraphicsProps> = ({
  targetDurationStats,
  dataset,
  className,
}) => {
  const { t } = useTranslation();
  const { glycemia_distribution } = targetDurationStats[dataset];
  const onReaderView = dataset === 'reader';

  return (
    <div className={className}>
      <Typo type="paragraphLarge">
        {t(
          onReaderView
            ? 'components.charts.target_duration.repart_glycemie'
            : 'components.charts.target_duration.temps_entre_seuils',
        )}
      </Typo>

      {glycemia_distribution ? (
        <GlycemiaBars
          width={300}
          height={275}
          data={glycemia_distribution}
          className="mt-1 mr-20"
        />
      ) : null}
    </div>
  );
};
