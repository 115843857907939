import React from 'react';

import { css, cx } from '@emotion/css';

import { IconType } from '@/assets/icons';
import { Button, ButtonProps } from '@/components/button/Button';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

type IconPlacement = 'left' | 'right';
export type IconButtonProps = ButtonProps & {
  icon: IconType;
  placement: IconPlacement;
  iconClassname?: string;
};

export const IconButton: React.FC<IconButtonProps> = ({
  icon: Icon,
  buttonType,
  textType = 'button',
  placement,
  iconClassname,
  children,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Button buttonType={buttonType} {...props}>
      <>
        {placement === 'left' ? (
          <Icon
            className={cx(
              styles.icon,
              {
                [styles.iconLeft]: children !== undefined && children !== null,
              },
              iconClassname,
            )}
          />
        ) : null}
        {typeof children === 'string' ? (
          <Typo type={textType} className={styles.text}>
            {children}
          </Typo>
        ) : (
          children
        )}
        {placement === 'right' ? (
          <Icon className={cx(styles.icon, styles.iconRight, iconClassname)} />
        ) : null}
      </>
    </Button>
  );
};

const makeStyles = (theme: Theme) => ({
  text: css`
    color: inherit;
  `,
  icon: css`
    width: ${Dimensions.font.M};
    height: ${Dimensions.font.M};
  `,
  iconLeft: css`
    margin-right: ${theme.button.paddingHorizontal};
  `,
  iconRight: css`
    margin-left: ${theme.button.paddingHorizontal};
  `,
  plain: css``,
});
