import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SliceState } from '@/state/store';

import { getPatientMedicalDevices } from './thunks';
import { MedicalDevicesData, PatientMedicalDevicesData } from './types';

const initialState: SliceState<MedicalDevicesData> = {
  status: 'idle',
  error: null,
  data: {},
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPatientMedicalDevices.pending, state => {
        state.status = 'loading';
      })
      .addCase(getPatientMedicalDevices.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        getPatientMedicalDevices.fulfilled,
        (state, action: PayloadAction<PatientMedicalDevicesData>) => {
          const { patientId, medicalDevices } = action.payload;

          state.status = 'succeeded';
          state.data[patientId] = medicalDevices;
        },
      );
  },
});

export default devicesSlice.reducer;
