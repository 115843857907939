import React, { AllHTMLAttributes, PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

export type TextExternalLinkProps = PropsWithChildren<
  {
    to: string;
    className?: string;
  } & AllHTMLAttributes<HTMLAnchorElement>
>;

export const TextExternalLink: React.FC<TextExternalLinkProps> = ({
  to,
  className,
  children,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <a
      href={to}
      target="_blank"
      rel="noopener noreferrer"
      className={cx(styles.link, className)}
      {...props}
    >
      {children}
    </a>
  );
};

const makeStyles = (theme: Theme) => ({
  link: css`
    color: ${theme.link.color};
    &:visited {
      color: ${theme.link.visited};
    }
    &:hover {
      color: ${theme.link.hover};
    }
    &:active {
      color: ${theme.link.active};
    }
  `,
});
