export enum DeviceType {
  PUMP = 'pump',
  READER = 'reader',
  SENSOR = 'sensor',
  MANUAL = 'manual',
  REMOTE_MONITORING = 'remote monitoring',
}

export type MedicalDeviceType = {
  id: number;
  name: string;
  manufacturer: string;
  device_type: DeviceType;
};

export type MedicalDevice = {
  id: number;
  device_type: MedicalDeviceType;
  serial_number?: string;
};

export type MedicalDeviceWithActivity = MedicalDevice & {
  //  active_since: string;
  last_sync: string;
};

export type PatientMedicalDevicesData = {
  patientId: number;
  medicalDevices: MedicalDeviceWithActivity[] & { patientId: number };
};

export type MedicalDevicesData = {
  [patientId: string]: MedicalDeviceWithActivity[];
};
