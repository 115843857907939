import { DecoderFunction, number, string } from 'typescript-json-decoder';

import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';
import { SupportedMimes } from '@/utils/files';

export type FileModel = {
  url: string;
  name: string;
  size?: number;
  checksum?: string;
  contentType?: SupportedMimes;
  versionId?: string;
  versions?: number;
  lastModified?: string;
};

export const fileDecoder: DecoderFunction<FileModel> = recordWithContext(
  'File',
  {
    url: string,
    name: string,
    size: nullOrUndef(number),
    checksum: nullOrUndef(string),
    contentType: nullOrUndef(
      stringUnion(
        'application/pdf',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/msword',
      ),
    ),
    versionId: nullOrUndef(string),
    versions: nullOrUndef(number),
    lastModified: nullOrUndef(string),
  },
);

export type FileToUpload = {
  file?: File;
  name: string;
  contentType?: SupportedMimes;
  size?: number;
};
