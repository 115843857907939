import React, { useEffect } from 'react';

import { css, cx } from '@emotion/css';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Button } from '@/components/button/Button';
import { IconButton } from '@/components/button/IconButton';
import { Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { UserPicture } from '@/components/user-picture/UserPicture';
import { useStyles } from '@/hooks/useTheme';
import { PatientWithCare } from '@/models/PatientWithCareModel';
import { Queries } from '@/queries/Queries';
import { Dimensions } from '@/theme/dimensions';

import { Tag } from '../tag/Tag';

export type PatientOverviewProps = {
  patient?: PatientWithCare;
  className?: string;
};

export const PatientOverview: React.FC<PatientOverviewProps> = ({
  patient,
  className,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Row align="center" className={cx(styles.container, className)}>
      <Row align="baseline">
        <UserPicture
          givenName={patient?.givenName}
          familyName={patient?.familyName}
          width={14}
          height={14}
          lettersSize="2xl"
          className={styles.margin}
        />
        <Typo type="subtitle" className={styles.margin}>
          {`${patient?.givenName ?? ''} ${patient?.familyName ?? ''}`.trim()}
        </Typo>
        {patient ? (
          <Typo type="paragraphLarge">
            {patient.user.last_login
              ? `${t('components.patient_header.actif_il_y_a')} ${moment(
                  patient.user.last_login,
                ).fromNow(true)}`
              : t('components.patient_header.pas_de_premiere_connexion')}
          </Typo>
        ) : null}
      </Row>
      <Row align="baseline">
        {patient?.roleForPatient === 'nurse' ? (
          <NurseActions patient={patient} />
        ) : null}
        {patient?.roleForPatient === 'doctor' ? (
          <DoctorActions patient={patient} />
        ) : null}
      </Row>
    </Row>
  );
};

const NurseActions: React.FC<{ patient: PatientWithCare }> = ({ patient }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const deleteNotice = Queries.observations.useDeleteNotice();
  const createNotice = Queries.observations.useCreateNotice();
  useEffect(() => {
    deleteNotice.reset();
    createNotice.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const notice = patient.nurseNotice;

  switch (true) {
    case notice == undefined:
      return (
        <IconButton
          buttonType="primary"
          icon={Icons.forward}
          placement="left"
          status={createNotice.status}
          onClick={() =>
            createNotice.mutate({
              patientId: patient.id,
            })
          }
        >
          {t('components.patient_header.notify_doctor')}
        </IconButton>
      );
    default:
      return (
        <>
          <Tag
            type="hybrid"
            color="grey"
            text={t('components.patient_header.notified')}
            icon={Icons.forward}
            placement="left"
            className={styles.margin}
          />
          <Button
            buttonType="secondary"
            status={deleteNotice.status}
            onClick={() =>
              deleteNotice.mutate({
                patientId: patient.id,
                noticeId: notice.id,
              })
            }
          >
            {t('components.patient_header.cancel_notify')}
          </Button>
        </>
      );
  }
};

const DoctorActions: React.FC<{ patient: PatientWithCare }> = ({ patient }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const mitigateNotice = Queries.observations.useMitigateNotice();
  const dismissAlert = Queries.alerts.useDismissAlert();

  useEffect(() => {
    mitigateNotice.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patient]);

  const notice = patient.nurseNotice;

  return (
    <>
      <Tag
        type="hybrid"
        color="blue"
        text={t('components.patient_header.notified')}
        icon={Icons.stethoscope}
        placement="left"
        className={cx(styles.margin, { [styles.fadeOut]: !notice })}
      />
      <Button
        disabled={!notice}
        className={cx({ [styles.fadeOut]: !notice })}
        buttonType="primary"
        status={mitigateNotice.status}
        onClick={() =>
          notice &&
          mitigateNotice.mutate(
            {
              patientId: patient.id,
              noticeId: notice.id,
            },
            {
              onSuccess: () => {
                patient.alerts.forEach(alert =>
                  dismissAlert.mutate({
                    patientId: patient.id,
                    alertId: alert.id,
                  }),
                );
              },
            },
          )
        }
      >
        {t('components.patient_header.mitigate_notify')}
      </Button>
    </>
  );
};

const makeStyles = () => ({
  container: css`
    justify-content: space-between;
  `,
  margin: css`
    margin-right: ${Dimensions.S};
  `,
  fadeOut: css`
    visibility: hidden;
    opacity: 0;
    transition: visibility 0.5s, opacity 0.5s;
  `,
});
