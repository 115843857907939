import React from 'react';

import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Container, FlexBox } from '@/components/box';
import DeviceTypeLogo from '@/components/device-type-logo';
import Typography from '@/components/typography/Typography';
import { DeviceType, MedicalDeviceWithActivity } from '@/state/devices/types';
import { translateDeviceType } from '@/state/devices/utils';

import styles from './MedicalDevices.module.scss';

const IGNORED_DEVICES = {
  manual: undefined,
  'libre link': undefined,
};

type MedicalDevicesProps = {
  medicalDevices: MedicalDeviceWithActivity[];
} & React.HTMLAttributes<HTMLElement>;

const MedicalDevices = ({ medicalDevices, ...rest }: MedicalDevicesProps) => {
  const { t } = useTranslation();

  return (
    <Container {...rest}>
      <Container.Title>
        {t('pages.medical_file.dispositif_medicaux')}
      </Container.Title>
      <Container.Body className={styles.container}>
        {dedupeMD(medicalDevices).map(medicalDevice => (
          <Device key={medicalDevice.id} device={medicalDevice} />
        ))}
      </Container.Body>
    </Container>
  );
};

export default MedicalDevices;

type DeviceProps = {
  device: MedicalDeviceWithActivity;
};

const Device = ({ device }: DeviceProps) => {
  const { t } = useTranslation();

  const dateFromNow = (date: string, withoutSuffix?: boolean) =>
    moment(date).fromNow(withoutSuffix);
  const deviceLastSync = () =>
    `${t('pages.medical_file.last_sync')} ${dateFromNow(device.last_sync)}`;

  return (
    <FlexBox className={styles.device}>
      <FlexBox className={styles.deviceType}>
        <Typography>
          <DeviceTypeLogo deviceType={device.device_type.device_type} />
        </Typography>
        <Typography>
          {t(translateDeviceType(device.device_type.device_type))}
        </Typography>
      </FlexBox>
      <Typography className={styles.deviceName}>
        {device.device_type.name}
      </Typography>
      <Typography className={styles.lastSync}>{deviceLastSync()}</Typography>
      {/*<LinkUploadMenu deviceType={device.device_type.device_type} />*/}
    </FlexBox>
  );
};
/*
const LinkUploadMenu = ({ deviceType }: { deviceType: DeviceType }) => {
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL();

  switch (deviceType) {
    case DeviceType.PUMP:
      return (
        <MenuLink
          links={[
            {
              href: `/patients/${patientId}/upload-data?device=${DeviceParamEnum._640G_MEDTRONIC}`,
              text: t('pages.medical_file.add_data_by_file'),
            },
          ]}
        />
      );
    case DeviceType.SENSOR:
      return (
        <MenuLink
          links={[
            {
              href: `/patients/${patientId}/upload-data?device=${DeviceParamEnum.FSL_WITH_ABBOTT_SMARTPHONE}`,
              text: t('pages.medical_file.add_data_by_file'),
            },
          ]}
        />
      );
    default:
      return <div className="h-5 w-5" />;
  }
};
const MenuLink = ({ links }: { links: { href: string; text: string }[] }) => (
  <MenuTooltip
    openOnHover
    placement="bottom-end"
    wrapperClassName={styles.menu}
    content={
      <Col>
        {links.map(link => (
          <Link
            key={link.href}
            to={link.href}
            className={styles.uploadTooltipLink}
          >
            {link.text}
          </Link>
        ))}
      </Col>
    }
  >
    <Icons.threePointMenu className={styles.upload} fill="#174EFF" />
  </MenuTooltip>
);
*/

// local utils

const findMDbyType = (type: DeviceType, list: MedicalDeviceWithActivity[]) =>
  list.find(elem => elem.device_type.device_type === type);

const filterMDbyType = (type: DeviceType, list: MedicalDeviceWithActivity[]) =>
  list.filter(device => device.device_type.device_type !== type);

const dedupeMD = (medicalDevices: MedicalDeviceWithActivity[]) =>
  medicalDevices
    .filter(device => !(device.device_type.name in IGNORED_DEVICES))
    .reduce((devices, md) => {
      const occurence = findMDbyType(md.device_type.device_type, devices);
      if (occurence) {
        if (moment(md.last_sync) > moment(occurence.last_sync)) {
          return [
            ...filterMDbyType(occurence.device_type.device_type, devices),
            md,
          ];
        }
        return devices;
      }
      return [...devices, md];
    }, [] as MedicalDeviceWithActivity[]);
