import React from 'react';

import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  AddPatientInfoData,
  addPatientInfoSchema,
} from '@/components/add-patient/AddPatient.schema';
import { Button } from '@/components/button/Button';
import { NewForm } from '@/components/form/NewForm';
import { FormInput } from '@/components/form/inputs/FormInput';
import { Col, Row } from '@/components/layout/Flex';
import { SideSheetActions } from '@/components/sidesheet/SideSheetActions';
import { SideSheetBody } from '@/components/sidesheet/SideSheetBody';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';

export type AddPatientInfoFormProps = {
  initialData?: AddPatientInfoData;
  onSubmit: (data: AddPatientInfoData) => void;
  close: () => void;
};

const FORM_ID = 'AddPatientInfoForm';

export const AddPatientInfoForm: React.FC<AddPatientInfoFormProps> = ({
  initialData,
  onSubmit,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const formContext = useForm<AddPatientInfoData>({
    resolver: yupResolver(addPatientInfoSchema),
    defaultValues: initialData,
  });

  return (
    <>
      <SideSheetBody className={styles.container}>
        <Typo type="subtitle" className={styles.subtitle}>
          {t('pages.patients.steto_account_patient')}
        </Typo>
        <NewForm id={FORM_ID} onSubmit={onSubmit} formContext={formContext}>
          <Col>
            <Row justify="space-between" className={styles.namesRow}>
              <FormInput<AddPatientInfoData>
                type="text"
                name="firstname"
                label={t('common.prenom')}
                required
              />
              <FormInput<AddPatientInfoData>
                type="text"
                name="lastname"
                label={t('common.nom')}
                required
              />
            </Row>
            <FormInput<AddPatientInfoData>
              type="email"
              name="email"
              label={t('common.adresse_email')}
              required
            />
            <FormInput<AddPatientInfoData>
              type="tel"
              name="phone"
              required
              label={t('common.numero_de_telephone')}
            />
          </Col>
        </NewForm>
      </SideSheetBody>
      <SideSheetActions>
        <Button key="cancel" buttonType="secondary" onClick={close}>
          {t('common.annuler')}
        </Button>
        <Button key="submit" buttonType="primary" type="submit" form={FORM_ID}>
          {t('common.next')}
        </Button>
      </SideSheetActions>
    </>
  );
};

const makeStyles = () => ({
  container: css`
    flex-grow: 1;
  `,
  subtitle: css`
    margin-bottom: ${Dimensions.M};
  `,
  namesRow: css`
    column-gap: ${Dimensions.S};
  `,
});
