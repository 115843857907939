import React from 'react';

import { css } from '@emotion/css';

import CarretDown from '@/assets/icons/ic-carret-down.svg';
import { useStyles } from '@/hooks/useTheme';

export default function FormSelect({
  children,
  className = '',
  ...rest
}: {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLSelectElement>) {
  const styles = useStyles(makeStyles);
  return (
    <span className={`relative items-center ${className}`}>
      <select
        className={`font-medium bg-transparent flex-1c ${styles.select}`}
        {...rest}
      >
        {children}
      </select>
    </span>
  );
}

const makeStyles = () => ({
  select: css`
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url(${CarretDown});
    background-position-x: 90%;
    background-size: 0.5em;
    background-repeat: no-repeat;
  `,
});
