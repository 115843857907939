/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';

import { Icons } from '@/assets/icons';
import { FlexBox } from '@/components/box';
import { PeriodicityType } from '@/pages/patient-monitoring/HeaderMonitoring';
import { Periodicities } from '@/state/diabetes/types';

import PeriodSelector from './PeriodSelector';

type PeriodPickerProps = {
  periodicities: Periodicities;
  defaultPeriodicity: { index: number; durationInDays: number };
  onChange: (args: PeriodicityType) => void;
};

export default function PeriodPicker({
  periodicities,
  defaultPeriodicity,
  onChange,
}: PeriodPickerProps) {
  const [periodicity, setPeriodicity] = React.useState(defaultPeriodicity);

  const handleChangePeriodicity = (
    periodicity: number,
    durationInDays: number,
  ) => {
    onChange({ index: periodicity, durationInDays });
    setPeriodicity({ index: periodicity, durationInDays });
  };

  return (
    <FlexBox className="items-center">
      <PeriodSelector
        value={periodicity.index}
        periodicities={periodicities}
        onChangePeriodicity={handleChangePeriodicity}
      />
    </FlexBox>
  );
}

type SelectPeriodProps = {
  onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
};

export const SelectPreviousPeriod = ({ onClick }: SelectPeriodProps) => (
  <button className="bg-white p-3" onClick={onClick}>
    <Icons.arrowLeft />
  </button>
);

type SelectNextPeriodProps = SelectPeriodProps & {
  disabled: boolean;
};

export const SelectNextPeriod = ({
  onClick,
  disabled,
}: SelectNextPeriodProps) => (
  <button className="bg-white p-3" disabled={disabled} onClick={onClick}>
    <Icons.arrowRight
      className={`fill-current ${disabled ? 'text-gray-300' : ''}`}
    />
  </button>
);
