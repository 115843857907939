/*
 **  Bind method to Object.prototype.
 **  This method returns the object's value nested under 'path'
 **  This is very useful for callback when value doesn't already exists
      (i.e. errors on react-hook-form)
 **  Having "phones[0].number" in path will get the method searching for:
      object['phones']['0']['number']
 */

Object.valueByPath = function (object, path) {
  if (typeof path === 'undefined') return null;
  if (typeof object !== 'object') return object;

  // convert indexes to properties
  path = path.replace(/\[(\w+)\]/g, '.$1');
  // strip a leading dot
  path = path.replace(/^\./, '');
  const properties = path.split('.');
  const lenProperties = properties.length;

  for (let i = 0; i < lenProperties; i++) {
    const property = properties[i];

    if (property in object) {
      object = object[property];
    } else {
      return null;
    }
  }
  return object;
};
