import React from 'react';

import CustomTag from '@/components/custom-tag/CustomTagComponent';

type Props = {
  fontWeight?: 'thin' | 'medium' | 'normal' | 'bold' | 'semibold';
  italic?: boolean;
  element?: string;
  className?: string;
  children: React.ReactNode;
  rest?: unknown;
};

export default function Typography({
  children,
  fontWeight = 'normal',
  italic = false,
  className = '',
  element = 'span',
  ...rest
}: Props) {
  return (
    <CustomTag
      tag={element}
      className={
        className +
        (fontWeight ? ` font-${fontWeight}` : '') +
        (italic ? ' italic' : '')
      }
      {...rest}
    >
      {children}
    </CustomTag>
  );
}
