import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { TextExternalLink } from '@/components/link/TextExternalLink';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';

export type TermsProps = {
  // Empty
};

export const Terms: React.FC<TermsProps> = ({}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card className={styles.container} elevation={0}>
      <TextCardTitle id="about-title" title={t('pages.terms.title')} />
      <CardBody className={styles.body}>
        <TextExternalLink
          id="help-contact"
          to="https://steto.notion.site/Conditions-G-n-rales-d-Utilisation-des-Applications-Web-et-Mobiles-St-to-040d5c1ab33544cf90cdbe121e3587cc"
        >
          {t('pages.terms.title')}
        </TextExternalLink>
      </CardBody>
    </Card>
  );
};

const makeStyles = () => ({
  container: css`
    margin-top: ${Dimensions.M};
  `,
  body: css``,
  iframe: css`
    width: 100%;
    height: 100%;
    aspect-ratio: 1;
  `,
});
