import React, { useLayoutEffect, useMemo } from 'react';

import { css } from '@emotion/css';
import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { DailyGraph } from '@/components/data-representations/';
import { Col, Row } from '@/components/layout/Flex';
import DayPicker from '@/components/period-picker/DayPicker';
import Typography from '@/components/typography/Typography';
import { usePatientIdFromURL } from '@/hooks';
import useDebounce from '@/hooks/useDebounce';
import useQueryString from '@/hooks/useQueryString';
import { useStyles } from '@/hooks/useTheme';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Queries } from '@/queries/Queries';
import { GlycemiaTypeEnum } from '@/state/diabetes/types';
import { Dimensions } from '@/theme/dimensions';
import { dateYMD_ISO } from '@/utils/date';

export const PatientData: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const id = usePatientIdFromURL();
  const { date } = useQueryString();
  const dateDebounced = useDebounce<string>(date, 100);
  const [width, setWidth] = React.useState(0);
  const windowSize = useWindowSize();
  const divRef = React.useRef<HTMLDivElement>(null);
  const styles = useStyles(makeStyles);
  const patientId = usePatientIdFromURL() ?? '';
  const dataviz = Queries.diabetes.useDataviz({
    patientId,
    from: moment(dateDebounced).startOf('day').format(),
    to: moment(dateDebounced).endOf('day').format(),
  });

  useLayoutEffect(() => {
    if (divRef.current) {
      setWidth(divRef.current.offsetWidth);
    }
  }, [windowSize.width]);

  const handleDateChange = (newDate: moment.Moment) =>
    navigate(`/patients/${id}/data?date=${dateYMD_ISO(newDate)}`);

  const dailyData = useMemo(() => {
    if (dataviz.isSuccess) {
      const data = dataviz.data[0];
      return {
        ...data,
        glycemiaTypes: {
          glycemiaSensor: data.glycemia
            .filter(glycemia => glycemia.type === GlycemiaTypeEnum.INTERSTITIAL)
            .sort((a, b) => moment(a.date).diff(moment(b.date))), //generateGlyc(),
          glycemiaReader: data.glycemia.filter(
            glycemia => glycemia.type === GlycemiaTypeEnum.CAPILLARY,
          ),
          glycemiaManual: [],
        },
      };
    } else {
      return undefined;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataviz.data, date]);

  return (
    <Col className={styles.content}>
      <Row justify="space-between">
        <Typography element="h2" fontWeight="bold" className="subtitle">
          {t('components.patient_header.vue_journaliere')}
        </Typography>
        <DayPicker setDate={handleDateChange} date={moment(date)} />
      </Row>
      <Card elevation={0}>
        <CardBody htmlRef={divRef}>
          {dailyData ? (
            <DailyGraph {...{ data: dailyData }} width={width} />
          ) : null}
        </CardBody>
      </Card>
    </Col>
  );
};

const makeStyles = () => ({
  content: css`
    flex: 1 1;
    gap: ${Dimensions.S};
  `,
});
