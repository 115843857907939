import React from 'react';

import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/button/Button';
import {
  CarePlanData,
  carePlanSchema,
} from '@/components/diabetes-form/CarePlan.schema';
import {
  DiabetesTypeInput,
  FileInput,
  InsulinSchemeInput,
  TelemonitoringCriteriaInput,
  TherapeuticObjectivesInput,
} from '@/components/diabetes-form/CarePlanFormInputs';
import { ShowApiError } from '@/components/error/ShowApiError';
import { NewForm } from '@/components/form/NewForm';
import { Col } from '@/components/layout/Flex';
import { SideSheetActions } from '@/components/sidesheet/SideSheetActions';
import { SideSheetBody } from '@/components/sidesheet/SideSheetBody';
import { useStyles } from '@/hooks/useTheme';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import { CarePlanCreationMode } from '@/pages/patient-settings/CarePlanDialogs';
import { Dimensions } from '@/theme/dimensions';
import { Status } from '@/utils/ReactQueryUtils';

export type DiabetesFormProps = {
  initialData: Partial<CarePlanData>;
  onSubmit: (data: CarePlanData) => void;
  requestError?: ApiErrorResponse;
  requestStatus: Status;
  back: (data: Partial<CarePlanData>) => void;
  prevText: string;
  nextText: string;
  editMode?: boolean;
  mode?: CarePlanCreationMode;
};

const FORM_ID = 'DiabetesForm';

export const CarePlanForm: React.FC<DiabetesFormProps> = ({
  initialData,
  onSubmit,
  requestError,
  requestStatus,
  back,
  prevText,
  nextText,
  editMode = false,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const formContext = useForm<CarePlanData>({
    resolver: yupResolver(carePlanSchema),
    defaultValues: initialData,
  });

  return (
    <>
      <SideSheetBody className={styles.container}>
        <NewForm id={FORM_ID} onSubmit={onSubmit} formContext={formContext}>
          <Col className={styles.inputs}>
            <DiabetesTypeInput t={t} labelClassName={styles.label} />
            <InsulinSchemeInput t={t} labelClassName={styles.label} />
            <TelemonitoringCriteriaInput t={t} labelClassName={styles.label} />
            {!editMode ? (
              <>
                {/*<StartDateInput t={t} />
                <MonthsDurationInput t={t} />*/}
              </>
            ) : null}
            <FileInput t={t} start={initialData.startDate} />
            <TherapeuticObjectivesInput t={t} className={styles.textarea} />
          </Col>
        </NewForm>
      </SideSheetBody>
      <SideSheetActions>
        <ShowApiError
          errorMapping={{
            PATIENT_ALREADY_EXISTS: 'errors.user_already_exists',
            CARE_PlANS_OVERLAPPING: 'errors.care_plans_overlapping',
            CARE_PlAN_ALREADY_RENEWED: 'errors.care_plan_already_renewed',
            FILE_SIZE_ERROR: 'errors.file_size_error',
          }}
          className={styles.error}
          error={requestError?.error}
          errorData={requestError?.data}
        />
        <Button
          key="back"
          buttonType="secondary"
          disabled={requestStatus === 'pending'}
          onClick={() => back(formContext.getValues())}
        >
          {prevText}
        </Button>
        <Button
          status={requestStatus}
          key="submit"
          buttonType="primary"
          type="submit"
          form={FORM_ID}
        >
          {nextText}
        </Button>
      </SideSheetActions>
    </>
  );
};

const makeStyles = () => ({
  container: css`
    flex-grow: 1;
  `,
  inputs: css`
    gap: ${Dimensions.M};
  `,
  error: css`
    flex-grow: 2;
    margin-right: ${Dimensions.S};
  `,
  textarea: css`
    margin-right: ${Dimensions.S};
  `,
  label: css`
    margin-bottom: ${Dimensions.S};
  `,
});
