import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Colors } from '@/theme/colors';
import { Theme } from '@/theme/theme';

import { StyleType } from './StyleTypes';

type ChipType = { background?: string; onClick?: () => void };

const Chip = ({
  background,
  onClick,
  children,
  className,
}: ChipType & PropsWithChildren & StyleType) => {
  const styles = useStyles(makeStyles);
  const backgroundColor = background ? background : Colors.gray[300];
  return (
    <div
      onClick={onClick}
      className={cx(styles.container, className, backgroundColor)}
    >
      {children}
    </div>
  );
};

export default Chip;

const makeStyles = (theme: Theme) => ({
  container: css`
    border-radius: ${theme.small};
    background: ${Colors.gray[300]};
    padding: ${theme.tiny} ${theme.small};
    gap: ${theme.small};
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
  `,
});
