import React from 'react';

import { Group } from '@visx/group';
import { Text } from '@visx/text';
import { useTranslation } from 'react-i18next';

// Gap between lines
const heightBetweenLines = 40;

// Font size of text
const fontSize = 16;

// get y position of bottom line from previous bottom line y position and height factor
const yBottomLineYPosition = (previousBottomLineY: number, heightFactor = 1) =>
  previousBottomLineY + heightBetweenLines * heightFactor;

type BottomLineYPositionsProps = {
  Start: number;
  Event: number;
  Glycemia: number;
  Food: number;
  InsulinFast: number;
  InsulinSlow: number;
  End: number;
};
// Bottom Line Y Positions of items
// TODO : Allow DailyGraph to change heightFactor for different sections (per ex: when overlap)
const bottomLineYPositions = {} as BottomLineYPositionsProps;
bottomLineYPositions.Start = 0;
bottomLineYPositions.Event = yBottomLineYPosition(bottomLineYPositions.Start);
bottomLineYPositions.Glycemia = yBottomLineYPosition(
  bottomLineYPositions.Event,
  7,
); // Glycemia 7 times larger than normal height
bottomLineYPositions.Food = yBottomLineYPosition(bottomLineYPositions.Glycemia);
bottomLineYPositions.InsulinFast = yBottomLineYPosition(
  bottomLineYPositions.Food,
); // InsulinFast 2 times larger than normal height
bottomLineYPositions.InsulinSlow = yBottomLineYPosition(
  bottomLineYPositions.InsulinFast,
);
bottomLineYPositions.End = yBottomLineYPosition(
  bottomLineYPositions.InsulinSlow,
);

const bottomLineYPositionsCompact = {} as BottomLineYPositionsProps;
bottomLineYPositionsCompact.Start = 0;
bottomLineYPositionsCompact.Event = bottomLineYPositionsCompact.Start;
bottomLineYPositionsCompact.Glycemia = yBottomLineYPosition(
  bottomLineYPositionsCompact.Event,
  3,
); // Glycemia 7 times larger than normal height
bottomLineYPositionsCompact.Food = bottomLineYPositionsCompact.Glycemia;
bottomLineYPositionsCompact.InsulinFast = yBottomLineYPosition(
  bottomLineYPositionsCompact.Food,
  0.8,
);
bottomLineYPositionsCompact.InsulinSlow = yBottomLineYPosition(
  bottomLineYPositionsCompact.InsulinFast,
  0.8,
);
bottomLineYPositionsCompact.End = yBottomLineYPosition(
  bottomLineYPositionsCompact.InsulinSlow,
  0.8,
);

// center text :  previous bottom line y position + gap between lines / 2 -  font size / 2
const yText = (previousBottomLineY: number) =>
  previousBottomLineY + heightBetweenLines / 2 - fontSize / 2;

export const TextElements = ({
  width,
  yPositions = bottomLineYPositions,
  size = 'full',
}: {
  width: number;
  yPositions?: BottomLineYPositionsProps;
  size?: 'compact' | 'full';
}) => {
  const { t } = useTranslation();
  const bottomLine = (bottomLineY: number) => (
    <line x2={width} y1={bottomLineY} y2={bottomLineY} stroke="#eff0f7" />
  );
  const text = (previousBottomLineY: number, textString: string) => (
    <Text
      dy={yText(previousBottomLineY)}
      textAnchor="start"
      verticalAnchor="start"
      fill="#838189"
    >
      {textString}
    </Text>
  );
  return (
    <Group top={yPositions.Start}>
      {size === 'full' &&
        text(yPositions.Start, t('components.daily_graph.events'))}
      {size === 'full' && bottomLine(yPositions.Event)}

      {text(yPositions.Event, t('components.daily_graph.glucose'))}
      {bottomLine(yPositions.Glycemia)}

      {size === 'full' &&
        text(yPositions.Glycemia, t('components.daily_graph.meal'))}
      {size === 'full' && bottomLine(yPositions.Food)}

      {text(yPositions.Food, t('components.daily_graph.bolus_insulin'))}
      {bottomLine(yPositions.InsulinFast)}

      {text(yPositions.InsulinFast, t('components.daily_graph.basal_insulin'))}
      {bottomLine(yPositions.InsulinSlow)}
    </Group>
  );
};

export {
  bottomLineYPositions,
  bottomLineYPositionsCompact,
  yText,
  heightBetweenLines,
  fontSize,
};
