import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SliceState } from '@/state/store';

import { deletePatientAlert } from './thunks';
import { AlertData, PatientAlertDelete } from './types';

const initialState: SliceState<AlertData> = {
  status: 'idle',
  error: null,
  data: {
    patients: {},
    physician: {},
  },
};

const alertsSlice = createSlice({
  name: 'alerts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(deletePatientAlert.pending, state => {
        state.status = 'loading';
      })
      .addCase(deletePatientAlert.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        deletePatientAlert.fulfilled,
        (state, action: PayloadAction<PatientAlertDelete>) => {
          const { patientId, alertId } = action.payload;
          const filteredPatientAlerts = state.data.patients[patientId].filter(
            alert => alert.id !== alertId,
          );

          state.status = 'succeeded';
          state.data.patients[patientId] = filteredPatientAlerts;
        },
      );
  },
});

export default alertsSlice.reducer;
