import React, { useCallback, useEffect } from 'react';

import { css } from '@emotion/css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { CarePlanForm } from '@/components/diabetes-form/CarePlanForm';
import { Dialog } from '@/components/floating/Dialog';
import { Loader } from '@/components/loading/Loader';
import { SideSheet } from '@/components/sidesheet/SideSheet';
import { useStyles } from '@/hooks/useTheme';
import { PatientWithCare } from '@/models/PatientWithCareModel';
import { Queries } from '@/queries/Queries';

type CreateCarePlanProps = {
  patientId: string;
  summitText: string;
  children: React.ReactNode;
  mode?: CarePlanCreationMode;
};

export const CreateCarePlan: React.FC<CreateCarePlanProps> = ({
  patientId,
  summitText,
  children,
  mode = 'create',
}) => {
  return (
    <Dialog
      placement="stretch end"
      content={setIsOpen => (
        <CarePlanDialogContent
          patientId={patientId}
          mode={mode}
          close={() => setIsOpen(false)}
          submitText={summitText}
        />
      )}
    >
      {children}
    </Dialog>
  );
};

type EditCarePlanProps = {
  patient: PatientWithCare;
};

export const EditCarePlan: React.FC<EditCarePlanProps> = ({ patient }) => {
  const { t } = useTranslation();
  const carePlan = patient.currentCarePlan;
  if (!carePlan) {
    return null;
  }

  return (
    <Dialog
      placement="stretch end"
      content={setIsOpen => (
        <CarePlanDialogContent
          patientId={patient.id}
          mode="edit"
          close={() => setIsOpen(false)}
          submitText={t('pages.patients.edit_patient')}
        />
      )}
    >
      <IconButton
        buttonType="secondary"
        textType="paragraphSmall"
        icon={Icons.edit}
        placement="left"
      >
        {t('pages.criteria_eligibility.edit_diabetes_infos')}
      </IconButton>
    </Dialog>
  );
};

export type CarePlanCreationMode = 'edit' | 'create';

type CarePlanDialogContentProps = {
  patientId: string;
  mode?: CarePlanCreationMode;
  close: () => void;
  submitText: string;
};
const CarePlanDialogContent: React.FC<CarePlanDialogContentProps> = ({
  patientId,
  mode = 'create',
  close,
  submitText,
}) => {
  const { t } = useTranslation();
  const { data: patient } = Queries.practitioner.useObservedPatient(patientId);
  const createCarePlan = Queries.practitioner.useCreateCarePlan();
  const editCarePlan = Queries.practitioner.useEditCarePlan();
  const styles = useStyles(makeStyles);

  const exit = useCallback(async () => {
    createCarePlan.reset();
    close();
  }, [close, createCarePlan]);

  useEffect(() => {
    if (createCarePlan.isSuccess || editCarePlan.isSuccess) {
      exit();
    }
  }, [createCarePlan.isSuccess, editCarePlan.isSuccess, exit]);

  if (!patient) {
    return (
      <SideSheet className={styles.container}>
        <Loader size="L" />
      </SideSheet>
    );
  }
  const carePlan = patient.currentCarePlan ?? patient.carePlans[0];

  return (
    <SideSheet>
      <CarePlanForm
        mode={mode}
        back={exit}
        onSubmit={data =>
          mode === 'create'
            ? createCarePlan.mutate(
                { patientId, ...data },
                {
                  onSuccess: () =>
                    Queries.practitioner.invalidateObservedPatient(patientId),
                },
              )
            : editCarePlan.mutate(
                {
                  patientId,
                  carePlanId: carePlan.id,
                  ...data,
                },
                {
                  onSuccess: () =>
                    Queries.practitioner.invalidateObservedPatient(patientId),
                },
              )
        }
        requestError={createCarePlan.error ?? editCarePlan.error ?? undefined}
        initialData={{
          diabetesType: carePlan?.diabetesType,
          insulinScheme: carePlan?.insulinTreatment,
          telemonitoringCriteria: carePlan?.telemonitoringCriteria,
          startDate:
            carePlan?.periodEnd && moment(carePlan.periodEnd).isAfter(moment())
              ? moment(carePlan.periodEnd).add(1, 'd').format('YYYY-MM-DD')
              : moment().format('YYYY-MM-DD'),
          durationMonths: 3,
          therapeuticObjectives: carePlan?.description,
          therapeuticObjectivesAttachment: carePlan?.descriptionAttachment,
          prescription:
            mode === 'edit' && carePlan.prescription
              ? carePlan.prescription
              : undefined,
        }}
        requestStatus={
          mode === 'edit' ? editCarePlan.status : createCarePlan.status
        }
        prevText={t('common.annuler')}
        nextText={submitText}
        editMode={mode === 'edit'}
      />
    </SideSheet>
  );
};

const makeStyles = () => ({
  container: css`
    display: flex;
    align-items: center;
    justify-content: center;
  `,
});
