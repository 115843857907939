/* eslint-disable @typescript-eslint/no-explicit-any */
import { fromEvent, race, timer } from 'rxjs';
import { bufferTime, finalize, map, takeWhile, tap } from 'rxjs/operators';

import { Command } from '../types';

const HID_PACKET_SIZE = 64;

interface RequestOptions {
  device: any;
  command: Command;
  data?: string;
}

export async function executeHidRequest(
  vendorId: number,
  productId: number,
  callback: any,
) {
  if (!('hid' in navigator)) {
    throw Error('Browser not supported');
  }
  const devices = await (navigator as any).hid.getDevices();
  let device = devices.find(
    (d: any) => d.vendorId === vendorId && d.productId === productId,
  );
  if (!device) {
    const savedDevices = await (navigator as any).hid.requestDevice({
      filters: [{ vendorId, productId }],
    });
    if (!savedDevices.length) {
      throw Error('Not selected device');
    }
    device = savedDevices[0];
  }
  await device.open();
  const obj = await callback(device);
  device.close();
  return obj;
}

function buildHidPacket(cmd: number, data?: string) {
  const buff = new Uint8Array(HID_PACKET_SIZE);
  const array = Array.from(buff);
  array.splice(0, 1, cmd);
  if (data) {
    array.splice(1, 1, data.length);
    data
      .split('')
      .map((item, index) => array.splice(index + 2, 1, item.charCodeAt(0)));
  }
  return array;
}

export function request(options: RequestOptions): Promise<Uint8Array> {
  return new Promise(resolve => {
    race(fromEvent(options.device, 'inputreport'), timer(1000)).subscribe(
      (event: any) =>
        resolve(new Uint8Array(event?.data ? event.data.buffer : [])),
    );
    options.device.sendReport(
      0,
      new Uint8Array(buildHidPacket(options.command, options.data)),
    );
  });
}

export function requestMultiple(
  options: RequestOptions,
): Promise<Uint8Array[]> {
  const buffer: Uint8Array[] = [];
  return new Promise(resolve => {
    fromEvent(options.device, 'inputreport')
      .pipe(
        map((event: any) => new Uint8Array(event.data.buffer)),
        bufferTime(1000),
        takeWhile(b => b.length > 0),
        tap(b => buffer.push(...b)),
        finalize(() => resolve(buffer)),
      )
      .subscribe();
    options.device.sendReport(
      0,
      new Uint8Array(buildHidPacket(options.command, options.data)),
    );
  });
}
