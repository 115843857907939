import React, {
  InputHTMLAttributes,
  TextareaHTMLAttributes,
  forwardRef,
} from 'react';

export type BaseFormInputProps = Omit<
  InputHTMLAttributes<HTMLInputElement>,
  'ref'
>;

export const BaseFormInput = forwardRef<HTMLInputElement, BaseFormInputProps>(
  function BaseFormInput({ className, ...props }, ref) {
    return <input ref={ref} className={className} {...props} />;
  },
);

export type BaseTextAreaFormInputProps = Omit<
  TextareaHTMLAttributes<HTMLTextAreaElement>,
  'ref'
>;

export const BaseTextAreaFormInput = forwardRef<
  HTMLTextAreaElement,
  BaseTextAreaFormInputProps
>(function BaseTextAreaFormInput({ className, ...props }, ref) {
  return <textarea ref={ref} className={className} {...props} />;
});

export type BaseSelectFormInputProps = Omit<
  InputHTMLAttributes<HTMLSelectElement>,
  'ref'
>;

export const BaseSelectFormInput = forwardRef<
  HTMLSelectElement,
  BaseSelectFormInputProps
>(function BaseFormInput({ className, ...props }, ref) {
  return <select ref={ref} className={className} {...props} />;
});
