import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useEffect,
  useState,
} from 'react';

import { css, cx } from '@emotion/css';
import {
  FloatingContext,
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  UseClickProps,
  UseDismissProps,
  autoUpdate,
  useClick,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
  useTransitionStyles,
} from '@floating-ui/react';
import { UseRoleProps, UseTransitionStylesProps } from '@floating-ui/react/';

import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';
import { ZIndex } from '@/theme/zIndex';

export type Alignment =
  | 'normal'
  | 'stretch'
  | 'center'
  | 'start'
  | 'end'
  | 'flex-start'
  | 'flex-end'
  | 'baseline';

export type DialogPlacement = 'center' | `${Alignment} ${Alignment}`;

export type BaseDialogProps = PropsWithChildren<{
  clickProps?: UseClickProps;
  placement: DialogPlacement;
  role: UseRoleProps['role'];
  autoOpen?: boolean;
  lockExit?: boolean;
  closeOnInteract?: boolean;
  dismiss?: UseDismissProps | boolean;
  className?: string;
  wrapperClassName?: string;
  floaterClassName?: string;
  animation?: UseTransitionStylesProps;
  content: (setIsOpen: Dispatch<SetStateAction<boolean>>) => React.ReactNode;
}>;

export const BaseDialog: React.FC<BaseDialogProps> = ({
  clickProps,
  placement,
  role,
  autoOpen = false,
  dismiss: dismissValue,
  className,
  floaterClassName,
  wrapperClassName,
  animation = {
    duration: 300,
    initial: { transform: 'translateX(100%)' },
  },
  content,
  children,
}) => {
  const styles = useStyles(makeStyles, placement);
  const [isOpen, setIsOpen] = useState(autoOpen);
  const [transitioning, setTransitioning] = useState(true);
  const { refs, context, elements } = useFloating<HTMLDivElement>({
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    transform: false,
  });
  const interactions = useDialogInteraction(
    context,
    role,
    clickProps,
    dismissValue,
  );
  const { isMounted, styles: animationStyle } = useTransitionStyles(
    context,
    animation,
  );
  const { styles: overlayAnimationStyle } = useTransitionStyles(context, {
    duration: animation.duration,
    initial: { opacity: 0 },
  });

  useEffect(() => {
    const endTransition = () => {
      setTransitioning(false);
    };
    const startTransition = () => {
      setTransitioning(true);
    };

    elements.floating?.addEventListener('transitionend', endTransition);
    elements.floating?.addEventListener('transitionstart', startTransition);

    return () => {
      elements.floating?.removeEventListener('transitionend', endTransition);
      elements.floating?.removeEventListener(
        'transitionstart',
        startTransition,
      );
    };
  }, [elements.floating]);

  return (
    <div
      className={cx(styles.container, className)}
      onClick={e => {
        if (!clickProps || clickProps.enabled !== false) {
          e.stopPropagation();
        }
      }}
    >
      <div
        ref={refs.setReference}
        className={cx(styles.wrapper, wrapperClassName)}
        {...interactions.getReferenceProps()}
      >
        {children}
      </div>
      {isMounted ? (
        <FloatingPortal>
          <FloatingOverlay
            style={overlayAnimationStyle}
            className={styles.overlay}
            lockScroll
          >
            <FloatingFocusManager
              context={context}
              guards={false}
              disabled={transitioning}
            >
              <div
                ref={refs.setFloating}
                style={animationStyle}
                className={cx(styles.floater, floaterClassName)}
                {...interactions.getFloatingProps()}
              >
                {content(setIsOpen)}
              </div>
            </FloatingFocusManager>
          </FloatingOverlay>
        </FloatingPortal>
      ) : null}
    </div>
  );
};

const useDialogInteraction = (
  context: FloatingContext<HTMLDivElement>,
  roleValue: UseRoleProps['role'],
  clickProps?: UseClickProps,
  dismissProps?: UseDismissProps | boolean,
) => {
  const click = useClick<HTMLDivElement>(context, clickProps);
  const role = useRole(context, { role: roleValue });
  const dismiss = useDismiss(
    context,
    typeof dismissProps === 'boolean'
      ? {
          enabled: dismissProps,
        }
      : dismissProps,
  );

  return useInteractions([click, dismiss, role]);
};

const makeStyles = (theme: Theme, placement: DialogPlacement) => ({
  container: css``,
  wrapper: css``,
  floater: css``,
  overlay: css`
    z-index: ${ZIndex.dialog};
    background-color: ${theme.tooltip.overlay};
    place-items: ${placement};
    display: grid;
    overflow: hidden !important;
    width: 100vw;
  `,
});
