import { DecoderFunction, string } from 'typescript-json-decoder';

import { nullOrUndef, recordWithContext } from '@/utils/decoderUtils';

export type Organization = {
  name: string;
  alias?: string;
  description?: string;
};

export const organizationDecoder: DecoderFunction<Organization> =
  recordWithContext('Organization', {
    name: string,
    alias: nullOrUndef(string),
    description: nullOrUndef(string),
  });
