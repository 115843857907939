import React from 'react';

import { css } from '@emotion/css';

import { ShowApiError } from '@/components/error/ShowApiError';
import { Col, Row } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { ActiveNotifications } from '@/components/notifications/ActiveNotifications';
import { usePatientIdFromURL } from '@/hooks';
import { useDateRangeFromURL } from '@/hooks/useDateRangeFromURL';
import { useStyles } from '@/hooks/useTheme';
import { useWindowSize } from '@/hooks/useWindowSize';
import { Queries } from '@/queries/Queries';
import { Dimensions } from '@/theme/dimensions';

import { GlycemiaProfiles } from './GlycemiaProfiles';
import { GlycemiaStats } from './GlycemiaStats/GlycemiaStats';
import HeaderMonitoring from './HeaderMonitoring';
import { InsulinStats } from './InsulinStats';
import './PatientMonitoring.scss';
import { PatientOverview } from './PatientOverview';
import { RPMInfo } from './RPMInfo';

export const PatientMonitoring: React.FC = () => {
  const patientId = usePatientIdFromURL();
  const dateRange = useDateRangeFromURL();
  const patientQuery = Queries.practitioner.useObservedPatient(patientId ?? '');
  const styles = useStyles(makeStyles);
  const { screenSize } = useWindowSize();

  switch (patientQuery.status) {
    case 'error':
      return (
        <ShowApiError errorMapping={{}} error={patientQuery.error.error} />
      );
    case 'pending':
      return (
        <Col className={styles.loading}>
          <Loader size="L" />
        </Col>
      );
    case 'success':
      const patient = patientQuery.data;
      switch (screenSize) {
        case 'SM':
        case 'MD':
          return (
            <Row className={styles.container}>
              <Col className={styles.content}>
                <HeaderMonitoring />
                <GlycemiaStats dateRange={dateRange} />
                <InsulinStats dateRange={dateRange} />
                <Row Component="aside" className={styles.aside}>
                  <PatientOverview patient={patient} />
                  <ActiveNotifications patient={patient} />
                </Row>
                <GlycemiaProfiles />
              </Col>
            </Row>
          );
        case 'LG':
        case 'XL':
        case 'XXL':
          return (
            <Row className={styles.container}>
              <Col className={styles.content}>
                <HeaderMonitoring />
                <GlycemiaStats dateRange={dateRange} />
                <InsulinStats dateRange={dateRange} />
                <GlycemiaProfiles />
              </Col>

              <Col Component="aside" className={styles.aside}>
                <PatientOverview patient={patient} />
                <RPMInfo patient={patient} />
                <ActiveNotifications patient={patient} />
              </Col>
            </Row>
          );
      }
  }
};

const makeStyles = () => ({
  container: css`
    width: 100%;
    justify-content: center;
    gap: ${Dimensions.S};
    margin-bottom: ${Dimensions.M};
  `,
  content: css`
    flex: 1 1;
    gap: ${Dimensions.S};
    min-width: 0;
  `,
  aside: css`
    flex: 0 1 300px;
    gap: ${Dimensions.S};
  `,
  loading: css`
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  `,
});
