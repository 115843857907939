import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '@/components/layout/Flex';
import { SideBar } from '@/components/navigation/SideBar';
import { useStyles } from '@/hooks/useTheme';
import { LayoutProps, RenderLayoutChildren } from '@/pages/layout/LayoutCommon';
import { Theme } from '@/theme/theme';

export const PatientLayout: React.FC<LayoutProps> = props => {
  const styles = useStyles(makeStyles);
  const { i18n } = useTranslation();

  return (
    <Row lang={i18n.language} className={styles.container}>
      <SideBar home="/" />
      <Col className={styles.content} Component="main">
        <RenderLayoutChildren {...props} />
      </Col>
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    align-items: stretch;
    margin: 0;
    padding: 0;
    width: 100vw;
    height: 100vh;
    background-color: ${theme.backgroundColor};
  `,
  content: css`
    padding: 4vh 4vw;
    flex-shrink: 1;
    flex-grow: 1;
    overflow-x: hidden;
  `,
});
