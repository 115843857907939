import React from 'react';

import { Tooltip } from '@visx/tooltip';
import { TooltipProps } from '@visx/tooltip/lib/tooltips/Tooltip';
import moment from 'moment-timezone';

import { ReactComponent as ChevronDown } from '@/assets/icons/ic-carret-down.svg';
import { FlexBox } from '@/components/box';

import { TooltipToShowProps } from './types';

const ToolTipTyped = Tooltip as React.FC<TooltipProps>;

type DynamicTooltipProps = {
  tooltipLeft: number;
  tooltipTop: number;
  tooltipData: TooltipToShowProps[];
};
//tooltipData: array of {title, content, date, dataInputMethod,icon = null}
export const DynamicTooltip = ({
  tooltipLeft,
  tooltipTop,
  tooltipData,
}: DynamicTooltipProps) => {
  return (
    <ToolTipTyped
      key={Math.random()} // needed for bounds to update correctly
      left={tooltipLeft}
      top={tooltipTop}
      offsetLeft={0} // prevent unexpected margin
      offsetTop={0} // prevent unexpected margin
      className="absolute flex flex-col whitespace-pre"
      unstyled
    >
      {tooltipData.map(
        (
          {
            tooltipData: { title, content, date, dataInputMethod, icon: Icon },
          },
          idx,
        ) => {
          return (
            <FlexBox className="z-50 flex-col" key={idx}>
              <FlexBox className="p-6 mx-auto bg-black rounded-md whitespace-no-wrap flex-col">
                {Icon && (
                  <div
                    className="text-center mx-auto flex center w-8 h-8 rounded-1/2 mb-1"
                    style={{
                      border: 'solid 2px var(--background-grayscale-000-2)',
                      backgroundColor: '#ffdddf',
                    }}
                  >
                    <Icon className="w-4 h-4 object-contain" />
                  </div>
                )}
                <div className="text-base font-semibold text-center text-white ">
                  {title}
                </div>
                <div className="text-xs font-normal text-center text-white ">
                  {content}
                </div>
                <div className="text-xs font-normal text-center text-gray-700 ">
                  {`${moment(date).format(
                    'Do MMMM HH:mm',
                  )} • ${dataInputMethod}`}
                </div>
              </FlexBox>
              <FlexBox className="justify-center">
                <ChevronDown />
              </FlexBox>
            </FlexBox>
          );
        },
      )}
    </ToolTipTyped>
  );
};
