import React from 'react';

import { Group } from '@visx/group';
import { scaleOrdinal } from '@visx/scale';
import Pie from '@visx/shape/lib/shapes/Pie';

import { largestRemainderRound } from '@/utils/math';

import './InsulinChart.scss';

const background = 'white';
const defaultMargin = { top: 0, left: 0, right: 0, bottom: 0 };

function getDataAsRatios(insulin) {
  const {
    daily_mean_long_insulin,
    daily_mean_short_insulin,
    daily_mean_basal_insulin,
    daily_mean_bolus_insulin,
  } = insulin ?? {};
  const [ratioBasal, ratioBolus] = largestRemainderRound([
    daily_mean_basal_insulin,
    daily_mean_bolus_insulin,
  ]);
  const [ratioSlow, ratioFast] = largestRemainderRound([
    daily_mean_long_insulin,
    daily_mean_short_insulin,
  ]);
  const hasBasalOrBolus = daily_mean_basal_insulin || daily_mean_bolus_insulin;

  return hasBasalOrBolus
    ? [
        {
          label: 'insuline basale',
          ratio: ratioBasal,
        },
        {
          label: 'insuline bolus',
          ratio: ratioBolus,
        },
      ]
    : [
        {
          label: 'insuline basale',
          ratio: ratioSlow,
        },
        {
          label: 'insuline bolus',
          ratio: ratioFast,
        },
      ];
}

export default ({ width, height, data, margin = defaultMargin }) => {
  if (width < 10) return null;

  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const donutThickness = 20;
  const ratio = d => d.ratio;
  const dataAsRatios = getDataAsRatios(data);

  // color scales
  const getFillColor = scaleOrdinal({
    domain: Object.keys(dataAsRatios),
    range: ['#e5e1ff', '#b3f2db'],
  });
  const getStrokeColor = scaleOrdinal({
    domain: Object.keys(dataAsRatios),
    range: ['#4e32ff', '#00d185'],
  });

  return (
    <div>
      <svg width={width} height={height}>
        <rect width={width} height={height} fill={background} rx={14} />
        <Group top={centerY + margin.top} left={centerX + margin.left}>
          <Pie
            data={dataAsRatios}
            pieValue={ratio}
            outerRadius={radius}
            innerRadius={radius - donutThickness}
            cornerRadius={3}
            padAngle={0.005}
          >
            {pie => (
              <CustomPie
                {...pie}
                getStrokeColor={arc => getStrokeColor(arc.data.label)}
                getFillColor={arc => getFillColor(arc.data.label)}
                getKey={arc => `${arc.data.label}`}
              />
            )}
          </Pie>
        </Group>
      </svg>
    </div>
  );
};

function CustomPie({
  arcs,
  path,
  getKey,
  getStrokeColor,
  getFillColor,
  onClickDatum = () => null,
}) {
  return (
    <>
      {arcs.map(arc => {
        return (
          <g key={getKey(arc)}>
            <path
              d={path({
                ...arc,
              })}
              fill={getFillColor(arc)}
              stroke={getStrokeColor(arc)}
              onClick={() => onClickDatum(arc)}
              onTouchStart={() => onClickDatum(arc)}
            />
          </g>
        );
      })}
    </>
  );
}
