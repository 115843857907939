import React from 'react';

import { Group } from '@visx/group';
import { scaleBand, scaleLinear, scaleOrdinal } from '@visx/scale';
import { BarRounded } from '@visx/shape';
import { Text } from '@visx/text';

import './GlycemiaBars.scss';

const defaultMargin = { top: 0, left: 100, right: 60, bottom: 0 };

// accessors
const getGlycemiaRange = d => d.glycemia_range;
const getRatio = d => Number(d.ratio);

// color scales
const borderColorScale = scaleOrdinal({
  domain: [...Array(5).keys()],
  range: ['#ff7538', '#ffa91d', '#00d185', '#ff1d25', '#600040'],
});
const fillColorScale = scaleOrdinal({
  domain: [...Array(5).keys()],
  range: ['#ffd6c4', '#ffe6bb', '#b3f2db', '#ffbbbe', '#d0b3c6'],
});
const textColorScale = scaleOrdinal({
  domain: [...Array(5).keys()],
  range: ['#d96330', '#d88f18', '#00b271', '#ff1d25', '#600040'],
});

export default ({
  data,
  width,
  height,
  margin = defaultMargin,
  className = '',
}) => {
  // bounds
  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  // scales, memoize for performance
  const yScale = React.useMemo(
    () =>
      scaleBand({
        range: [0, yMax],
        round: true,
        domain: data.map(getGlycemiaRange),
        padding: 0.7,
      }),
    [yMax, data],
  );
  const xScale = React.useMemo(
    () =>
      scaleLinear({
        range: [0, xMax],
        round: true,
        domain: [0, Math.max(...data.map(getRatio))],
      }),
    [xMax, data],
  );

  const springs = data.map(d => ({
    barWidth: xScale(getRatio(d)),
    labelX: xScale(getRatio(d)) + 10,
  }));

  return (
    <div className={className}>
      <svg width={width} height={height}>
        <Group top={margin.top} left={margin.left}>
          {springs.map(({ barWidth, labelX }, index) => {
            const letter = getGlycemiaRange(data[index]);
            const barHeight = yScale.bandwidth();
            const barX = 0;
            const barY = yScale(letter);
            return (
              <Group key={`bar-group-${letter}`}>
                <BarRounded
                  key={`bar-${letter}`}
                  x={barX}
                  y={barY}
                  width={barWidth}
                  height={barHeight}
                  fill={fillColorScale(index)}
                  stroke={borderColorScale(index)}
                  strokeWidth="1px"
                  radius={2}
                  all={true}
                />
                <Text
                  key={`bar-data-${letter}`}
                  x={labelX}
                  y={barY + barHeight}
                  fontSize={16}
                  fill={textColorScale(index)}
                >
                  {getRatio(data[index]) + '%'}
                </Text>
                <Text
                  key={`bar-legend-${letter}`}
                  x={barX - 10}
                  y={barY + barHeight}
                  fontSize={12}
                  fill={'grey'}
                  textAnchor="end"
                >
                  {getGlycemiaRange(data[index])}
                </Text>
              </Group>
            );
          })}
        </Group>
      </svg>
    </div>
  );
};
