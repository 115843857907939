import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { CardFooter } from '@/components/card/CardFooter';
import { TextCardTitle } from '@/components/card/CardTitle';
import { useStyles } from '@/hooks/useTheme';
import { PatientWithCare } from '@/models/PatientWithCareModel';
import {
  CurrentCarePlan,
  TelemonitoringInfo,
} from '@/pages/patient-settings/CurrentCarePlan';
import { Queries } from '@/queries/Queries';
import { Dimensions } from '@/theme/dimensions';

import { CreateCarePlan, EditCarePlan } from './CarePlanDialogs';

export type TelemonitoringFormProps = {
  patientId: string;
};

export const TelemonitoringForm: React.FC<TelemonitoringFormProps> = ({
  patientId,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  const { data: patient } = Queries.practitioner.useObservedPatient(patientId);
  if (!patient) {
    return null;
  }

  return (
    <Card className={styles.container} elevation={0}>
      <TextCardTitle
        id="tls-title"
        title={t('pages.diabete_et_inscription_a_la_telesurveillance')}
      />
      <CardBody className={styles.body}>
        {patient.currentCarePlan ? (
          <CurrentCarePlan
            carePlan={patient.currentCarePlan}
            patient={patient}
          />
        ) : (
          <TelemonitoringInfo patient={patient} />
        )}
      </CardBody>
      <CardFooter className={styles.footer}>
        <TelemonitoringFormFooter patient={patient} />
      </CardFooter>
    </Card>
  );
};

export type TelemonitoringFormFooterProps = {
  patient: PatientWithCare;
};

const TelemonitoringFormFooter: React.FC<TelemonitoringFormFooterProps> = ({
  patient,
}) => {
  const { t } = useTranslation();
  const telemonitoringState = patient.telemonitoring
    ? patient.upcomingCarePlan
      ? 'renewed'
      : 'renewable'
    : 'inactive';

  switch (telemonitoringState) {
    case 'renewed':
      return (
        <>
          <EditCarePlan patient={patient} />
          <IconButton
            buttonType="secondary"
            textType="paragraphSmall"
            icon={Icons.filePlus}
            placement="left"
            disabled
          >
            {t('components.diabetes_form.renewed')}
          </IconButton>
        </>
      );
    case 'renewable': {
      const buttonText = t('components.diabetes_form.renew');
      return (
        <>
          <EditCarePlan patient={patient} />
          <CreateCarePlan patientId={patient.id} summitText={buttonText}>
            <IconButton
              buttonType="primary"
              textType="paragraphSmall"
              icon={Icons.filePlus}
              placement="left"
            >
              {buttonText}
            </IconButton>
          </CreateCarePlan>
        </>
      );
    }
    case 'inactive': {
      const buttonText = t(
        'pages.criteria_eligibility.activer_la_telesurveillance',
      );
      return (
        <CreateCarePlan patientId={patient.id} summitText={buttonText}>
          <IconButton
            buttonType="primary"
            textType="paragraphSmall"
            icon={Icons.filePlus}
            placement="left"
          >
            {buttonText}
          </IconButton>
        </CreateCarePlan>
      );
    }
  }
};

const makeStyles = () => ({
  container: css`
    margin-top: ${Dimensions.M};
  `,
  body: css``,
  footer: css`
    justify-content: flex-end;
    gap: ${Dimensions.M};
  `,
  checkbox: css`
    margin-right: ${Dimensions.XS};
  `,
});
