export enum Command {
  INIT_REQUEST_1 = 0x04,
  INIT_REQUEST_2 = 0x05,
  INIT_REQUEST_3 = 0x15,
  INIT_REQUEST_4 = 0x01,
  BINARY_REQUEST = 0x0a,
  BINARY_RESPONSE = 0x0b,
  ACK_FROM_DEVICE = 0x0c,
  ACK_FROM_HOST = 0x0d,
  TEXT_REQUEST = 0x21,
  TEXT_RESPONSE = 0x60,
}
