import {
  DecoderFunction,
  boolean,
  field,
  string,
} from 'typescript-json-decoder';

import { PractitionerSummary } from '@/models/PractitionerModel';
import { User } from '@/models/UserModel';
import {
  decodeId,
  deepField,
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type AlertCategory =
  | 'hypoglycemia'
  | 'hyperglycemia'
  | 'transmission'
  | 'measurement'
  | 'severe_hypoglycemia'
  | 'technical_alert'
  | 'remove_consent'
  | 'hypoglycemia_tbr'
  | 'hyperglycemia_tar';

export type AlertCriticity = 'low' | 'medium' | 'high';

export type Alert = {
  id: string;
  date: string;
  isActive: boolean;
  dateRemoved?: string;
  criticity: AlertCriticity;
  category: AlertCategory;
  parameters?: string;
  userRemoved?: User & PractitionerSummary;
};

export type AlertSummary = {
  id: string;
  patientId: string;
  date: string;
  isActive: boolean;
  dateRemoved?: string;
  criticity: AlertCriticity;
  category: AlertCategory;
  parameters?: string;
};

export const alertSummaryDecoder: DecoderFunction<AlertSummary> =
  recordWithContext('AlertSummary', {
    id: decodeId,
    patientId: deepField('config.patient', string),
    date: string,
    isActive: field('is_active', boolean),
    dateRemoved: field('date_removed', nullOrUndef(string)),
    criticity: deepField(
      'config.criticity',
      stringUnion('low', 'medium', 'high'),
    ),
    category: deepField(
      'config.category',
      stringUnion(
        'hypoglycemia',
        'hyperglycemia',
        'transmission',
        'measurement',
        'severe_hypoglycemia',
        'technical_alert',
        'remove_consent',
        'hypoglycemia_tbr',
        'hyperglycemia_tar',
      ),
    ),
    parameters: deepField('config.parameters', nullOrUndef(string)),
  });

export const UNCLEARABLE_ALERTS = {
  transmission: undefined,
  measurement: undefined,
} satisfies Partial<Record<AlertCategory, undefined>>;
