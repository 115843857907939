import React from 'react';

export default function useCaptureClickOutside<ElementType extends HTMLElement>(
  action: () => void,
) {
  const container = React.useRef<ElementType>(null);

  React.useEffect(() => {
    function handler(e: Event) {
      if (container.current && e.target !== null) {
        if (!container.current.contains(e.target as Node)) {
          action();
        }
      }
    }

    document.addEventListener('mousedown', handler);

    return function cleanup() {
      document.removeEventListener('mousedown', handler);
    };
  }, [action]);

  return container;
}
