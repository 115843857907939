import React from 'react';

import { BrowserRouter } from 'react-router-dom';

import { Button } from '@/components/button/Button';
import { Col } from '@/components/layout/Flex';
import { LoadingScreen } from '@/components/loading/LoadingScreen';
import { Typo } from '@/components/typography/Text';
import { useKeycloak } from '@/keycloak';
import { Queries } from '@/queries/Queries';
import { PatientRouter } from '@/router/PatientRouter';
import { PractitionerRouter } from '@/router/PractitionerRouter';

export type RouterProps = {
  // Empty
};

export const Router: React.FC<RouterProps> = ({}) => {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
};

const Routes: React.FC = () => {
  const keycloak = useKeycloak();
  const me = Queries.user.useMe();
  switch (me.status) {
    case 'pending':
      return <LoadingScreen />;
    case 'success': {
      switch (me.data.userType) {
        case 'practitioner':
          return <PractitionerRouter />;
        case 'patient':
          return <PatientRouter />;
        case 'admin':
          return (
            <Col>
              <Typo type="title">Admin panel</Typo>
              <Button
                buttonType="secondary"
                onClick={() =>
                  keycloak.logout({
                    redirectUri: `${process.env.REACT_APP_BASE_URL}`,
                  })
                }
              >
                logout
              </Button>
            </Col>
          );
      }
    }
    case 'error': {
      if (me.error.statusCode === 401) {
        keycloak.logout();
      }
      return <LoadingScreen />;
    }
  }
};
