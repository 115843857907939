import React from 'react';

import { Group } from '@visx/group';

export const badgeHeight = 24;
export const badgeWidth = 70;
export const badgeWidthSmall = 25;

type BadgeProps = Partial<{
  text: string;
  Icon: React.FC<
    React.SVGProps<SVGSVGElement> & {
      title?: string | undefined;
    }
  > | null;
  backgroundColor: string;
  colorText: string;
  fontSize: number;
  left: number;
  top: number;
  iconShiftY: number;
  small: boolean;
  width?: number;
  number: number;
  numberCircleR: number;
  numberTextSize: string;
  numberCircleColor: string;
  numberTextColor: string;
  numberCircleStrokeColor: string;
  numberCircleStrokeWidth: number;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}>;

export const Badge = ({
  text = '0',
  Icon,
  backgroundColor = '#ffdddf',
  colorText = '#ff1d25',
  fontSize = 14,
  left = 0,
  top = 0,
  iconShiftY = 0,
  small = false,
  width,
  number = undefined,
  numberCircleR = 7,
  numberTextSize = '0.625rem',
  numberCircleColor = '#ffdddf',
  numberTextColor = 'red',
  numberCircleStrokeColor = 'white',
  numberCircleStrokeWidth = 1,
  onMouseEnter = () => null,
  onMouseLeave = () => null,
}: BadgeProps) => {
  text = text + '';

  const textFontSizeRatio = 3 / 5;
  const textFontSizeHeightRatio = 1.35;

  const badgeWidthColumnGrid =
    (width ? width : small ? badgeWidthSmall : badgeWidth) / 24;

  const iconWidthHeight = 14; // what ??

  const marginLeft = text ? badgeWidthColumnGrid : 0;

  const secondPartMarginLeft =
    ((width ? width : small ? badgeWidthSmall : badgeWidth) -
      (iconWidthHeight +
        (text.length > 0 ? 1 : 0) * marginLeft +
        text.length * fontSize * textFontSizeRatio)) /
    2;

  const textHeight = fontSize * textFontSizeHeightRatio;
  const textYposition = textHeight - (badgeHeight - textHeight) / 2;

  const noIcon = (
    <Group
      left={left}
      top={top}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <rect
        width={width ? width : small ? badgeWidthSmall : badgeWidth}
        height={badgeHeight}
        rx={badgeHeight / 2}
        ry={badgeHeight / 2}
        fill={backgroundColor}
      ></rect>
      <text
        x={secondPartMarginLeft + marginLeft + iconWidthHeight / 2}
        y={textYposition}
        fontSize={fontSize}
      >
        {text}
      </text>
    </Group>
  );

  const withIcon = (
    <Group
      left={left}
      top={top}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <rect
        width={width ? width : small ? badgeWidthSmall : badgeWidth}
        height={badgeHeight}
        rx={badgeHeight / 2}
        ry={badgeHeight / 2}
        fill={backgroundColor}
      ></rect>
      <Group left={secondPartMarginLeft}>
        {/* First Part : Icon*/}
        {Icon && (
          <Icon
            x={0}
            width={iconWidthHeight}
            height={iconWidthHeight}
            y={(badgeHeight - iconWidthHeight) / 2 + iconShiftY}
          />
        )}
        {/* Second Part : Text or Icon*/}
        <text
          fontSize={fontSize}
          fill={colorText}
          x={iconWidthHeight + marginLeft}
          y={textYposition}
        >
          {text}
        </text>
      </Group>
      {number && (
        <Group left={width ? width : small ? badgeWidthSmall : badgeWidth}>
          <circle
            r={numberCircleR}
            fill={numberCircleColor}
            stroke={numberCircleStrokeColor}
            strokeWidth={numberCircleStrokeWidth}
          />
          <text
            textAnchor="middle"
            dominantBaseline="central"
            fontSize={numberTextSize}
            fill={numberTextColor}
          >
            {number}
          </text>
        </Group>
      )}
    </Group>
  );
  return Icon === null ? noIcon : withIcon;
};
