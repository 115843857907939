import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { MenuTooltip } from '@/components/floating/MenuTooltip';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { useKeycloak } from '@/keycloak';
import { patientQueries } from '@/queries/PatientQueries';
import { Queries } from '@/queries/Queries';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

import { UserPicture } from '../user-picture/UserPicture';

export type UserMenuProps = {
  // Empty
};

export const UserMenu: React.FC<UserMenuProps> = ({}) => {
  const styles = useStyles(makeStyles);
  const user = Queries.user.useMe();
  const patient = patientQueries.usePatient({
    enabled: user.isSuccess && user.data.userType === 'patient',
  });
  const practitioner = Queries.practitioner.usePractitioner({
    enabled: user.status === 'success' && user.data.userType === 'practitioner',
  });

  const data =
    patient.status === 'success'
      ? patient.data
      : practitioner.status === 'success'
      ? practitioner.data
      : undefined;
  switch (data) {
    case undefined:
      return (
        <div className={styles.picture}>
          <UserPicture />
        </div>
      );
    default:
      return (
        <MenuTooltip
          openOnHover
          placement="right-end"
          floaterClassName={styles.floater}
          content={
            <div className={styles.tooltip} data-testid="user-menu-tooltip">
              <UserActions />
            </div>
          }
        >
          <div className={styles.picture}>
            <UserPicture
              givenName={data.givenName}
              familyName={data.familyName}
            />
          </div>
        </MenuTooltip>
      );
  }
};

const UserActions: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const keycloak = useKeycloak();

  return (
    <>
      <Link id="account" to="/account" className={styles.action}>
        {t('pages.account.mon_compte')}
      </Link>
      <Typo
        type="paragraph"
        onClick={() =>
          keycloak.logout({ redirectUri: `${process.env.REACT_APP_BASE_URL}` })
        }
        className={styles.action}
      >
        {t('pages.navbar.se_deconnecter')}
      </Typo>
    </>
  );
};

const makeStyles = (theme: Theme) => ({
  tooltip: css``,
  picture: css`
    display: flex;
    position: relative;
    flex-direction: column;
    width: 100%;
    height: 100%;
  `,
  action: css`
    display: flex;
    padding: ${Dimensions.XS} ${Dimensions.S};
    cursor: pointer;
    &:hover {
      background-color: ${theme.sideBar.backgroundHover};
    }
  `,
  floater: css`
    min-width: min(200px, calc(90vw - ${theme.sideBar.width}));
    max-width: calc(90vw - ${theme.sideBar.width});
  `,
});
