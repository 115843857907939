import React, { InputHTMLAttributes, ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { Path, RegisterOptions, useFormContext } from 'react-hook-form';
import { FieldValues } from 'react-hook-form/dist/types/fields';

import { IconType, Icons } from '@/assets/icons';
import { BaseSelectFormInput } from '@/components/form/inputs/BaseFormInput';
import { InputError, getError } from '@/components/form/inputs/InputError';
import { Col, Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

type InputType = Required<{
  type: InputHTMLAttributes<HTMLInputElement>['type'];
}>;

type LabelProps =
  | string
  | {
      type: 'above' | 'left';
      label: string;
    };

type OptionItem<T extends FieldValues> = { value: T[keyof T]; label: string };

export type SelectFormInputProps<T extends FieldValues> = InputType & {
  IconStart?: IconType;
  IconEnd?: IconType;
  options: OptionItem<T>[];
  containerStyle?: string;
  inputStyle?: string;
  label?: LabelProps;
  name: Path<T>;
} & RegisterOptions<T>;

export const SelectFormInput = <T extends FieldValues>({
  IconStart,
  IconEnd = Icons.chevronDown,
  options,
  containerStyle,
  name,
  inputStyle,
  label,
  type,
  ...inputProps
}: SelectFormInputProps<T>): ReactElement => {
  const context = useFormContext<T>();
  const styles = useStyles(makeStyles, !!getError({ context, name }));
  // TODO other kinds of labels than placeholder
  return (
    <Col grow className={cx(styles.container, containerStyle)}>
      <Row align="center" shrink className={styles.content}>
        {IconStart ? <IconStart className={styles.icon} /> : null}
        <BaseSelectFormInput
          type={type}
          className={cx(styles.input, inputStyle)}
          {...context.register(name, inputProps)}
        >
          <SelectOption
            key="empty"
            label={
              typeof label === 'string'
                ? `${label} ${inputProps.required ? '*' : ''}`
                : undefined
            }
            value=""
            hidden
          />
          {options.map(item => (
            <SelectOption
              key={item.label}
              label={item.label}
              value={item.value}
            />
          ))}
        </BaseSelectFormInput>
        <IconEnd className={styles.arrow} />
      </Row>
      <InputError<T> className={styles.error} name={name} context={context} />
    </Col>
  );
};

type SelectOptionProps = {
  label?: string;
  value: string | number | readonly string[] | undefined;
  hidden?: boolean;
};
const SelectOption: React.FC<SelectOptionProps> = ({
  label = '',
  value,
  hidden,
}) => {
  return (
    <option value={value} hidden={hidden}>
      {label}
    </option>
  );
};

const makeStyles = (theme: Theme, error: boolean) => ({
  container: css`
    min-width: 0;
  `,
  content: css`
    position: relative;
    padding: ${theme.input.paddingVertical} ${theme.input.paddingHorizontal};
    margin-bottom: ${theme.input.bottomMargin};
    border-color: ${error ? theme.input.error : theme.input.border};
    border-width: ${theme.input.borderWidth};
    border-radius: ${theme.input.borderRadius};
  `,
  icon: css`
    fill: ${error ? theme.input.error : theme.input.border};
    width: 1em;
    height: 1em;
    margin-right: ${theme.input.paddingHorizontal};
  `,
  arrow: css`
    position: absolute;
    pointer-events: none;
    right: 0;
    fill: ${error ? theme.input.error : theme.input.border};
    width: 1em;
    height: 1em;
    margin-right: ${theme.input.paddingHorizontal};
  `,
  input: css`
    flex: 1 1 0;
    min-width: 0;
    color: ${theme.input.text};
    ::placeholder {
      color: inherit;
      opacity: 0.5;
    }
  `,
  error: css`
    height: 2.5em;
    color: ${theme.input.error};
  `,
});
