import { DeviceType } from './types';

export const translateDeviceType = (deviceType: DeviceType) => {
  switch (deviceType) {
    case DeviceType.MANUAL:
      return 'models.devices.type.manual';
    case DeviceType.PUMP:
      return 'models.devices.type.pump';
    case DeviceType.READER:
      return 'models.devices.type.reader';
    case DeviceType.REMOTE_MONITORING:
      return 'models.devices.type.remote_monitoring';
    case DeviceType.SENSOR:
      return 'models.devices.type.sensor';
  }
};
