import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { FlexBox } from '@/components/box';
import {
  toast,
  toastError,
  toastInfo,
} from '@/components/toast/ToastComponent';
import { usePatientIdFromURL } from '@/hooks';
import { SelfPatient } from '@/models/SelfPatientModel';
import { Queries } from '@/queries/Queries';

import styles from './FileUpload.module.scss';

const noFileChosen = (file: FileList | null): file is null =>
  file === null || file.length === 0;

type FileUploadProps = {
  patient: SelfPatient;
};

export default function FileUpload({ patient }: FileUploadProps) {
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL();
  const uploadFile = Queries.diabetes.useUploadFile();
  const [file, setFile] = React.useState<FileList | null>(null);
  const isLoading = uploadFile.isPending;
  const disabled = noFileChosen(file) || uploadFile.isPending;

  const handleSubmit = async (evt: React.ChangeEvent<HTMLFormElement>) => {
    evt.preventDefault();
    if (file === null) return;

    const fileToUpload = file[0];

    uploadFile.mutate(
      {
        patientId: patientId ?? patient.id,
        file: fileToUpload,
      },
      {
        onSuccess: () => {
          setFile(null);
          toastInfo(
            t('pages.add_measurement_file.success'),
            toast.POSITION.BOTTOM_RIGHT,
          );
        },
        onError: () => {
          toastError(
            t('pages.add_measurement_file.error'),
            toast.POSITION.BOTTOM_RIGHT,
          );
        },
      },
    );
  };

  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (evt.target.files === null || evt.target.files.length === 0) {
      setFile(null);
    } else {
      setFile(evt.target.files);
    }
  };

  return (
    <form onSubmit={handleSubmit} className={styles.form}>
      <label
        htmlFor="file"
        className={[
          styles.labelFile,
          noFileChosen(file) ? styles.labelPrimary : styles.labelSecondary,
        ].join(' ')}
      >
        {t('pages.add_measurement_file.choose_file')}
      </label>
      <input
        id="file"
        className={styles.inputFile}
        type="file"
        accept=".csv"
        onChange={handleChange}
      />
      {noFileChosen(file) ? (
        <p>{t('pages.add_measurement_file.no_file_chosen')}</p>
      ) : (
        <FlexBox className={styles.fileName}>
          <Icons.trash type="button" onClick={() => setFile(null)} />
          <p>{file[0].name}</p>
        </FlexBox>
      )}
      <button type="submit" disabled={disabled} className={styles.btnSubmit}>
        {t(isLoading ? 'common.chargement' : 'common.envoyer')}
      </button>
    </form>
  );
}
