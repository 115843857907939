import React from 'react';

import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Line } from '@visx/shape';
import moment from 'moment-timezone';

import {
  BottomAxis,
  DynamicTooltip,
  FoodElements,
  GlycemiaElements,
  InsulinElements,
  ReportsElements,
  TextElements,
  VerticalGrids,
  badgeHeight,
  badgeWidth,
  badgeWidthSmall,
  bottomLineYPositions,
  glycemiaCircleRadius,
  heightBetweenLines,
} from '@/components/data-representations/DailyGraph';
import { bottomLineYPositionsCompact } from '@/components/data-representations/DailyGraph/TextElements';
import { Dataviz } from '@/models/Dataviz';
import { GlycemiaDataPoint, GlycemiaTypes } from '@/state/diabetes/types';

import {
  HandleMouseEnterProps,
  ScaleLinear,
  TooltipToShowProps,
} from './types';

const GAP = 20.0;
// Separates the data into subgroups when there are more than "gap" minutes between them.
// data: [{date:..., value:..}]
// gap: remove segment between to point separed of gap minutes
const split_data_gap = (data: GlycemiaDataPoint[], gap = GAP) => {
  if (!data || data.length == 0) {
    return [];
  }
  const split = [];
  for (let i = 0, j = 1; i < data.length - 1; i++, j++) {
    const d1 = data[i].date,
      d2 = data[j].date;
    split.push(moment(d2).diff(moment(d1), 'minutes', true) > gap);
  }
  const data_splited = [];
  let data_splited_i = [data[0]];
  for (let i = 0; i < split.length; i++) {
    if (split[i]) {
      data_splited.push(data_splited_i);
      data_splited_i = [];
    }
    data_splited_i.push(data[i + 1]);
  }
  if (data_splited_i.length > 0) {
    data_splited.push(data_splited_i);
  }
  return data_splited;
};

const margin = { top: 25, left: 100, right: 100, bottom: 25 };

// x position of some graph elements

const leftGraphXPosition = 100;
const leftAxeXPosition = 11;

// offset for the tooltip
const offsetTooltipTop = 10;

const getHour = (date: string | moment.Moment) =>
  moment(date).hour() + moment(date).minutes() / 60; // add minutes to hour

const getYPosition = (
  upperYPosition: number,
  size: 'compact' | 'full' = 'full',
) =>
  upperYPosition +
  (heightBetweenLines * (size === 'compact' ? 0.8 : 1)) / 2 -
  badgeHeight / 2;

type DailyGraphProps = {
  width: number;
  data: (Dataviz & GlycemiaTypes) | null;
  size?: 'compact' | 'full';
};

export const DailyGraph = ({ data, width, size = 'full' }: DailyGraphProps) => {
  const yPositions =
    size === 'compact' ? bottomLineYPositionsCompact : bottomLineYPositions;
  const height = yPositions.End;

  const { parameters, glycemia, insulin, food, reports, glycemiaTypes } =
    data ?? {};

  const {
    glycemiaSensor = [],
    glycemiaReader = [],
    glycemiaManual = [],
  } = glycemiaTypes ?? {
    glycemiaReader: glycemia,
  };

  const [glycemiaSensor_splitted, setGlycemiaSensor_splitted] = React.useState<
    GlycemiaDataPoint[][]
  >([]);

  React.useEffect(() => {
    if (glycemiaSensor) {
      setGlycemiaSensor_splitted(split_data_gap(glycemiaSensor, GAP)); // 45 min
    }
  }, [glycemiaSensor]);

  const [maxGlucose, setMaxGlucose] = React.useState(0);
  const [yScaleGlucose, setYScaleGlucose] =
    React.useState<ScaleLinear<number, number>>();

  const [tooltips, setTooltips] = React.useState<TooltipToShowProps[]>([]);

  const hoursLegends = Array.from(Array(26).keys())
    .map(e => e + ' h')
    .map(e => (e.length === 3 ? '0' + e : e));

  const xScale = scaleLinear({
    domain: [0, hoursLegends.length],
    range: [0, width - margin.right],
  });

  const rightAxeXPosition = xScale(24) + leftAxeXPosition;

  React.useEffect(() => {
    let isMounted = true;
    const UpdateLayoutBoundaries = () => {
      if (isMounted) {
        if (glycemia && parameters) {
          setMaxGlucose(
            Math.max(
              ...[
                Math.max(
                  ...(glycemiaReader || []).map(dataPoint => dataPoint.value),
                ),
                Math.max(
                  ...(glycemiaManual || []).map(dataPoint => dataPoint.value),
                ),
                Math.max(
                  ...(glycemiaSensor_splitted || [])
                    .flat()
                    .map(dataPoint => dataPoint.value),
                ),
                parameters.thresholdHypoglycemia,
                parameters.thresholdHyperglycemia,
                parameters.thresholdHyperglycemiaSevere,
                parameters.thresholdHypoglycemiaSevere,
              ],
            ),
          );
        }
        if (maxGlucose) {
          setYScaleGlucose(() =>
            scaleLinear({
              domain: [maxGlucose, 0],
              range: [
                yPositions.Event + glycemiaCircleRadius, // prevent overflow of the circle above and below Glycemia section
                yPositions.Glycemia - glycemiaCircleRadius,
              ],
            }),
          );
        }
      }
    };
    UpdateLayoutBoundaries();

    return () => {
      isMounted = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    data,
    maxGlucose,
    glycemiaReader,
    glycemiaManual,
    glycemiaSensor_splitted,
  ]);

  const [tooltipOpen, setTooltipOpen] = React.useState(false);

  // we use Ref to get the width and height of the tooltip at this creation
  const tooltipRef = React.useRef<HTMLDivElement>(null);

  // event handlers
  // if multiple tooltips `data` is used and others props are ignored
  const handleMouseEnter =
    (xShift = 0, yShift = 0) =>
    ({
      x,
      y,
      title,
      content,
      date,
      dataInputMethod,
      icon,
      data = null,
    }: HandleMouseEnterProps) => {
      if (tooltipOpen) return;
      //    if (tooltipTimeout) clearTimeout(tooltipTimeout);

      let tooltipsToShow: TooltipToShowProps[];

      if (data === null) {
        tooltipsToShow = [
          {
            tooltipLeft:
              (x ?? 0) + leftGraphXPosition + leftAxeXPosition + xShift, // x + different Xshift in the graph
            tooltipTop: (y ?? 0) - offsetTooltipTop - yShift, // y - the offset top of the tooltip - different Yshift in the graph
            tooltipData: { title, content, date, dataInputMethod, icon },
          },
        ];
      } else {
        tooltipsToShow = data.map(tooltip => ({
          tooltipLeft:
            tooltip.x + leftGraphXPosition + leftAxeXPosition + xShift, // x + different Xshift in the graph
          tooltipTop: tooltip.y - offsetTooltipTop - yShift, // y - the offset top of the tooltip - different Yshift in the graph
          tooltipData: {
            title: tooltip?.title,
            content: tooltip?.content,
            date: tooltip?.date,
            dataInputMethod: tooltip?.dataInputMethod,
            icon: tooltip?.icon,
          },
        }));
      }
      setTooltips(tooltipsToShow);
      setTooltipOpen(true);
    };

  // adjust tooltip top/left position, when tooltip is open and tooltip is visible
  React.useEffect(() => {
    if (tooltipRef.current && tooltipOpen) {
      const tooltipElem = tooltipRef.current.children[0] as HTMLElement;
      setTooltips(tooltips =>
        tooltips.map(tooltip => ({
          ...tooltip,
          tooltipTop: tooltip.tooltipTop - tooltipElem.offsetHeight,
          tooltipLeft: tooltip.tooltipLeft - tooltipElem.offsetWidth / 2,
        })),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipOpen]);

  /*
  const handleMouseLeave = () => {
    setTooltipOpen(false);
  };
*/

  const handleMouseLeave = React.useCallback(() => {
    return setTooltipOpen(false);
  }, [setTooltipOpen]);

  /*

  type TooltipData = string;

  const { containerRef, containerBounds, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
    detectBounds: true,
  });

  const {
    showTooltip,
    hideTooltip,
    tooltipData,
    tooltipLeft = 0,
    tooltipTop = 0,
  } = useTooltip<TooltipData>({
    // initial tooltip state
    tooltipLeft: width / 3,
    tooltipTop: height / 3,
    tooltipData: 'Move me with your mouse or finger',
  });


  const handlePointerMove = React.useCallback(
    (event: React.PointerEvent<HTMLDivElement>) => {
      
      // coordinates should be relative to the container in which Tooltip is rendered
      const containerX = ('clientX' in event ? event.clientX : 0) - containerBounds.left;
      const containerY = ('clientY' in event ? event.clientY : 0) - containerBounds.top;
      showTooltip({
        tooltipLeft: containerX,
        tooltipTop: containerY,
        tooltipData: 'Blablabla'
      });
    },
    [showTooltip, containerBounds],
  );

  */
  return (
    <div style={{ position: 'relative' }}>
      <svg
        {...{
          width,
          height,
        }}
      >
        <TextElements {...{ width }} yPositions={yPositions} size={size} />

        <Group left={leftGraphXPosition}>
          <Line
            x1={leftAxeXPosition}
            x2={leftAxeXPosition}
            y2={height - margin.bottom}
            stroke="#eff0f7"
          />
          <Line
            x1={rightAxeXPosition}
            x2={rightAxeXPosition}
            y2={height - margin.bottom}
            stroke="#eff0f7"
          />
          <Group left={leftAxeXPosition}>
            <VerticalGrids {...{ height, margin, xScale }} />
            {reports && size == 'full' && (
              <ReportsElements
                {...{
                  reports,
                  xScale,
                  getHour,
                  top: getYPosition(yPositions.Start),
                  handleMouseEnter: handleMouseEnter(badgeWidthSmall / 2), // need xShift because tooltip is on the middle of the badge
                  handleMouseLeave,
                }}
              />
            )}

            <GlycemiaElements
              {...{
                parameters,
                xScale,
                yScaleGlucose,
                glycemiaManual: glycemiaManual || [],
                glycemiaReader: glycemiaReader || [],
                glycemiaSensor: glycemiaSensor_splitted,
                getHour,
                handleMouseEnterSensor: handleMouseEnter(),
                handleMouseEnter: handleMouseEnter(750, 750),
                handleMouseLeave,
                marginLeft: leftGraphXPosition + leftAxeXPosition,
                small: false,
              }}
            />

            {food && size == 'full' && (
              <FoodElements
                {...{
                  food: food,
                  xScale,
                  getHour,
                  top: getYPosition(yPositions.Glycemia),
                  handleMouseEnter: handleMouseEnter(badgeWidthSmall / 2),
                  handleMouseLeave,
                }}
              />
            )}

            {insulin && (
              <InsulinElements
                {...{
                  insulin,
                  xScale,
                  getHour,
                  topBolus: getYPosition(yPositions.Food, size),
                  topBasal: getYPosition(yPositions.InsulinFast, size),
                  end: yPositions.InsulinSlow,
                  handleMouseEnter: handleMouseEnter(badgeWidth / 2),
                  handleMouseLeave,
                  //                  onPointerMove: handlePointerMove
                }}
              />
            )}

            <BottomAxis {...{ height, margin, xScale }} />
          </Group>
        </Group>
      </svg>
      {tooltipOpen && tooltips && tooltips.length > 0 && (
        <div ref={tooltipRef}>
          <DynamicTooltip
            {...{
              tooltipData: tooltips,
              tooltipTop: tooltips[0].tooltipTop,
              tooltipLeft: tooltips[0].tooltipLeft,
            }}
          />
        </div>
      )}
    </div>
  );
};
