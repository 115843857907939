/*
 ** This file uses the "Compound Component" pattern.
 ** More info:
 **   - ComponentsPattern.md
 **   - https://kentcdodds.com/blog/compound-components-with-react-hooks/
 */
import React from 'react';

import { useTranslation } from 'react-i18next';

import { FlexBox } from '@/components/box';
import FormButtons from '@/components/form/FormButtons';
import Typography from '@/components/typography/Typography';
import { capitalize } from '@/utils/common';

type ModalLayoutProps = {
  children: React.ReactNode;
  className?: string;
  rest?: unknown;
};
function ModalLayout({ children, className = '', ...rest }: ModalLayoutProps) {
  return (
    <FlexBox className={'modal-wrapper flex-col ' + className} {...rest}>
      {children}
    </FlexBox>
  );
}

type TitleProps = {
  children: React.ReactNode;
  className?: string;
  rest?: unknown;
};
function Title({ children, className, ...rest }: TitleProps) {
  return (
    <Typography
      element="h2"
      fontWeight="bold"
      className={`text-2xl ${className}`}
      {...rest}
    >
      {children}
    </Typography>
  );
}

type BodyProps = {
  children: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;
function Body({ children, ...rest }: BodyProps) {
  return <FlexBox {...rest}>{children}</FlexBox>;
}

type ButtonsProps = {
  isLoading: boolean;
  close: () => void;
  resolve: () => void;
  textSubmit: string;
  position?: 'start' | 'center' | 'end';
  disabledPredicate?: () => boolean;
  rest?: unknown;
};
function Buttons({
  isLoading,
  close,
  resolve,
  textSubmit,
  position = 'center',
  disabledPredicate = () => false,
  ...rest
}: ButtonsProps) {
  const { t } = useTranslation();

  return (
    <FormButtons isLoading={isLoading} position={position} {...rest}>
      <FormButtons.Cancel onClick={close}>
        {capitalize(t('common.annuler'))}
      </FormButtons.Cancel>

      <FormButtons.Submit
        onClick={resolve}
        disabledPredicate={disabledPredicate}
      >
        {textSubmit}
      </FormButtons.Submit>
    </FormButtons>
  );
}

ModalLayout.Title = Title;
ModalLayout.Body = Body;
ModalLayout.Buttons = Buttons;

export default ModalLayout;
