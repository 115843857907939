import { ChatRepository } from '@/io/repository/ChatRepository';
import {
  ChatFilterParametersType,
  CreateMessage,
  Message,
  UpdateMessage,
} from '@/models/ChatModel';

import { PractitionerQueries } from './PractitionerQueries';
import {
  createAppMutation,
  createAppQuery,
  queryClient,
  stripQueryResult,
} from './QueryClient';

type ChatRequest = { subjectId: string; visibility: ChatFilterParametersType };

export class ChatQueries {
  constructor(
    private readonly chat: ChatRepository = new ChatRepository(),
    private readonly practitionerQueries: PractitionerQueries = new PractitionerQueries(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useChat = createAppQuery<Message[], ChatRequest>({
    queryKey: ({ subjectId, visibility }) => ['chat', subjectId, visibility],
    queryFn: async ({ subjectId, visibility }) => {
      const res = stripQueryResult(
        await this.chat.getMessages(subjectId, visibility),
      );
      this.practitionerQueries.invalidatePatientsList();
      this.practitionerQueries.invalidateObservedPatient(subjectId);
      return res;
    },
  });

  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useCreateMessage = createAppMutation<
    CreateMessage,
    { patientId: string } & CreateMessage
  >({
    mutationFn: async ({ patientId, ...data }) =>
      stripQueryResult(await this.chat.createMessage(patientId, data)),
    onSuccess: () => {
      this.invalidateMessageList();
    },
  });

  useUpdateMessage = createAppMutation<
    UpdateMessage,
    { patientId: string; messageId: string } & UpdateMessage
  >({
    mutationFn: async ({ patientId, messageId, ...data }) =>
      stripQueryResult(
        await this.chat.updateMessage(patientId, messageId, data),
      ),
    onSuccess: () => {
      this.invalidateMessageList();
    },
  });

  /*******************************/
  /*********** HELPERS ***********/
  /*******************************/

  invalidateMessageList = () => {
    queryClient.invalidateQueries({ queryKey: ['chat'] });
  };
}
