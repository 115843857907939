import React, { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { FlexBox } from '@/components/box';
import FormSelect from '@/components/form/FormSelect';
import IconNotification from '@/components/notifications/IconNotification';
import Typography from '@/components/typography/Typography';
import {
  AlertCategoriesTranslations,
  AlertCreatableCategoriesTranslations,
  createParameters,
  isHyperglycemia,
  isHyperglycemiaTar,
  isHypoglycemia,
  isHypoglycemiaTbr,
  isInactive,
  isObservance,
  isObservanceMeasurement,
  isObservanceTransmission,
} from '@/state/alert-configs/utils';

import EventSettings from './AlertConfigSettings';

export default function AlertConfigComponent({
  alertConfig,
  onPatch,
  onCreated,
  onDelete,
}) {
  const [isEditing, setIsEditing] = useState(alertConfig.id > 0);
  useEffect(() => {
    setIsEditing(alertConfig.id > 0);
  }, [alertConfig.id]);

  return (
    <FlexBox className="flex-col w-full">
      <FlexBox className="items-center w-full px-8 py-4 border-t border-gray-200 alert-config-component">
        <AlertCategory
          alertConfig={alertConfig}
          onCreate={onCreated}
          isEditing={isEditing}
        />

        {isEditing ? (
          <>
            <AlertCriticity alertConfig={alertConfig} onPatch={onPatch} />
            <AlertSettings alertConfig={alertConfig} onPatch={onPatch} />

            <FlexBox
              className={'items-center justify-between w-1/12 alert-status'}
              style={
                isObservance(alertConfig)
                  ? { display: 'none' }
                  : { cursor: 'pointer' }
              }
            >
              <Icons.closeIcon
                id="btn-delete"
                className={'w-4 h-4 ml-8 '}
                onClick={() => {
                  if (!isObservance(alertConfig)) {
                    onDelete(alertConfig.id);
                  }
                }}
              />
            </FlexBox>
          </>
        ) : null}
      </FlexBox>
    </FlexBox>
  );
}

const AlertCategory = ({ alertConfig, onCreate, isEditing }) => {
  const { t } = useTranslation();

  const handleCategoryChange = evt => {
    alertConfig.category = evt.target.value;
    alertConfig.parameters = createParameters(alertConfig.category);
    onCreate(alertConfig);
  };

  const renderCategories = () =>
    Object.entries(AlertCreatableCategoriesTranslations).map(
      (categorie, key) => (
        <option key={key} value={categorie[0]}>
          {t(categorie[1])}
        </option>
      ),
    );

  return (
    <div
      className={
        'alert-type w-3/12 font-medium px-2 ' +
        (isInactive(alertConfig) ? 'opacity-25' : '')
      }
    >
      {isEditing ? (
        <Typography>
          {t(AlertCategoriesTranslations[alertConfig.category])}
        </Typography>
      ) : (
        <FormSelect
          disabled={isInactive(alertConfig)}
          onChange={handleCategoryChange}
          name="category"
          value={alertConfig.category}
        >
          <option value="">{t('pages.account.type_de_notification')}</option>
          {renderCategories()}
        </FormSelect>
      )}
    </div>
  );
};

const AlertCriticity = ({ alertConfig, onPatch }) => {
  const { t } = useTranslation();

  const handleCriticityChange = evt => {
    const patch = { criticity: evt.target.value };
    onPatch(alertConfig.id, patch);
  };

  return (
    <FlexBox
      className={
        'alert-criticity w-4/12 font-medium justify-center flex-1 flex-no-wrap items-center space-x-2' +
        (isInactive(alertConfig) ? ' opacity-25' : '')
      }
    >
      <IconNotification criticity={alertConfig?.criticity} />

      <FormSelect
        disabled={isInactive(alertConfig)}
        onChange={handleCriticityChange}
        value={alertConfig?.criticity}
        name="criticity"
      >
        <option value="low">{t('components.intervention.mineur')}</option>
        <option value="medium">{t('components.intervention.haut')}</option>
        <option value="high">{t('components.intervention.tres_haut')}</option>
      </FormSelect>
    </FlexBox>
  );
};

const AlertSettings = ({ alertConfig, onPatch }) => {
  const { t } = useTranslation();

  return (
    <FlexBox
      className={
        'alert-parameters items-center flex-wrap px-2 ' +
        (isInactive(alertConfig) ? 'opacity-25' : '')
      }
      style={isObservance(alertConfig) ? { width: '50%' } : { width: '42%' }}
    >
      {isHypoglycemia(alertConfig) ? (
        <HypoglycemiaSettings alertConfig={alertConfig} onPatch={onPatch} />
      ) : null}
      {isHypoglycemiaTbr(alertConfig) ? (
        <HypoglycemiaTbrSettings alertConfig={alertConfig} onPatch={onPatch} />
      ) : null}
      {isHyperglycemia(alertConfig) ? (
        <HyperglycemiaSettings alertConfig={alertConfig} onPatch={onPatch} />
      ) : null}
      {isHyperglycemiaTar(alertConfig) ? (
        <HyperglycemiaTarSettings alertConfig={alertConfig} onPatch={onPatch} />
      ) : null}
      {isObservanceTransmission(alertConfig) ? (
        <TransmissionSettings alertConfig={alertConfig} onPatch={onPatch} />
      ) : null}
      {isObservanceMeasurement(alertConfig) ? (
        <MeasurementSettings alertConfig={alertConfig} onPatch={onPatch} />
      ) : null}
      {!isHypoglycemiaTbr(alertConfig) &&
      !isHyperglycemiaTar(alertConfig) &&
      !isHypoglycemia(alertConfig) &&
      !isHyperglycemia(alertConfig) &&
      !isObservance(alertConfig) ? (
        <Typography fontWeight="thin">
          {t(
            'models.alert_config.notification_declenchee_des_que_l_evenement_est_constate',
          )}
        </Typography>
      ) : null}
    </FlexBox>
  );
};

const HypoglycemiaSettings = ({ alertConfig, onPatch }) => {
  const { t } = useTranslation();

  const handleMaxHypoChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;
    const patch = {
      parameters: {
        ...alertConfig.parameters,
        max_number_of_hypos: newValue,
        type: alertConfig.parameters.type ?? 1,
      },
    };
    onPatch(alertConfig.id, patch);
  };

  const handleNDaysHypoChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;
    const patch = {
      parameters: {
        ...alertConfig.parameters,
        n_days: newValue,
        type: alertConfig.parameters.type ?? 1,
      },
    };
    onPatch(alertConfig.id, patch);
  };

  const handleTypeChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;

    const patch = {
      parameters: { ...alertConfig.parameters, type: newValue },
    };
    onPatch(alertConfig.id, patch);
  };

  return (
    <EventSettings disabled={isInactive(alertConfig)}>
      <EventSettings.MaxOccurence
        onInput={handleMaxHypoChange}
        value={alertConfig.parameters.max_number_of_hypos}
        name="parameters.max_number_of_hypos"
        label={t('common.plus_de')}
        eventType={t('common.hypoglycemie_pluriel')}
      />
      <FormSelect
        onChange={handleTypeChange}
        value={alertConfig.parameters.type ?? 1}
        name="type"
        className={'bg-gray-300 flex-1'}
      >
        <option value="1">{t('components.alert-configs.low_very_low')}</option>
        <option value="2">{t('components.alert-configs.very_low')}</option>
      </FormSelect>
      <EventSettings.SinceDay
        onInput={handleNDaysHypoChange}
        value={alertConfig.parameters.n_days}
        name="parameters.n_days"
      />
    </EventSettings>
  );
};

const HyperglycemiaSettings = ({ alertConfig, onPatch }) => {
  const { t } = useTranslation();

  const handleMaxHyperChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;
    const patch = {
      parameters: {
        ...alertConfig.parameters,
        max_number_of_hypers: newValue,
        type: alertConfig.parameters.type ?? 1,
      },
    };
    onPatch(alertConfig.id, patch);
  };

  const handleNDaysHyperChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;
    const patch = {
      parameters: {
        ...alertConfig.parameters,
        n_days: newValue,
        type: alertConfig.parameters.type ?? 1,
      },
    };
    onPatch(alertConfig.id, patch);
  };
  const handleTypeChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;

    const patch = {
      parameters: { ...alertConfig.parameters, type: newValue },
    };
    onPatch(alertConfig.id, patch);
  };

  return (
    <EventSettings disabled={isInactive(alertConfig)}>
      <EventSettings.MaxOccurence
        onInput={handleMaxHyperChange}
        value={alertConfig.parameters.max_number_of_hypers}
        name="parameters.max_number_of_hypers"
        label={t('common.plus_de')}
        eventType={t('common.hyperglycemie_pluriel')}
      />
      <FormSelect
        onChange={handleTypeChange}
        value={alertConfig.parameters.type ?? 1}
        name="type"
        className={'bg-gray-300 flex-1'}
      >
        <option value="1">
          {t('components.alert-configs.high_very_high')}
        </option>
        <option value="2">{t('components.alert-configs.very_high')}</option>
      </FormSelect>
      <EventSettings.SinceDay
        onInput={handleNDaysHyperChange}
        value={alertConfig.parameters.n_days}
        name="parameters.n_days"
      />
    </EventSettings>
  );
};

const HypoglycemiaTbrSettings = ({ alertConfig, onPatch }) => {
  const { t } = useTranslation();

  const handleMaxHypoChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value) / 100;
    if (isNaN(newValue)) return;
    const patch = {
      parameters: {
        ...alertConfig.parameters,
        max_tbr: newValue,
        type: alertConfig.parameters.type ?? 1,
      },
    };
    onPatch(alertConfig.id, patch);
  };

  const handleNDaysHypoChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;
    const patch = {
      parameters: {
        ...alertConfig.parameters,
        n_days: newValue,
        type: alertConfig.parameters.type ?? 1,
      },
    };
    onPatch(alertConfig.id, patch);
  };
  const handleTypeChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;

    const patch = {
      parameters: { ...alertConfig.parameters, type: newValue },
    };
    onPatch(alertConfig.id, patch);
  };

  return (
    <EventSettings disabled={isInactive(alertConfig)}>
      <FlexBox className="flex-col mb-3">
        <EventSettings.MaxPercent
          onInput={handleMaxHypoChange}
          value={(alertConfig.parameters.max_tbr * 100).toFixed(0)}
          name="parameters.max_tbr"
          label={t('common.plus_de')}
        />
        <Typography fontWeight="medium">
          {t('components.alert-configs.event_hypo')}
        </Typography>
        <FormSelect
          onChange={handleTypeChange}
          value={alertConfig.parameters.type ?? 1}
          name="type"
          className={'bg-gray-300 flex-1'}
        >
          <option value="1">
            {t('components.alert-configs.low_very_low')}
          </option>
          <option value="2">{t('components.alert-configs.very_low')}</option>
        </FormSelect>
      </FlexBox>
      <EventSettings.SinceDay
        onInput={handleNDaysHypoChange}
        value={alertConfig.parameters.n_days}
        name="parameters.n_days"
      />
    </EventSettings>
  );
};

const HyperglycemiaTarSettings = ({ alertConfig, onPatch }) => {
  const { t } = useTranslation();

  const handleMaxHyperChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value) / 100;
    if (isNaN(newValue)) return;

    const patch = {
      parameters: {
        ...alertConfig.parameters,
        max_tar: newValue,
        type: alertConfig.parameters.type ?? 1,
      },
    };
    onPatch(alertConfig.id, patch);
  };

  const handleNDaysHyperChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;
    const patch = {
      parameters: {
        ...alertConfig.parameters,
        n_days: newValue,
        type: alertConfig.parameters.type ?? 1,
      },
    };
    onPatch(alertConfig.id, patch);
  };
  const handleTypeChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;

    const patch = {
      parameters: { ...alertConfig.parameters, type: newValue },
    };
    onPatch(alertConfig.id, patch);
  };

  return (
    <EventSettings disabled={isInactive(alertConfig)}>
      <FlexBox className="flex-col mb-3">
        <EventSettings.MaxPercent
          onInput={handleMaxHyperChange}
          value={(alertConfig.parameters.max_tar * 100).toFixed(0)}
          name="parameters.max_number_of_hypers"
          label={t('common.plus_de')}
        />
        <Typography fontWeight="medium">
          {t('components.alert-configs.event_hyper')}
        </Typography>
        <FormSelect
          onChange={handleTypeChange}
          value={alertConfig.parameters.type ?? 1}
          name="type"
          className={'bg-gray-300 flex-1'}
        >
          <option value="1">
            {t('components.alert-configs.high_very_high')}
          </option>
          <option value="2">{t('components.alert-configs.very_high')}</option>
        </FormSelect>
      </FlexBox>
      <EventSettings.SinceDay
        onInput={handleNDaysHyperChange}
        value={alertConfig.parameters.n_days}
        name="parameters.n_days"
      />
    </EventSettings>
  );
};

const TransmissionSettings = ({ alertConfig, onPatch }) => {
  const { t } = useTranslation();

  const handleNDaysObservanceChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue)) return;

    const patch = {
      parameters: { ...alertConfig.parameters, n_days: newValue },
    };
    onPatch(alertConfig.id, patch);
  };

  return (
    <EventSettings disabled={isInactive(alertConfig)}>
      <Typography fontWeight="thin">
        {t('components.alert-configs.transmission')}
      </Typography>
      <EventSettings.SinceDayDropdown
        onInput={handleNDaysObservanceChange}
        value={alertConfig.parameters.n_days}
        min={1}
        max={7}
        incr={1}
        name="parameters.n_days"
      />
    </EventSettings>
  );
};

const MeasurementSettings = ({ alertConfig, onPatch }) => {
  const { t } = useTranslation();
  const handleNDaysObservanceChange = evt => {
    const newValue = !evt.target.value ? 0 : parseInt(evt.target.value);
    if (isNaN(newValue) || newValue < 0 || newValue > 7) return;

    const patch = {
      parameters: { ...alertConfig.parameters, n_days: newValue },
    };
    onPatch(alertConfig.id, patch);
  };

  return (
    <EventSettings disabled={isInactive(alertConfig)}>
      <Typography fontWeight="thin" className="w-full">
        {t('components.alert-configs.capillary_measures')}
      </Typography>
      <EventSettings.ForXRequiresY
        disabled
        value={alertConfig.parameters.min_nb_of_measurements_mono}
        name="parameters.min_nb_of_measurements_mono"
        forX={t('components.alert-configs.capillary_mono')}
        requiresY={t('components.alert-configs.measures_per_day')}
      />
      <EventSettings.ForXRequiresY
        disabled
        value={alertConfig.parameters.min_nb_of_measurements_multi}
        name="parameters.min_nb_of_measurements_multi"
        forX={t('components.alert-configs.capillary_multi')}
        requiresY={t('components.alert-configs.measures_per_day')}
      />
      <Typography fontWeight="thin" className="w-full">
        {t('components.alert-configs.interstitial_measures')}
      </Typography>
      <EventSettings.ForXRequiresY
        disabled
        value={alertConfig.parameters.min_nb_scan}
        name="parameters.min_nb_scan"
        requiresY={t('components.alert-configs.scans_per_day')}
      />
      <EventSettings.SinceDayDropdown
        onInput={handleNDaysObservanceChange}
        value={alertConfig.parameters.n_days}
        min={1}
        max={7}
        incr={1}
        name="parameters.n_days"
      />
    </EventSettings>
  );
};
