import React, { useCallback, useRef, useState } from 'react';

import { css, cx } from '@emotion/css';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import { PageCallback } from 'react-pdf/src/shared/types';

import { Loader } from '@/components/loading/Loader';
import { useStyles } from '@/hooks/useTheme';

import { PdfOverlay } from './PdfOverlay';

export type PdfViewerProps = {
  url?: string;
  className?: string;
};
export const PdfViewer: React.FC<PdfViewerProps> = ({ url, className }) => {
  const styles = useStyles(makeStyles);
  const [scale, setScale] = useState<number>(1.0);
  const [height, setHeight] = useState<number>(0);
  const [numPages, setNumPages] = useState<number>(0);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [status, setStatus] = useState<'loading' | 'ready'>('loading');
  const containerRef = useRef<HTMLDivElement>(null);
  const onPageLoad = (page: PageCallback) => {
    if (containerRef.current) {
      const scale = containerRef.current.clientWidth / page.originalWidth;
      setScale(scale);
      setHeight(page.originalHeight * scale);
    }
  };

  const onDocumentLoadStart = useCallback((): void => {
    setStatus('loading');
  }, []);

  const onDocumentLoadSuccess = useCallback(
    ({ numPages }: { numPages: number }): void => {
      setNumPages(numPages);
      setStatus('ready');
    },
    [],
  );

  const changePage = (action: 'next' | 'prev') => {
    const page = pageNumber;
    if (action === 'prev' && page > 1) {
      setPageNumber(page - 1);
    } else if (action === 'next' && page < numPages) {
      setPageNumber(page + 1);
    }
  };

  return (
    <div className={cx(styles.container, className)} ref={containerRef}>
      <Document
        file={url}
        loading={<Loader size="M" />}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadStart={onDocumentLoadStart}
        className={css`
          height: ${height}px;
        `}
      >
        <Page
          pageNumber={pageNumber}
          className={styles.page}
          onLoadSuccess={onPageLoad}
          scale={scale}
        />
      </Document>
      <PdfOverlay
        currentPage={pageNumber}
        numPages={numPages}
        changePage={changePage}
        visible={status === 'ready'}
      />
    </div>
  );
};

const makeStyles = () => ({
  container: css`
    width: 100%;
    position: relative;
  `,
  document: css`
    width: 100%;
  `,
  page: css`
    width: 100%;
    min-width: 0 !important;
    overflow: hidden;
  `,
});
