import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

import FSL_SMARTPHONE_ABBOTT from '@/assets/icons/fsl_smartphone_abbott.jpeg';
import PUMP_MEDTRONIC from '@/assets/icons/pump_medtronic.png';
import { FlexBox } from '@/components/box';
import { usePatientIdFromURL, useQueryString } from '@/hooks';
import { useStyles } from '@/hooks/useTheme';
import { SelfPatient } from '@/models/SelfPatientModel';
import { PageContainer } from '@/pages/PageContainer';
import { patientQueries } from '@/queries/PatientQueries';
import { Colors } from '@/theme/colors';
import { Theme } from '@/theme/theme';

import FileUpload from './FileUpload';
import HidUpload from './HidUpload';
import styles from './MeasurementImport.module.scss';

/*

TODO cleanup and fix for health pro to be able to import

 */
export enum DeviceParamEnum {
  FSL_WITH_ABBOTT_READER = 'FSL_WITH_ABBOTT_READER',
  FSL_WITH_ABBOTT_SMARTPHONE = 'FSL_WITH_ABBOTT_SMARTPHONE',
  _640G_MEDTRONIC = '_640G_MEDTRONIC',
}

const getTitlePage = (device: DeviceParamEnum) => {
  switch (device) {
    case DeviceParamEnum.FSL_WITH_ABBOTT_READER:
      return 'pages.add_measurement_device.fsl.add_data';
    case DeviceParamEnum.FSL_WITH_ABBOTT_SMARTPHONE:
      return 'pages.add_measurement_file.fsl.add_data';
    case DeviceParamEnum._640G_MEDTRONIC:
      return 'pages.add_measurement_file._640g.add_data';
    default:
      return 'pages.add_measurement_file.choose_medical_device_to_load';
  }
};

export const MeasurementImportPage: React.FC = () => {
  const { t } = useTranslation();
  const { device } = useQueryString();
  const hasSelectedADevice = device !== undefined;
  // TODO handle when health pro want to import for user
  const patient = patientQueries.usePatient();

  return (
    <PageContainer
      title={t(getTitlePage(device as DeviceParamEnum))}
      back={hasSelectedADevice}
    >
      {device && patient.status === 'success' ? (
        <DeviceUpload
          patient={patient.data}
          device={device as DeviceParamEnum}
        />
      ) : (
        <DeviceCardList
          disabled={
            patient.status !== 'success' ||
            patient.data.user.userType !== 'patient'
          }
        />
      )}
    </PageContainer>
  );
};

const DeviceCardList = ({ disabled }: { disabled: boolean }) => {
  const { t } = useTranslation();
  const deviceList: DeviceCardProps[] = [
    {
      title: 'pages.add_measurement_file.fsl_with_abbott_smartphone',
      imgSrc: FSL_SMARTPHONE_ABBOTT,
      type: DeviceParamEnum.FSL_WITH_ABBOTT_SMARTPHONE,
      disabled,
    },
    {
      title: 'pages.add_measurement_file.pump_640g_medtronic',
      imgSrc: PUMP_MEDTRONIC,
      type: DeviceParamEnum._640G_MEDTRONIC,
      disabled,
    },
  ];

  return (
    <FlexBox className={styles.deviceCardList}>
      {deviceList.map((device, idx) => (
        <DeviceCard
          disabled={device.disabled}
          key={`${device.type}-${idx}`}
          title={t(device.title)}
          imgSrc={device.imgSrc}
          type={device.type}
        />
      ))}
    </FlexBox>
  );
};

type DeviceCardProps = {
  title: string;
  imgSrc: string;
  type: DeviceParamEnum;
  disabled: boolean;
};

const DeviceCard = ({ title, imgSrc, type, disabled }: DeviceCardProps) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const cardStyles = useStyles(makeDeviceCardStyles, disabled);
  return (
    <FlexBox className={styles.deviceCard}>
      <h4>{title}</h4>
      <img src={imgSrc} alt="device" />
      <Link to={`${pathname}?device=${type}`} className={cardStyles.link}>
        {t('common.choose')}
      </Link>
    </FlexBox>
  );
};

const makeDeviceCardStyles = (_: Theme, disabled: boolean) => ({
  link: css`
    pointer-events: ${disabled ? 'none' : 'initial'};
    background-color: ${disabled
      ? `${Colors['steto-blue']['300']} !important`
      : 'initial'};
  `,
});

type DeviceUploadProps = {
  patient: SelfPatient;
  device: DeviceParamEnum;
};

const DeviceUpload = ({ patient, device }: DeviceUploadProps) => {
  return (
    <FlexBox className={styles.deviceUpload}>
      <DeviceUploadInstructions device={device} />
      <DeviceUploadForm patient={patient} device={device} />
    </FlexBox>
  );
};

const DeviceUploadInstructions = ({ device }: { device: DeviceParamEnum }) => {
  const { t } = useTranslation();

  switch (device) {
    case DeviceParamEnum.FSL_WITH_ABBOTT_READER:
      return (
        <>
          <p>{t('pages.add_measurement_device.fsl.instructions')}</p>
          <p className="italic">{t('pages.add_measurement_device.fsl.note')}</p>
        </>
      );
    case DeviceParamEnum.FSL_WITH_ABBOTT_SMARTPHONE:
      return <p>{t('pages.add_measurement_file.fsl.instructions')}</p>;
    case DeviceParamEnum._640G_MEDTRONIC:
      return <p>{t('pages.add_measurement_file._640g.instructions')}</p>;
    default:
      return null;
  }
};

const DeviceUploadForm = ({ patient, device }: DeviceUploadProps) => {
  const patientId = usePatientIdFromURL();
  const navigate = useNavigate();
  const fallbackURL =
    patient.user.userType === 'patient'
      ? '/'
      : `/patients/${patientId}/medical-file`;

  switch (device) {
    case DeviceParamEnum.FSL_WITH_ABBOTT_READER:
      return <HidUpload patient={patient} />;
    case DeviceParamEnum.FSL_WITH_ABBOTT_SMARTPHONE:
      return <FileUpload patient={patient} />;
    case DeviceParamEnum._640G_MEDTRONIC:
      return <FileUpload patient={patient} />;
    default:
      navigate(fallbackURL);
      return null;
  }
};
