import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { Col, Row } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { DateRange } from '@/hooks/useDateRangeFromURL';
import { useStyles } from '@/hooks/useTheme';
import { AgpProfiles } from '@/pages/patient-monitoring/AgpProfiles';
import { GlycemiaGraphics } from '@/pages/patient-monitoring/GlycemiaStats/GlycemiaGraphics';
import { GlycemiaReaderValues } from '@/pages/patient-monitoring/GlycemiaStats/GlycemiaReaderValues';
import { GlycemiaSensorValues } from '@/pages/patient-monitoring/GlycemiaStats/GlycemiaSensorValue';
import { Queries } from '@/queries/Queries';
import { Dimensions } from '@/theme/dimensions';

export type GlycemiaStatsProps = {
  dateRange: DateRange;
};

export const GlycemiaStats: React.FC<Partial<GlycemiaStatsProps>> = ({
  dateRange,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Card elevation={0}>
      <TextCardTitle title={t('common.glycemie')} />
      <CardBody>
        {dateRange ? (
          <_GlycemiaStats dateRange={dateRange} />
        ) : (
          <Col className={styles.loader}>
            <Loader size="L" />
          </Col>
        )}
      </CardBody>
    </Card>
  );
};

const _GlycemiaStats: React.FC<GlycemiaStatsProps> = ({ dateRange }) => {
  const styles = useStyles(makeStyles);

  const globalStats = Queries.diabetes.useGlobalStats(dateRange);
  const targetDurationStats =
    Queries.diabetes.useTargetDurationStats(dateRange);

  if (!globalStats.isSuccess || !targetDurationStats.isSuccess) {
    return (
      <Col className={styles.loader}>
        <Loader size="L" />
      </Col>
    );
  }

  const { glycemia } = globalStats.data;
  const selectedDataset =
    glycemia['sensor']['mean_glycemia'] === null ? 'reader' : 'sensor';
  const glycemiaValues =
    selectedDataset === 'sensor' ? (
      <GlycemiaSensorValues data={glycemia['sensor']} />
    ) : (
      <GlycemiaReaderValues data={glycemia['reader']} />
    );

  return (
    <Col className={styles.content}>
      <Row className={styles.statsRow}>
        <div className={styles.statsValues}>{glycemiaValues}</div>
        <GlycemiaGraphics
          dataset={selectedDataset}
          targetDurationStats={targetDurationStats.data}
          className={styles.statsGraphics}
        />
      </Row>
      {selectedDataset === 'sensor' && <AgpProfiles dateRange={dateRange} />}
    </Col>
  );
};

const makeStyles = () => ({
  container: css``,
  loader: css`
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,
  content: css`
    width: 100%;
    overflow: hidden;
  `,
  statsRow: css`
    flex-wrap: wrap;
    width: 100%;
    row-gap: ${Dimensions.S};
  `,
  statsValues: css`
    flex: 1 1 50%;
  `,
  statsGraphics: css`
    flex: 0 0;
  `,
});
