import {
  DecoderFunction,
  literal,
  nullable,
  record,
  string,
  union,
} from 'typescript-json-decoder';

import { CareTeamParticipantRole } from '@/models/CareTeamParticipantModel';
import { Patient, patientDecoder } from '@/models/PatientModel';
import {
  PractitionerSummary,
  practitionerSummaryDecoder,
} from '@/models/PractitionerModel';
import {
  forcedArray,
  intersectionWithContext,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

import { FileModel, FileToUpload, fileDecoder } from './FileModel';

export type ChatFilterParametersType = 'all' | 'practitioners';
export type Category = 'notification' | 'alert';

export type PractitionerSender = {
  userType: 'practitioner';
  roleForPatient: CareTeamParticipantRole;
} & PractitionerSummary;

export type PatientSender = { userType: 'patient' } & Patient;

export type AdminSender = { userType: 'admin'; id: string };

export type Sender = PractitionerSender | PatientSender | AdminSender;

export type Message = {
  id: string;
  status: string;
  category: Category;
  visibility: ChatFilterParametersType;
  contentText: string | null;
  subject: string;
  createdAt: string;
  sender: Sender;
  attachment: FileModel[] | [];
};

const practitionerSenderDecoder: DecoderFunction<PractitionerSender> =
  intersectionWithContext(
    'Practitioner Sender',
    practitionerSummaryDecoder,
    record({
      userType: literal('practitioner'),
      roleForPatient: stringUnion('doctor', 'nurse'),
    }),
  );

const patientSenderDecoder: DecoderFunction<PatientSender> =
  intersectionWithContext('Patient Sender', patientDecoder, {
    userType: literal('patient'),
  });

const adminSenderDecoder: DecoderFunction<AdminSender> = record({
  userType: literal('admin'),
  id: string,
});

export const senderDecoder: DecoderFunction<Sender> = union(
  practitionerSenderDecoder,
  patientSenderDecoder,
  adminSenderDecoder,
);

export const messageDecoder: DecoderFunction<Message> = recordWithContext(
  'Message',
  {
    id: string,
    status: string,
    category: stringUnion('notification', 'alert'),
    visibility: stringUnion('all', 'practitioners'),
    contentText: nullable(string),
    subject: string,
    createdAt: string,
    sender: senderDecoder,
    attachment: forcedArray(fileDecoder),
  },
);

export type CreateMessage = {
  status: string;
  category: Category;
  visibility: ChatFilterParametersType;
  contentText: string | null;
  contentAttachment?: FileToUpload[];
};

export type Status = 'stopped';
export type UpdateMessage = {
  status: Status;
};

export const updateMessageDecoder: DecoderFunction<UpdateMessage> =
  recordWithContext('UpdateMessage', {
    status: stringUnion('stopped'),
  });
