import React from 'react';

import { css, cx } from '@emotion/css';

import { Col } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

export type StatItemProps = {
  title: string;
  value: number;
  precision?: number;
  unit?: string;
  variation?: number;
  className?: string;
  lowerIsBetter?: boolean;
  variationThreshold?: number;
};

export const StatItem: React.FC<StatItemProps> = ({
  title,
  value,
  precision = 2,
  unit,
  variation,
  className,
  lowerIsBetter = false,
  variationThreshold = 0,
}) => {
  const styles = useStyles(
    makeStyles,
    variation,
    lowerIsBetter,
    variationThreshold,
  );
  const variationIcon = !variation ? '=' : variation > 0 ? '▲' : '▼';
  return (
    <Col className={cx(styles.container, className)}>
      <Typo type={'paragraphLarge'} className={styles.title}>
        {title}
      </Typo>
      <Typo type={'standout'} Component="span" className={styles.value}>
        {value.toFixed(precision)}
        <Typo type={'paragraphLarge'} Component="span">
          {` ${unit ?? ''}`}
        </Typo>
      </Typo>

      <Typo type={'supplement'} Component="span" className={styles.variation}>
        {`${variationIcon} ${
          variation !== undefined ? variation.toFixed(precision) : ''
        }  ${unit ?? ''}`}
      </Typo>
    </Col>
  );
};

const makeStyles = (
  theme: Theme,
  variation: number | undefined,
  lowerIsBetter: boolean,
  variationThreshold: number,
) => ({
  container: css``,
  title: css`
    flex: 1 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
  value: css`
    margin-top: ${Dimensions.S};
    margin-bottom: ${Dimensions.XXS};
  `,
  variation: css`
    color: ${!variation
      ? 'transparent'
      : (!lowerIsBetter && variation > variationThreshold) ||
        (lowerIsBetter && variation < variationThreshold)
      ? theme.typography.success
      : theme.typography.error};
    visibility: ${!variation ? 'hidden' : 'shown'};
  `,
});
