import React, { useContext, useLayoutEffect, useRef } from 'react';

import { css } from '@emotion/css';

import { Card } from '@/components/card/Card';
import { useStyles } from '@/hooks/useTheme';
import { Message } from '@/models/ChatModel';
import { Dimensions } from '@/theme/dimensions';

import { MessagesByStateContext } from '../ChatContext';
import { DateSeparator } from './DateSeparator';
import { MessageContent } from './MessageContent';

type GroupedMessage = {
  sentDate: string;
  messages: Message[];
};

export const Messages = () => {
  const styles = useStyles(makeStyle);
  const endOfMessageRef = useRef<HTMLDivElement>(null);
  const { messages } = useContext(MessagesByStateContext);

  useLayoutEffect(() => {
    if (endOfMessageRef?.current) {
      endOfMessageRef.current.scrollIntoView();
    }
  });

  const dateViewed: string[] = [];

  const res: GroupedMessage[] = Object.values(
    messages.reduce<GroupedMessage[]>((acc, curr) => {
      const sentDate = curr.createdAt;
      const date = new Date(sentDate).toLocaleDateString();
      if (dateViewed.includes(date)) {
        const existing = acc.find(
          e => new Date(e.sentDate).toLocaleDateString() === date,
        );
        existing?.messages.push(curr);
      } else {
        acc.push({ sentDate: sentDate, messages: [curr] });
        dateViewed.push(date);
      }

      return acc;
    }, []),
  );

  return (
    <div className={styles.container}>
      {messages &&
        res.map((groupedMessage, i) => (
          <div key={i}>
            <DateSeparator date={groupedMessage.sentDate}></DateSeparator>
            {groupedMessage.messages.map((message, i) => (
              <Card key={i} elevation={0} className={styles.card}>
                <MessageContent message={message}></MessageContent>
              </Card>
            ))}
          </div>
        ))}
      <div ref={endOfMessageRef}></div>
    </div>
  );
};

const makeStyle = () => ({
  container: css`
    height: 60vh;
  `,
  card: css`
    display: flex;
    padding-top: ${Dimensions.XS};
    padding-bottom: ${Dimensions.XS};
  `,
});
