import { DecoderFunction, field, string } from 'typescript-json-decoder';

import { Profession, professionDecoder } from '@/models/ProfessionModel';
import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type CareTeamParticipantRole = 'doctor' | 'nurse';

export type CareTeamParticipant = {
  id: string;
  careTeam: string;
  role: CareTeamParticipantRole;
  onBehalfOf?: string;
  createdAt: string;
  updatedAt: string;
  givenName: string;
  familyName: string;
  contactPhone?: string;
  qualification: Profession;
  userId: string;
};

export const careTeamParticipantDecoder: DecoderFunction<CareTeamParticipant> =
  recordWithContext('CareTeamParticipant', {
    id: string,
    careTeam: string,
    role: stringUnion('doctor', 'nurse'),
    onBehalfOf: nullOrUndef(string),
    createdAt: string,
    updatedAt: string,
    givenName: string,
    familyName: string,
    contactPhone: nullOrUndef(string),
    qualification: professionDecoder,
    userId: field('user_id', string),
  });
