import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { FlexBox } from '@/components/box';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import DataLiteral from '@/components/data-literal/DataLiteral';
import { InsulinChart } from '@/components/data-representations';
import { Col, Row } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import Typography from '@/components/typography/Typography';
import { DateRange } from '@/hooks/useDateRangeFromURL';
import { useStyles } from '@/hooks/useTheme';
import { GlobalStats } from '@/models/GlobalStatsModel';
import { Queries } from '@/queries/Queries';
import { largestRemainderRound, round } from '@/utils/math';

type InsulinStatsProps = {
  dateRange: DateRange | undefined;
};

export const InsulinStats: React.FC<InsulinStatsProps> = ({ dateRange }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Card elevation={0}>
      <TextCardTitle title={t('pages.data.insuline')} />
      <CardBody>
        <Row justify="space-between" className={styles.body}>
          {dateRange ? (
            <InsulinStatsData dateRange={dateRange} />
          ) : (
            <Col className={styles.loader}>
              <Loader size="L" />
            </Col>
          )}
        </Row>
      </CardBody>
    </Card>
  );
};

type InsulinStatsDataProps = {
  dateRange: DateRange;
};

export const InsulinStatsData: React.FC<InsulinStatsDataProps> = ({
  dateRange,
}) => {
  const styles = useStyles(makeStyles);
  const globalStats = Queries.diabetes.useGlobalStats(dateRange);
  const targetDurationStats =
    Queries.diabetes.useTargetDurationStats(dateRange);

  if (!globalStats.isSuccess || !targetDurationStats.isSuccess) {
    return (
      <Col className={styles.loader}>
        <Loader size="L" />
      </Col>
    );
  }
  const insulin = globalStats.data['insulin'];

  return (
    <>
      <InsulinOverview insulin={insulin} />
      <FlexBox className="flex-row">
        <InsulinChart width={168} height={168} data={insulin} />
        <LegendChart insulin={insulin} />
      </FlexBox>
    </>
  );
};

type InsulinProps = {
  insulin: GlobalStats['insulin'];
};

const MeanInsulinPerDay = ({
  insulin: { daily_mean_basal_insulin, daily_mean_bolus_insulin },
}: InsulinProps) => {
  const { t } = useTranslation();

  return (
    <DataLiteral>
      <DataLiteral.Header>
        {t('pages.patient_monitoring.insulin.moy_insuline_par_jour')}
      </DataLiteral.Header>
      <DataLiteral.Body
        data={(
          round(daily_mean_basal_insulin ?? 0, 1) +
            round(daily_mean_bolus_insulin ?? 0, 1) || 0
        ).toFixed(1)}
        unit="U"
      />
    </DataLiteral>
  );
};
const InsulinOverview = ({ insulin }: InsulinProps) => {
  const { t } = useTranslation();
  const { daily_mean_count_injections } = insulin;

  return (
    <FlexBox className="flex-row justify-between">
      <FlexBox className="flex-col justify-between lg:mr-6 xl:mr-12">
        <DataLiteral>
          <DataLiteral.Header>
            {t('pages.patient_monitoring.insulin.moy_nb_injections_par_jour')}
          </DataLiteral.Header>
          <DataLiteral.Body
            data={daily_mean_count_injections?.toFixed(1) || 0}
          />
        </DataLiteral>

        <MeanInsulinPerDay insulin={insulin} />
      </FlexBox>
    </FlexBox>
  );
};

const LegendChart = ({
  insulin: { daily_mean_basal_insulin, daily_mean_bolus_insulin },
}: InsulinProps) => {
  return (
    <FlexBox className="flex-col ml-6 mr-10 space-y-16">
      <BasalBolusInsulin
        basal={daily_mean_basal_insulin ?? 0}
        bolus={daily_mean_bolus_insulin ?? 0}
      />
    </FlexBox>
  );
};

const BasalBolusInsulin = ({
  basal,
  bolus,
}: {
  basal: number;
  bolus: number;
}) => {
  const [ratioBasal, ratioBolus] = largestRemainderRound([basal, bolus]);

  return (
    <>
      <LegendChartData
        title={InsulinTitle.BASAL}
        ratioValue={ratioBasal}
        roundValue={round(basal, 1)}
        LegendDotIndicator={<LegendDot color="legend-purple" />}
      />
      <LegendChartData
        title={InsulinTitle.BOLUS}
        ratioValue={ratioBolus}
        roundValue={round(bolus, 1)}
        LegendDotIndicator={<LegendDot color="legend-green" />}
      />
    </>
  );
};

enum InsulinTitle {
  BASAL = 'pages.patient_monitoring.insulin.insuline_basale',
  BOLUS = 'pages.patient_monitoring.insulin.insuline_bolus',
}

type LegendChartDataProps = {
  title: InsulinTitle;
  ratioValue: number;
  roundValue: number;
  LegendDotIndicator: React.ReactNode;
};

const LegendChartData = ({
  title,
  ratioValue,
  roundValue,
  LegendDotIndicator,
}: LegendChartDataProps) => {
  const { t } = useTranslation();

  return (
    <DataLiteral>
      <DataLiteral.Header className="space-x-1">
        {LegendDotIndicator}
        <Typography>{`${t(title)}/${t('common.jour')}`}</Typography>
      </DataLiteral.Header>
      <FlexBox className="space-y-1 space-x-1">
        <DataLiteral.Body data={`${ratioValue}%`} />
        <DataLiteral.SubBody>
          <Typography>{`${roundValue} U`}</Typography>
        </DataLiteral.SubBody>
      </FlexBox>
    </DataLiteral>
  );
};

const LegendDot = ({ color }: { color: string }) => (
  <div className={`rounded-full border ${color}`} />
);

const makeStyles = () => ({
  body: css`
    height: 180px;
  `,
  loader: css`
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  `,
});
