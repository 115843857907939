import React, { ButtonHTMLAttributes, ReactElement } from 'react';

import { css, cx } from '@emotion/css';
import { isArray } from 'rxjs/internal-compatibility';

import { Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

export type ActionElement = ReactElement<
  ButtonHTMLAttributes<HTMLButtonElement>
>;

export type SideSheetActionsProps = {
  children: ActionElement | ActionElement[];
  className?: string;
};
export const SideSheetActions: React.FC<SideSheetActionsProps> = ({
  children,
  className,
}) => {
  const styles = useStyles(makeStyles);

  const isChildrenArray = isArray(children);
  return !isChildrenArray || (isChildrenArray && children.length > 0) ? (
    <Row
      justify="flex-end"
      align="flex-end"
      shrink
      className={cx(styles.actions, className)}
    >
      {children}
    </Row>
  ) : null;
};

const makeStyles = (theme: Theme) => ({
  actions: css`
    padding-top: ${theme.sideSheet.actionsPadding};
    column-gap: ${theme.sideSheet.actionsSpacing};
  `,
});
