import React from 'react';

import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { FlexBox } from '@/components/box';
import { useClickOutside } from '@/hooks';
import { Periodicities } from '@/state/diabetes/types';

import styles from './PeriodSelector.module.scss';

type PeriodSelectorProps = {
  value: number;
  onChangePeriodicity: (periodicity: number, durationInDays: number) => void;
  periodicities: Periodicities;
};

export default function PeriodSelector({
  value,
  onChangePeriodicity,
  periodicities,
}: PeriodSelectorProps) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = React.useState(false);

  const close = React.useCallback(() => setIsOpen(false), []);
  const ref = useClickOutside<HTMLDivElement>(close);
  return (
    <div ref={ref} className={styles.container}>
      <button className={styles.select} onClick={() => setIsOpen(true)}>
        {t(periodicities[value].label)}
      </button>

      {isOpen ? (
        <PerdiodicityOptions
          value={value}
          onChangePeriodicity={onChangePeriodicity}
          periodicities={periodicities}
          close={close}
        />
      ) : null}
    </div>
  );
}

type PeriodicityOptionsProps = PeriodSelectorProps & {
  close: () => void;
};

const PerdiodicityOptions = ({
  value,
  onChangePeriodicity,
  periodicities,
  close,
}: PeriodicityOptionsProps) => {
  const { t } = useTranslation();

  const handleClick = (selected: number) => {
    onChangePeriodicity(selected, periodicities[selected].durationInDays);
    close();
  };

  return (
    <FlexBox className={styles.dropdown}>
      {periodicities.slice(0, -1).map((periodicity, idx) => (
        <button
          key={idx}
          onClick={() => handleClick(idx)}
          className={styles.dropdownBtn}
        >
          <PeriodicityRow
            label={t(periodicity.label)}
            isSelected={value === idx}
          />
        </button>
      ))}
    </FlexBox>
  );
};

type PeriodicityRowProps = {
  label: string;
  isSelected?: boolean;
  isCustomPeriodicity?: boolean;
};

const PeriodicityRow = ({
  label,
  isSelected = false,
  isCustomPeriodicity = false,
}: PeriodicityRowProps) => (
  <>
    <FlexBox>
      {isCustomPeriodicity ? <Icons.editNote /> : <Icons.calendar />}
      <p>{label}</p>
    </FlexBox>

    {isSelected && <Icons.check className={styles.selected} />}
  </>
);
