import React, { ButtonHTMLAttributes, ReactElement } from 'react';

import { css, cx } from '@emotion/css';

import { Spinner } from '@/components/Spinner';
import { TextType, Typo } from '@/components/typography/Text';
import { useStyles, useTheme } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';
import { Status } from '@/utils/ReactQueryUtils';

export type ButtonType =
  | 'primary'
  | 'secondary'
  | 'inverted'
  | 'critical'
  | 'link'
  | 'plain'
  | 'tag';

export type ButtonProps = {
  buttonType: ButtonType;
  textType?: TextType;
  children?: string | ReactElement;
  status?: Status;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'children'>;

export const Button: React.FC<ButtonProps> = ({
  status = 'idle',
  disabled,
  textType = 'button',
  children,
  ...props
}) => {
  const theme = useTheme();
  const styles = useStyles(makeStyles);

  switch (status) {
    case 'pending':
      return (
        <_Button {...props} textType={textType} disabled>
          <div className={styles.spinnerOverlay}>
            <div className={styles.overlaidContent}>
              {typeof children === 'string' ? (
                <Typo type={textType}>{children}</Typo>
              ) : (
                children
              )}
            </div>
            <div className={styles.spinner}>
              <Spinner
                foregroundColor={theme.button.primary.background}
                backgroundColor={theme.button.primary.backgroundDisabled}
                height={'15px'}
              />
            </div>
          </div>
        </_Button>
      );
    case 'success':
      return (
        <_Button {...props} textType={textType} disabled={disabled}>
          {children}
        </_Button>
      );
    case 'error':
    case 'idle':
      return (
        <_Button {...props} textType={textType} disabled={disabled}>
          {children}
        </_Button>
      );
  }
};

const _Button: React.FC<ButtonProps> = ({
  buttonType,
  textType = 'button',
  children,
  className,
  ...props
}) => {
  const styles = useStyles(makeStyles);
  return (
    <button
      className={cx(styles.common, styles[buttonType], className)}
      {...props}
    >
      {typeof children === 'string' ? (
        <Typo type={textType}>{children}</Typo>
      ) : (
        children
      )}
    </button>
  );
};

const makeStyles = (theme: Theme) => ({
  common: css`
    display: flex;
    justify-items: center;
    align-items: center;
    border-radius: ${theme.button.borderRadius};
    font-weight: 500;
    font-size: 0.875rem; // TODO in typography
    line-height: 1.25rem; // TODO in typography
    padding-left: ${theme.button.paddingHorizontal};
    padding-right: ${theme.button.paddingHorizontal};
    padding-top: ${theme.button.paddingVertical};
    padding-bottom: ${theme.button.paddingVertical};
    &:focus {
      outline: 0;
    }
  `,
  primary: css`
    background-color: ${theme.button.primary.background};
    color: ${theme.button.primary.text};
    border-width: 1px;
    border-color: ${theme.button.primary.border};
    &:disabled {
      cursor: default;
      background-color: ${theme.button.primary.backgroundDisabled};
      color: ${theme.button.primary.textDisabled};
      //border: 0;
    }
    &:not(:disabled):hover {
      background-color: ${theme.button.primary.backgroundHover};
    }
    &:not(:disabled):focus {
      background-color: ${theme.button.primary.backgroundHover};
    }
  `,
  secondary: css`
    background-color: ${theme.button.secondary.background};
    color: ${theme.button.secondary.text};
    border-width: 1px;
    border-color: ${theme.button.secondary.border};
    &:disabled {
      cursor: default;
      background-color: ${theme.button.secondary.backgroundDisabled};
      color: ${theme.button.secondary.textDisabled};
      //border: 0;
    }
    &:not(:disabled):hover {
      background-color: ${theme.button.secondary.backgroundHover};
    }
    &:not(:disabled):focus {
      background-color: ${theme.button.secondary.backgroundHover};
    }
  `,
  link: css`
    color: ${theme.button.secondary.text};
    &:disabled {
      cursor: default;
    }
    &:not(:disabled):hover {
      color: ${theme.link.hover};
    }
    &:not(:disabled):active {
      color: ${theme.link.active};
    }
  `,
  inverted: css`
    background-color: ${theme.button.primary.text};
    color: ${theme.button.primary.background};
    border-width: 1px;
    border-color: ${theme.button.primary.border};
    &:disabled {
      cursor: default;
      background-color: ${theme.button.primary.textDisabled};
      color: ${theme.button.primary.backgroundDisabled};
      //border: 0;
    }
    &:not(:disabled):hover {
      background-color: ${theme.button.primary.textHover};
    }
    &:not(:disabled):focus {
      background-color: ${theme.button.primary.textHover};
    }
  `,
  critical: css`
    background-color: ${theme.button.critical.background};
    color: ${theme.button.critical.text};
    border-width: 1px;
    border-color: ${theme.button.critical.border};
    &:disabled {
      cursor: default;
      background-color: ${theme.button.critical.backgroundDisabled};
      color: ${theme.button.critical.textDisabled};
      //border: 0;
    }
    &:not(:disabled):hover {
      background-color: ${theme.button.critical.backgroundHover};
    }
    &:not(:disabled):focus {
      background-color: ${theme.button.critical.backgroundHover};
    }
  `,
  plain: css``,
  spinnerOverlay: css`
    display: flex;
    position: relative;
  `,
  overlaidContent: css`
    visibility: hidden;
    display: flex;
    justify-items: center;
    align-items: center;
  `,
  spinner: css`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  `,
  tag: css`
    background-color: ${theme.button.primary.background};
    padding-left: ${theme.tag.paddingHorizontal};
    padding-right: ${theme.tag.paddingHorizontal};
    padding-top: ${theme.tag.paddingVertical};
    padding-bottom: ${theme.tag.paddingVertical};
    color: ${theme.button.primary.text};
    border-width: 1px;
    border-color: ${theme.button.primary.border};
    &:disabled {
      cursor: default;
      background-color: ${theme.button.primary.backgroundDisabled};
      color: ${theme.button.primary.textDisabled};
      //border: 0;
    }
    &:not(:disabled):hover {
      background-color: ${theme.button.primary.backgroundHover};
    }
    &:not(:disabled):focus {
      background-color: ${theme.button.primary.backgroundHover};
    }
  `,
});
