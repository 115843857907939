import React from 'react';

import moment from 'moment';

import { Icons } from '@/assets/icons';
import Calendar from '@/components/calendar';
import { useClickOutside } from '@/hooks';
import { isSameDate } from '@/utils';

import styles from './DayPicker.module.scss';

const LITERAL_MOMENT_FORMAT = '[Le] DD MMMM YYYY';

type PeriodPickerProps = {
  date: moment.Moment;
  setDate: React.Dispatch<moment.Moment>;
};

export default function PeriodPicker({ date, setDate }: PeriodPickerProps) {
  const [isCalendarOpen, setIsCalendarOpen] = React.useState(false);
  const close = React.useCallback(() => setIsCalendarOpen(false), []);
  const ref = useClickOutside<HTMLDivElement>(close);

  const decrementPeriodicity = () => {
    const newDate = date?.subtract(1, 'days');
    setDate(moment(newDate));
  };

  const incrementPeriodicity = () => {
    if (dateIsToday) return;

    const newDate = date?.add(1, 'days');
    setDate(moment(newDate));
  };

  const dateIsToday = isSameDate(date, moment());

  return (
    <div ref={ref} className={styles.container}>
      <SelectPreviousRange onClick={decrementPeriodicity} />
      <button className={styles.date} onClick={() => setIsCalendarOpen(true)}>
        {date?.format(LITERAL_MOMENT_FORMAT)}
      </button>
      {isCalendarOpen ? (
        <Calendar
          className={styles.calendar}
          date={date.toDate()}
          refDate={date.toDate()}
          onDateChange={(value: Date) => setDate(moment(value))}
          tileDisabled={({ date: day }) => day > new Date()}
          showNeighboringMonth={false}
          minDetail="year"
        />
      ) : null}
      <SelectNextRange disabled={dateIsToday} onClick={incrementPeriodicity} />
    </div>
  );
}

const SelectPreviousRange = ({
  onClick,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}) => (
  <button className="p-3 bg-white" onClick={onClick}>
    <Icons.arrowLeft />
  </button>
);

const SelectNextRange = ({
  onClick,
  disabled,
}: {
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled: boolean;
}) => (
  <button className="p-3 bg-white" disabled={disabled} onClick={onClick}>
    <Icons.arrowRight
      className={`fill-current ${disabled ? 'opacity-25' : ''}`}
    />
  </button>
);
