import React, { useContext } from 'react';

import { SidePeekHeader } from '@/components/side-peek/SidePeekHeader';

import { ChatStatusContext } from '../ChatContext';
import { ChatFilter } from './ChatFilter';

export const ChatHeader: React.FC = () => {
  const { setOpen, open } = useContext(ChatStatusContext);
  return (
    <SidePeekHeader
      title={<ChatFilter />}
      exit={() => setOpen?.(!open)}
    ></SidePeekHeader>
  );
};
