import { Colors } from './colors';
import { Dimensions } from './dimensions';
import { ThemeStructure } from './types';

export const defaultTheme = {
  backgroundColor: Colors.gray['100'],
  small: Dimensions.S,
  verySmall: Dimensions.XS,
  tiny: Dimensions.XXS,
  typography: {
    primary: Colors['steto-blue']['900'],
    secondary: Colors.gray['700'],
    title: Colors['steto-blue']['800'],
    light: Colors['steto-blue']['700'],
    inverted: Colors.gray['100'],
    warning: Colors.brown['200'],
    error: 'crimson',
    success: 'limegreen',
  },
  input: {
    text: Colors['steto-blue']['800'],
    error: Colors.red['600'],
    border: Colors.gray['600'],
    disabledBackground: Colors.gray['200'],
    borderWidth: '1px',
    borderRadius: Dimensions.S,
    paddingVertical: Dimensions.XS,
    paddingHorizontal: Dimensions.S,
    bottomMargin: '2px',
    selected: Colors.blue['800'],
    selectedBackground: Colors.blue['100'],
  },
  sideSheet: {
    buttonBackground: Colors.gray[200],
    background: Colors.black,
    contentBackground: Colors.white,
    headerPadding: Dimensions.L,
    contentVPadding: Dimensions.M,
    contentHPadding: Dimensions.M,
    actionsPadding: Dimensions.XL,
    actionsSpacing: Dimensions.S,
  },
  loading: {
    color: Colors.gray['100'],
    background: Colors.gray['900'],
    fontSize: Dimensions.font.XL,
    lineHeight: Dimensions.font.XXXL,
    barColor: Colors.blue['700'],
    barHeight: Dimensions.XXXS,
    barWidth: Dimensions.UL,
    loaderBackground: Colors['steto-blue']['100'],
    loader: Colors['steto-blue']['900'],
  },
  sideBar: {
    width: Dimensions.XL,
    background: Colors.white,
    backgroundHover: Colors.gray['200'],
    border: Colors.gray['200'],
    notCurrent: Colors['steto-blue']['900'],
    current: Colors.white,
    notCurrentBackground: Colors.white,
    currentBackground: Colors['steto-blue']['900'],
    borderWidth: '1px',
  },
  alert: {
    warning: {
      color: Colors.brown['200'],
      backgroundColor: Colors.brown['700'],
    },
  },
  button: {
    borderRadius: Dimensions.XXS,
    paddingHorizontal: Dimensions.S,
    paddingVertical: Dimensions.XS,
    radio: {
      checked: Colors['steto-blue']['300'],
    },
    primary: {
      border: Colors['steto-blue']['900'],
      background: Colors['steto-blue']['900'],
      backgroundHover: Colors['steto-blue']['700'],
      backgroundDisabled: Colors['steto-blue']['100'],
      text: Colors.white,
      textHover: Colors.gray['300'],
      textDisabled: Colors.gray['700'],
    },
    secondary: {
      background: Colors.white,
      border: Colors['steto-blue']['900'],
      backgroundHover: Colors.gray['200'],
      backgroundDisabled: Colors['steto-blue']['100'],
      text: Colors['steto-blue']['900'],
      textDisabled: Colors.gray['700'],
    },
    critical: {
      background: Colors.white,
      border: 'crimson',
      backgroundHover: Colors.gray['200'],
      backgroundDisabled: Colors['steto-blue']['100'],
      text: 'crimson',
      textDisabled: 'darkred',
    },
    disabled: {
      borderColor: Colors.white,
    },
    icon: {
      disabled: {
        stroke: Colors.gray['200'],
      },
      fill: Colors.white,
    },
  },
  preview: {
    background: Colors.gray[300],
  },
  card: {
    background: Colors.white,
    rounded: Dimensions.XXS,
    shadow: 'lightgrey',
    title: {
      borderColor: Colors.gray['200'],
      borderWidth: '1px',
      padding: Dimensions.M,
      paddingBottom: Dimensions.XS,
      itemsGap: Dimensions.XS,
    },
    footer: {
      borderColor: Colors.gray['200'],
      borderWidth: '1px',
      padding: Dimensions.M,
      paddingTop: Dimensions.XS,
      itemsGap: Dimensions.XS,
    },
    body: {
      padding: Dimensions.M,
    },
  },
  link: {
    color: 'blue',
    hover: 'darkblue',
    active: 'red',
    visited: 'purple',
  },
  tooltip: {
    background: Colors.white,
    borderRadius: Dimensions.XS,
    padding: Dimensions.S,
    overlay: `${Colors.black}50`,
    minWidth: Dimensions.XL,
    shadow: 'lightgrey',
  },
  tag: {
    colors: {
      green: { back: Colors.green[200], fore: Colors.green['900'] },
      grey: { back: Colors.gray[300], fore: Colors['steto-blue']['900'] },
      blue: { back: Colors.blue[300], fore: Colors.blue['900'] },
      brown: { back: Colors.brown[700], fore: Colors.brown[200] },
      darkBlue: { back: Colors.blue[900], fore: Colors.white },
    },
    paddingHorizontal: Dimensions.XS,
    paddingVertical: Dimensions.XXS,
    borderRadius: Dimensions.XXS,
    gap: Dimensions.XS,
    iconSize: Dimensions.font.XS,
  },
  chat: {
    color: {
      receivedBackground: Colors.gray[200],
      sentBackground: Colors.green[200],
      text: Colors.black,
      tag: Colors.blue[900],
      tagText: Colors.white,
    },
    cardRadius: Dimensions.XS,
  },
} satisfies ThemeStructure;
