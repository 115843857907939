import React, { useState } from 'react';

import SidePeekFooter from '@/components/side-peek/SidePeekFooter';

import { AttachmentContext } from './AttachmentContext';
import { FooterForm } from './FooterForm';

export const ChatFooter = () => {
  const [files, setFiles] = useState<File[]>([]);

  return (
    <AttachmentContext.Provider value={{ files, setFiles }}>
      <SidePeekFooter>
        <FooterForm></FooterForm>
      </SidePeekFooter>
    </AttachmentContext.Provider>
  );
};
