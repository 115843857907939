import React from 'react';

import { css } from '@emotion/css';

import { AlertIconNotification } from '@/components/notifications/AlertIconNotification';
import { useStyles } from '@/hooks/useTheme';
import { AlertCriticity } from '@/models/AlertModel';
import { Patient } from '@/models/PatientModel';

export type PatientAlertProps = {
  patient: Patient;
};

export const PatientAlert: React.FC<PatientAlertProps> = ({ patient }) => {
  const styles = useStyles(makeStyles);
  const alerts = patient.alerts.sort(
    (a, b) => sortCriticity(b.criticity) - sortCriticity(a.criticity),
  );
  const mostCriticalAlert = alerts.length > 0 ? alerts[0] : undefined;
  return mostCriticalAlert ? (
    <div className={styles.container}>
      <AlertIconNotification criticity={mostCriticalAlert.criticity} size="S" />
    </div>
  ) : null;
};

const sortCriticity = (criticity: AlertCriticity): number => {
  switch (criticity) {
    case 'low':
      return 0;
    case 'medium':
      return 1;
    case 'high':
      return 2;
  }
};

const makeStyles = () => ({
  container: css`
    position: absolute;
    bottom: -0.5rem;
    right: -0.5rem;
  `,
});
