import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import Logo from '@/assets/icons/logo-steto-light.png';
import { Button } from '@/components/button/Button';
import { Col } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { useKeycloak } from '@/keycloak';
import { Dimensions } from '@/theme/dimensions';
import { ScreenMin } from '@/theme/responsive';
import { Theme } from '@/theme/theme';

export const Login: React.FC = () => {
  const keycloak = useKeycloak();

  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Col align="center" className={styles.container}>
      <div className={styles.logo}>
        <img src={Logo} className="" alt="Steto" />
      </div>
      <Typo type="paragraph" className={styles.text}>
        {t('pages.login.disclaimer')}
      </Typo>
      <Button
        buttonType="inverted"
        className={styles.button}
        onClick={() =>
          keycloak.login({
            redirectUri: `${process.env.REACT_APP_BASE_URL}`,
          })
        }
      >
        {t('pages.login.connexion')}
      </Button>
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    height: 100%;
    background-color: #141821;
  `,
  logoContainer: css`
    display: inline-block;
    margin: auto;
  `,
  logo: css`
    width: 33%;
    margin-top: ${Dimensions.L};
  `,
  text: css`
    color: ${theme.typography.inverted};
    width: 50%;
    margin-top: ${Dimensions.L};
    text-align: center;
    ${ScreenMin.LG} {
      width: 33%;
    }
  `,
  button: css`
    margin-top: ${Dimensions.L};
  `,
});
