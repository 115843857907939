import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { useStyles } from '@/hooks/useTheme';
import { PageContainer } from '@/pages/PageContainer';
import { AboutSteto } from '@/pages/account/AboutSteto';
import { Security } from '@/pages/account/Security';
import { UserHelp } from '@/pages/account/UserHelp';

export const PatientAccountPage: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <PageContainer
      title={t('pages.account.mon_compte')}
      titleClassName={styles.title}
    >
      <Security />
      <UserHelp />
      <AboutSteto />
    </PageContainer>
  );
};

const makeStyles = () => ({
  title: css`
    margin: 0;
  `,
});
