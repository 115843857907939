import React from 'react';

import { curveMonotoneX } from '@visx/curve';
import { Area, Circle, LinePath } from '@visx/shape';

import styles from './AgpProfile.module.scss';
import { AGPCurvesProps } from './types';

export default function AGPCurves({ agp, scales }: AGPCurvesProps) {
  const { rescaleX, rescaleY } = scales;
  if (Object.keys(agp).length === 1) {
    const key = Object.keys(agp)[0];
    const values = Object.values(agp)[0];
    return (
      <Circle
        cx={rescaleX(parseInt(key))}
        r={5}
        cy={rescaleY(values['50'])}
        className={styles.circle}
      />
    );
  }
  return (
    <>
      <LinePath
        strokeLinejoin="round"
        strokeLinecap="round"
        curve={curveMonotoneX}
        data={Object.entries(agp)}
        x={([hour]) => rescaleX(parseInt(hour))}
        y={([, values]) => rescaleY(values['50'])}
        className={styles.median}
      />

      <Area
        curve={curveMonotoneX}
        data={Object.entries(agp)}
        x={([hour]) => rescaleX(parseInt(hour))}
        y0={([, values]) => rescaleY(values['5'])}
        y1={([, values]) => rescaleY(values['95'])}
        className={styles.percentilesLight}
      />
      <LinePath
        curve={curveMonotoneX}
        data={Object.entries(agp)}
        x={([hour]) => rescaleX(parseInt(hour))}
        y={([, values]) => rescaleY(values['95'])}
        className={styles.percentilesDashes}
        strokeDasharray={4}
      />

      <LinePath
        curve={curveMonotoneX}
        data={Object.entries(agp)}
        x={([hour]) => rescaleX(parseInt(hour))}
        y={([, values]) => rescaleY(values['5'])}
        className={styles.percentilesDashes}
        strokeDasharray={4}
      />

      <Area
        curve={curveMonotoneX}
        data={Object.entries(agp)}
        x={([hour]) => rescaleX(parseInt(hour))}
        y0={([, values]) => rescaleY(values['25'])}
        y1={([, values]) => rescaleY(values['75'])}
        className={styles.percentilesDark}
      />
    </>
  );
}
