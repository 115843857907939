import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { AddPatientForm } from '@/components/add-patient/AddPatientForm';
import { IconButton } from '@/components/button/IconButton';
import { Dialog } from '@/components/floating/Dialog';
import { Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useTheme';
import { Practitioner } from '@/models/PractitionerModel';
import { Dimensions } from '@/theme/dimensions';

export type AddPatientProps = {
  practitioner: Practitioner;
};

export const AddPatient: React.FC<AddPatientProps> = ({ practitioner }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  if (practitioner.qualification === 'nurse') {
    return null;
  }

  return (
    <Row justify="end" className={styles.container}>
      <Dialog
        placement="stretch end"
        content={setIsOpen => <AddPatientForm close={() => setIsOpen(false)} />}
      >
        <IconButton buttonType="primary" icon={Icons.userPlus} placement="left">
          {t('pages.patients.ajouter_un_patient', '')}
        </IconButton>
      </Dialog>
    </Row>
  );
};

const makeStyles = () => ({
  container: css`
    padding: ${Dimensions.M};
    position: sticky;
    bottom: 1px;
  `,
});
