import React from 'react';

import { css, cx } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Optional } from '@/utils/UtilityTypes';

import { BaseDialog, BaseDialogProps } from './BaseDialog';

export type DialogProps = Optional<BaseDialogProps, 'role'>;

export const Dialog: React.FC<DialogProps> = ({
  role = 'dialog',
  className,
  floaterClassName,
  wrapperClassName,
  children,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <BaseDialog
      role={role}
      className={cx(styles.container, className)}
      wrapperClassName={cx(styles.wrapper, wrapperClassName)}
      floaterClassName={cx(styles.floater, floaterClassName)}
      {...props}
    >
      {children}
    </BaseDialog>
  );
};

const makeStyles = () => ({
  container: css`
    cursor: pointer;
  `,
  wrapper: css``,
  floater: css`
    display: flex;
    position: relative;
    overflow: auto;
  `,
});
