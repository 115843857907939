import React, { Dispatch, SetStateAction } from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import SearchBar from '@/components/search-bar/SearchBar';
import { useStyles } from '@/hooks/useTheme';

export type PatientsSearchProps = {
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
};

export const PatientsSearch: React.FC<PatientsSearchProps> = ({
  search,
  setSearch,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <SearchBar
        value={search}
        onChange={setSearch}
        placeholder={t('pages.rechercher_un_patient')}
        variant="filled"
        className="p-1 pl-2 mt-6 ml-5 w-84"
      />
    </div>
  );
};

const makeStyles = () => ({
  container: css``,
});
