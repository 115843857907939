import React from 'react';

import { css, cx } from '@emotion/css';

import { PatientMenu } from '@/components/navigation/PatientMenu';
import { PatientNavigationIcons } from '@/components/navigation/PatientNavigationIcons';
import { PatientOverview } from '@/components/navigation/PatientOverview';
import { usePatientIdFromURL } from '@/hooks';
import { useStyles } from '@/hooks/useTheme';
import { Queries } from '@/queries/Queries';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

import { Col, Row } from '../layout/Flex';

type PatientNavigationProps = {
  className?: string;
};

export const PatientNavigation: React.FC<PatientNavigationProps> = ({
  className,
}) => {
  const styles = useStyles(makeStyles);
  const id = usePatientIdFromURL();
  const patientQuery = Queries.practitioner.useObservedPatient(id ?? '');

  return (
    <Col className={cx(styles.container, className)}>
      <PatientOverview patient={patientQuery.data} />

      {patientQuery.data ? (
        <Row justify="space-between" align="center">
          <PatientMenu />
          <PatientNavigationIcons patient={patientQuery.data} />
        </Row>
      ) : (
        <Row className={styles.emptyNav} />
      )}
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    background-color: ${theme.card.background};
    padding: 2vh 4vw 0 4vw;
    gap: 2vh;
  `,
  emptyNav: css`
    height: calc(${Dimensions.font.XXL} + 5px);
  `,
});
