import React from 'react';

import CustomTag from '@/components/custom-tag/CustomTagComponent';

type FlexBoxProps = {
  element?: string;
  className?: string;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;
export default function FlexBox({
  element = 'div',
  className = '',
  children,
  ...rest
}: FlexBoxProps) {
  return (
    <CustomTag tag={element} className={className + ' flex'} {...rest}>
      {children}
    </CustomTag>
  );
}
