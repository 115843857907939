import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { AlertSummary, alertSummaryDecoder } from '@/models/AlertModel';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import { Result } from '@/utils/Result';

export class AlertsRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  dismissAlert = (
    patientId: string,
    alertId: string,
  ): Promise<Result<AlertSummary, ApiErrorResponse>> => {
    return this.datasource.delete(
      `/alerts/${patientId}/${alertId}/`,
      alertSummaryDecoder,
    );
  };
}
