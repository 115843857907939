import React from 'react';

import { useTranslation } from 'react-i18next';

import { AboutSteto } from '@/pages/account/AboutSteto';
import { GeneralInformation } from '@/pages/account/GeneralInformation';
import { Security } from '@/pages/account/Security';
import { UserHelp } from '@/pages/account/UserHelp';
import { HealthProLayout } from '@/pages/layout/HealthProLayout';

import { PageContainer } from '../PageContainer';
import { Terms } from './Terms';

export type HealthProAccountProps = {
  // Empty
};

export const HealthProAccountPage: React.FC<HealthProAccountProps> = ({}) => {
  const { t } = useTranslation();

  return (
    <HealthProLayout>
      <PageContainer title={t('pages.account.mon_compte')}>
        <GeneralInformation />
        <Security />
        <UserHelp isPractitioner />
        <Terms />
        <AboutSteto />
      </PageContainer>
    </HealthProLayout>
  );
};
