import {
  DecoderFunction,
  boolean,
  field,
  string,
} from 'typescript-json-decoder';

import { Phone, multiplePhoneDecoder } from '@/models/PhoneModel';
import {
  decodeId,
  nullOrUndef,
  recordWithContext,
  stringUnionMapUndef,
} from '@/utils/decoderUtils';

export type ContactType = 'ide' | 'aidant' | 'famille' | 'autre';
export type ContactRelationship =
  | 'grand_parent'
  | 'parent'
  | 'parent_in_law'
  | 'partner'
  | 'child'
  | 'brother'
  | 'cousin'
  | 'friend'
  | 'other';

export type Contact = {
  userId: string;
  id: string;

  type?: ContactType;
  firstname?: string;
  lastname?: string;
  email?: string;
  postalAddress?: string;
  // TODO proper city / post code with address
  phone?: Phone;
  comment?: string;
  isEmergencyContact: boolean;

  relationshipWithPatient?: ContactRelationship;
};

export const contactDecoder: DecoderFunction<Contact> = recordWithContext(
  'Contact',
  {
    userId: field('patient', string),
    id: decodeId,
    firstname: field('first_name', nullOrUndef(string)),
    lastname: field('last_name', nullOrUndef(string)),
    email: nullOrUndef(string),
    postalAddress: field('postal_address', nullOrUndef(string)),
    phone: field('phones', nullOrUndef(multiplePhoneDecoder)),
    comment: nullOrUndef(string),

    isEmergencyContact: field('is_emergency_contact', boolean),
    type: stringUnionMapUndef('', 'ide', 'aidant', 'famille', 'autre'),

    relationshipWithPatient: field(
      'relationship_with_patient',
      stringUnionMapUndef(
        'unspecified',
        'grand_parent',
        'parent',
        'parent_in_law',
        'partner',
        'child',
        'brother',
        'cousin',
        'friend',
        'other',
      ),
    ),
  },
);
