import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '@/api';

type AsyncThunkBaseParams = {
  token: string;
  id: number;
};

type AsyncThunkPatchParams = AsyncThunkBaseParams & {
  data: unknown;
  alertConfigId: number;
};

type AsyncThunkPostParams = AsyncThunkBaseParams & {
  data: unknown;
};

type AsyncThunkDeleteParams = AsyncThunkBaseParams & {
  alertConfigId: number;
};

export const getPatientAlertConfigs = createAsyncThunk(
  'patients/getPatientAlertConfigs',
  async ({ token, id }: AsyncThunkBaseParams) => {
    const alertConfigs = await API(token).get(`alert-configs/${id}/`);
    return { patientId: id, alertConfigs: alertConfigs.data };
  },
);

export const createPatientAlertConfigs = createAsyncThunk(
  'patients/createPatientAlertConfigs',
  async ({ token, id, data }: AsyncThunkPostParams) => {
    const alertConfig = await API(token).post(`alert-configs/${id}/`, data);
    return { patientId: id, alertConfig: alertConfig.data };
  },
);

export const patchPatientAlertConfigs = createAsyncThunk(
  'patients/patchPatientAlertConfigs',
  async ({ token, id, alertConfigId, data }: AsyncThunkPatchParams) => {
    const alertConfig = await API(token).patch(
      `alert-configs/${id}/${alertConfigId}/`,
      data,
    );
    return { patientId: id, alertConfig: alertConfig.data };
  },
);

export const deletePatientAlertConfigs = createAsyncThunk(
  'patients/deletePatientAlertConfigs',
  async ({ token, id, alertConfigId }: AsyncThunkDeleteParams) => {
    await API(token).delete(`alert-configs/${id}/${alertConfigId}/`);
    return { patientId: id, alertConfigId };
  },
);
