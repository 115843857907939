import React from 'react';

import { css, cx } from '@emotion/css';

import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';

import { StyleType } from '../../StyleTypes';
import { OptionGroupProps, OptionProps } from './InputInterface';
import { Radio } from './Radio';

export const RadioGroup = ({
  label,
  options,
  onChange,
  className,
}: OptionGroupProps & StyleType) => {
  const styles = useStyles(makeStyles);
  return (
    <fieldset className={cx(styles.radioGroup, className)}>
      {label && (
        <legend>
          <Typo type="subtitle" className={styles.label}>
            {label}
          </Typo>
        </legend>
      )}
      <RenderOptions options={options} onChange={onChange} />
    </fieldset>
  );
};

type RenderOptionsProps = {
  options: OptionProps[];
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const RenderOptions = ({
  options,
  onChange,
}: RenderOptionsProps & StyleType) => {
  return options.map(({ label, name, disabled, checked, value }) => {
    const optionId = `radio-option-${label.replace(/\s+/g, '')}`;
    return (
      <Radio
        value={value}
        label={label}
        key={optionId}
        id={optionId}
        name={name}
        disabled={disabled}
        defaultChecked={checked}
        onChange={onChange}
      />
    );
  });
};

const makeStyles = () => ({
  radioGroup: css`
    display: flex;
    padding: ${Dimensions.XXS} ${Dimensions.S};
    align-items: center;
    gap: ${Dimensions.XS};
  `,
  label: css`
    margin-bottom: ${Dimensions.S};
  `,
});
