import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Col, Row } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import Typography from '@/components/typography/Typography';
import { usePatientIdFromURL } from '@/hooks';
import { useStyles } from '@/hooks/useTheme';
import { TelemonitoringForm } from '@/pages/patient-settings/TelemonitoringForm';
import { Queries } from '@/queries/Queries';
import { Dimensions } from '@/theme/dimensions';

import Notifications from './Notifications';
import './PatientSettings.scss';
import ThresholdGlycemia from './ThresholdGlycemia';

export default function PatientSettings() {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL();
  const patientQuery = Queries.practitioner.useObservedPatient(patientId ?? '');

  if (!patientQuery.data) {
    return (
      <Col align="center" justify="center">
        <Loader size="L" />
      </Col>
    );
  }

  const patient = patientQuery.data;
  return (
    <Col>
      <Typography element="h2" fontWeight="bold" className="text-2xl mb-4">
        {t('constants.route.parametres_et_notifications')}
      </Typography>
      <TelemonitoringForm patientId={patient.id} />
      {patient.telemonitoring ? (
        <Row className={styles.container}>
          <ThresholdGlycemia />
          <Notifications className="flex-1" />
        </Row>
      ) : null}
    </Col>
  );
}

const makeStyles = () => ({
  container: css`
    margin-top: ${Dimensions.M};
    flex-wrap: wrap-reverse;
    gap: ${Dimensions.S};
  `,
});
