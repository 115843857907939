import React from 'react';

import { css } from '@emotion/css';
import moment from 'moment';
import { TFunction } from 'react-i18next';

import { CarePlanData } from '@/components/diabetes-form/CarePlan.schema';
import { DateFormInput } from '@/components/form/inputs/DateFormInput';
import { FileFormInput } from '@/components/form/inputs/FileFormInput';
import { NumberFormInput } from '@/components/form/inputs/NumberFormInput';
import { RadioFormInput } from '@/components/form/inputs/RadioFormInput';
import { TextAreaFormInput } from '@/components/form/inputs/TextAreaFormInput';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';

import { GridItem } from '../layout/Grid';
import { Typo } from '../typography/Text';

export type CarePlanInputProps = {
  t: TFunction<'translation'>;
  labelClassName?: string;
  className?: string;
};

export const DiabetesTypeInput: React.FC<CarePlanInputProps> = ({
  t,
  labelClassName,
}) => {
  return (
    <RadioFormInput<CarePlanData>
      labelStyle={labelClassName}
      options={[
        {
          value: 'type1',
          label: t('components.diabetes_form.diabetes_type_1'),
        },
        {
          value: 'type2',
          label: t('components.diabetes_form.diabetes_type_2'),
        },
        {
          value: 'gestational',
          label: t('components.diabetes_form.diabetes_gestational'),
        },
      ]}
      label={t('components.diabetes_form.diabetes_type')}
      name="diabetesType"
      noReservedErrorSpace
    />
  );
};

export const InsulinSchemeInput: React.FC<CarePlanInputProps> = ({
  t,
  labelClassName,
}) => {
  return (
    <RadioFormInput<CarePlanData>
      labelStyle={labelClassName}
      options={[
        {
          value: 'mono',
          label: t('components.diabetes_form.insulin_mono'),
        },
        {
          value: 'multi',
          label: t('components.diabetes_form.insulin_complex'),
        },
      ]}
      label={t('components.diabetes_form.insulin_scheme')}
      name="insulinScheme"
      noReservedErrorSpace
    />
  );
};

export const TelemonitoringCriteriaInput: React.FC<CarePlanInputProps> = ({
  t,
  labelClassName,
}) => {
  return (
    <RadioFormInput<CarePlanData>
      labelStyle={labelClassName}
      options={[
        {
          value: 'base',
          label: t('components.diabetes_form.criteria_base'),
          tooltip: t('components.diabetes_form.criteria_base_desc'),
        },
        {
          value: 'level1',
          label: t('components.diabetes_form.criteria_level_1'),
          tooltip: t('components.diabetes_form.criteria_level_1_desc'),
        },
        {
          value: 'level2',
          label: t('components.diabetes_form.criteria_level_2'),
          tooltip: t('components.diabetes_form.criteria_level_2_desc'),
        },
      ]}
      label={t('components.diabetes_form.criteria')}
      name="telemonitoringCriteria"
      noReservedErrorSpace
    />
  );
};
export const TherapeuticObjectivesInput: React.FC<CarePlanInputProps> = ({
  t,
  className,
}) => {
  const styles = useStyles(makeStyles);
  return (
    <>
      <Typo type="subtitle">{t('components.diabetes_form.goals_short')}</Typo>
      <div className={styles.grid}>
        <GridItem row={1} col={`1 / span 3`}>
          <TextAreaFormInput<CarePlanData>
            containerStyle={className}
            name="therapeuticObjectives"
            rows={10}
            noReservedErrorSpace
            label={{
              placeholder: t('components.diabetes_form.goals'),
            }}
          />
        </GridItem>
        <GridItem row={1} col={4}>
          <FileFormInput<CarePlanData>
            name="therapeuticObjectivesAttachment"
            accepts={[
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ]}
            noReservedErrorSpace
            iconStyle={styles.attachmentTherapeuticUpload}
          ></FileFormInput>
        </GridItem>
      </div>
    </>
  );
};

export const StartDateInput: React.FC<CarePlanInputProps> = ({ t }) => {
  return (
    <DateFormInput<CarePlanData>
      type="date"
      name="startDate"
      label={{ type: 'above', label: t('components.diabetes_form.startDate') }}
      noReservedErrorSpace
    ></DateFormInput>
  );
};

export const MonthsDurationInput: React.FC<CarePlanInputProps> = ({ t }) => {
  return (
    <NumberFormInput<CarePlanData>
      type="number"
      inputProps={{ min: 1, max: 3, step: 1, readOnly: true }}
      name="durationMonths"
      label={{ type: 'above', label: t('components.diabetes_form.duration') }}
      noReservedErrorSpace
    ></NumberFormInput>
  );
};

type PrescriptionProps = {
  start?: string;
};

export const FileInput: React.FC<CarePlanInputProps & PrescriptionProps> = ({
  t,
  start,
}) => {
  const styles = useStyles(makeStyles);
  const startFormatted = moment(start).format('DD/MM/YYYY');

  const dateLabel = (
    <>
      {t('components.diabetes_form.prescriptionDescription')}{' '}
      <u>{startFormatted}</u>
    </>
  );
  return (
    <FileFormInput<CarePlanData>
      name="prescription"
      accepts={['application/pdf']}
      label={dateLabel}
      labelTooltip={
        <Typo type="paragraph" className={styles.labelTooltip}>
          {t('components.diabetes_form.prescription_tooltip')}
        </Typo>
      }
      noFileText={t('components.diabetes_form.add_prescription')}
      noReservedErrorSpace
    ></FileFormInput>
  );
};

const makeStyles = () => ({
  labelTooltip: css`
    white-space: pre-wrap;
  `,
  grid: css`
    display: grid;
    gap: ${Dimensions.XS};
  `,
  attachmentTherapeuticUpload: css`
    margin: ${Dimensions.None};
  `,
});
