import React from 'react';

import { useApi, usePatientIdFromURL, useTypedSelector } from '@/hooks';
import { selectPatientMedicalDevices } from '@/state/devices/selectors';
import { getPatientMedicalDevices } from '@/state/devices/thunks';
import { RootState } from '@/state/store';

export default function usePatientMedicalFileData() {
  const patientId = usePatientIdFromURL();
  const { dispatchActionWithAuth } = useApi();
  const medicalDevices = useTypedSelector((state: RootState) =>
    selectPatientMedicalDevices(state, patientId),
  );

  React.useEffect(() => {
    dispatchActionWithAuth(getPatientMedicalDevices, { id: patientId });
  }, [dispatchActionWithAuth, patientId]);

  return {
    medicalDevices,
  };
}
