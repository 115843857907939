import React, { useRef } from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Dialog } from '@/components/floating/Dialog';
import { Col } from '@/components/layout/Flex';
import { SideSheet } from '@/components/sidesheet/SideSheet';
import { SideSheetBody } from '@/components/sidesheet/SideSheetBody';
import { SideSheetHeader } from '@/components/sidesheet/SideSheetHeader';
import { useStyles } from '@/hooks/useTheme';
import { Patient } from '@/models/PatientModel';
import { Practitioner } from '@/models/PractitionerModel';
import { ValidateOrRejectIdentity } from '@/models/identity/Identity';
import { Queries } from '@/queries/Queries';
import { queryClient } from '@/queries/QueryClient';
import { Dimensions } from '@/theme/dimensions';

import { ValidatePatientCard } from './ValidatePatientCard';

type ValidateIdentitiesProps = {
  practitioner: Practitioner;
};

export const ValidateIdentities: React.FC<ValidateIdentitiesProps> = ({
  practitioner,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const patients = Queries.practitioner.usePatientsList({
    select: patients =>
      patients
        .filter(
          patient =>
            patient.status === 'notValidated' && patient.regulatoryCountry,
        )
        .sort((a, b) => a.id.localeCompare(b.id)),
  });
  const patientsSubmitted = useRef<Set<string>>(new Set());

  const validateOrRejectQuery =
    Queries.practitioner.useValidateOrRejectIdentity({
      onMutate: ({ patientId }) => patientsSubmitted.current.add(patientId),
      onSettled: (_, __, { patientId }) =>
        patientsSubmitted.current.delete(patientId),
    });

  if (practitioner.qualification === 'nurse') {
    return null;
  }

  const validateOrReject =
    (patientId: string, close: () => void) =>
    (action: ValidateOrRejectIdentity) =>
      validateOrRejectQuery.mutate(
        { patientId, action },
        {
          onSuccess: updatedPatient => {
            queryClient.setQueryData(
              Queries.practitioner.usePatientsList.getQueryKey(),
              (data: Patient[] | undefined) => {
                if (!data) {
                  return data;
                }

                for (const index in data) {
                  if (data[index].id === updatedPatient.id) {
                    data[index] = updatedPatient;
                  }
                }

                if (
                  data.filter(
                    patient =>
                      patient.status === 'notValidated' &&
                      patient.regulatoryCountry,
                  ).length < 1
                ) {
                  close();
                }
                return data;
              },
            );
          },
        },
      );

  switch (patients.status) {
    case 'success':
      return (
        <Dialog
          placement="stretch end"
          autoOpen={patients.data.length > 0}
          dismiss={{ enabled: false }}
          content={setIsOpen => (
            <SideSheet>
              <SideSheetHeader
                title={t('pages.identityValidation.title')}
                subtitle={t('pages.identityValidation.subtitle')}
              />
              <SideSheetBody>
                <Col className={styles.list}>
                  {patients.data.map(patient =>
                    patient.regulatoryCountry === 'France' ? (
                      <ValidatePatientCard
                        key={patient.id}
                        patient={patient}
                        identity={patient}
                        submitting={patientsSubmitted.current.has(patient.id)}
                        validateOrReject={validateOrReject(patient.id, () =>
                          setIsOpen(false),
                        )}
                      />
                    ) : null,
                  )}
                </Col>
              </SideSheetBody>
            </SideSheet>
          )}
        />
      );
    default:
      return null;
  }
};

const makeStyles = () => ({
  list: css`
    padding: ${Dimensions.S} ${Dimensions.XXS};
    gap: ${Dimensions.S};
  `,
});
