import React, { AllHTMLAttributes, PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';

import { IconType } from '@/assets/icons';
import { Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

export type CardTitleProps = PropsWithChildren<
  {
    className?: string;
  } & AllHTMLAttributes<HTMLDivElement>
>;

export const CardTitle: React.FC<CardTitleProps> = ({
  children,
  className,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Row Component="header" className={cx(styles.title, className)} {...props}>
      {children}
    </Row>
  );
};

export type TextCardTitleProps = {
  title: string;
  className?: string;
} & AllHTMLAttributes<HTMLDivElement>;

export const TextCardTitle: React.FC<TextCardTitleProps> = ({
  title,
  className,
  ...props
}) => {
  return (
    <CardTitle className={className} {...props}>
      <Typo Component="h3" type="subtitle">
        {title}
      </Typo>
    </CardTitle>
  );
};

export type IconCardTitleProps = {
  Icon: IconType;
  title: string;
  className?: string;
} & AllHTMLAttributes<HTMLDivElement>;

export const IconCardTitle: React.FC<IconCardTitleProps> = ({
  Icon,
  title,
  className,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <CardTitle className={className} {...props}>
      <Icon className={styles.icon} />
      <Typo Component="h3" type="subtitle">
        {title}
      </Typo>
    </CardTitle>
  );
};

const makeStyles = (theme: Theme) => ({
  title: css`
    align-items: center;
    column-gap: ${theme.card.title.itemsGap};
    padding: ${theme.card.title.padding};
    padding-bottom: ${theme.card.title.paddingBottom};
    border-bottom: ${theme.card.title.borderWidth} solid
      ${theme.card.title.borderColor};
  `,
  icon: css`
    height: 1em;
    width: 1em;
  `,
});
