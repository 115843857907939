import { AlertConfig } from '@/state/alert-configs/types';
import { RootState } from '@/state/store';
import { isNotNull } from '@/utils/common';

export const selectAlertConfigsStatus = (state: RootState) =>
  state.alertConfigs.status;

const empty: AlertConfig[] = [];
export const selectPatientAlertsConfigs = (
  state: RootState,
  patientId: number | null,
) =>
  isNotNull(patientId) ? state.alertConfigs.data?.[patientId] ?? empty : empty;
