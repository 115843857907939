/*
 ** This file uses the "Compound Component" pattern.
 ** More info:
 **   - ComponentsPattern.md
 **   - https://kentcdodds.com/blog/compound-components-with-react-hooks/
 */
import React, { ReactNode } from 'react';

import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';

import Typography from '@/components/typography/Typography';
import { useComponentContext } from '@/hooks';

import './ToggleComponent.scss';

const ToggleContext = React.createContext<{
  on: boolean;
  toggle: () => void;
} | null>(null);

type ToggleProps = {
  children: ReactNode;
  onToggle?: ((on: boolean) => void) | null;
  initialValue: boolean;
};
function Toggle({
  children,
  onToggle = null,
  initialValue = false,
  ...rest
}: ToggleProps) {
  const [on, setOn] = React.useState(false);
  React.useEffect(() => setOn(initialValue), [initialValue]);

  const toggle = React.useCallback(() => {
    if (onToggle) onToggle(!on);
    setOn(oldOn => !oldOn);
  }, [onToggle, on]);
  const ctx = React.useMemo(() => ({ on, toggle }), [on, toggle]);

  return (
    <ToggleContext.Provider value={ctx}>
      <div {...rest}>{children}</div>
    </ToggleContext.Provider>
  );
}

function On({ children }: { children: ReactNode }) {
  const value = useComponentContext(ToggleContext);

  return (
    <Typography
      fontWeight="bold"
      className={'ml-2 ' + (value?.on ? 'text-secondary-600' : '')}
    >
      {children}
    </Typography>
  );
}

function Off({ children }: { children: ReactNode }) {
  const value = useComponentContext(ToggleContext);

  return (
    <Typography
      fontWeight="bold"
      className={'mr-2 ' + (value?.on ? '' : 'text-primary-600')}
    >
      {children}
    </Typography>
  );
}

function Switch({
  name = '',
  bgColor = '',
  disabled = false,
  prevent = false,
  ...rest
}) {
  const value = useComponentContext(ToggleContext);
  const formContext = useFormContext();

  return (
    <label className="switch">
      <input
        {...formContext.register(name, {
          onChange: () => {
            if (!disabled) value?.toggle();
          },
        })}
        checked={value?.on}
        {...rest}
        type="checkbox"
      />
      <span
        onClick={prevent ? e => e.preventDefault() : () => null}
        className={
          'slider round ' +
          bgColor +
          (value?.on ? ' before:bg-secondary-600' : ' before:bg-primary-600') +
          (disabled ? ' cursor-default' : ' cursor-pointer')
        }
      />
    </label>
  );
}

function SwitchMonoChrome({ name = '', disabled = false, ...rest }) {
  const value = useComponentContext(ToggleContext);
  const formContext = useFormContext();

  return (
    <label className="switch">
      <input
        readOnly={disabled}
        checked={value?.on}
        {...rest}
        type="checkbox"
        {...(formContext
          ? formContext.register(name, {
              onChange: () => {
                if (!disabled) value?.toggle();
              },
            })
          : { onChange: () => null })}
      />
      <span
        onClick={e => {
          e.preventDefault();
          if (!disabled) value?.toggle();
        }}
        className={
          'slider round before:bg-white' +
          (value?.on ? ' bg-secondary-600' : ' bg-gray-300') +
          (disabled ? ' cursor-default bg-gray-300' : ' cursor-pointer')
        }
      />
    </label>
  );
}

Toggle.On = On;
Toggle.Off = Off;
Toggle.Switch = Switch;
Toggle.SwitchMonoChrome = SwitchMonoChrome;

export default Toggle;

Toggle.propTypes = {
  onToggle: PropTypes.func,
  initialValue: PropTypes.bool,
  className: PropTypes.string,
};
