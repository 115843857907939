import React, { Dispatch, SetStateAction } from 'react';

import { useTranslation } from 'react-i18next';

import { Container, FlexBox } from '@/components/box';
import FormButtons from '@/components/form/FormButtons';
import Typography from '@/components/typography/Typography';
import { usePatientIdFromURL } from '@/hooks';
import {
  DiabetesParameters,
  DiabetesParametersType,
} from '@/models/DiabetesDataModel';
import { Queries } from '@/queries/Queries';
import { capitalizeChar } from '@/utils/common';

import './PatientSettings.scss';

export default function ThresholdGlycemia({ ...rest }) {
  const { t } = useTranslation();
  const [editMode, setEditMode] = React.useState<
    DiabetesParameters | undefined
  >();

  return (
    <Container {...rest}>
      <Container.Title>{t('pages.remote_monitoring.seuils')}</Container.Title>
      <Container.Body className="flex-col px-8 py-6">
        <GlycemiaCategories editMode={editMode} setEditMode={setEditMode} />
        <Buttons editMode={editMode} setEditMode={setEditMode} />
      </Container.Body>
    </Container>
  );
}

type GlycemiaCategoriesProps = {
  editMode: DiabetesParameters | undefined;
  setEditMode: Dispatch<SetStateAction<DiabetesParameters | undefined>>;
};
const GlycemiaCategories: React.FC<GlycemiaCategoriesProps> = ({
  editMode,
  setEditMode,
}) => {
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL() ?? '';
  const parameters = Queries.diabetes.useParameters(patientId);

  if (!parameters.isSuccess) {
    return null;
  }

  return (
    <FlexBox className="threshold-glycemia space-y-1">
      <Category
        label={t('pages.account.hyperglycemie_tres_elevee')}
        className="hyperglycemia2"
      >
        <Threshold
          name={'thresholdHyperglycemiaSevere'}
          editMode={editMode}
          setEditMode={setEditMode}
          parameters={parameters.data}
          className="-bottom-5"
        />
      </Category>

      <Category
        label={t('pages.account.hyperglycemie_elevee')}
        className="hyperglycemia1"
      >
        <Threshold
          name={'thresholdHyperglycemia'}
          editMode={editMode}
          setEditMode={setEditMode}
          parameters={parameters.data}
          className="-bottom-5"
        />
      </Category>

      <Category
        label={t('pages.account.glycemie_cible')}
        className="targetglycemia"
      />

      <Category
        label={t('pages.account.hypoglycemie_basse')}
        className="hypoglycemia1"
      >
        <Threshold
          name={'thresholdHypoglycemia'}
          editMode={editMode}
          setEditMode={setEditMode}
          parameters={parameters.data}
          className="-top-5"
        />
      </Category>

      <Category
        label={t('pages.account.hypoglycemie_tres_basse')}
        className="hypoglycemia2"
      >
        <Threshold
          name={'thresholdHypoglycemiaSevere'}
          editMode={editMode}
          setEditMode={setEditMode}
          parameters={parameters.data}
          className="-top-5"
        />
      </Category>
    </FlexBox>
  );
};

type CategoryProps = {
  label: string;
  className: string;
  children?: React.ReactNode;
} & React.HTMLAttributes<HTMLElement>;

const Category = ({ label, className, children, ...rest }: CategoryProps) => {
  return (
    <FlexBox
      className={
        'relative h-20 w-full justify-center items-center rounded ' + className
      }
      {...rest}
    >
      <Typography fontWeight="semibold" className="text-sm">
        {label}
      </Typography>
      {children}
    </FlexBox>
  );
};

type ThresholdProps = {
  name: DiabetesParametersType;
  parameters: DiabetesParameters;
  editMode: DiabetesParameters | undefined;
  setEditMode: Dispatch<SetStateAction<DiabetesParameters | undefined>>;
  className: string;
} & React.HTMLAttributes<HTMLElement>;

const Threshold: React.FC<ThresholdProps> = ({
  name,
  parameters,
  editMode,
  setEditMode,
  className,
  ...rest
}) => {
  return (
    <FlexBox className={'threshold-value space-x-1 ' + className} {...rest}>
      {editMode ? (
        <ThresholdInput
          name={name}
          editMode={editMode}
          setEditMode={setEditMode}
        />
      ) : (
        <ThresholdValue name={name} parameter={parameters[name]} />
      )}
      <Typography className="text-sm">mg/dL</Typography>
    </FlexBox>
  );
};

type ThresholdValueProps = {
  name: DiabetesParametersType;
  parameter: number;
};

const ThresholdValue: React.FC<ThresholdValueProps> = ({ parameter }) => {
  return (
    <Typography fontWeight="bold" className="text-base">
      {parameter}
    </Typography>
  );
};

type ThresholdInputProps = {
  editMode: DiabetesParameters | undefined;
  setEditMode: Dispatch<SetStateAction<DiabetesParameters | undefined>>;
  name: DiabetesParametersType;
};
const ThresholdInput: React.FC<ThresholdInputProps> = ({
  name,
  editMode,
  setEditMode,
}) => {
  const handleChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    if (editMode) {
      setEditMode({
        ...editMode,
        [evt.target.name]: evt.target.value ? parseInt(evt.target.value) : 0,
      });
    }
  };

  return editMode ? (
    <input
      value={editMode[name]}
      onChange={handleChange}
      name={name}
      className="w-full px-1 text-base font-bold bg-gray-300 rounded"
      onFocus={e => e.target.select()}
    />
  ) : null;
};

type ButtonsProps = {
  editMode: DiabetesParameters | undefined;
  setEditMode: Dispatch<SetStateAction<DiabetesParameters | undefined>>;
};

const Buttons: React.FC<ButtonsProps> = ({ editMode, setEditMode }) => {
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL() ?? '';
  const editParameters = Queries.diabetes.useEditParameters();
  const parameters = Queries.diabetes.useParameters(patientId);

  const handleSubmit = () => {
    if (editMode) {
      editParameters.mutate(editMode);
      setEditMode(undefined);
    }
  };

  const resetEditParams = () => {
    setEditMode(undefined);
  };

  return editMode ? (
    <FormButtons isLoading={editParameters.isPending || parameters.isPending}>
      <FormButtons.Cancel onClick={resetEditParams}>
        {capitalizeChar(t('common.annuler'))}
      </FormButtons.Cancel>

      <FormButtons.Submit onClick={handleSubmit}>
        {t('common.enregistrer')}
      </FormButtons.Submit>
    </FormButtons>
  ) : (
    <button
      className="self-end button-primary"
      onClick={() => setEditMode(parameters.data)}
    >
      {t('common.modifier')}
    </button>
  );
};
