import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '@/api';

type AsyncThunkBaseParams = {
  token: string;
  id: number;
};

type AsyncThunkPostFile = AsyncThunkBaseParams & {
  data: FormData;
  filename: string;
};

type AsyncThunkPostFreeStyleLibre = AsyncThunkBaseParams & {
  data: unknown;
  serial: string;
};

export const postFile = createAsyncThunk(
  'data/postFile',
  async ({ token, id, data, filename }: AsyncThunkPostFile) => {
    const response = await API(token).post(`diabetes/file/${id}/`, data, {
      headers: {
        'Content-Disposition': `attachment; filename=${filename}`,
      },
    });
    return response.data;
  },
);

export const postFreeStyleLibre = createAsyncThunk(
  'data/postFreeStyleLibre',
  async ({ token, id, data, serial }: AsyncThunkPostFreeStyleLibre) => {
    const response = await API(token).post(
      `/diabetes/glycemia/${id}/?entry_type=automatic`,
      {
        measurements: data,
        device_name: 'freestyle libre',
        device_manufacturer: 'abbott',
        device_sn: serial,
      },
    );
    return response.data;
  },
);
