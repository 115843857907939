import React, { PropsWithChildren } from 'react';

import { css } from '@emotion/css';

import { Col } from '@/components/layout/Flex';
import { Logo } from '@/components/navigation/Logo';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';
import { ZIndex } from '@/theme/zIndex';

import { UserMenu } from './UserMenu';

export type SideBarProps = PropsWithChildren<{
  home: string;
}>;

export const SideBar: React.FC<SideBarProps> = ({ home, children }) => {
  const styles = useStyles(makeStyles);

  return (
    <Col
      justify="space-between"
      align="center"
      className={styles.container}
      Component="nav"
    >
      <Logo to={home} />
      <Col className={styles.items} grow>
        {children}
      </Col>
      <Col className={styles.foot}>
        <UserMenu />
      </Col>
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    position: fixed;
    width: ${theme.sideBar.width};
    height: ${Dimensions.full};
    z-index: ${ZIndex.sidebar};
    border-right-width: ${theme.sideBar.borderWidth};
    border: ${theme.sideBar.border} solid;
    background-color: ${theme.sideBar.background};
  `,
  items: css``,
  foot: css`
    margin-bottom: ${Dimensions.S};
  `,
});
