import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';

import { Col } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

export type CardProps = PropsWithChildren<{
  className?: string;
  elevation: 0 | 2;
}>;

export const Card: React.FC<CardProps> = ({
  children,
  className,
  elevation,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Col
      Component="section"
      className={cx(
        styles.container,
        styles[`elevation${elevation}`],
        className,
      )}
    >
      {children}
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    background-color: ${theme.card.background};
    border-radius: ${theme.card.rounded};
  `,
  elevation0: css``,
  elevation2: css`
    box-shadow: 3px 3px 5px ${theme.card.shadow};
  `,
});
