import React, { ReactElement } from 'react';

import { css } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';
import { ZIndex } from '@/theme/zIndex';

import { Col } from '../layout/Flex';
import { SideSheetActionsProps } from '../sidesheet/SideSheetActions';
import { SideSheetBodyProps } from '../sidesheet/SideSheetBody';
import { SideSheetHeaderProps } from '../sidesheet/SideSheetHeader';

type SideSheetChild =
  | ReactElement<SideSheetHeaderProps>
  | ReactElement<SideSheetBodyProps>
  | ReactElement<SideSheetActionsProps>;

export type SidePeekProps = {
  className?: string;
  children: SideSheetChild | SideSheetChild[];
};

export const SidePeek: React.FC<SidePeekProps> = ({ children }) => {
  const styles = useStyles(makeStyles);

  return <Col className={styles.sidePeekContainer}>{children}</Col>;
};

const makeStyles = (theme: Theme) => ({
  sidePeekContainer: css`
    border-radius: var(--spacing-32, 32px) 0px 0px var(--spacing-32, 32px);
    background: ${theme.sideSheet.contentBackground};
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.08);
    width: min(500px, 95vw);
    height: 100vh;
    position: fixed;
    right: 0;
    z-index: ${ZIndex.dialog};
    overflow: hidden !important;
    justify-content: space-between;
  `,
});
