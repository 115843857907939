import i18n from '@/plugins/i18n';
import { relationships } from '@/state/contacts/utils';
import { AllPhoneCountryCodes } from '@/yup/YupPhoneCountryCodes';
import yup from '@/yup/yup';

export default yup.object().shape({
  lastname: yup.string().required('components.last_name_required'),
  firstname: yup.string().required('components.first_name_required'),
  phone: yup
    .string()
    .phone(AllPhoneCountryCodes, 'components.phone_number_not_valid')
    .required('components.form.phone_required'),
  email: yup.string().email('components.email_not_valid'),
  // empty string or single digit, beware if this grow higher than 9 in the future
  relationshipWithPatient: yup.mixed().oneOf(Object.keys(relationships), () =>
    Object.values(relationships)
      .map(a => i18n.t(a))
      .join(', '),
  ),
  comment: yup.string(),
  isEmergencyContact: yup.boolean(),
});
