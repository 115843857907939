import React from 'react';

import { AxisBottom } from '@visx/axis';
import { GridColumns } from '@visx/grid';

import { MarginProps, ScaleLinear } from './types';

type DecorationsProps = {
  height: number;
  margin: MarginProps;
  xScale: ScaleLinear<number, number, never>;
};

export const VerticalGrids = ({ height, margin, xScale }: DecorationsProps) => {
  return (
    <GridColumns
      scale={xScale}
      tickValues={[6, 12, 18]}
      height={height - (margin.bottom ?? 0)}
      offset={0}
      stroke="#f7f8fb"
      strokeWidth={2}
    />
  );
};

export const BottomAxis = ({ height, margin, xScale }: DecorationsProps) => {
  return (
    <AxisBottom
      top={height - (margin.bottom ?? 0)}
      hideTicks
      hideAxisLine
      stroke="#eff0f7"
      scale={xScale}
      tickLabelProps={() => ({
        dy: '-0.5em',
        dx: '0em',
        fill: '#838189',
        fontFamily: 'Arial',
        fontSize: 14,
        textAnchor: 'start',
      })}
      tickFormat={e => ((e + '').length === 1 ? '0' + e + ' h' : e + ' h')}
    />
  );
};
