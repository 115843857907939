import React, { useContext } from 'react';

import { FilePreview } from '@/uiKit/FilePreview';

import { AttachmentContext } from './AttachmentContext';

export const FileListPreview = () => {
  const attachmentContext = useContext(AttachmentContext);
  const handleFileDeletion = (fileIdx: number) => {
    const filesCopy = Array.from(attachmentContext.files);
    filesCopy.splice(fileIdx, 1);
    attachmentContext.setFiles?.(filesCopy);
  };

  return (
    <>
      {attachmentContext.files.map((uploadedFile, i) => (
        <FilePreview
          key={i}
          onClick={handleFileDeletion}
          name={uploadedFile.name}
          idx={i}
        />
      ))}
    </>
  );
};
