import { InferType } from 'yup';

import yup from '@/yup/yup';

export const addMessageSchema = yup.object().shape({
  visibility: yup
    .string()
    .required('components.form.required')
    .oneOf(['practitioners', 'all']),
  category: yup
    .string()
    .required('components.form.required')
    .oneOf(['notification', 'alert']),
  status: yup
    .string()
    .required('components.form.required')
    //oneOf will have another option as soon as draft is added
    .oneOf(['completed']),
  contentText: yup.string(),
  contentAttachment: yup
    .array()
    .of(
      yup
        .mixed()
        .file('')
        .fileAccepts(
          'components.chat.contentTypeError',
          [
            'application/pdf',
            'image/png',
            'image/jpeg',
            'image/jpg',
            'image/bmp',
            'image/webp',
            'image/svg+xml',
            'text/plain',
            'text/html',
            'text/csv',
            'text/calendar',
            'text/markdown',
            'audio/mpeg',
            'video/mp4',
            'application/zip',
          ],
          false,
        ),
    ),
});

export type AddMessageInfoData = InferType<typeof addMessageSchema>;
