import React from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { useTranslation } from 'react-i18next';

import CarretDown from '@/assets/icons/ic-carret-down.svg';
import { FlexBox } from '@/components/box';
import Form from '@/components/form/Form';
import FormButtons from '@/components/form/FormButtons';
import Toggle from '@/components/toggle/ToggleComponent';
import { useApi, usePatientIdFromURL } from '@/hooks';
import { Contact } from '@/models/ContactModel';
import { Queries } from '@/queries/Queries';
import {
  createPatientContact,
  patchPatientContact,
} from '@/state/contacts/thunks';
import { Relationship } from '@/state/contacts/types';
import { ContactForm, relationships } from '@/state/contacts/utils';
import { capitalize } from '@/utils/common';

import './ContactPatientFormComponent.scss';
import schema from './contact-schema';

type ContactPatientFormProps = {
  contact: ContactForm;
  onCreateOrModify?: () => void;
  cancel?: () => void;
  addClassName?: string;
};

export default function ContactPatientForm({
  contact: contactOrigin,
  onCreateOrModify = () => true,
  cancel = () => true,
  addClassName = '',
}: ContactPatientFormProps) {
  const { t } = useTranslation();
  const id = usePatientIdFromURL();
  const { dispatchActionWithAuth } = useApi();
  const [isLoading] = React.useState(false);

  const methods = useForm({
    defaultValues: { ...contactOrigin, phone: contactOrigin.phone?.number },
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data: Contact) => {
    if (!id) return;

    if (contactOrigin.id === '') {
      //create
      await dispatchActionWithAuth(createPatientContact, { id, data });
    } else {
      //update
      await dispatchActionWithAuth(patchPatientContact, {
        id,
        contactId: contactOrigin.id,
        data,
      });
    }
    Queries.practitioner.invalidateObservedPatient(id);
    onCreateOrModify();
  };

  const onBlur = async (ev: React.FormEvent) => {
    const evTargetName = (ev.target as HTMLFormElement).name;
    await methods.trigger(evTargetName as keyof Contact); //we check the validation of the evTargetName field of the form
  };

  return (
    <FlexBox className={`contact-patient-form flex-col pr-10 ${addClassName}`}>
      <Form methods={methods} onSubmit={onSubmit} onBlur={onBlur}>
        <InputRelationships
          label={t('components.contact.form.lien_avec_le_patient')}
        />
        <FlexBox className="flex-wrap justify-between mb-4">
          <InputFirstName label={`${t('common.prenom')}*`} />
          <InputLastName label={`${t('common.nom')}*`} />
        </FlexBox>
        <InputPhone
          label={`${t('common.numero_de_telephone')}*`}
          errors={methods.formState.errors}
        />

        <div className="mb-4">
          <InputEmail
            label={t('common.email')}
            errors={methods.formState.errors}
          />
        </div>

        <InputComment label={t('common.commentaire')} />

        <SwitchEmergencyContact
          label={t('components.contact.a_contacter_en_cas_d_urgence')}
          offLabel={t('common.non')}
          onLabel={t('common.oui')}
          initialValue={contactOrigin.isEmergencyContact}
        />

        <FlexBox className="flex-col">
          <FormButtons isLoading={isLoading}>
            <FormButtons.Cancel onClick={cancel}>
              {capitalize(t('common.annuler'))}
            </FormButtons.Cancel>
            <FormButtons.Submit>{t('common.enregistrer')}</FormButtons.Submit>
          </FormButtons>
        </FlexBox>
      </Form>
    </FlexBox>
  );
}

const InputFirstName = ({ label }: { label: string }) => (
  <Form.Group className="flex flex-col flex-1 mr-2">
    <Form.Label className="font-extralight">{label}</Form.Label>
    <Form.Control
      name="firstname"
      className="border-gray-200 form-input"
      placeholder="Suzanne"
    />
    <Form.Error name="firstname.message" />
  </Form.Group>
);

const InputLastName = ({ label }: { label: string }) => (
  <Form.Group className="flex flex-col flex-1">
    <Form.Label className="font-extralight">{label}</Form.Label>
    <Form.Control
      name="lastname"
      className="border-gray-200 form-input"
      placeholder="Lenglen"
    />
    <Form.Error name="lastname.message" />
  </Form.Group>
);

const RelationshipList = ({
  relationships,
}: {
  relationships: Record<Relationship, string>;
}) => {
  const { t } = useTranslation();

  return (
    <>
      {Object.entries(relationships).map(
        ([relationship, relationshipText], idx) => (
          <option key={idx} value={relationship}>
            {t(relationshipText)}
          </option>
        ),
      )}
    </>
  );
};

const InputRelationships = ({ label }: { label: string }) => (
  <Form.Group className="flex flex-col w-full mb-4">
    <Form.Label className="w-2/5 font-extralight">{label}</Form.Label>
    <Form.Control
      element="select"
      name="relationshipWithPatient"
      className="flex-grow h-10 pl-2 font-medium text-right bg-white border border-gray-200"
      style={{ backgroundImage: `url(${CarretDown})` }}
    >
      <RelationshipList relationships={relationships} />
    </Form.Control>
    <Form.Error name="relationshipWithPatient.message" />
  </Form.Group>
);

const InputPhone = ({
  label,
  errors,
}: {
  label: string;
  errors: FieldErrors<Contact>;
}) => (
  <Form.Group className="flex flex-col flex-1 w-full">
    <Form.Label className="font-extralight">{label}</Form.Label>
    <Form.Control
      name="phone"
      type="phone"
      className={
        'form-input border-gray-200 w-full ' +
        (errors.email ? 'border-red-400' : 'border-gray-200')
      }
    />
    <Form.Error name="phone.message" />
  </Form.Group>
);

const InputEmail = ({
  label,
  errors,
}: {
  label: string;
  errors: FieldErrors<Contact>;
}) => (
  <Form.Group className="flex flex-col flex-1 w-full">
    <Form.Label className="font-extralight">{label}</Form.Label>
    <Form.Control
      name="email"
      type="email"
      className={
        'form-input border-gray-200 w-full ' +
        (errors.email ? 'border-red-400' : 'border-gray-200')
      }
      placeholder="nom.prenom@domain.com"
    />
    <Form.Error name="email.message" />
  </Form.Group>
);

const InputComment = ({ label }: { label: string }) => (
  <Form.Group className="flex flex-col flex-1 w-full overflow-auto align-top">
    <Form.Label className="mr-4 font-extralight">{label}</Form.Label>
    <Form.Control
      element="textarea"
      name="comment"
      className="w-full mb-8 border-gray-200 resize-none h-18 form-input"
      placeholder="Un commentaire..."
    />
  </Form.Group>
);

type SwitchEmergencyContactProps = {
  label: string;
  offLabel: string;
  onLabel: string;
  initialValue: boolean;
};

const SwitchEmergencyContact = ({
  label,
  offLabel,
  onLabel,
  initialValue,
}: SwitchEmergencyContactProps) => (
  <Form.Group className="flex justify-between mb-4">
    <Form.Label className="font-extralight" htmlFor="inline-toggle">
      {label}
    </Form.Label>
    <Toggle initialValue={initialValue}>
      <Toggle.Off>{offLabel}</Toggle.Off>
      <Toggle.Switch bgColor="bg-gray-200" name="isEmergencyContact" />
      <Toggle.On>{onLabel}</Toggle.On>
    </Toggle>
  </Form.Group>
);
