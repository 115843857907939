import React from 'react';

import { css, cx } from '@emotion/css';

import { Icons } from '@/assets/icons';
import { Col, Row } from '@/components/layout/Flex';
import Typography from '@/components/typography/Typography';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

export type SideSheetHeaderProps = {
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
  exit?: () => void;
  className?: string;
};

export const SideSheetHeader: React.FC<SideSheetHeaderProps> = ({
  exit,
  title,
  subtitle,
  className,
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Row shrink className={cx(styles.header, className)}>
      <Col grow justify="center">
        {typeof title === 'string' ? (
          <Typography fontWeight="bold" className="text-2xl">
            {title}
          </Typography>
        ) : (
          title
        )}
        {typeof subtitle === 'string' ? (
          <Typography className="text-lg">{subtitle}</Typography>
        ) : (
          subtitle
        )}
      </Col>
      {exit ? (
        <Icons.closeBoldIcon
          className="w-10 h-10 cursor-pointer center"
          onClick={exit}
        />
      ) : null}
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  header: css`
    padding-bottom: ${theme.sideSheet.headerPadding};
  `,
});
