import React from 'react';

import { css, cx } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Button } from '@/components/button/Button';
import { Tag } from '@/components/tag/Tag';
import { TagList } from '@/components/tag/TagList';
import { useStyles } from '@/hooks/useTheme';
import { Patient } from '@/models/PatientModel';
import { StyleType } from '@/uiKit/StyleTypes';

import { CreateCarePlan } from '../patient-settings/CarePlanDialogs';

type PatientsTagsListProps = {
  patient: Patient;
  hovering: boolean;
  isNurse: boolean;
};

export const PatientsTagsList = ({
  patient,
  isNurse,
  hovering,
  className,
}: PatientsTagsListProps & StyleType) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const telemonitoring = patient.telemonitoring;
  const requiresRenewal = patient.requiresRenewal;

  let tags;

  if (telemonitoring && requiresRenewal) {
    tags = (
      <>
        <Tag color="green" text={t('pages.patients.active')} />
        <Tag color="grey" text={t('pages.patients.expiringSoon')} />
      </>
    );
  } else if (telemonitoring && !requiresRenewal) {
    tags = <Tag color="green" text={t('pages.patients.active')} />;
  } else if (!telemonitoring && requiresRenewal) {
    tags = <Tag color="brown" text={t('pages.patients.expiredRecently')} />;
  } else if (!telemonitoring && !requiresRenewal) {
    tags = <Tag color="brown" text={t('pages.patients.expired')} />;
  }

  const buttonText = t('common.renew');

  const displayRenewButton =
    (!patient.telemonitoring || patient.requiresRenewal) && !isNurse;

  return (
    <TagList className={cx(styles.container, className)}>
      {displayRenewButton && hovering ? null : tags}
      {displayRenewButton ? (
        <CreateCarePlan patientId={patient.id} summitText={buttonText}>
          <Button
            buttonType="tag"
            textType="buttonTag"
            style={{ display: hovering ? '' : 'none' }}
          >
            {buttonText}
          </Button>
        </CreateCarePlan>
      ) : null}
    </TagList>
  );
};
const makeStyles = () => ({
  container: css`
    align-self: flex-start;
  `,
});
