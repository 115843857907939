import {
  DecoderFunction,
  boolean,
  record,
  string,
} from 'typescript-json-decoder';

import { CarePlan, carePlanDecoder } from '@/models/CarePlanModel';
import { Consent, consentDecoder } from '@/models/ConsentModel';
import { Contact, contactDecoder } from '@/models/ContactModel';
import { User, userDecoder } from '@/models/UserModel';
import { Identity, identityDecoder } from '@/models/identity/Identity';
import {
  deepField,
  forcedArray,
  intersectionWithContext,
  nullOrUndef,
} from '@/utils/decoderUtils';

/*********************************
 *         Self Patient          *
 *********************************/

export type SelfPatient = {
  id: string;
  user: User;
  givenName: string;
  familyName: string;
  contactPhone?: string;
  addressLine?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressCountry?: string;

  socialSecurityNumber?: string;

  updatedAt: string;
  createdAt: string;

  telemonitoring: boolean;
  currentCarePlan?: CarePlan;

  consents: Consent[];
  contacts: Contact[];
} & Identity;

export const selfPatientDecoder: DecoderFunction<SelfPatient> =
  intersectionWithContext(
    'SelfPatient',
    record({
      id: deepField('user.id', string),
      user: userDecoder,
      givenName: string,
      familyName: string,
      contactPhone: nullOrUndef(string),
      addressLine: nullOrUndef(string),
      addressCity: nullOrUndef(string),
      addressPostalCode: nullOrUndef(string),
      addressCountry: nullOrUndef(string),

      socialSecurityNumber: nullOrUndef(string),

      updatedAt: string,
      createdAt: string,

      telemonitoring: boolean,
      currentCarePlan: nullOrUndef(carePlanDecoder),

      consents: forcedArray(consentDecoder),
      contacts: forcedArray(contactDecoder),
    }),
    identityDecoder,
  );
