import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { NewForm } from '@/components/form/NewForm';
import { RadioFormInput } from '@/components/form/inputs/RadioFormInput';
import { Col } from '@/components/layout/Flex';
import { GridItem } from '@/components/layout/Grid';
import { FileView } from '@/components/pdf/FileView';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { CarePlan } from '@/models/CarePlanModel';
import { PatientWithCare } from '@/models/PatientWithCareModel';
import { Dimensions } from '@/theme/dimensions';

import { PatientsTagsList } from '../patients/PatientsTagsList';

type CurrentCarePlanProps = {
  patient: PatientWithCare;
  carePlan: CarePlan;
};

export const CurrentCarePlan: React.FC<CurrentCarePlanProps> = ({
  patient,
  carePlan,
}) => {
  const fakeForm = useForm<CarePlan>();
  const styles = useStyles(makeStyles);

  useEffect(() => {
    if (carePlan) {
      fakeForm.setValue('diabetesType', carePlan.diabetesType);
      fakeForm.setValue('insulinTreatment', carePlan.insulinTreatment);
      fakeForm.setValue(
        'telemonitoringCriteria',
        carePlan.telemonitoringCriteria,
      );
    }
  }, [fakeForm, carePlan]);

  return (
    <NewForm
      id="FAKE_DIABETES_FORM"
      formContext={fakeForm}
      onSubmit={() => {
        // Empty
      }}
      className={styles.grid}
    >
      <GridItem row={1} col={1}>
        <TelemonitoringInfo patient={patient} />
      </GridItem>
      <GridItem row={1} col={2}>
        <TelemonitoringDatesInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={1} col={3}>
        <ResponsiblePractitionerInfo patient={patient} />
      </GridItem>
      <GridItem row={2} col={1}>
        <DiabetesTypeInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={2} col={2}>
        <InsulinSchemeInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={2} col={3}>
        <TelemonitoringCriteriaInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={3} col={`1 / span 2`}>
        <TelemonitoringGoalsInfo carePlan={carePlan} />
      </GridItem>
      <GridItem row={3} col={3}>
        <PrescriptionFileInfo carePlan={carePlan} />
      </GridItem>
    </NewForm>
  );
};

type TelemonitoringInfoProps = {
  patient: PatientWithCare;
};
export const TelemonitoringInfo: React.FC<TelemonitoringInfoProps> = ({
  patient,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Col>
      <Typo type="subtitle" className={styles.labelStyle}>
        {t('components.diabetes_form.telemonitoring')}
      </Typo>
      <PatientsTagsList patient={patient} hovering={false} isNurse={false} />
    </Col>
  );
};

type ResponsiblePractitionerInfo = {
  patient: PatientWithCare;
};

export const ResponsiblePractitionerInfo: React.FC<
  ResponsiblePractitionerInfo
> = ({ patient }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const participants = patient.careTeam?.participants ?? [];

  const nurses = participants.filter(
    participant => participant.role === 'nurse',
  );
  const doctor = participants.find(
    participant => participant.role === 'doctor',
  );

  return (
    <Col>
      <Typo type="subtitle" className={styles.labelStyle}>
        {patient.roleForPatient === 'nurse'
          ? t('components.diabetes_form.responsibleDoctor')
          : t('components.diabetes_form.responsibleNurse')}
      </Typo>
      {patient.roleForPatient === 'nurse' ? (
        doctor ? (
          <Typo type="paragraph">{`${doctor.givenName} ${doctor.familyName}`}</Typo>
        ) : (
          <Typo type="novalue">{t('components.diabetes_form.no_doctor')}</Typo>
        )
      ) : nurses.length > 0 ? (
        <Typo type="paragraph">
          {nurses
            .map(nurse => `${nurse.givenName} ${nurse.familyName}`)
            .join(', ')}
        </Typo>
      ) : (
        <Typo type="novalue">{t('components.diabetes_form.no_doctor')}</Typo>
      )}
    </Col>
  );
};

type InfoProps = {
  carePlan?: CarePlan;
};

export const DiabetesTypeInfo: React.FC<InfoProps> = ({ carePlan }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <RadioFormInput<CarePlan>
      options={[
        {
          id: 'fake-type_1',
          value: 'type1',
          label: t('components.diabetes_form.diabetes_type_1'),
          hidden: carePlan?.diabetesType !== 'type1',
        },
        {
          id: 'fake-type_2',
          value: 'type2',
          label: t('components.diabetes_form.diabetes_type_2'),
          hidden: carePlan?.diabetesType !== 'type2',
        },
        {
          id: 'fake-gestational',
          value: 'gestational',
          label: t('components.diabetes_form.diabetes_gestational'),
          hidden: carePlan?.diabetesType !== 'gestational',
        },
        {
          id: 'fake-undef',
          value: undefined,
          label: t('common.not_specified'),
          hidden: carePlan?.diabetesType !== undefined,
        },
      ]}
      label={t('components.diabetes_form.diabetes_type')}
      labelStyle={styles.labelStyle}
      name="diabetesType"
      disabled
      noReservedErrorSpace
    />
  );
};

export const InsulinSchemeInfo: React.FC<InfoProps> = ({ carePlan }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <RadioFormInput<CarePlan>
      options={[
        {
          id: 'fake-single_injection',
          value: 'mono',
          label: t('components.diabetes_form.insulin_mono'),
          hidden: carePlan?.insulinTreatment !== 'mono',
        },
        {
          id: 'fake-complex',
          value: 'multi',
          label: t('components.diabetes_form.insulin_complex'),
          hidden: carePlan?.insulinTreatment !== 'multi',
        },
        {
          id: 'fake-undef',
          value: undefined,
          label: t('common.not_specified'),
          hidden: carePlan?.insulinTreatment !== undefined,
        },
      ]}
      label={t('components.diabetes_form.insulin_scheme')}
      labelStyle={styles.labelStyle}
      name="insulinTreatment"
      disabled
      noReservedErrorSpace
    />
  );
};

export const TelemonitoringCriteriaInfo: React.FC<InfoProps> = ({
  carePlan,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  return (
    <RadioFormInput<CarePlan>
      options={[
        {
          id: 'fake-base',
          value: 'base',
          label: t('components.diabetes_form.criteria_base'),
          tooltip: t('components.diabetes_form.criteria_base_desc'),
          hidden: carePlan?.telemonitoringCriteria !== 'base',
        },
        {
          id: 'fake-level_1',
          value: 'level1',
          label: t('components.diabetes_form.criteria_level_1'),
          tooltip: t('components.diabetes_form.criteria_level_1_desc'),
          hidden: carePlan?.telemonitoringCriteria !== 'level1',
        },
        {
          id: 'fake-level_2',
          value: 'level2',
          label: t('components.diabetes_form.criteria_level_2'),
          tooltip: t('components.diabetes_form.criteria_level_2_desc'),
          hidden: carePlan?.telemonitoringCriteria !== 'level2',
        },
        {
          id: 'fake-undef',
          value: undefined,
          label: t('common.not_specified'),
          hidden: carePlan?.telemonitoringCriteria !== undefined,
        },
      ]}
      label={t('components.diabetes_form.criteria')}
      labelStyle={styles.labelStyle}
      name="telemonitoringCriteria"
      disabled
      noReservedErrorSpace
    />
  );
};

const TelemonitoringGoalsInfo: React.FC<Required<InfoProps>> = ({
  carePlan,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <div>
      <Typo type="subtitle" className={styles.labelStyle}>
        {t('components.diabetes_form.goals_short')}
      </Typo>
      {!carePlan.description && !carePlan.descriptionAttachment && (
        <Typo type="novalue">{t('components.diabetes_form.no_goals')}</Typo>
      )}
      {carePlan.descriptionAttachment && (
        <FileView file={carePlan.descriptionAttachment} />
      )}
      {carePlan.description && (
        <Typo type="paragraph" className={styles.goals}>
          {carePlan.description}
        </Typo>
      )}
    </div>
  );
};

const TelemonitoringDatesInfo: React.FC<Required<InfoProps>> = ({
  carePlan,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <div>
      <Typo type="subtitle" className={styles.labelStyle}>
        {t('components.diabetes_form.dates')}
      </Typo>
      {
        <Typo type="paragraph">{`${new Date(
          carePlan.periodStart,
        ).toLocaleDateString()} - ${
          carePlan.periodEnd
            ? new Date(carePlan.periodEnd).toLocaleDateString()
            : '...'
        }`}</Typo>
      }
    </div>
  );
};

const PrescriptionFileInfo: React.FC<Required<InfoProps>> = ({ carePlan }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Col>
      <Typo type="subtitle" className={styles.labelStyle}>
        {t('components.diabetes_form.prescription')}
      </Typo>
      {carePlan?.prescription ? (
        <FileView file={carePlan.prescription} />
      ) : (
        <Typo type="novalue">
          {t('components.diabetes_form.no_prescription')}
        </Typo>
      )}
    </Col>
  );
};

const makeStyles = () => ({
  grid: css`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: ${Dimensions.XS};
  `,
  labelStyle: css`
    font-size: ${Dimensions.font.XS};
  `,
  goals: css`
    white-space: pre-wrap;
  `,
});
