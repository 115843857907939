import React from 'react';

import { css, cx } from '@emotion/css';
import { UseHoverProps } from '@floating-ui/react';

import {
  BaseTooltip,
  BaseTooltipProps,
} from '@/components/floating/BaseTooltip';
import { useStyles } from '@/hooks/useTheme';
import { Optional } from '@/utils/UtilityTypes';

export type MenuTooltipProps = Omit<
  Optional<BaseTooltipProps, 'role'>,
  'type'
> & {
  click?: UseHoverProps<HTMLDivElement>;
  openOnHover?: boolean;
};

export const MenuTooltip: React.FC<MenuTooltipProps> = ({
  role = 'menu',
  click,
  openOnHover = false,
  className,
  floaterClassName,
  wrapperClassName,
  children,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <BaseTooltip
      type={
        openOnHover
          ? { type: 'all', click: click ?? {}, hover: {} }
          : { type: 'click', ...click }
      }
      role={role}
      className={cx(styles.container, className)}
      wrapperClassName={cx(styles.wrapper, wrapperClassName)}
      floaterClassName={cx(styles.floater, floaterClassName)}
      {...props}
    >
      {children}
    </BaseTooltip>
  );
};

const makeStyles = () => ({
  container: css`
    cursor: pointer;
  `,
  wrapper: css``,
  floater: css`
    padding: 0;
  `,
});
