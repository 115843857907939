import React from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Row } from '@/components/layout/Flex';
import { StatItem } from '@/components/stat/StatItem';
import { useStyles } from '@/hooks/useTheme';
import { GlobalStatsGlycemiaSensor } from '@/models/GlobalStatsModel';
import {
  calculateHbA1C,
  compareValues,
} from '@/pages/patient-monitoring/GlycemiaStats/Common';
import { Dimensions } from '@/theme/dimensions';

export type GlycemiaSensorValuesProps = {
  data: GlobalStatsGlycemiaSensor;
};

export const GlycemiaSensorValues: React.FC<GlycemiaSensorValuesProps> = ({
  data,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  return (
    <Row className={styles.container}>
      <StatItem
        className={styles.item}
        title={t('pages.patient_monitoring.moy_glucose_sensor')}
        value={data.mean_glycemia ?? 0}
        unit={'mg/dL'}
        precision={0}
        variation={compareValues(data.mean_glycemia, data.prev_mean_glycemia)}
        lowerIsBetter
      />
      <StatItem
        className={styles.item}
        title={t('pages.patient_monitoring.hba1c_estimee')}
        value={calculateHbA1C(data.mean_glycemia)}
        unit={'%'}
        precision={1}
        variation={compareValues(
          calculateHbA1C(data.mean_glycemia),
          calculateHbA1C(data.prev_mean_glycemia),
        )}
        lowerIsBetter
      />
      <StatItem
        className={styles.item}
        title={t('pages.patient_monitoring.coef_variation')}
        value={(data.coefficient_of_variation ?? 0) * 100}
        unit={'%'}
        precision={0}
        variation={
          compareValues(
            data.coefficient_of_variation,
            data.prev_coefficient_of_variation,
          ) * 100
        }
        lowerIsBetter
      />
      <StatItem
        className={styles.item}
        title={t('pages.patient_monitoring.utilisation_capteur')}
        value={data.usage_percent}
        unit={'%'}
        precision={0}
        variation={compareValues(data.usage_percent, data.prev_usage_percent)}
      />
      <StatItem
        className={styles.item}
        title={t('pages.patient_monitoring.nb_hypo')}
        value={data.count_hypoglycemia}
        precision={0}
        variation={compareValues(
          data.count_hypoglycemia,
          data.prev_count_hypoglycemia,
        )}
        lowerIsBetter
      />
    </Row>
  );
};

const makeStyles = () => ({
  container: css`
    column-gap: ${Dimensions.S};
    row-gap: ${Dimensions.M};
    flex-wrap: wrap;
  `,
  item: css`
    flex: 0 1 30%;
  `,
});
