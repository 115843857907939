import {
  DecoderFunction,
  boolean,
  field,
  number,
  string,
} from 'typescript-json-decoder';

import {
  PractitionerSummary,
  practitionerSummaryDecoder,
} from '@/models/PractitionerModel';
import { recordWithContext } from '@/utils/decoderUtils';

export type Observation = {
  id: number;
  description: string;
  read: boolean;
  author: PractitionerSummary;
  date: string;
  text: string;
};

export const observationDecoder: DecoderFunction<Observation> =
  recordWithContext('Observation', {
    id: number,
    description: field('analysis_description', string),
    read: field('call_happened', boolean),
    date: string,
    text: field('message_text', string),
    author: practitionerSummaryDecoder,
  });
