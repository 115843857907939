import {
  DecoderFunction,
  boolean,
  number,
  record,
  string,
} from 'typescript-json-decoder';

import { AlertSummary, alertSummaryDecoder } from '@/models/AlertModel';
import { CareTeamParticipantRole } from '@/models/CareTeamParticipantModel';
import { Consent, consentDecoder } from '@/models/ConsentModel';
import { Contact, contactDecoder } from '@/models/ContactModel';
import { Gender } from '@/models/GenderModel';
import { Profession } from '@/models/ProfessionModel';
import { User, userDecoder } from '@/models/UserModel';
import { Identity, identityDecoder } from '@/models/identity/Identity';
import {
  deepField,
  forcedArray,
  forcedBoolean,
  intersectionWithContext,
  nullOrUndef,
  stringUnion,
} from '@/utils/decoderUtils';

import {
  PractitionerSummary,
  practitionerSummaryDecoder,
} from './PractitionerModel';

/*********************************
 *            Patient            *
 *********************************/

export type MessageCountType = {
  patient: number;
  practitioner: number;
  count: number;
};

export const messageCountDecoder: DecoderFunction<MessageCountType> =
  intersectionWithContext('MessageCount', {
    patient: number,
    practitioner: number,
    count: number,
  });
export type Patient = {
  id: string;
  user: User;
  givenName: string;
  familyName: string;
  contactPhone?: string;
  addressLine?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressCountry?: string;

  socialSecurityNumber?: string;

  updatedAt: string;
  createdAt: string;

  telemonitoring: boolean;
  requiresRenewal: boolean;
  hasNurseNotice: boolean;
  roleForPatient: CareTeamParticipantRole;
  notifications: number;

  consents: Consent[];
  contacts: Contact[];
  alerts: AlertSummary[];
  doctor?: PractitionerSummary;
  message: MessageCountType;
} & Identity;

export const patientDecoder: DecoderFunction<Patient> = intersectionWithContext(
  'Patient',
  record({
    id: deepField('user.id', string),
    user: userDecoder,
    givenName: string,
    familyName: string,
    contactPhone: nullOrUndef(string),
    addressLine: nullOrUndef(string),
    addressCity: nullOrUndef(string),
    addressPostalCode: nullOrUndef(string),
    addressCountry: nullOrUndef(string),

    socialSecurityNumber: nullOrUndef(string),

    updatedAt: string,
    createdAt: string,

    telemonitoring: boolean,
    requiresRenewal: boolean,
    hasNurseNotice: forcedBoolean,
    roleForPatient: stringUnion<CareTeamParticipantRole>('doctor', 'nurse'),
    notifications: number,

    consents: forcedArray(consentDecoder),
    contacts: forcedArray(contactDecoder),
    alerts: forcedArray(alertSummaryDecoder),
    doctor: nullOrUndef(practitionerSummaryDecoder),
    message: messageCountDecoder,
  }),
  identityDecoder,
);

export type UpdatePatient = {
  givenName?: string;
  familyName?: string;
  contactPhone?: string;
  addressLine?: string;
  addressCity?: string;
  addressPostalCode?: string;
  addressCountry?: string;
  gender?: Gender;
  birthDate?: string;
  qualification?: Profession;
};
