import React, { useState } from 'react';

import { ChatFilterParametersType } from '@/models/ChatModel';

import { SidePeek } from '../side-peek/SidePeek';
import { ChatContent } from './ChatContent';
import { ChatFilterContext } from './ChatContext';

export const ChatContainer: React.FC = () => {
  const [filter, setFilter] = useState<ChatFilterParametersType>('all');
  return (
    <SidePeek>
      <ChatFilterContext.Provider value={{ filter, setFilter }}>
        <ChatContent />
      </ChatFilterContext.Provider>
    </SidePeek>
  );
};
