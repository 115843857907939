import React, { useMemo, useState } from 'react';

import { css } from '@emotion/css';

import { Icons } from '@/assets/icons';
import { IconButton } from '@/components/button/IconButton';
import { Row } from '@/components/layout/Flex';
import { PdfViewer } from '@/components/pdf/PdfViewer';
import { useStyles } from '@/hooks/useTheme';
import { FileModel } from '@/models/FileModel';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

import { Typo } from '../typography/Text';

export type FileViewProps = {
  file: FileModel;
};

/**
 * TODO preview in popup and fix not working outside localhost
 */
export const FileView: React.FC<FileViewProps> = ({ file }) => {
  const styles = useStyles(makeStyles);
  const [status] = useState<'normal' | 'preview'>('normal');

  const fileView = useMemo(() => {
    return (
      <Row align="center" width="100%" className={styles.container}>
        <Typo type="paragraph">{file.name}</Typo>
        <IconButton
          className={styles.button}
          onClick={() => window.open(file.url, '_blank')}
          iconClassname={styles.icon}
          icon={Icons.download}
          buttonType="plain"
          placement="left"
        />
        {/*<IconButton
          className={styles.button}
          iconClassname={styles.icon}
          icon={Icons.eye}
          buttonType="plain"
          placement="left"
          onClick={() =>
            setStatus(prevState =>
              prevState === 'preview' ? 'normal' : 'preview',
            )
          }
        />*/}
      </Row>
    );
  }, [file, styles.icon, styles.container, styles.button]);

  switch (status) {
    case 'normal':
      return fileView;
    case 'preview': {
      return (
        <div>
          {fileView}
          <PdfViewer url={file.url} className={styles.pdf} />
        </div>
      );
    }
  }
};

const makeStyles = (theme: Theme) => ({
  container: css`
    gap: ${Dimensions.XS};
  `,
  pdf: css`
    margin-top: ${Dimensions.XS};
  `,
  button: css`
    padding: ${Dimensions.XXS};
    :hover {
      background-color: ${theme.button.secondary.backgroundHover};
    }
    color: ${theme.button.secondary.text};
  `,
  icon: css`
    height: ${Dimensions.font.L};
    width: ${Dimensions.font.L};
    margin-right: 0;
  `,
});
