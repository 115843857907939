import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';
import { useNavigate } from 'react-router';

import { Icons } from '@/assets/icons';
import { Col, Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

export type PageContainersProps = PropsWithChildren<{
  title: string;
  back?: boolean;
  className?: string;
  titleClassName?: string;
}>;

export const PageContainer: React.FC<PageContainersProps> = ({
  title,
  back = false,
  className,
  titleClassName,
  children,
}) => {
  const styles = useStyles(makeStyles);
  const navigate = useNavigate();

  return (
    <Col className={cx(styles.container, className)}>
      <title>{`Steto - ${title}`}</title>
      <Row className={cx(styles.titleRow, titleClassName)}>
        {back ? (
          <Icons.arrowLeft
            className={styles.goBack}
            onClick={() => navigate(-1)}
          />
        ) : null}
        <Typo type="title" Component="h1">
          {title}
        </Typo>
      </Row>
      {children}
    </Col>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css``,
  titleRow: css`
    align-items: center;
    margin-bottom: ${Dimensions.M};
  `,
  goBack: css`
    fill: ${theme.typography.title};
    width: calc(${Dimensions.XXS} * 2 + ${Dimensions.font.XXXL});
    height: calc(${Dimensions.XXS} * 2 + ${Dimensions.font.XXXL});
    padding: ${Dimensions.XXS};
    margin-right: ${Dimensions.XS};
    cursor: pointer;
    &:hover {
      background-color: ${theme.button.secondary.backgroundHover};
    }
  `,
});
