import React, { PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

import { Row } from '../layout/Flex';

export type TagListProps = PropsWithChildren<{
  className?: string;
}>;

export const TagList: React.FC<TagListProps> = ({ children, className }) => {
  const styles = useStyles(makeStyles);

  return <Row className={cx(styles.container, className)}>{children}</Row>;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    flex-wrap: wrap;
    gap: ${theme.tag.gap};
  `,
});
