import { Patient } from '@/models/PatientModel';

import { Alert } from '../alerts/types';

export enum AlertCriticityChoices {
  CRITICITY_HIGH = 'high',
  CRITICITY_MEDIUM = 'medium',
  CRITICITY_LOW = 'low',
}
export type AlertCriticity =
  | AlertCriticityChoices.CRITICITY_LOW
  | AlertCriticityChoices.CRITICITY_MEDIUM
  | AlertCriticityChoices.CRITICITY_HIGH;

export enum ParametersCategoriesChoices {
  CATEGORY_HYPO = 'hypoglycemia',
  CATEGORY_HYPO_TBR = 'hypoglycemia_tbr',
  CATEGORY_HYPER = 'hyperglycemia',
  CATEGORY_HYPER_TAR = 'hyperglycemia_tar',
  CATEGORY_OBSERVANCE_TRANSMISSION = 'transmission',
  CATEGORY_OBSERVANCE_MEASUREMENT = 'measurement',
}

export type ParametersCategories =
  | ParametersCategoriesChoices.CATEGORY_HYPO
  | ParametersCategoriesChoices.CATEGORY_HYPER
  | ParametersCategoriesChoices.CATEGORY_OBSERVANCE_TRANSMISSION
  | ParametersCategoriesChoices.CATEGORY_OBSERVANCE_MEASUREMENT
  | ParametersCategoriesChoices.CATEGORY_HYPO_TBR
  | ParametersCategoriesChoices.CATEGORY_HYPER_TAR;

export enum AlertConfigChoices {
  CATEGORY_SEVERE_HYPO = 'severe_hypoglycemia',
  CATEGORY_TECH_ALERT = 'technical_alert',
  CATEGORY_CONSENT = 'remove_consent',
}
export type AlertCategory =
  | ParametersCategories
  | AlertConfigChoices.CATEGORY_SEVERE_HYPO
  | AlertConfigChoices.CATEGORY_TECH_ALERT
  | AlertConfigChoices.CATEGORY_CONSENT;

type BaseParameters = {
  n_days: number;
};

export type HyperglycemiaParameters = BaseParameters & {
  max_number_of_hypers?: number;
};

export type HyperglycemiaTarParameters = BaseParameters & {
  max_tar?: number;
};

export type HypoglycemiaParameters = BaseParameters & {
  max_number_of_hypos?: number;
};

export type HypoglycemiaTbrParameters = BaseParameters & {
  max_tbr?: number;
};

export type ObservanceTransmissionParameters = BaseParameters;

export type ObservanceMeasurementParameters = BaseParameters & {
  min_nb_of_measurements_mono?: number;
  min_nb_of_measurements_multi?: number;
  min_nb_scan?: number;
};

type AlertParameters =
  | HyperglycemiaParameters
  | HypoglycemiaParameters
  | ObservanceTransmissionParameters
  | ObservanceMeasurementParameters;

export type AlertConfig = {
  id: number;
  criticity: AlertCriticity;
  is_active: boolean;
  category: AlertCategory;
  parameters: AlertParameters;
  last_alert: Alert | null;
  patient: Patient;
};

type BasePatientAlertConfig = {
  patientId: number;
};

export type PatientAlertConfigs = BasePatientAlertConfig & {
  alertConfigs: AlertConfig[];
};

export type PatientAlertConfig = BasePatientAlertConfig & {
  alertConfig: AlertConfig;
};

export type PatientAlertConfigDelete = BasePatientAlertConfig & {
  alertConfigId: number;
};

export type AlertConfigData = {
  [patientId: number]: AlertConfig[];
};
