import moment from 'moment-timezone';
import {
  DecoderFunction,
  array,
  decodeType,
  field,
  record,
  string,
} from 'typescript-json-decoder';

import { Gender, genderDecoder } from '@/models/GenderModel';
import {
  InseeCountryName,
  InseeForeignCountry,
  InseeFrenchCountry,
  inseeCountryDecoder,
  isInseeFrenchCountry,
} from '@/models/identity/InseeCountry';
import { nullOrUndef, recordWithContext } from '@/utils/decoderUtils';

export const decodeInseeTownship = record({
  town: field('town', string),
  postCode: field('post_code', string),
});

export const decodeInseeTownshipList = (value: unknown) => {
  try {
    return array(decodeInseeTownship)(value);
  } catch (e) {
    return [];
  }
};

export type InseeTownship = decodeType<typeof decodeInseeTownship>;

export const isBornInFrance = (
  value: FrenchIdentity,
): value is BornInFrance & BaseFrenchIdentity =>
  isInseeFrenchCountry(value.birthCountry);

export type BaseFrenchIdentity = {
  identityLastname: string;
  identityFirstnames: string[];
  gender: Gender;
  birthDate: string;
  ins?: string;
  oid?: string;
};
export type BornInFrance = {
  birthLocation: InseeTownship;
  birthCountry: InseeFrenchCountry;
};
export type BornAbroad = {
  birthCountry: InseeForeignCountry;
};

export type FrenchIdentity = BaseFrenchIdentity & (BornInFrance | BornAbroad);

export type FrenchIdentityDTO = {
  identityLastname?: string;
  identityFirstnames?: string[];
  gender?: Gender;
  franceBirthLocation?: string;
  franceBirthCountry?: InseeCountryName;
  birthDate?: string;
};

export const frenchIdentityDecoder: DecoderFunction<FrenchIdentity> = (
  value: unknown,
) => {
  const data = recordWithContext('FrenchIdentity', {
    identityLastname: field('identityLastname', string),
    identityFirstnames: field('identityFirstnames', array(string)),
    gender: genderDecoder,
    birthCountry: field('franceBirthCountry', inseeCountryDecoder),
    birthDate: field('birthDate', string),
    ins: field('franceINS', nullOrUndef(string)),
    oid: field('franceOID', nullOrUndef(string)),
  })(value);
  if (isInseeFrenchCountry(data.birthCountry)) {
    return {
      ...data,
      birthCountry: data.birthCountry,
      birthLocation: field('franceBirthLocation', decodeInseeTownship)(value),
    };
  } else {
    return {
      ...data,
      birthCountry: data.birthCountry,
    };
  }
};
export const frenchIdentityEncoder = (
  identity: FrenchIdentity,
): FrenchIdentityDTO => ({
  identityLastname: identity.identityLastname,
  identityFirstnames: identity.identityFirstnames,
  gender: identity.gender,
  birthDate: moment(identity.birthDate).format('YYYY-MM-DD'),
  franceBirthLocation:
    'birthLocation' in identity ? identity.birthLocation.postCode : undefined,
  franceBirthCountry: identity.birthCountry.name,
});

export const inseeTownshipToString = (inseeTownship: InseeTownship) => {
  return `${inseeTownship.town} (${inseeTownship.postCode})`;
};
