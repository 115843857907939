import React from 'react';

import { css } from '@emotion/css';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { ReactComponent as CheckIcon } from '@/assets/icons/check.svg';
import { FlexBox } from '@/components/box';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { AlertIconNotification } from '@/components/notifications/AlertIconNotification';
import { useApi, usePatientIdFromURL } from '@/hooks';
import {
  AlertCategory,
  AlertSummary,
  UNCLEARABLE_ALERTS,
} from '@/models/AlertModel';
import { PatientWithCare } from '@/models/PatientWithCareModel';
import { AlertCategoriesTranslations } from '@/state/alert-configs/utils';
import { deletePatientAlert } from '@/state/alerts/thunks';
import { Dimensions } from '@/theme/dimensions';
import { dateYMD_ISO } from '@/utils/date';

import { Card } from '../card/Card';
import { Typo } from '../typography/Text';
import './ActiveNotifications.scss';

const LITERAL_MOMENT_FORMAT = 'dddd DD MMMM [-] HH[h]mm';

export type ActiveNotificationsProps = {
  patient: PatientWithCare;
  className?: string;
};

export const ActiveNotifications: React.FC<ActiveNotificationsProps> = ({
  patient,
  className,
}) => {
  const { t } = useTranslation();
  const styles = makeStyles();
  return (
    <Card className={className} elevation={0}>
      <TextCardTitle
        title={t('pages.patient_monitoring.notifications_actives')}
      />
      <CardBody className="flex-col px-4 pt-2 pb-4">
        {patient.alerts.length > 0 ? (
          <NotificationsList notifications={patient.alerts} />
        ) : (
          <Typo type="supplement" className={styles.noNotif}>
            {t('pages.patient_monitoring.aucune_notification_active')}
          </Typo>
        )}
      </CardBody>
    </Card>
  );
};

const NotificationsList = ({
  notifications,
}: {
  notifications: AlertSummary[];
}) => (
  <>
    {notifications.map((notification, idx) => (
      <Notification key={idx} notification={notification} />
    ))}
  </>
);

const Notification = ({ notification }: { notification: AlertSummary }) => {
  const id = usePatientIdFromURL();
  const { dispatchActionWithAuth } = useApi();

  return (
    <Link
      to={`/patients/${id}/?from=${dateYMD_ISO(
        moment(notification.date).subtract(14, 'days'),
      )}&to=${dateYMD_ISO(moment(notification.date).add(1, 'days'))}`}
      id="notification-wrapper"
      className="flex flex-row items-center justify-between px-2 py-2 text-sm cursor-pointer"
    >
      <AlertIconNotification size="S" criticity={notification.criticity} />
      <InfoNotification
        date={notification.date}
        category={notification.category}
      />
      {notification.category in UNCLEARABLE_ALERTS ? null : (
        <HoverButton
          openModal={() =>
            dispatchActionWithAuth(deletePatientAlert, {
              id,
              alertId: notification.id,
            })
          }
        />
      )}
    </Link>
  );
};

const InfoNotification = ({
  date,
  category,
}: {
  date: string | moment.Moment;
  category: AlertCategory;
}) => {
  const { t } = useTranslation();
  return (
    <FlexBox className="flex-col flex-grow ml-2">
      <Typo type="paragraphSmall" weight={600}>
        {t(AlertCategoriesTranslations[category])}
      </Typo>
      <Typo type="legend">
        {moment(date).format(LITERAL_MOMENT_FORMAT).capitalize() || ''}
      </Typo>
    </FlexBox>
  );
};

const HoverButton = ({ openModal }: { openModal: () => void }) => {
  const handleClick = async (evt: React.MouseEvent) => {
    // prevent Link to redirect
    evt.preventDefault();

    // prevent Tooltip to autoclose
    evt.stopPropagation();

    openModal();
  };

  return (
    <FlexBox className="notification-actions-wrapper">
      <FlexBox
        className="notification-actions notification-rounded-icon-wrapper"
        onClick={handleClick}
      >
        <CheckIcon className="notification-rounded-icon" />
      </FlexBox>
    </FlexBox>
  );
};

const makeStyles = () => ({
  noNotif: css`
    margin-bottom: ${Dimensions.XS};
  `,
});
