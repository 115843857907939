import React from 'react';

import { css, cx } from '@emotion/css';

import { IconType } from '@/assets/icons';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

import { Row } from '../layout/Flex';
import { Typo } from '../typography/Text';

export type TagColor = keyof Theme['tag']['colors'];

type TagContentProps =
  | { type?: 'text'; text: string }
  | { type: 'icon'; icon: IconType }
  | {
      type: 'hybrid';
      text: string;
      icon: IconType | IconType[];
      placement: 'left' | 'right';
    };

export type TagProps = {
  color: TagColor;
  className?: string;
} & TagContentProps;

export const Tag: React.FC<TagProps> = ({ color, className, ...data }) => {
  const styles = useStyles(makeStyles, color);

  return (
    <div
      className={cx(
        styles.tag,
        { [styles.iconTag]: data.type === 'icon' },
        className,
      )}
    >
      <TagContent color={color} {...data} />
    </div>
  );
};

type TagIconProps = {
  icon: IconType;
  color: TagColor;
};
const TagIcon = (ownedIcon: TagIconProps) => {
  const styles = useStyles(makeStyles, ownedIcon.color);
  return <ownedIcon.icon className={styles.icon} />;
};

const TagContent: React.FC<TagContentProps & { color: TagColor }> = ({
  color,
  ...data
}) => {
  const styles = useStyles(makeStyles, color);

  switch (data.type) {
    case 'hybrid': {
      switch (data.placement) {
        case 'left':
          return (
            <Row className={styles.hybrid}>
              {Array.isArray(data.icon) ? (
                data.icon.map((iv, i) => (
                  <TagIcon key={i} icon={iv} color={color} />
                ))
              ) : (
                <TagIcon icon={data.icon} color={color} />
              )}
              <Typo type="tag" className={styles.text}>
                {data.text}
              </Typo>
            </Row>
          );
        case 'right':
          return (
            <Row className={styles.hybrid}>
              <Typo type="tag" className={styles.text}>
                {data.text}
              </Typo>
              {Array.isArray(data.icon) ? (
                data.icon.map((iv, i) => (
                  <TagIcon key={i} icon={iv} color={color} />
                ))
              ) : (
                <TagIcon icon={data.icon} color={color} />
              )}
            </Row>
          );
      }
    }
    case 'icon':
      return <data.icon className={styles.icon} />;
    case undefined:
    case 'text':
      return (
        <Typo type="tag" className={styles.text}>
          {data.text}
        </Typo>
      );
  }
};

const makeStyles = (theme: Theme, color: TagColor) => ({
  tag: css`
    width: fit-content;
    border-radius: ${theme.tag.borderRadius};
    padding: ${theme.tag.paddingVertical} ${theme.tag.paddingHorizontal};
    background-color: ${theme.tag.colors[color].back};
  `,
  iconTag: css`
    aspect-ratio: 1;
    display: flex;
    align-items: center;
  `,
  icon: css`
    color: ${theme.tag.colors[color].fore};
    width: ${theme.tag.iconSize};
    height: ${theme.tag.iconSize};
  `,
  text: css`
    color: ${theme.tag.colors[color].fore};
  `,
  hybrid: css`
    align-items: center;
    gap: ${theme.tag.gap};
  `,
});
