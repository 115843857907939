import React, { ChangeEvent, useRef } from 'react';

import { css, cx } from '@emotion/css';

import { ScrollBar } from '@/components/scroll/ScrollBar';
import { useStyles } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

import { TextAreaNestableType } from './textArea';

export const TextAreaNestable = (
  props: TextAreaNestableType & React.HTMLProps<HTMLDivElement>,
) => {
  const styles = useStyles(makeStyle);
  const defaultValue = useRef(props.defaultValue);

  const handleInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (props.onInput) {
      props.onInput(event);
    }
  };

  return (
    <div className={cx(styles.container, props.className)}>
      {props.header && <div>{props.header}</div>}
      <ScrollBar
        options={{
          suppressScrollX: true,
          wheelPropagation: false,
          wheelSpeed: 0.2,
        }}
      >
        <div
          placeholder={props.placeholder}
          id="text-area-nestable"
          className={styles.textArea}
          {...props}
          contentEditable
          suppressContentEditableWarning={true}
          onInput={handleInput}
        >
          {defaultValue.current}
        </div>
      </ScrollBar>
    </div>
  );
};

TextAreaNestable.displayName = 'TextAreaNestable';

const makeStyle = (theme: Theme) => ({
  container: css`
    border-width: ${theme.input.borderWidth};
    border-color: ${theme.input.border};
    border-radius: ${theme.input.borderRadius};
    border-style: solid;
    width: 430px;
    color: ${theme.input.text};
    position: relative;
  `,
  textArea: css`
    width: 100%
    display: inline-block;
    white-space: pre-wrap;
    -moz-appearance: textfield-multiline;
    -webkit-appearance: textarea;
    flex-shrink: 0;
    height: 15vh;
    padding: ${Dimensions.S};
    :empty:before {
      content: attr(placeholder);
      color: grey;
    }
  `,
});
