import React from 'react';

import { useTranslation } from 'react-i18next';

import { FlexBox } from '@/components/box';
import FormSelect from '@/components/form/FormSelect';
import Typography from '@/components/typography/Typography';
import { useComponentContext } from '@/hooks';

const EventSettingsContext = React.createContext();

function EventSettings({ disabled, children }) {
  return (
    <EventSettingsContext.Provider value={{ disabled }}>
      {children}
    </EventSettingsContext.Provider>
  );
}

function MaxOccurence({ onInput, name, value, label, eventType }) {
  const { disabled } = useComponentContext(EventSettingsContext);

  return (
    <FlexBox className="my-1 mr-2">
      <Typography fontWeight="thin">{label}</Typography>
      <input
        disabled={disabled}
        onChange={onInput}
        value={value}
        name={name}
        onFocus={e => e.target.select()}
        className={
          'w-6 text-center mx-2 font-medium ' + (disabled ? '' : 'bg-gray-300')
        }
      />
      <Typography fontWeight="medium">{eventType}</Typography>
    </FlexBox>
  );
}

function MaxPercent({ onInput, name, value, label, eventType = '' }) {
  return (
    <MaxOccurence
      {...{
        onInput,
        name,
        value,
        label,
        eventType: `% ${eventType}`,
      }}
    />
  );
}

function SinceDay({ onInput, name, value, disabled: disabledProp = false }) {
  const { disabled: disabledContext } =
    useComponentContext(EventSettingsContext);
  const { t } = useTranslation();

  return (
    <div className="w-full">
      <Typography fontWeight="thin">{t('common.dans_les')}</Typography>
      <input
        disabled={disabledProp || !!disabledContext}
        onChange={onInput}
        value={value}
        name={name}
        onFocus={e => e.target.select()}
        className={
          'w-6 text-center mx-2 font-medium ' +
          (disabledProp || !!disabledContext ? '' : 'bg-gray-300')
        }
      />
      <Typography fontWeight="medium">{t('common.derniers_jours')}</Typography>
    </div>
  );
}

function SinceDayDropdown({ onInput, name, value, min, max, incr }) {
  const { disabled } = useComponentContext(EventSettingsContext);
  const { t } = useTranslation();

  return (
    <div>
      <Typography fontWeight="thin">{t('common.dans_les')}</Typography>
      <FormSelect
        disabled={disabled}
        onChange={onInput}
        value={value}
        name={name}
        className={'px-2 font-medium mx-2 ' + (disabled ? '' : 'bg-gray-300')}
      >
        {(() => {
          const ret = [];
          for (let i = min; i <= max; i += incr) {
            ret.push(
              <option key={i} value={i}>
                {i}
              </option>,
            );
          }
          return ret;
        })()}
      </FormSelect>
      <Typography fontWeight="medium">{t('common.derniers_jours')}</Typography>
    </div>
  );
}

function ForXRequiresY({
  onInput,
  name,
  value,
  forX,
  requiresY,
  disabled: disabledProp = false,
}) {
  const { disabled: disabledContext } =
    useComponentContext(EventSettingsContext);

  return (
    <div>
      <Typography fontWeight="thin">{forX ? ` - ${forX}:` : ' - '}</Typography>
      <input
        disabled={disabledProp || !!disabledContext}
        onChange={onInput}
        value={value}
        name={name}
        onFocus={e => e.target.select()}
        className={
          'w-6 text-center mx-2 font-medium ' +
          (disabledProp || !!disabledContext ? '' : 'bg-gray-300')
        }
      />
      <Typography fontWeight="thin">{requiresY}</Typography>
    </div>
  );
}

EventSettings.MaxOccurence = MaxOccurence;
EventSettings.SinceDay = SinceDay;
EventSettings.MaxPercent = MaxPercent;
EventSettings.SinceDayDropdown = SinceDayDropdown;
EventSettings.ForXRequiresY = ForXRequiresY;
export default EventSettings;
