import React, { AllHTMLAttributes } from 'react';

import { css, cx } from '@emotion/css';

type HtmlTag = {
  [key in keyof JSX.IntrinsicElements]: JSX.IntrinsicElements[key] extends AllHTMLAttributes<HTMLDivElement>
    ? key
    : never;
}[keyof JSX.IntrinsicElements];

type GridLine = 'auto' | number | `span ${number}`;

type GridValue = GridLine | `${GridLine} / ${GridLine}`;

/************************
 *      GRID ITEM       *
 ************************/

type GridItemLayoutProps = AllHTMLAttributes<HTMLDivElement> & {
  row: GridValue;
  col: GridValue;
  shrink?: number | boolean;
  grow?: number | boolean;
  Component?: HtmlTag;
};

const _GridItem: React.FC<GridItemLayoutProps> = ({
  className,
  row,
  col,
  Component = 'div',
  ...props
}): JSX.Element => {
  return (
    <Component
      className={cx(
        css`
          grid-row: ${row};
          grid-column: ${col};
        `,
        className,
      )}
      {...props}
    />
  );
};

export const GridItem = React.memo(_GridItem);
