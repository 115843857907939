import React, { Reducer, useReducer } from 'react';

import moment from 'moment/moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { AddPatientInfoData } from '@/components/add-patient/AddPatient.schema';
import { AddPatientInfoForm } from '@/components/add-patient/AddPatientInfoForm';
import { CarePlanData } from '@/components/diabetes-form/CarePlan.schema';
import { CarePlanForm } from '@/components/diabetes-form/CarePlanForm';
import { Queries } from '@/queries/Queries';

import { SideSheet } from '../sidesheet/SideSheet';
import { SideSheetHeader } from '../sidesheet/SideSheetHeader';

type AddPatientFormData =
  | {
      step: 'PatientInfo';
      info?: AddPatientInfoData;
      diabetes: Partial<CarePlanData>;
    }
  | {
      step: 'PatientDiabetes';
      info: AddPatientInfoData;
      diabetes: Partial<CarePlanData>;
    };

type AddPatientAction =
  | {
      type: 'BACK_TO_PATIENT_INFO';
      diabetes: Partial<CarePlanData>;
    }
  | {
      type: 'TO_PATIENT_DIABETES';
      info: AddPatientInfoData;
    };

const addPatientReducer: Reducer<AddPatientFormData, AddPatientAction> = (
  prevState,
  action,
) => {
  switch (action.type) {
    case 'BACK_TO_PATIENT_INFO':
      return {
        ...prevState,
        step: 'PatientInfo',
        diabetes: {
          ...prevState.diabetes,
          ...action.diabetes,
        },
      };
    case 'TO_PATIENT_DIABETES':
      return {
        step: 'PatientDiabetes',
        info: action.info,
        diabetes: prevState.diabetes,
      };
  }
};

export type AddPatientProps = {
  close: () => void;
};

export const AddPatientForm: React.FC<AddPatientProps> = ({ close }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [addPatientData, dispatch] = useReducer(addPatientReducer, {
    step: 'PatientInfo',
    diabetes: {},
  });

  const addPatient = Queries.practitioner.useCreatePatient({
    onSettled: async data => {
      if (data) {
        navigate(`/patients/${data.id}`);
      }
    },
  });

  const onSubmit = (info: AddPatientInfoData, diabetes: CarePlanData) => {
    addPatient.mutate({ ...info, ...diabetes });
  };

  switch (addPatientData.step) {
    case 'PatientInfo':
      return (
        <SideSheet>
          <SideSheetHeader
            title={t('pages.patients.ajouter_un_patient')}
            exit={close}
          />
          <AddPatientInfoForm
            onSubmit={info => dispatch({ type: 'TO_PATIENT_DIABETES', info })}
            close={close}
            initialData={addPatientData.info}
          />
        </SideSheet>
      );
    case 'PatientDiabetes':
      return (
        <SideSheet>
          <SideSheetHeader
            title={t('pages.patients.ajouter_un_patient')}
            exit={close}
          />
          <CarePlanForm
            onSubmit={diabetes => onSubmit(addPatientData.info, diabetes)}
            requestStatus={addPatient.status}
            requestError={addPatient.error ?? undefined}
            initialData={{
              startDate: moment().format('YYYY-MM-DD'),
              durationMonths: 3,
              ...addPatientData.diabetes,
            }}
            back={partialDiabetes =>
              dispatch({
                type: 'BACK_TO_PATIENT_INFO',
                diabetes: partialDiabetes,
              })
            }
            prevText={t('common.back')}
            nextText={t('pages.patients.ajouter_un_patient')}
          />
        </SideSheet>
      );
  }
};
