import React from 'react';

export default function useComponentContext<T>(
  ComponentContext: React.Context<T>,
): T {
  const context = React.useContext(ComponentContext);

  if (!context) {
    throw new Error(
      `Compound components cannot be rendered outside the parent component`,
    );
  }
  return context;
}
