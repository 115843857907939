import { AlertCategory, AlertCriticity } from '@/models/AlertModel';
import i18n from '@/plugins/i18n';

import {
  AlertConfig,
  AlertConfigChoices,
  AlertCriticityChoices,
  HyperglycemiaParameters,
  HyperglycemiaTarParameters,
  HypoglycemiaParameters,
  HypoglycemiaTbrParameters,
  ObservanceMeasurementParameters,
  ObservanceTransmissionParameters,
  ParametersCategories,
  ParametersCategoriesChoices,
} from './types';

export const AlertCategoriesTranslations = {
  hypoglycemia: 'models.alert_config.evenement_hypoglycemique',
  hypoglycemia_tbr: 'models.alert_config.evenement_hypoglycemique_tbr',
  hyperglycemia: 'models.alert_config.evenement_hyperglycemique',
  hyperglycemia_tar: 'models.alert_config.evenement_hyperglycemique_tar',
  transmission: 'models.alert_config.transmission',
  measurement: 'models.alert_config.measurement',
  severe_hypoglycemia: 'models.alert_config.hypoglycemie_severe_declaree',
  technical_alert: 'models.alert_config.alertes_techniques',
  remove_consent: 'models.alert_config.retrait_du_consentement',
} as const satisfies Record<AlertCategory, string>;

export const AlertCreatableCategoriesTranslations = {
  hypoglycemia: 'models.alert_config.evenement_hypoglycemique',
  hypoglycemia_tbr: 'models.alert_config.evenement_hypoglycemique_tbr',
  hyperglycemia: 'models.alert_config.evenement_hyperglycemique',
  hyperglycemia_tar: 'models.alert_config.evenement_hyperglycemique_tar',
  severe_hypoglycemia: 'models.alert_config.hypoglycemie_severe_declaree',
  technical_alert: 'models.alert_config.alertes_techniques',
  remove_consent: 'models.alert_config.retrait_du_consentement',
};

export const isActive = (alertConfig: AlertConfig) => {
  return alertConfig.is_active;
};

export const isInactive = (alertConfig: AlertConfig) => {
  return !isActive(alertConfig);
};

export const getFormattedCategory = (category: AlertCategory) => {
  if (category) {
    return AlertCategoriesTranslations[category];
  } else {
    return i18n.t('models.alert_config.alerte_inconnue');
  }
};

export const isHypoglycemia = (alertConfig: AlertConfig) => {
  return alertConfig.category === ParametersCategoriesChoices.CATEGORY_HYPO;
};

export const isHypoglycemiaTbr = (alertConfig: AlertConfig) => {
  return alertConfig.category === ParametersCategoriesChoices.CATEGORY_HYPO_TBR;
};

export const isHyperglycemia = (alertConfig: AlertConfig) => {
  return alertConfig.category === ParametersCategoriesChoices.CATEGORY_HYPER;
};

export const isHyperglycemiaTar = (alertConfig: AlertConfig) => {
  return (
    alertConfig.category === ParametersCategoriesChoices.CATEGORY_HYPER_TAR
  );
};

export const isObservanceTransmission = (alertConfig: AlertConfig) => {
  return (
    alertConfig.category ===
    ParametersCategoriesChoices.CATEGORY_OBSERVANCE_TRANSMISSION
  );
};

export const isObservanceMeasurement = (alertConfig: AlertConfig) => {
  return (
    alertConfig.category ===
    ParametersCategoriesChoices.CATEGORY_OBSERVANCE_MEASUREMENT
  );
};

export const isObservance = (alertConfig: AlertConfig) => {
  return (
    isObservanceMeasurement(alertConfig) ||
    isObservanceTransmission(alertConfig)
  );
};

export const isSevereHypoglycemia = (alertConfig: AlertConfig) => {
  return alertConfig.category === AlertConfigChoices.CATEGORY_SEVERE_HYPO;
};

export const isTechnical = (alertConfig: AlertConfig) => {
  return alertConfig.category === AlertConfigChoices.CATEGORY_TECH_ALERT;
};

export const isConsent = (alertConfig: AlertConfig) => {
  return alertConfig.category === AlertConfigChoices.CATEGORY_CONSENT;
};

export const getThemeColorClass = (criticity: AlertCriticity) => {
  switch (criticity) {
    case AlertCriticityChoices.CRITICITY_HIGH:
      return 'criticity-high';
    case AlertCriticityChoices.CRITICITY_MEDIUM:
      return 'criticity-medium';
    case AlertCriticityChoices.CRITICITY_LOW:
      return 'criticity-low';
    default:
      return 'gray-500';
  }
};

export const createParameters = (category: ParametersCategories) => {
  switch (category) {
    case ParametersCategoriesChoices.CATEGORY_HYPER:
      return {
        max_number_of_hypers: 0,
        n_days: 7,
        type: 2,
      } as HyperglycemiaParameters;
    case ParametersCategoriesChoices.CATEGORY_HYPER_TAR:
      return {
        max_tar: 0.0,
        n_days: 7,
        type: 1,
      } as HyperglycemiaTarParameters;
    case ParametersCategoriesChoices.CATEGORY_HYPO:
      return {
        max_number_of_hypos: 0,
        n_days: 7,
        type: 2,
      } as HypoglycemiaParameters;
    case ParametersCategoriesChoices.CATEGORY_HYPO_TBR:
      return {
        max_tbr: 0.0,
        n_days: 7,
        type: 1,
      } as HypoglycemiaTbrParameters;
    case ParametersCategoriesChoices.CATEGORY_OBSERVANCE_TRANSMISSION:
      return { n_days: 7 } as ObservanceTransmissionParameters;
    case ParametersCategoriesChoices.CATEGORY_OBSERVANCE_MEASUREMENT:
      return {
        n_days: 7,
        min_nb_of_measurements_mono: 2,
        min_nb_of_measurements_multi: 4,
        min_nb_scan: 4,
      } as ObservanceMeasurementParameters;
    default:
      return {};
  }
};
