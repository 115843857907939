import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { SliceState } from '@/state/store';

import {
  createPatientContact,
  deletePatientContact,
  getPatientContacts,
  patchPatientContact,
} from './thunks';
import {
  ContactsData,
  PatientContact,
  PatientContactRemoved,
  PatientContacts,
} from './types';

const initialState: SliceState<ContactsData> = {
  status: 'idle',
  error: null,
  data: {},
};

// we should better split contact && medContact to keep smthg short
const contactsSlice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getPatientContacts.pending, state => {
        state.status = 'loading';
      })
      .addCase(getPatientContacts.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        getPatientContacts.fulfilled,
        (state, action: PayloadAction<PatientContacts>) => {
          const { patientId, contacts } = action.payload;

          state.status = 'succeeded';
          if (patientId in state.data) {
            state.data[patientId].contacts = contacts;
          } else {
            state.data[patientId] = { contacts, medicalContacts: [] };
          }
        },
      )

      .addCase(createPatientContact.pending, state => {
        state.status = 'loading';
      })
      .addCase(createPatientContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        createPatientContact.fulfilled,
        (state, action: PayloadAction<PatientContact>) => {
          const { patientId, contact } = action.payload;

          state.status = 'succeeded';
          if (patientId in state.data) {
            state.data[patientId].contacts = [
              ...state.data[patientId].contacts,
              contact,
            ];
          } else {
            state.data[patientId] = {
              contacts: [contact],
              medicalContacts: [],
            };
          }
        },
      )

      .addCase(patchPatientContact.pending, state => {
        state.status = 'loading';
      })
      .addCase(patchPatientContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        patchPatientContact.fulfilled,
        (state, action: PayloadAction<PatientContact>) => {
          const { patientId, contact } = action.payload;
          const patchedPatientContacts = state.data[patientId].contacts.map(c =>
            c.id === contact.id ? contact : c,
          );

          state.status = 'succeeded';
          state.data[patientId].contacts = patchedPatientContacts;
        },
      )

      .addCase(deletePatientContact.pending, state => {
        state.status = 'loading';
      })
      .addCase(deletePatientContact.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      })
      .addCase(
        deletePatientContact.fulfilled,
        (state, action: PayloadAction<PatientContactRemoved>) => {
          const { patientId, contactId } = action.payload;
          const filteredPatientContacts = state.data[patientId].contacts.filter(
            c => c.id !== contactId,
          );

          state.status = 'succeeded';
          state.data[patientId].contacts = filteredPatientContacts;
        },
      );
  },
});

export default contactsSlice.reducer;
