import { DecoderFunction, string } from 'typescript-json-decoder';

import {
  nullOrUndef,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type UserType = 'practitioner' | 'patient' | 'admin';

export type User = {
  id: string;
  email: string;
  userType: UserType;
  last_login?: string;
  createdAt: string;
  updatedAt: string;
};

export const userDecoder: DecoderFunction<User> = recordWithContext('User', {
  id: string,
  email: string,
  userType: stringUnion('practitioner', 'patient', 'admin'),
  last_login: nullOrUndef(string),
  createdAt: string,
  updatedAt: string,
});
