import { useMemo } from 'react';

import moment from 'moment/moment';

import { useQueryString } from '@/hooks/index';
import usePatientIdFromURL from '@/hooks/usePatientIdFromURL';

export type DateRange = {
  patientId: string;
  from: string;
  to: string;
};

export const useDateRangeFromURL = (): DateRange | undefined => {
  const patientId = usePatientIdFromURL() ?? '';
  const { from, to } = useQueryString();
  return useMemo(
    () =>
      from && to
        ? {
            patientId,
            from: moment(from).startOf('day').format(),
            to: moment(to).startOf('day').format(),
          }
        : undefined,
    [patientId, from, to],
  );
};
