import { AlertsRepository } from '@/io/repository/AlertsRepository';
import { AlertSummary } from '@/models/AlertModel';
import { PractitionerQueries } from '@/queries/PractitionerQueries';
import { createAppMutation, stripQueryResult } from '@/queries/QueryClient';

export class AlertsQueries {
  constructor(
    private readonly practitionerQueries: PractitionerQueries = new PractitionerQueries(),
    private readonly diabetes: AlertsRepository = new AlertsRepository(),
  ) {}

  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useDismissAlert = createAppMutation<
    AlertSummary,
    { patientId: string; alertId: string }
  >({
    mutationFn: async ({ patientId, alertId }) =>
      stripQueryResult(await this.diabetes.dismissAlert(patientId, alertId)),
    onSuccess: alert => {
      this.practitionerQueries.invalidateObservedPatient(alert.patientId);
      this.practitionerQueries.invalidatePatientsList();
    },
  });
}
