import React from 'react';

import { FlexBox } from '@/components/box';
import Typography from '@/components/typography/Typography';

type ContainerProps = {
  children: React.ReactNode;
  className?: string;
} & React.HTMLAttributes<HTMLElement>;

function Container({ children, className = '', ...rest }: ContainerProps) {
  return (
    <FlexBox className={`bg-white rounded flex-col ${className}`} {...rest}>
      {children}
    </FlexBox>
  );
}

function Title({ children }: { children: React.ReactNode }) {
  return (
    <Typography
      element="h3"
      fontWeight="bold"
      className="mt-4 pb-2 px-6 border-b text-lg"
    >
      {children}
    </Typography>
  );
}

type BodyProps = {
  children: React.ReactNode;
  className?: string;
};

function Body({ children, className = '' }: BodyProps) {
  return <FlexBox className={className}>{children}</FlexBox>;
}

function Separator({ ...rest }: React.HTMLAttributes<HTMLHRElement>) {
  return <hr {...rest} />;
}

Container.Title = Title;
Container.Body = Body;
Container.Separator = Separator;

export default Container;
