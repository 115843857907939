import moment from 'moment/moment';
import { AnyObject, InferType } from 'yup';

import yup from '@/yup/yup';

export const carePlanSchema = yup.object().shape(
  {
    diabetesType: yup
      .string()
      .oneOf(['type1', 'type2', 'gestational'])
      .required('components.form.required'),
    insulinScheme: yup
      .string()
      .oneOf(['mono', 'multi'])
      .required('components.form.required'),
    telemonitoringCriteria: yup
      .string()
      .oneOf(['base', 'level1', 'level2'])
      .required('components.form.required'),
    therapeuticObjectives: yup
      .string()
      .when('therapeuticObjectivesAttachment', {
        is: (therapeuticObjectivesAttachment: AnyObject) =>
          therapeuticObjectivesAttachment !== undefined,
        then: () => yup.string().nullable(),
        otherwise: () => yup.string().required('components.form.atLeastOne'),
      }),
    startDate: yup
      .string()
      .date('components.form.required')
      .minDate(
        moment(moment.now()).startOf('day').toDate(),
        'components.form.futureDate',
      )
      .required('components.form.required'),
    durationMonths: yup
      .number()
      .integer('components.form.integer')
      .positive('components.form.positive')
      .min(1, 'components.form.numberMin')
      .max(3, 'components.form.numberMax')
      .required('components.form.required'),
    prescription: yup
      .mixed()
      .file('components.form.required')
      .fileAccepts('components.form.pdfRequired', ['application/pdf'], false)
      .required('components.form.required'),
    therapeuticObjectivesAttachment: yup.mixed().when('therapeuticObjectives', {
      is: (therapeuticObjectives: string) => therapeuticObjectives.length <= 0,
      then: () =>
        yup
          .mixed()
          .file('')
          .fileAccepts(
            'components.form.pdfWordRequired',
            [
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ],
            false,
          )
          .required('components.form.atLeastOne'),
      otherwise: () =>
        yup
          .mixed()
          .fileAccepts(
            'components.form.pdfWordRequired',
            [
              'application/pdf',
              'application/msword',
              'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
            ],
            true,
          ),
    }),
  },
  [['therapeuticObjectives', 'therapeuticObjectivesAttachment']],
);

export type CarePlanData = InferType<typeof carePlanSchema>;
