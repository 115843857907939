import moment from 'moment/moment';
import { StringSchema, addMethod, string } from 'yup';

declare module 'yup' {
  interface StringSchema {
    date(message: string): this;
    minDate(minDate: Date, message: string): this;
    maxDate(maxDate: Date, message: string): this;
  }
}

function date(this: StringSchema, message: string) {
  return this.test('isDate', message, a => !!a && moment(a).isValid());
}

addMethod(string, 'date', date);

function minDate(this: StringSchema, minDate: Date, message: string) {
  return this.test(
    'isAtLeastDate',
    message,
    a => !!a && moment(a).isSameOrAfter(minDate),
  );
}

addMethod(string, 'minDate', minDate);

function maxDate(this: StringSchema, maxDate: Date, message: string) {
  return this.test(
    'isAtMostDate',
    message,
    a => !!a && moment(a).isSameOrBefore(maxDate),
  );
}

addMethod(string, 'maxDate', maxDate);
