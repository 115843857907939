import React from 'react';

import { Contact } from '@/models/ContactModel';
import { ContactForm } from '@/state/contacts/utils';

import ContactPatientFormComponent from './form/ContactPatientFormComponent';
import ContactPatientViewComponent from './view/ContactPatientViewComponent';

type ContactProps = {
  contact: Contact | ContactForm;
  onFinish?: () => void;
  onEdit?: () => void;
  authorizeEditDelete?: boolean;
  addClassNameToForm?: string;
  addClassNameToView?: string;
};

export default function ContactPatients({
  contact,
  onFinish = () => null,
  onEdit = () => null,
  authorizeEditDelete = true,
  addClassNameToForm = '',
  addClassNameToView = '',
}: ContactProps) {
  const [isOnEditMode, setIsOnEditMode] = React.useState(contact.id === '');

  React.useEffect(() => {
    if (!isOnEditMode) {
      onFinish();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOnEditMode]);

  const onCreate = () => {
    setIsOnEditMode(false);
  };

  const cancelEdition = () => {
    setIsOnEditMode(false);
  };

  return isOnEditMode ? (
    <ContactPatientFormComponent
      contact={contact}
      onCreateOrModify={onCreate}
      cancel={cancelEdition}
      addClassName={addClassNameToForm}
    />
  ) : (
    <ContactPatientViewComponent
      contact={contact}
      onEdit={() => {
        onEdit();
        setIsOnEditMode(true);
      }}
      onDelete={onFinish}
      addClassName={addClassNameToView}
      authorizeEditDelete={authorizeEditDelete}
    />
  );
}
