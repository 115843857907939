import React from 'react';

type Props = {
  tag: string; // a bit dangerous: supposed to be a valid html tag, but not enforced.
  className?: string;
  children: React.ReactNode;
  rest?: unknown;
};

type Ref = HTMLElement;

const CustomTag = React.forwardRef<Ref, Props>(function renderDynamicTag(
  { tag: Tag, children, ...rest },
  ref,
) {
  return React.createElement(Tag, { ref, ...rest }, children ? children : null);
});

export default CustomTag;
