import { UserRepository } from '@/io/repository/UserRepository';
import { User } from '@/models/UserModel';
import { createAppQuery, stripQueryResult } from '@/queries/QueryClient';

export class UserQueries {
  constructor(
    private readonly repository: UserRepository = new UserRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useMe = createAppQuery<User>({
    queryKey: 'use-me',
    queryFn: async () => stripQueryResult(await this.repository.getUser()),
  });
}
