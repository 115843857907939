import React, { AllHTMLAttributes, PropsWithChildren } from 'react';

import { css, cx } from '@emotion/css';

import { Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';

export type CardFooterProps = PropsWithChildren<
  {
    className?: string;
  } & AllHTMLAttributes<HTMLDivElement>
>;

export const CardFooter: React.FC<CardFooterProps> = ({
  children,
  className,
  ...props
}) => {
  const styles = useStyles(makeStyles);

  return (
    <Row className={cx(styles.footer, className)} {...props}>
      {children}
    </Row>
  );
};

const makeStyles = (theme: Theme) => ({
  footer: css`
    column-gap: ${theme.card.footer.itemsGap};
    padding: ${theme.card.footer.padding};
    padding-top: ${theme.card.footer.paddingTop};
    border-top: ${theme.card.footer.borderWidth} solid
      ${theme.card.footer.borderColor};
  `,
});
