import { AddPatientInfoData } from '@/components/add-patient/AddPatient.schema';
import { CarePlanData } from '@/components/diabetes-form/CarePlan.schema';
import { ManagePatientRepository } from '@/io/repository/ManagePatientRepository';
import { PractitionerRepository } from '@/io/repository/PractitionerRepository';
import { Patient } from '@/models/PatientModel';
import { PatientWithCare } from '@/models/PatientWithCareModel';
import { Practitioner, UpdatePractitioner } from '@/models/PractitionerModel';
import { ValidateOrRejectIdentity } from '@/models/identity/Identity';
import {
  createAppMutation,
  createAppQuery,
  queryClient,
  stripQueryResult,
} from '@/queries/QueryClient';

export class PractitionerQueries {
  constructor(
    private readonly practitioner: PractitionerRepository = new PractitionerRepository(),
    private readonly managePatient: ManagePatientRepository = new ManagePatientRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  usePractitioner = createAppQuery<Practitioner>({
    queryKey: 'health-pro',
    queryFn: async () =>
      stripQueryResult(await this.practitioner.getPractitioner()),
  });

  usePatientsList = createAppQuery<Patient[]>({
    staleTime: 30_000,
    queryKey: 'practitioner-patients',
    queryFn: async () =>
      stripQueryResult(await this.managePatient.getPatientList()),
  });

  useObservedPatient = createAppQuery<PatientWithCare, string>({
    staleTime: 30_000,
    queryKey: variables => ['observed-patient', variables],
    queryFn: async id =>
      stripQueryResult(await this.managePatient.getPatient(id)),
  });

  /*******************************/
  /********** MUTATIONS **********/
  /*******************************/

  useUpdatePractitioner = createAppMutation<Practitioner, UpdatePractitioner>({
    mutationFn: async data =>
      stripQueryResult(await this.practitioner.updatePractitioner(data)),
    onSuccess: async practitioner => {
      await this.manuallyUpdatePractitioner(() => practitioner);
    },
  });

  useCreatePatient = createAppMutation<
    PatientWithCare,
    AddPatientInfoData & CarePlanData
  >({
    mutationFn: async data =>
      stripQueryResult(await this.managePatient.createPatient(data)),
    onSuccess: async () => {
      await this.invalidatePatientsList();
    },
  });

  useValidateOrRejectIdentity = createAppMutation<
    Patient,
    { patientId: string; action: ValidateOrRejectIdentity }
  >({
    mutationFn: async ({ patientId, action }) =>
      stripQueryResult(
        await this.managePatient.validateOrRejectIdentity(patientId, action),
      ),
  });

  useCreateCarePlan = createAppMutation<
    PatientWithCare,
    { patientId: string } & CarePlanData
  >({
    mutationFn: async ({ patientId, ...diabetesData }) =>
      stripQueryResult(
        await this.managePatient.createCarePlan(patientId, diabetesData),
      ),
    onSuccess: async patient => {
      await Promise.all([
        this.manuallyUpdateObservedPatient(patient.id, () => patient),
        this.invalidatePatientsList(),
      ]);
    },
  });

  useEditCarePlan = createAppMutation<
    PatientWithCare,
    { patientId: string; carePlanId: string } & CarePlanData
  >({
    mutationFn: async ({ patientId, carePlanId, ...diabetesData }) =>
      stripQueryResult(
        await this.managePatient.editCarePlan(
          patientId,
          carePlanId,
          diabetesData,
        ),
      ),
    onSuccess: async patient => {
      await Promise.all([
        this.manuallyUpdateObservedPatient(patient.id, () => patient),
        this.invalidatePatientsList(),
      ]);
    },
  });

  /*******************************/
  /*********** HELPERS ***********/
  /*******************************/

  manuallyUpdatePractitioner = (
    updater: (patient: Practitioner | undefined) => Practitioner | undefined,
  ) => queryClient.setQueryData(this.usePractitioner.getQueryKey(), updater);

  manuallyUpdatePatientsList = (
    updater: (patients: Patient[] | undefined) => Patient[] | undefined,
  ) => queryClient.setQueryData(this.usePatientsList.getQueryKey(), updater);

  invalidatePatientsList = () =>
    queryClient.invalidateQueries({
      queryKey: this.usePatientsList.getQueryKey(),
      refetchType: 'all',
    });

  invalidateObservedPatient = (patientId: string) =>
    queryClient.invalidateQueries({
      queryKey: this.useObservedPatient.getQueryKey(patientId),
    });

  manuallyUpdateObservedPatient = (
    patientId: string,
    updater: (
      patient: PatientWithCare | undefined,
    ) => PatientWithCare | undefined,
  ) =>
    queryClient.setQueryData(
      this.useObservedPatient.getQueryKey(patientId),
      updater,
    );
}
