import { createAsyncThunk } from '@reduxjs/toolkit';

import API from '@/api';
import { Queries } from '@/queries/Queries';

type AsyncThunkBaseParams = {
  token: string;
  id: string;
};

type AsyncThunkDeleteParams = AsyncThunkBaseParams & {
  alertId: number;
};

// anciennement 'patchPatientAlert'
// mais appel un delete donc je l'ai renomme
export const deletePatientAlert = createAsyncThunk(
  'patients/deletePatientAlert',
  async ({ token, id, alertId }: AsyncThunkDeleteParams) => {
    await API(token).delete(`alerts/${id}/${alertId}/`);
    Queries.practitioner.invalidateObservedPatient(id);
    return { patientId: id, alertId };
  },
);
