import moment from 'moment';

import { DiabetesRepository } from '@/io/repository/DiabetesRepository';
import { AGPStats } from '@/models/AGPStatsModel';
import { Dataviz } from '@/models/Dataviz';
import { DiabetesParameters } from '@/models/DiabetesDataModel';
import { GlobalStats } from '@/models/GlobalStatsModel';
import { TargetDurationStats } from '@/models/TargetDurationStatsModel';
import {
  createAppMutation,
  createAppQuery,
  createInfiniteAppQuery,
  queryClient,
  stripQueryResult,
} from '@/queries/QueryClient';

type StatsRequest = { patientId: string; from: string; to: string };

export class DiabetesQueries {
  constructor(
    private readonly diabetes: DiabetesRepository = new DiabetesRepository(),
  ) {}

  /*******************************/
  /*********** QUERIES ***********/
  /*******************************/

  useDataviz = createAppQuery<Dataviz[], StatsRequest>({
    queryKey: ({ patientId, from, to }) => ['diabetes', patientId, from, to],
    queryFn: async ({ patientId, from, to }) =>
      stripQueryResult(await this.diabetes.getDataViz(patientId, from, to)),
    staleTime: 0,
  });

  useInfiniteDataviz = createInfiniteAppQuery<Dataviz[], StatsRequest>({
    queryKey: ({ patientId, from, to }) => [
      'diabetes-data-infinite',
      patientId,
      from,
      to,
    ],
    queryFn: async ({ patientId, from, to }) =>
      stripQueryResult(await this.diabetes.getDataViz(patientId, from, to)),
    getNextPageParam: (lastPage, allPages, lastPageParam) => {
      let diff = Math.abs(
        moment(lastPageParam.from).diff(moment(lastPageParam.to), 'days'),
      );

      if (moment(lastPageParam.to).isBefore(moment(), 'day')) {
        diff += 1;
      }

      return {
        patientId: lastPageParam.patientId,
        from: moment(lastPageParam.from).subtract(diff, 'days').format(),
        to: moment(lastPageParam.from).subtract(1, 'days').format(),
      };
    },
    getPreviousPageParam: (firstPage, allPages, firstPageParam) => {
      if (moment(firstPageParam.to).isSameOrAfter(moment(), 'day')) {
        return undefined;
      }

      const diff =
        Math.abs(
          moment(firstPageParam.from).diff(moment(firstPageParam.to), 'days'),
        ) + 1;

      let newTo = moment(firstPageParam.to).add(diff, 'days').format();
      if (moment(newTo).isSameOrAfter(moment().subtract(1, 'day'), 'day')) {
        newTo = moment().format();
      }

      return {
        patientId: firstPageParam.patientId,
        from: moment(firstPageParam.to).add(1, 'days').format(),
        to: newTo,
      };
    },
    maxPages: 100,
  });

  useParameters = createAppQuery<DiabetesParameters, string>({
    queryKey: patientId => ['diabetes-parameters', patientId],
    queryFn: async patientId =>
      stripQueryResult(await this.diabetes.getParameters(patientId)),
  });

  useTargetDurationStats = createAppQuery<TargetDurationStats, StatsRequest>({
    queryKey: ({ patientId, from, to }) => [
      'target-duration-stats',
      patientId,
      from,
      to,
    ],
    queryFn: async ({ patientId, from, to }) =>
      stripQueryResult(
        await this.diabetes.getTargetDurationStats(patientId, from, to),
      ),
  });

  useGlobalStats = createAppQuery<GlobalStats, StatsRequest>({
    queryKey: ({ patientId, from, to }) => [
      'global-stats',
      patientId,
      from,
      to,
    ],
    queryFn: async ({ patientId, from, to }) =>
      stripQueryResult(await this.diabetes.getGlobalStats(patientId, from, to)),
  });

  useAGPStats = createAppQuery<AGPStats, StatsRequest>({
    queryKey: ({ patientId, from, to }) => ['agp-stats', patientId, from, to],
    queryFn: async ({ patientId, from, to }) =>
      stripQueryResult(await this.diabetes.getAGPStats(patientId, from, to)),
  });

  /*******************************/
  /*********** MUTATIONS *********/
  /*******************************/

  useEditParameters = createAppMutation<DiabetesParameters, DiabetesParameters>(
    {
      mutationFn: async data =>
        stripQueryResult(await this.diabetes.editParameters(data)),
      onSuccess: parameters =>
        this.manuallyUpdateParameters(parameters.id, () => parameters),
    },
  );

  useUploadFile = createAppMutation<
    void,
    { patientId: string; file: File; timezone?: string }
  >({
    mutationFn: async ({ patientId, file, timezone }) =>
      stripQueryResult(
        await this.diabetes.uploadFile(patientId, file, timezone),
      ),
  });

  /*******************************/
  /*********** ACTIONS ***********/
  /*******************************/

  manuallyUpdateParameters = (
    patientId: string,
    updater: (
      patient: DiabetesParameters | undefined,
    ) => DiabetesParameters | undefined,
  ) =>
    queryClient.setQueryData(
      this.useParameters.getQueryKey(patientId),
      updater,
    );
}
