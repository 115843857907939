import {
  DecoderFunction,
  array,
  decodeType,
  string,
} from 'typescript-json-decoder';

import { recordWithContext, stringUnion } from '@/utils/decoderUtils';

export type PhoneType = decodeType<typeof phoneTypeDecoder>;

export const phoneTypeDecoder = stringUnion('mobile', 'landline', 'Portable');

export type Phone = {
  number: string;
  description: PhoneType;
};

export const phoneDecoder: DecoderFunction<Phone> = recordWithContext('Phone', {
  number: string,
  description: phoneTypeDecoder,
});

export const multiplePhoneDecoder: DecoderFunction<Phone | undefined> = (
  value: unknown,
) => {
  const phones = array(phoneDecoder)(value);

  if (phones.length < 1 || !phones[0].number) {
    return undefined;
  } else {
    return phones[0];
  }
};
