import React, { useContext } from 'react';

import { css } from '@emotion/css';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Tooltip } from '@/components/floating/Tooltip';
import { Row } from '@/components/layout/Flex';
import { Typo } from '@/components/typography/Text';
import usePatientIdFromURL from '@/hooks/usePatientIdFromURL';
import { useStyles } from '@/hooks/useTheme';
import { PatientWithCare } from '@/models/PatientWithCareModel';
import { Queries } from '@/queries/Queries';
import { queryClient } from '@/queries/QueryClient';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

import { ChatFilterContext, ChatStatusContext } from '../chat/ChatContext';

export type PatientNavigationIconsProps = {
  patient?: PatientWithCare;
};

export const PatientNavigationIcons: React.FC<PatientNavigationIconsProps> = ({
  patient,
}) => {
  const currentPractitioner = Queries.practitioner.usePractitioner();
  const isNurse = currentPractitioner.data?.qualification === 'nurse';

  return (
    <Row align="center">
      {isNurse && <PhoneIcon patient={patient} />}
      <ChatIcon />
    </Row>
  );
};

const PhoneIcon: React.FC<PatientNavigationIconsProps> = ({ patient }) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);

  return (
    <Tooltip
      className={styles.container}
      floaterClassName={styles.phoneTooltip}
      content={
        patient?.contactPhone ? (
          <Typo
            type="paragraph"
            className={styles.phoneAction}
            Component="a"
            href={`tel://${patient.contactPhone}`}
          >
            {patient.contactPhone}
          </Typo>
        ) : (
          <Typo type="paragraph" className={styles.phoneNone}>
            {t('components.telephone_non_renseigne')}
          </Typo>
        )
      }
      placement="bottom-end"
    >
      <Icons.phoneOutline className={styles.icon} />
    </Tooltip>
  );
};

const ChatIcon: React.FC<PatientNavigationIconsProps> = () => {
  const { setOpen, open } = useContext(ChatStatusContext);
  const patientId = usePatientIdFromURL();
  const patient = Queries.practitioner.useObservedPatient(patientId ?? '');
  const styles = useStyles(makeStyles);
  const selectedFilter = useContext(ChatFilterContext);

  const hasNewMessage = patient.data?.message.count
    ? patient.data?.message.count > 0
    : false;
  return (
    <div className={styles.container}>
      <Icons.letter
        className={styles.letterIcon}
        onClick={() => {
          setOpen?.(!open);
          queryClient.invalidateQueries({
            queryKey: ['chat', patientId, selectedFilter?.filter],
          });
        }}
      />

      {patient && hasNewMessage && (
        <span className={styles.badge}>{patient.data?.message.count}</span>
      )}
    </div>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    margin-left: ${Dimensions.M};
    position: relative;
    padding-bottom: ${Dimensions.XS};
  `,
  icon: css`
    fill: ${theme.typography.secondary};
    width: ${Dimensions.font.XXXL};
    height: ${Dimensions.font.XXXL};
  `,
  bellWrapper: css`
    position: relative;
    cursor: pointer;
  `,
  notificationBubble: css`
    position: absolute;
    border-radius: 100px;
    text-align: center;
    width: ${Dimensions.font.XXXS};
    height: ${Dimensions.font.XXXS};
    transform: translate(100%, -200%);
    background-color: ${theme.typography.error};
    color: ${theme.typography.inverted};
  `,
  phoneTooltip: css`
    padding: 0;
    cursor: pointer;
  `,
  phoneNone: css`
    padding: ${Dimensions.XS} ${Dimensions.S};
  `,
  letterIcon: css`
    cursor: pointer;
    width: ${Dimensions.font.XXXL};
    height: ${Dimensions.font.XXXL};
    stroke: ${theme.typography.secondary};
  `,
  phoneAction: css`
    display: flex;
    padding: ${Dimensions.XS} ${Dimensions.S};
    cursor: pointer;
    &:hover {
      background-color: ${theme.sideBar.backgroundHover};
    }
  `,
  badge: css`
    /* circle shape, size and position */
    position: absolute;
    right: -0.7em;
    top: -0.7em;
    width: ${Dimensions.S};
    height: ${Dimensions.S};
    border-radius: 0.8em; /* or 50%, explained below. */
    border: 0.05em solid red;
    background-color: red;

    /* number size and position */
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.7em;
    color: white;
  `,
});
