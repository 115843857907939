import React, { useEffect } from 'react';

import { css } from '@emotion/css';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { NewForm } from '@/components/form/NewForm';
import { Row } from '@/components/layout/Flex';
import { useStyles } from '@/hooks/useTheme';
import { CarePlan } from '@/models/CarePlanModel';
import { PatientWithCare } from '@/models/PatientWithCareModel';
import {
  DiabetesTypeInfo,
  InsulinSchemeInfo,
} from '@/pages/patient-settings/CurrentCarePlan';
import { Dimensions } from '@/theme/dimensions';

export type TelemonitoringFormProps = {
  patient: PatientWithCare;
};

export const DiabetesInfo: React.FC<TelemonitoringFormProps> = ({
  patient,
}) => {
  const { t } = useTranslation();
  const styles = useStyles(makeStyles);
  const currentCarePlan =
    patient.currentCarePlan ??
    (patient.carePlans.length > 0 ? patient.carePlans[0] : undefined);
  const fakeForm = useForm<CarePlan>();
  useEffect(() => {
    if (currentCarePlan) {
      fakeForm.setValue('diabetesType', currentCarePlan.diabetesType);
      fakeForm.setValue('insulinTreatment', currentCarePlan.insulinTreatment);
      fakeForm.setValue(
        'telemonitoringCriteria',
        currentCarePlan.telemonitoringCriteria,
      );
    }
  }, [fakeForm, currentCarePlan]);

  return (
    <Card className={styles.container} elevation={0}>
      <TextCardTitle id="tls-title" title={t('pages.diabetes')} />
      <CardBody className={styles.body}>
        <NewForm
          id="FAKE_DIABETES_FORM"
          formContext={fakeForm}
          onSubmit={() => {
            // Empty
          }}
        >
          <Row className={styles.row}>
            <DiabetesTypeInfo carePlan={currentCarePlan} />
            <InsulinSchemeInfo carePlan={currentCarePlan} />
          </Row>
        </NewForm>
      </CardBody>
    </Card>
  );
};

const makeStyles = () => ({
  container: css`
    margin-top: ${Dimensions.M};
  `,
  body: css``,
  footer: css`
    justify-content: flex-end;
    gap: ${Dimensions.M};
  `,
  checkbox: css`
    margin-right: ${Dimensions.XS};
  `,
  row: css`
    margin-bottom: ${Dimensions.S};
    width: 100%;
  `,
  labelStyle: css`
    font-size: ${Dimensions.font.XS};
  `,
});
