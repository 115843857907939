import React, { PropsWithChildren } from 'react';

import { css } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Colors } from '@/theme/colors';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

export type SidePeekBodyProps = PropsWithChildren<{
  className?: string;
}>;

export const SidePeekBody: React.FC<SidePeekBodyProps> = ({ children }) => {
  const styles = useStyles(makeStyles);
  return <div className={styles.container}>{children}</div>;
};

const makeStyles = (theme: Theme) => ({
  container: css`
    overscroll-behavior: contain;
    position: relative;
    height: 70vh;
    width: 100%;
    padding: ${theme.sideSheet.contentVPadding}
      ${theme.sideSheet.contentHPadding};
    overflow-y: scroll;

    /* Styles pour les navigateurs basés sur WebKit */
    &::-webkit-scrollbar {
      width: ${Dimensions.XS};
    }

    &::-webkit-scrollbar-track {
      display: None;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${Colors.gray[600]};
      border-radius: ${Dimensions.XS};
    }
  `,
});
