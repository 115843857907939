import React, { ReactNode } from 'react';

import './ButtonWithIconComponent.scss';

type ButtonWithIconProps = {
  text?: string;
  iconSrc?: ReactNode;
  classNameIcon?: string;
  labelClassName?: string;
  className?: string;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function ButtonWithIcon({
  text = '',
  iconSrc: IconSrc,
  classNameIcon = '',
  labelClassName = '',
  className = '',
  ...buttonExtraProps
}: ButtonWithIconProps) {
  return (
    <button
      className={`BackgroundButtonWithIcon ${className}`}
      {...buttonExtraProps}
    >
      <label className={`labelButtonButtonWithIcon ${labelClassName}`}>
        <div className={`user-plusButtonWithIcon ${classNameIcon}`}>
          {IconSrc}
        </div>
        {text}
      </label>
    </button>
  );
}
