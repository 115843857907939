import React, { forwardRef, useMemo } from 'react';

import { css, cx } from '@emotion/css';

import { FontsList } from '@/assets/fonts';
import { useTheme } from '@/hooks/useTheme';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';
import { TextHTMLTags } from '@/utils/HTMLTags';

type FontWeight =
  | 'normal'
  | 'bold'
  | 100
  | 200
  | 300
  | 400
  | 500
  | 600
  | 700
  | 800
  | 900;

export type TextType =
  | 'title'
  | 'subtitle'
  | 'standout'
  | 'paragraphLarge'
  | 'paragraph'
  | 'paragraphSmall'
  | 'supplement'
  | 'emphasis'
  | 'error'
  | 'warning'
  | 'button'
  | 'buttonTag'
  | 'radio'
  | 'bubble'
  | 'tag'
  | 'novalue'
  | 'legend';

type TextStyleDefinition = (
  theme: Theme,
  fontWeight?: FontWeight,
  italic?: boolean,
) => string;

type TypoProps<Tag extends keyof TextHTMLTags> = TextHTMLTags[Tag][0] & {
  type: TextType;
  weight?: FontWeight;
  italic?: boolean;
  htmlRef?: React.ForwardedRef<TextHTMLTags[Tag][1]>;
  Component?: Tag;
};

export const Typo = <Tag extends keyof TextHTMLTags = 'p'>({
  type,
  weight,
  italic,
  Component,
  className,
  htmlRef,
  ...props
}: TypoProps<Tag>): React.ReactElement => {
  const theme = useTheme();
  const typeStyle = useMemo(
    () => textStyleDefinitions[type](theme, weight, italic),
    [type, theme, weight, italic],
  );

  const _Typo = useMemo(
    () =>
      forwardRef<TextHTMLTags[Tag][1], {}>(function _Typo({}, ref) {
        const Comp = Component ?? 'p';
        return (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          <Comp ref={ref} className={cx(typeStyle, className)} {...props} />
        );
      }),
    [Component, typeStyle, className, props],
  );

  return <_Typo ref={htmlRef} />;
};

/************************
 *        Styles        *
 ************************/

const textStyleDefinitions = {
  title: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.title};
    font-size: ${Dimensions.font.XXXL};
    font-weight: ${fontWeight ?? 'bold'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  subtitle: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.primary};
    font-size: ${Dimensions.font.L};
    font-weight: ${fontWeight ?? 'bold'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  standout: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.primary};
    font-size: ${Dimensions.font.XXL};
    font-weight: ${fontWeight ?? 'bold'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  paragraphLarge: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.primary};
    font-size: ${Dimensions.font.M};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  paragraph: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.primary};
    font-size: ${Dimensions.font.S};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  paragraphSmall: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.primary};
    font-size: ${Dimensions.font.XS};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  emphasis: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.primary};
    font-size: ${Dimensions.font.M};
    font-weight: ${fontWeight ?? 'bold'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  supplement: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.light};
    font-size: ${Dimensions.font.XXS};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  error: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.error};
    font-size: ${Dimensions.font.M};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  warning: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.warning};
    font-size: ${Dimensions.font.M};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  button: () => css`
    font-family: ${FontsList.NunitoSans};
    font-size: ${Dimensions.font.M};
  `,
  buttonTag: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    font-size: ${Dimensions.font.XS};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  radio: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.light};
    font-size: ${Dimensions.font.XXXS};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  bubble: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.light};
    font-size: ${Dimensions.font.UUUS};
    font-weight: ${fontWeight ?? 'light'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  tag: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.light};
    font-size: ${Dimensions.font.US};
    font-weight: ${fontWeight ?? 'light'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
  novalue: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.secondary};
    font-size: ${Dimensions.font.S};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ?? 'italic'};
  `,
  legend: (theme, fontWeight, italic) => css`
    font-family: ${FontsList.NunitoSans};
    color: ${theme.typography.light};
    font-size: ${Dimensions.font.US};
    font-weight: ${fontWeight ?? 'normal'};
    font-style: ${italic ? 'italic' : 'normal'};
  `,
} satisfies Record<TextType, TextStyleDefinition>;
