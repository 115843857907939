import React, { useState } from 'react';

import { useTranslation } from 'react-i18next';

import { Card } from '@/components/card/Card';
import { PageContainer } from '@/pages/PageContainer';
import { HealthProLayout } from '@/pages/layout/HealthProLayout';
import { Queries } from '@/queries/Queries';

import { AddPatient } from './AddPatient';
import { PatientsSearch } from './PatientsSearch';
import { PatientsTable } from './PatientsTable';
import { ValidateIdentities } from './ValidateIdentities';

export const PatientsPage: React.FC = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState<string>('');
  const practitioner = Queries.practitioner.usePractitioner();

  return (
    <HealthProLayout>
      <PageContainer title={t('pages.navbar.mes_patients')}>
        <Card elevation={0}>
          {practitioner.data ? (
            <>
              <PatientsSearch search={search} setSearch={setSearch} />
              <PatientsTable search={search} />
              <AddPatient practitioner={practitioner.data} />
              <ValidateIdentities practitioner={practitioner.data} />
            </>
          ) : null}
        </Card>
      </PageContainer>
    </HealthProLayout>
  );
};
