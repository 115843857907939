import React from 'react';

import { css, keyframes } from '@emotion/css';

import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';
import { ThemeDim } from '@/theme/types';

export type SpinnerProps = {
  foregroundColor: string;
  backgroundColor: string;
  height: ThemeDim;
};

export const Spinner: React.FC<SpinnerProps> = ({
  foregroundColor,
  backgroundColor,
  height,
}) => {
  const styles = useStyles(
    makeStyles,
    foregroundColor,
    backgroundColor,
    height,
  );

  return <div className={styles.container}></div>;
};

const makeStyles = (
  theme: Theme,
  foregroundColor: string,
  backgroundColor: string,
  height: ThemeDim,
) => ({
  container: css`
    border: calc(${height} / 6) solid ${backgroundColor};
    border-top: calc(${height} / 6) solid ${foregroundColor};
    border-radius: 50%;
    width: ${height};
    height: ${height};
    animation: ${spin} 1s linear infinite;
  `,
});

const spin = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;
