import React from 'react';

import { ReactComponent as AlertCircle } from '@/assets/icons/alert-circle.svg';
import { ReactComponent as AlertTriangle } from '@/assets/icons/alert-triangle.svg';
import { FlexBox } from '@/components/box';
import { AlertCriticity } from '@/models/AlertModel';
import { AlertCriticityChoices } from '@/state/alert-configs/types';

const { CRITICITY_LOW, CRITICITY_MEDIUM, CRITICITY_HIGH } =
  AlertCriticityChoices;

type IconShape = {
  height?: number;
  width?: number;
};
type IconNotificationProps = {
  criticity?: AlertCriticity;
} & IconShape;

export default function IconNotification({
  criticity,
  height = 8,
  width = 8,
}: IconNotificationProps) {
  switch (criticity) {
    case CRITICITY_LOW:
      return <LowCriticityIcon height={height} width={width} />;
    case CRITICITY_MEDIUM:
      return <MediumCriticityIcon height={height} width={width} />;
    case CRITICITY_HIGH:
      return <HighCriticityIcon height={height} width={width} />;
    default:
      return (
        <IconNotification
          criticity={CRITICITY_LOW}
          height={height}
          width={width}
        />
      );
  }
}

const LowCriticityIcon = ({ height, width }: IconShape) => (
  <FlexBox
    className={`justify-center items-center bg-criticity-low bg-opacity-25 rounded-full h-${height} w-${width}`}
  >
    <AlertCircle className={`fill-current text-criticity-low`} />
  </FlexBox>
);

const MediumCriticityIcon = ({ height, width }: IconShape) => (
  <FlexBox
    className={`justify-center items-center bg-criticity-high bg-opacity-25 rounded-full h-${height} w-${width}`}
  >
    <AlertTriangle className={`fill-current text-criticity-high`} />
  </FlexBox>
);

const HighCriticityIcon = ({ height, width }: IconShape) => (
  <FlexBox
    className={`justify-center items-center bg-criticity-high rounded-full h-${height} w-${width}`}
  >
    <AlertTriangle className={`fill-current text-white`} />
  </FlexBox>
);
