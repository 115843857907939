import React from 'react';

import { css } from '@emotion/css';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Icons } from '@/assets/icons';
import { Button } from '@/components/button/Button';
import { Card } from '@/components/card/Card';
import { CardBody } from '@/components/card/CardBody';
import { TextCardTitle } from '@/components/card/CardTitle';
import { ShowApiError } from '@/components/error/ShowApiError';
import { NewForm } from '@/components/form/NewForm';
import { FormInput } from '@/components/form/inputs/FormInput';
import { SelectFormInput } from '@/components/form/inputs/SelectFormInput';
import { Col, Row } from '@/components/layout/Flex';
import { Loader } from '@/components/loading/Loader';
import { Typo } from '@/components/typography/Text';
import { useStyles } from '@/hooks/useTheme';
import { Practitioner } from '@/models/PractitionerModel';
import {
  ProfessionsTranslations,
  professionsList,
} from '@/models/ProfessionModel';
import {
  EditPractitionerData,
  editPractitionerSchema,
} from '@/pages/account/EditPractitioner.schema';
import { Queries } from '@/queries/Queries';
import { Dimensions } from '@/theme/dimensions';
import { Theme } from '@/theme/theme';

export const GeneralInformation: React.FC = () => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Card className={styles.container} elevation={0}>
      <TextCardTitle
        id="security-title"
        title={t('pages.informations_generales')}
      />
      <CardBody>
        <GeneralInformationContent />
      </CardBody>
    </Card>
  );
};

export const GeneralInformationContent: React.FC = () => {
  const practitioner = Queries.practitioner.usePractitioner();
  const [editing, setEditing] = React.useState(false);
  const { t } = useTranslation();

  switch (practitioner.status) {
    case 'success': {
      switch (editing) {
        case true:
          return (
            <InformationEdit
              practitioner={practitioner.data}
              toggleEditing={() => setEditing(state => !state)}
            />
          );
        case false:
          return (
            <InformationShow
              practitioner={practitioner.data}
              toggleEditing={() => setEditing(state => !state)}
            />
          );
      }
    }
    case 'pending':
      return <Loader size="S" />;
    case 'error':
      return <Typo type="error">{t('errors.unknown_error')}</Typo>;
  }
};

type InformationProps = {
  practitioner: Practitioner;
  toggleEditing: () => void;
};

const InformationShow: React.FC<InformationProps> = ({
  practitioner,
  toggleEditing,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();

  return (
    <Row align="center" className={styles.infoDisplay}>
      <Col>
        <Typo type="paragraphLarge">
          {`${practitioner?.givenName ?? ''} ${
            practitioner?.familyName ?? ''
          }`.trim()}
        </Typo>
        <Typo type="supplement">{practitioner.user.email}</Typo>
        <Typo type="supplement">
          {t(ProfessionsTranslations[practitioner.qualification])}
        </Typo>
      </Col>
      <Icons.edit className={styles.editIcon} onClick={toggleEditing} />
    </Row>
  );
};

const FORM_ID = 'PractitionerInformationEditForm';

const InformationEdit: React.FC<InformationProps> = ({
  practitioner,
  toggleEditing,
}) => {
  const styles = useStyles(makeStyles);
  const { t } = useTranslation();
  const formContext = useForm<EditPractitionerData>({
    defaultValues: {
      givenName: practitioner.givenName,
      familyName: practitioner.familyName,
      qualification: practitioner.qualification,
    },
    resolver: yupResolver(editPractitionerSchema),
  });
  const updatePractitioner = Queries.practitioner.useUpdatePractitioner();
  const onSubmit = (data: EditPractitionerData) => {
    updatePractitioner.mutate(data, {
      onSettled: (_, error) => error || toggleEditing(),
    });
  };

  return (
    <NewForm id={FORM_ID} onSubmit={onSubmit} formContext={formContext}>
      <Col>
        <Row justify="space-between" className={styles.row}>
          <FormInput<EditPractitionerData>
            type="text"
            name="givenName"
            label={t('common.prenom')}
            required
          />
          <FormInput<EditPractitionerData>
            type="text"
            name="familyName"
            label={t('common.nom')}
            required
          />
        </Row>
        <SelectFormInput<EditPractitionerData>
          type=""
          name="qualification"
          options={professionsList.map(profession => ({
            value: profession,
            label: t(ProfessionsTranslations[profession]),
          }))}
          label={t('common.profession')}
          required
        />
      </Col>
      <Row className={styles.row}>
        <Button
          key="cancel"
          buttonType="secondary"
          disabled={updatePractitioner.isPending}
          onClick={close}
        >
          {t('common.annuler')}
        </Button>
        <Button
          status={updatePractitioner.status}
          key="submit"
          buttonType="primary"
          type="submit"
          form={FORM_ID}
        >
          {t('common.valider')}
        </Button>
      </Row>
      <ShowApiError
        errorMapping={{}}
        error={updatePractitioner.error?.error}
        className={styles.error}
      />
    </NewForm>
  );
};

const makeStyles = (theme: Theme) => ({
  container: css`
    margin-top: ${Dimensions.M};
  `,
  infoDisplay: css`
    column-gap: ${Dimensions.M};
  `,
  editIcon: css`
    background-color: ${theme.button.primary.background};
    fill: ${theme.button.primary.text};
    width: ${Dimensions.M};
    height: ${Dimensions.M};
    padding: ${Dimensions.XXS};
    border-radius: ${Dimensions.M};
    cursor: pointer;
    &:hover {
      background-color: ${theme.button.primary.backgroundHover};
    }
  `,
  row: css`
    column-gap: ${Dimensions.S};
  `,
  error: css`
    margin-top: ${Dimensions.S};
  `,
});
