import { AddPatientInfoData } from '@/components/add-patient/AddPatient.schema';
import { CarePlanData } from '@/components/diabetes-form/CarePlan.schema';
import {
  ApiDataSourceSpec,
  apiDataSource,
} from '@/io/datasource/ApiDatasource';
import { ApiErrorResponse } from '@/models/ApiErrorResponse';
import { Patient, patientDecoder } from '@/models/PatientModel';
import {
  PatientWithCare,
  patientWithCareDecoder,
} from '@/models/PatientWithCareModel';
import { ValidateOrRejectIdentity } from '@/models/identity/Identity';
import { Result } from '@/utils/Result';
import { forcedArray } from '@/utils/decoderUtils';

/**
 * For health pro only
 */
export class ManagePatientRepository {
  constructor(private readonly datasource: ApiDataSourceSpec = apiDataSource) {}

  createPatient = (
    data: AddPatientInfoData & CarePlanData,
  ): Promise<Result<PatientWithCare, ApiErrorResponse>> => {
    return this.datasource.post(
      `patients/create/`,
      patientWithCareDecoder,
      this._prepareCarePlanData(data),
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      },
    );
  };

  getPatientList = (): Promise<Result<Patient[], ApiErrorResponse>> => {
    return this.datasource.get(
      `practitioners/patients/`,
      forcedArray(patientDecoder),
    );
  };

  getPatient = (
    id: string,
  ): Promise<Result<PatientWithCare, ApiErrorResponse>> => {
    return this.datasource.get(
      `practitioners/patients/${id}/`,
      patientWithCareDecoder,
    );
  };

  validateOrRejectIdentity = (
    patientId: string,
    action: ValidateOrRejectIdentity,
  ): Promise<Result<Patient, ApiErrorResponse>> => {
    return this.datasource.post(
      `practitioners/patients/${patientId}/validate-identity/${action}/`,
      patientDecoder,
    );
  };

  _prepareCarePlanData = (data: CarePlanData) => {
    const {
      prescription,
      durationMonths,
      therapeuticObjectivesAttachment,
      ...restData
    } = data;
    const formData = new FormData();
    for (const itemIndex in restData) {
      const itemData = restData[itemIndex as keyof typeof restData];
      if (itemData) {
        formData.append(itemIndex, itemData);
      }
    }
    formData.append('durationMonths', durationMonths.toFixed(0));
    if (prescription.file) {
      formData.append('prescription', prescription.file);
    }
    if (
      therapeuticObjectivesAttachment &&
      therapeuticObjectivesAttachment.file
    ) {
      formData.append(
        'therapeuticObjectivesAttachment',
        therapeuticObjectivesAttachment.file,
      );
    }
    return formData;
  };

  createCarePlan = async (
    patientId: string,
    data: CarePlanData,
  ): Promise<Result<PatientWithCare, ApiErrorResponse>> => {
    return this.datasource.post(
      `care-plans/${patientId}/`,
      patientWithCareDecoder,
      this._prepareCarePlanData(data),
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      },
    );
  };

  editCarePlan = async (
    patientId: string,
    carePlanId: string,
    data: CarePlanData,
  ): Promise<Result<PatientWithCare, ApiErrorResponse>> => {
    return this.datasource.patch(
      `care-plans/${patientId}/${carePlanId}/`,
      patientWithCareDecoder,
      this._prepareCarePlanData(data),
      {
        headers: {
          'Content-type': 'multipart/form-data',
        },
      },
    );
  };
}
