import React from 'react';

import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Flag } from '@/assets/icons/evenement.svg';
import { ReactComponent as Injection } from '@/assets/icons/injection.svg';
import { ReactComponent as InjectionLente } from '@/assets/icons/injectionLente.svg';
import { ReactComponent as Repas } from '@/assets/icons/repas.svg';
import {
  Badge,
  badgeWidth,
  badgeWidthSmall,
} from '@/components/data-representations/DailyGraph';
import { Insulin, ReportCategory } from '@/models/DiabetesDataModel';
import {
  FoodDataPoint,
  InsulinContext,
  MealSize,
  ReportsDataPoint,
} from '@/state/diabetes/types';
import { reportCategories } from '@/state/diabetes/utils';
import { findOverlap, range } from '@/utils';
import { toFixedIfNotZero } from '@/utils/math';

import {
  FoodsWithoutOverlaps,
  HandleMouseEnterProps,
  InsulinWithoutOverlaps,
  ReportsWithoutOverlaps,
  ScaleLinear,
} from './types';

type BaseElementProps = {
  xScale: ScaleLinear<number, number, never>;
  getHour: (date: string | moment.Moment) => number;
  top: number;
  handleMouseEnter: (props: HandleMouseEnterProps) => void;
  handleMouseLeave: () => void;
};

type ReportsElementsProps = BaseElementProps & {
  reports: ReportsDataPoint[];
};

export const ReportsElements = ({
  reports = [],
  xScale,
  getHour,
  top,
  handleMouseEnter,
  handleMouseLeave,
}: ReportsElementsProps) => {
  const { t } = useTranslation();

  const small = true; // small width for badge

  let reportsWithoutOverlaps: ReportsWithoutOverlaps[] = reports || [];

  //find overlap and create Element with multiple reports
  //find overlap index
  const overlaps = findOverlap(
    reportsWithoutOverlaps.map(report => ({
      position: xScale(getHour(report.date ?? '')),
      size: small ? badgeWidthSmall : badgeWidth,
    })),
  );

  //get no overlap report
  reportsWithoutOverlaps = reportsWithoutOverlaps.filter(
    (_, i) => !overlaps.flat().includes(i),
  );
  //create element that contains multiples reports (number is set)
  overlaps.forEach(overlapGroup => {
    const reportsGroup = overlapGroup.map(reportIndex => reports[reportIndex]);
    reportsWithoutOverlaps.push({
      number: reportsGroup.length,
      categories: reportsGroup.map(report => report.category),
      messages: reportsGroup.map(report => report.message),
      dates: reportsGroup.map(report => report.date),
      date: moment(
        reportsGroup
          .map(report => moment(report.date))
          .reduce((sum, date) => sum + date.valueOf(), 0) / reportsGroup.length, // average of overlap date
      ).toString(),
    });
  });

  return (
    <>
      {reportsWithoutOverlaps.map((report, i) => {
        const cx = xScale(getHour(report.date ?? ''));
        const dataTooltip = ({
          reportCategory,
          message,
          date,
        }: Partial<{
          reportCategory: ReportCategory;
          message: string;
          date: string;
        }>) => ({
          x: cx,
          y: top,
          dataInputMethod: 'Saisie',
          title: t(reportCategories[reportCategory ?? 'other']),
          content: `"${message}"`,
          date,
        });

        return (
          <Badge
            key={i}
            text={''}
            top={top}
            left={cx}
            Icon={Flag}
            small={small}
            number={report.number}
            onMouseEnter={() => {
              //if element contains multiple reports
              if (
                report.number !== undefined &&
                Number.isInteger(report.number)
              ) {
                return handleMouseEnter({
                  data: range(report.number ?? 0).map(reportNum =>
                    dataTooltip({
                      reportCategory: report.categories?.[reportNum],
                      message: report.messages?.[reportNum],
                      date: report.dates?.[reportNum],
                    }),
                  ),
                });
              }
              //if element not contains multiple reports
              return handleMouseEnter({
                ...dataTooltip({
                  reportCategory: report.category,
                  message: report.message,
                  date: report.date,
                }),
                data: null,
              });
            }}
            onMouseLeave={handleMouseLeave}
          />
        );
      })}
    </>
  );
};

type FoodElementsProps = BaseElementProps & {
  food: FoodDataPoint[];
};

export const FoodElements = ({
  food: foods,
  xScale,
  getHour,
  top,
  handleMouseEnter,
  handleMouseLeave,
}: FoodElementsProps) => {
  const { t } = useTranslation();

  const small = true;

  let foodWithoutOverlaps: FoodsWithoutOverlaps[] = foods || [];

  //find overlap and create Element with multiple foods
  //find overlap index
  const overlaps = findOverlap(
    foodWithoutOverlaps.map(food => ({
      position: xScale(getHour(food.date ?? '')),
      size: small ? badgeWidthSmall : badgeWidth,
    })),
  );

  //get no overlap report
  foodWithoutOverlaps = foodWithoutOverlaps.filter(
    (_, i) => !overlaps.flat().includes(i),
  );
  //create element that contains multiples reports (number is set)
  overlaps.forEach(overlapGroup => {
    const foodsGroup = overlapGroup.map(foodIndex => foods[foodIndex]);
    foodWithoutOverlaps.push({
      number: foodsGroup.length,
      sizes: foodsGroup.map(food => food.size),
      dates: foodsGroup.map(food => food.date),
      date: moment(
        foodsGroup
          .map(food => moment(food.date))
          .reduce((sum, date) => sum + date.valueOf(), 0) / foodsGroup.length, // average of overlap date
      ).toString(),
    });
  });

  return (
    <>
      {foodWithoutOverlaps.map((food, i) => {
        const cx = xScale(getHour(food.date ?? ''));
        const dataTooltip = ({
          size,
          date,
        }: Partial<{ size: MealSize; date: string }>) => ({
          x: cx,
          y: top,
          title: size === 'big' ? 'Consistant' : 'Léger',
          content: t('common.glucide', 'Glucide'),
          date,
          dataInputMethod: 'Saisie',
        });
        return (
          <Badge
            key={i}
            text={''}
            top={top}
            left={cx}
            Icon={Repas}
            small={small}
            number={food.number}
            numberCircleColor="#d9f7f6"
            numberTextColor="#00b271"
            backgroundColor="#d9f7f6"
            colorText="#00b271"
            onMouseEnter={() => {
              //if element contains multiple foods
              if (food.number !== undefined && Number.isInteger(food.number)) {
                return handleMouseEnter({
                  data: range(food.number ?? 0).map(foodNum =>
                    dataTooltip({
                      size: food.sizes?.[foodNum],
                      date: food.dates?.[foodNum],
                    }),
                  ),
                });
              }

              handleMouseEnter({
                ...dataTooltip({
                  size: food.size,
                  date: food.date,
                }),
                data: null,
              });
            }}
            onMouseLeave={handleMouseLeave}
          />
        );
      })}
    </>
  );
};

type InsulinProps = BaseElementProps & {
  insulin: Insulin[];
};

type InsulinShortProps = InsulinProps & {
  title?: string;
};

const getInsulinContext = (context?: InsulinContext): string => {
  return context ? `components.daily_graph.${context}` : '';
};

const InsulinShort = ({
  insulin: insulins,
  xScale,
  getHour,
  top,
  handleMouseEnter,
  handleMouseLeave,
}: InsulinShortProps) => {
  const { t } = useTranslation();

  let insulinWithoutOverlaps: InsulinWithoutOverlaps[] = insulins || [];

  //find overlap and create Element with multiple foods
  //find overlap index
  const overlaps = findOverlap(
    insulinWithoutOverlaps.map(insulin => ({
      position: xScale(getHour(insulin.date ?? '')),
      size: badgeWidth,
    })),
  );

  //get no overlap report
  insulinWithoutOverlaps = insulinWithoutOverlaps.filter(
    (_, i) => !overlaps.flat().includes(i),
  );
  //create element that contains multiples reports (number is set)
  overlaps.forEach(overlapGroup => {
    const insulinsGroup = overlapGroup.map(
      insulinIndex => insulins[insulinIndex],
    );
    insulinWithoutOverlaps.push({
      number: insulinsGroup.length,
      quantities: insulinsGroup.map(insulin => insulin.quantity),
      dates: insulinsGroup.map(insulin => insulin.date),
      date: moment(
        insulinsGroup
          .map(insulin => moment(insulin.date))
          .reduce((sum, date) => sum + date.valueOf(), 0) /
          insulinsGroup.length, // average of overlap date
      ).toString(),
    });
  });

  return (
    <>
      {insulinWithoutOverlaps.map((insulin, i) => {
        const cx = xScale(getHour(insulin.date ?? ''));
        const dataTooltip = ({
          quantity,
          date,
        }: {
          quantity: number;
          date: string;
        }) => ({
          x: cx,
          y: top,
          title: `${quantity} U`,
          content: `${t(
            'pages.patient_monitoring.insulin.insuline_rapide',
          )}\n${t(getInsulinContext(insulin.context))}`,
          date,
          dataInputMethod: 'Saisie',
        });
        return (
          <Badge
            key={i}
            text={`${
              insulin.number != undefined && Number.isInteger(insulin.number)
                ? toFixedIfNotZero(
                    (insulin.quantities ?? []).reduce((sum, nb) => sum + nb),
                  )
                : toFixedIfNotZero(insulin.quantity ?? 0)
            } U`}
            top={top}
            left={cx}
            Icon={Injection}
            backgroundColor="#dde5ff"
            colorText="#174eff"
            number={insulin.number}
            numberCircleColor="#dde5ff"
            numberTextColor="#174eff"
            onMouseEnter={() => {
              //if element contains multiple insulins
              if (
                insulin.number != undefined &&
                Number.isInteger(insulin.number)
              ) {
                return handleMouseEnter({
                  data: range(insulin.number ?? 0).map(insulinNum =>
                    dataTooltip({
                      quantity: insulin.quantities?.[insulinNum] ?? 0,
                      date: insulin.dates?.[insulinNum] ?? '',
                    }),
                  ),
                });
              }

              handleMouseEnter({
                ...dataTooltip({
                  quantity: insulin.quantity ?? 0,
                  date: insulin.date ?? '',
                }),
                data: null,
              });
            }}
            onMouseLeave={handleMouseLeave}
          />
        );
      })}
    </>
  );
};

type InsulinBasalProps = Omit<InsulinBasalBolusElementsProps, 'topBolus'>;

const InsulinBasal = ({
  insulin: insulins,
  xScale,
  getHour,
  handleMouseEnter,
  handleMouseLeave,
  topBasal,
}: InsulinBasalProps) => {
  const { t } = useTranslation();

  let insulinWithoutOverlaps: InsulinWithoutOverlaps[] = insulins || [];

  //find overlap and create Element with multiple foods
  //find overlap index
  const overlaps = findOverlap(
    insulinWithoutOverlaps.map(insulin => ({
      position: xScale(getHour(insulin.date ?? '')),
      size: badgeWidth,
    })),
  );

  //get no overlap report
  insulinWithoutOverlaps = insulinWithoutOverlaps.filter(
    (_, i) => !overlaps.flat().includes(i),
  );
  //create element that contains multiples reports (number is set)
  overlaps.forEach(overlapGroup => {
    const insulinsGroup = overlapGroup.map(
      insulinIndex => insulins[insulinIndex],
    );
    insulinWithoutOverlaps.push({
      number: insulinsGroup.length,
      quantities: insulinsGroup.map(insulin => insulin.quantity),
      dates: insulinsGroup.map(insulin => insulin.date),
      date: moment(
        insulinsGroup
          .map(insulin => moment(insulin.date))
          .reduce((sum, date) => sum + date.valueOf(), 0) /
          insulinsGroup.length, // average of overlap date
      ).toISOString(),
    });
  });

  return (
    <>
      {insulinWithoutOverlaps.map((insulin, i) => {
        const cx = xScale(getHour(insulin.date ?? ''));
        const dataTooltip = ({
          quantity,
          date,
        }: {
          quantity: number;
          date: string;
        }) => ({
          x: cx,
          y: topBasal,
          title: `${quantity} U`,
          content: `${
            insulin.type == 'long'
              ? t('pages.patient_monitoring.insulin.insuline_lente')
              : t('pages.patient_monitoring.insulin.insuline_rapide')
          }`,
          date,
          dataInputMethod: 'Saisie',
        });
        return (
          <Badge
            key={i}
            text={`${
              insulin.number != undefined && Number.isInteger(insulin.number)
                ? (insulin.quantities ?? []).reduce((sum, nb) => sum + nb)
                : insulin.quantity
            } U`}
            top={topBasal}
            left={cx}
            Icon={InjectionLente}
            backgroundColor="#e8d9e3"
            colorText="#600040"
            number={insulin.number}
            numberCircleColor="#e8d9e3"
            numberTextColor="#600040"
            onMouseEnter={() => {
              //if element contains multiple insulins
              if (
                insulin.number != undefined &&
                Number.isInteger(insulin.number)
              ) {
                return handleMouseEnter({
                  data: range(insulin.number ?? 0).map(insulinNum =>
                    dataTooltip({
                      quantity: insulin.quantities?.[insulinNum] ?? 0,
                      date: insulin.dates?.[insulinNum] ?? '',
                    }),
                  ),
                });
              }

              handleMouseEnter({
                ...dataTooltip({
                  quantity: insulin.quantity ?? 0,
                  date: insulin.date ?? '',
                }),
                data: null,
              });
            }}
            onMouseLeave={handleMouseLeave}
          />
        );
      })}
    </>
  );
};

const InsulinBolus = (props: InsulinShortProps) => <InsulinShort {...props} />;

type InsulinBasalBolusElementsProps = InsulinElementProps;

const InsulinBasalBolusElements = ({
  insulin,
  xScale,
  getHour,
  topBolus,
  topBasal,
  end,
  handleMouseEnter,
  handleMouseLeave,
}: InsulinBasalBolusElementsProps) => {
  const insulinBasal = insulin.filter(e => e.reason === 'basal');
  const insulinBolus = insulin.filter(e => e.reason === 'bolus');
  return (
    <>
      <InsulinBolus
        {...{
          insulin: insulinBolus,
          xScale,
          getHour,
          top: topBolus,
          handleMouseEnter,
          handleMouseLeave,
        }}
      />
      <InsulinBasal
        {...{
          insulin: insulinBasal,
          xScale,
          getHour,
          topBasal,
          end,
          handleMouseEnter,
          handleMouseLeave,
        }}
      />
    </>
  );
};

type InsulinElementProps = Omit<BaseElementProps, 'top'> & {
  insulin: Insulin[];
  topBolus: number;
  topBasal: number;
  end: number;
};

export const InsulinElements = (props: InsulinElementProps) => (
  <InsulinBasalBolusElements {...props} />
);
