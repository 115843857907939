import React, { ReactElement } from 'react';

import { css, cx } from '@emotion/css';

import { Col } from '@/components/layout/Flex';
import { SideSheetActionsProps } from '@/components/sidesheet/SideSheetActions';
import { SideSheetBodyProps } from '@/components/sidesheet/SideSheetBody';
import { SideSheetHeaderProps } from '@/components/sidesheet/SideSheetHeader';
import { useStyles } from '@/hooks/useTheme';
import { Theme } from '@/theme/theme';
import { ThemeDim } from '@/theme/types';
import { ZIndex } from '@/theme/zIndex';

type SideSheetChild =
  | ReactElement<SideSheetHeaderProps>
  | ReactElement<SideSheetBodyProps>
  | ReactElement<SideSheetActionsProps>
  | null;

export type SideSheetProps = {
  width?: ThemeDim;
  className?: string;
  children: SideSheetChild | SideSheetChild[];
};

export const SideSheet: React.FC<SideSheetProps> = ({
  className = '',
  width = '600px',
  children,
}) => {
  const styles = useStyles(makeStyles, width);

  return (
    <Col grow={false} className={cx(styles.content, className)}>
      {children}
    </Col>
  );
};

const makeStyles = (theme: Theme, width: ThemeDim) => ({
  container: css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 50;
  `,
  content: css`
    width: min(${width}, 95vw);
    background-color: ${theme.sideSheet.contentBackground};
    padding: ${theme.sideSheet.contentVPadding}
      ${theme.sideSheet.contentHPadding};
    z-index: ${ZIndex.dialog};
  `,
});
