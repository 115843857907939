import axios from 'axios';

export default function (token: string) {
  return axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      Version: 2, // this is an API-specific value
    },
  });
}
