import React from 'react';

import PropTypes from 'prop-types';

import { FlexBox } from '@/components/box';

export default function ModalWrapper({ children, open, close }) {
  React.useEffect(() => {
    if (open) {
      const onEscape = evt => {
        if (evt.keyCode === 27) {
          close();
        }
      };

      document.addEventListener('keydown', onEscape);

      return function cleanup() {
        document.removeEventListener('keydown', onEscape);
      };
    }
  }, [open, close]);

  return open ? (
    <FlexBox className="modal fixed inset-0 items-center justify-center z-50">
      <div className="absolute inset-0 bg-black opacity-25" onClick={close} />
      <div className="relative">{children}</div>
    </FlexBox>
  ) : null;
}

ModalWrapper.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
};
