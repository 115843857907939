import {
  DecoderFunction,
  boolean,
  field,
  number,
  record,
  string,
} from 'typescript-json-decoder';

import {
  intersectionWithContext,
  recordWithContext,
  stringUnion,
} from '@/utils/decoderUtils';

export type BaseDiabetesDataModel = {
  id: number;
  patientId: string;
  date: string;
};

export type BaseDiabetesDataModelWithDevice = BaseDiabetesDataModel & {
  deviceId: number;
};

export const baseDiabetesDataModelDecoder: DecoderFunction<BaseDiabetesDataModel> =
  recordWithContext('BaseDiabetesDataModel', {
    id: number,
    patientId: field('patient', string),
    date: string,
  });

export const baseDiabetesDataModelWithDeviceDecoder: DecoderFunction<BaseDiabetesDataModelWithDevice> =
  intersectionWithContext(
    'BaseDiabetesDataModelWithDevice',
    baseDiabetesDataModelDecoder,
    record({
      deviceId: field('device', number),
    }),
  );

export type GlycemiaMeasureType = 'interstitial' | 'capillary';
export type GlycemiaPeriod = 'breakfast' | 'lunch' | 'dinner' | 'before_bed';
export type GlycemiaPeriodTiming = 'before' | 'after';

export type Glycemia = {
  type: GlycemiaMeasureType;
  period: GlycemiaPeriod;
  periodTiming: GlycemiaPeriodTiming;
  value: number;
} & BaseDiabetesDataModelWithDevice;

export type Measurement = {
  type: 'Interstitial' | 'Capillary';
  baseTime: string;
  time: string;
  glucoseConcentration: number;
  data: unknown;
};

export type InsulinType = 'long' | 'short';
export type InsulinReason = 'bolus' | 'basal';
export type InsulinPeriod = 'breakfast' | 'lunch' | 'dinner';

export type Insulin = {
  quantity: number;
  type: InsulinType;
  period: InsulinPeriod;
  reason: InsulinReason;
  correction: boolean;
} & BaseDiabetesDataModelWithDevice;

export type FoodSize = 'unknown' | 'light' | 'big';

export type Food = {
  size: FoodSize;
} & BaseDiabetesDataModel;

export type ActivityIntensity = 'low' | 'medium' | 'high';

export type Activity = {
  intensity: ActivityIntensity;
  duration: number;
} & BaseDiabetesDataModel;

export type ReportCategory =
  | 'severe_hypoglycemia'
  | 'perceived_hypoglycemia'
  | 'perceived_hyperglycemia'
  | 'tech_alert'
  | 'other';

export type Report = {
  category: ReportCategory;
  message: string;
} & BaseDiabetesDataModel;

export const glycemiaDecoder: DecoderFunction<Glycemia> =
  intersectionWithContext(
    'Glycemia',
    baseDiabetesDataModelWithDeviceDecoder,
    record({
      type: stringUnion<GlycemiaMeasureType>('interstitial', 'capillary'),
      period: stringUnion<GlycemiaPeriod>(
        'breakfast',
        'lunch',
        'dinner',
        'before_bed',
      ),
      periodTiming: stringUnion<GlycemiaPeriodTiming>('before', 'after'),
      value: number,
    }),
  );

export const insulinDecoder: DecoderFunction<Insulin> = intersectionWithContext(
  'Insulin',
  baseDiabetesDataModelWithDeviceDecoder,
  record({
    quantity: number,
    type: stringUnion<InsulinType>('long', 'short'),
    reason: stringUnion<InsulinReason>('bolus', 'basal'),
    period: stringUnion<InsulinPeriod>('breakfast', 'lunch', 'dinner'),
    correction: boolean,
  }),
);

export const foodDecoder: DecoderFunction<Food> = intersectionWithContext(
  'Food',
  baseDiabetesDataModelDecoder,
  record({
    size: stringUnion<FoodSize>('unknown', 'light', 'big'),
  }),
);

export const activityDecoder: DecoderFunction<Activity> =
  intersectionWithContext(
    'Activity',
    baseDiabetesDataModelDecoder,
    record({
      intensity: stringUnion<ActivityIntensity>('low', 'medium', 'high'),
      duration: number,
    }),
  );

export const reportDecoder: DecoderFunction<Report> = intersectionWithContext(
  'Report',
  baseDiabetesDataModelDecoder,
  record({
    category: stringUnion<ReportCategory>(
      'severe_hypoglycemia',
      'perceived_hypoglycemia',
      'perceived_hyperglycemia',
      'tech_alert',
      'other',
    ),
    message: string,
  }),
);

export type DiabetesParametersType =
  | 'thresholdHypoglycemia'
  | 'thresholdHyperglycemia'
  | 'thresholdHypoglycemiaSevere'
  | 'thresholdHyperglycemiaSevere';

export type DiabetesParameters = {
  id: string;
} & {
  [K in DiabetesParametersType]: number;
};

export const diabetesParametersDecoder: DecoderFunction<DiabetesParameters> =
  recordWithContext('DiabetesParameters', {
    id: field('patient', string),
    thresholdHypoglycemia: field('hypoglycemia', number),
    thresholdHyperglycemia: field('hyperglycemia', number),
    thresholdHypoglycemiaSevere: field('severeHypoglycemia', number),
    thresholdHyperglycemiaSevere: field('severeHyperglycemia', number),
  });
