import React from 'react';

import { useTranslation } from 'react-i18next';

import {
  toast,
  toastError,
  toastInfo,
} from '@/components/toast/ToastComponent';
import { freestylelibre } from '@/connectivity';
import { useApi, usePatientIdFromURL } from '@/hooks';
import { SelfPatient } from '@/models/SelfPatientModel';
import { postFreeStyleLibre } from '@/state/diabetes/thunks';
import { RequestStatus } from '@/state/store';

type HidUploadProps = {
  patient: SelfPatient;
};

export default function HidUpload({ patient }: HidUploadProps) {
  const { t } = useTranslation();
  const patientId = usePatientIdFromURL();
  const { dispatchActionWithAuth } = useApi();
  const [isFslLoading, setIsFslLoading] = React.useState<boolean>(false);

  const handleHidDevice = async (evt: React.ChangeEvent<HTMLFormElement>) => {
    evt.preventDefault();
    setIsFslLoading(true);
    if (!('hid' in navigator)) {
      return toastError(
        t('pages.add_measurement_device.browser_error'),
        toast.POSITION.BOTTOM_RIGHT,
      );
    }
    let fsl;
    try {
      fsl = await freestylelibre(0x1a61, 0x3650);
    } catch (error) {
      setIsFslLoading(false);
      return;
    }
    const { meta } = await dispatchActionWithAuth(postFreeStyleLibre, {
      id: patientId ?? patient.id,
      data: fsl.measurements,
      serial: fsl.serial,
    });
    setIsFslLoading(false);

    switch (meta.requestStatus) {
      case RequestStatus.FULFILLED:
        return toastInfo(
          `${meta.arg.data.length} ${t(
            'pages.add_measurement_device.success',
          )}`,
          toast.POSITION.BOTTOM_RIGHT,
        );
      case RequestStatus.REJECTED:
        return toastError(
          t('pages.add_measurement_device.error'),
          toast.POSITION.BOTTOM_RIGHT,
        );
      default:
        return;
    }
  };

  return (
    <form onSubmit={handleHidDevice}>
      <button
        type="submit"
        disabled={isFslLoading}
        className="button-primary w-36"
      >
        {t(
          isFslLoading
            ? 'common.chargement'
            : 'pages.add_measurement_device.download',
        )}
      </button>
    </form>
  );
}
